import React, { useState, useEffect, } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Top20px from "../screens/Top20px"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ScrollToTop from 'react-scroll-to-top';

import AdminDashboard from '../admin/adminDashboard';
import SDadminHeader from '../admin/SDadminHeader';
import { Box } from '@mui/material';


ChartJS.register(ArcElement, Tooltip, Legend);



const AdminPage = () => {



  const navigate = useNavigate()



  const [chngPwd, SetPwd] = useState(false);
  const [confirmPassword, SetConfirmPassword] = useState({
    // oldPwd: "",
    confirmPwd: "",
    reconfirmPwd: "",
  });



  const pwdhandler = (evt) => {
    SetConfirmPassword({ ...confirmPassword, [evt.target.name]: evt.target.value });
  };

  const clickSubmit = () => {
    if (confirmPassword.confirmPwd !== "") {
      if (confirmPassword.confirmPwd === confirmPassword.reconfirmPwd) {

        const url = `https://backend.shubadinam.com/admin/upt/password`;

        const adminId = sessionStorage.getItem("adminId")

        axios
          .post(url, { ...confirmPassword, adminId: adminId })
          .then((response) => {
            // console.log(response);
            alert(response.data.message)
            // alert("Password updted successfully.");
          })
          .catch((error) => {
            alert(error.response.data.message)
            // console.log(error);
          });

        SetPwd(false);
      } else {
        alert("not equal");
        SetPwd(false);
      }
    } else {
      alert("password field is empty");
      SetPwd(false);
    }

  };

  const clickClose = () => {
    SetPwd(false)
  };

  const handlePopupClick = (e) => {
    // Prevent the click event from reaching the pwd-container
    e.stopPropagation();
  };


  const [userData, setUserData] = useState([]);
  const [newUser, setNewuser] = useState([]);


  useEffect(() => {

    const url = `https://backend.shubadinam.com/admin/analyseusers`

    axios.get(url)
      .then(response => setUserData(response.data))
    // .catch(error => console.error('Error fetching user data:', error));


    const getTodayUsers = `https://backend.shubadinam.com/admin/newusers`;

    axios.get(getTodayUsers)
      .then(response => setNewuser(response.data.setNewuser))
    // .catch(error => console.error('Error fetching user data:', error));



  }, []);



  const [users, setUsers] = useState([]);


  useEffect(() => {
    const url = `https://backend.shubadinam.com/admin/nonpremiumusers`

    axios.get(url)
      .then((response) => {
        // console.log(response.data);
        setUsers(response.data)
      })
      .catch((error) => {
        // console.log(error);
      })
  }, [])

  const togglePassword = () => {
    SetPwd(true)
  }




  return (


    <div className="sd-adminpage">
      <ScrollToTop smooth />
      <SDadminHeader clickPass={togglePassword} />
      <Box sx={{ mt: 15 }}>
        <AdminDashboard />
      </Box>

      {
        chngPwd ?
          <div className="pwd-container" onClick={clickClose}>
            <div className="pwd-popup" onClick={handlePopupClick}>

              <label>Enter your New Password :</label>
              <input type="text" name="confirmPwd" onChange={pwdhandler} />
              <br />
              <label>Reconfirm your Password :</label>
              <input type="text" name="reconfirmPwd" onChange={pwdhandler} />
              <br />
              <button onClick={() => clickSubmit()}>Submit</button>
              <button style={{ marginLeft: "10px", backgroundColor: "#ffd7d7", color: "black" }} onClick={() => clickClose()}>Close</button>

            </div>
          </div>
          :
          <span></span>
      }


    </div>


  )
}



export default AdminPage;