// src/CustomTheme.js
import React, { createContext, useState, useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Create Context for Theme
export const ThemeContext = createContext();

const ThemeProviderWrapper = ({ children }) => {
    const [mode, setMode] = useState("light"); // Default is light mode

    const toggleTheme = () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
    };

    // Define theme configurations
    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode, // Switch between 'light' and 'dark'
                    primary: {
                        main: "#1b3e69",
                    },
                    secondary: {
                        main: "#4b769f",
                    },
                    background: {
                        default: mode === "light" ? "#ffff" : "#121212",
                        paper: mode === "light" ? "#fff" : "#1e1e1e",
                    },
                    text: {
                        primary: mode === "light" ? "#000" : "#fff",
                    },
                },
                components: {
                    MuiInput: {
                        styleOverrides: {
                            root: {
                                // "&:before": {
                                //     borderBottom: "1px solid transparent !important", // Default: invisible border
                                // },
                                "&:hover:before": {
                                    borderBottom: "1px solid #4b769f !important", // No hover effect
                                },
                                "&:after": {
                                    borderBottom: "1.5px solid #1b3e69 !important", // 1.5px solid primary color on focus
                                },
                            },
                        },
                    },
                },



            }),
        [mode]
    );

    return (
        <ThemeContext.Provider value={{ mode, toggleTheme }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export default ThemeProviderWrapper;
