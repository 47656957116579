import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';
import AdminSearch from '../adminUtils/AdminSearch';
import { Chip, Typography } from '@mui/material';

// Pagination Actions Component
function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

// Main Component
export default function NonPremiumUsers() {

    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        allUsers();
    }, []);

    const allUsers = () => {
        const url = `https://backend.shubadinam.com/admin/get/nonpremiumusers`;
        axios.get(url)
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
        setPage(0); // Reset page to 0 when search query changes
    };

    const filteredUsers = users.filter(user =>
        (user.userName && user.userName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.uniqueId && user.uniqueId.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.userEmail && user.userEmail.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.userPhone && user.userPhone.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.entry_time && user.entry_time.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.userCountry && user.userCountry.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.userState && user.userState.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredUsers.length) : 0;

    return (
        <Box>
            <Typography variant='h6' sx={{ textAlign: "center", mt: 2 }}> Non-Premium users </Typography>

            <br />
            <Box sx={{ minWidth: 50, paddingLeft: "20px", display: "flex", flexDirection: "row", justifyContent: "end" }}>
                <AdminSearch onSearch={handleSearch} />
            </Box>
            <br />

            <TableContainer elevation={0} component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead >
                        <TableRow >
                            <TableCell style={{ width: 50, backgroundColor: "#eeee" }}>S.No</TableCell>
                            <TableCell style={{ backgroundColor: "#eeee" }} align="left">Name</TableCell>
                            <TableCell style={{ backgroundColor: "#eeee" }} align="left">ID</TableCell>
                            <TableCell style={{ backgroundColor: "#eeee" }} align="left">Email</TableCell>
                            <TableCell style={{ backgroundColor: "#eeee" }} align="left">Phone</TableCell>
                            <TableCell style={{ backgroundColor: "#eeee" }} align="left">Entry</TableCell>
                            <TableCell style={{ backgroundColor: "#eeee" }} align="left">Location</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.length > 0
                            ? filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => (
                                <TableRow key={index} onClick={() => {
                                    const uniqueId = user.uniqueId;
                                    window.open(`https://www.shubadinam.com/admin-panel/per-customer-detail/?uniqueId=${uniqueId}`, '_blank');
                                }}>
                                    <TableCell component="th" scope="row">
                                        {index + 1 + page * rowsPerPage}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="left">
                                        {user.userName}
                                        <Chip
                                            label={`${user.profile_percent}%`}
                                            size="small"
                                            sx={{
                                                fontWeight: "bold",
                                                backgroundColor: user.profile_percent === "100" ? "green" : "orange",
                                                color: "white",
                                                padding: "5px 10px"
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="left">
                                        {user.uniqueId}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="left">
                                        {user.userEmail}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="left">
                                        {user.userPhone}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="left">
                                        {new Date(user.entry_time).toLocaleString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true,
                                            timeZone: "UTC"
                                        })}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="left">
                                        {user.userCountry}/{user.userState}
                                    </TableCell>
                                </TableRow>
                            ))
                            : null
                        }
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={7} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={7}
                                count={filteredUsers.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                slotProps={{
                                    select: {
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>

        </Box>
    );
}
