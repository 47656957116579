

import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../components/footer";
import SecondDataSharing from "../context/second-data-sharing";
import Button from '@mui/material/Button';

import "bootstrap/dist/css/bootstrap.min.css";

import ScrollToTop from "react-scroll-to-top";
import Top10px from "./Top10px";
import axios from "axios";
import TestimonialScreen from "./testimonials";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import SlidingHeader from "../components/SlidingHeader";


const Features = () => {
    const [data, setData] = useState(null);
    const [daysLeft, setDaysLeft] = useState(0);

    const tableTwoData = useContext(SecondDataSharing);
    // console.log(tableTwoData);

    const Navigate = useNavigate();

    const signOut = () => {
        setSignOut(false);
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("fkey");
        localStorage.removeItem("homeauth")
        Navigate("/");
    };

    const logoBtn = () => {
        Navigate("/");
    };

    // MENU BAR

    const [isOpenMenu, setOpenMenu] = useState(false);

    const [dashsignOut, setSignOut] = useState(false);

    const [selectedPack, setSelectedPack] = useState('SD FREE'); // State to track the selected pack

    const handleSelectPack = (pack) => {
        setSelectedPack(pack);
    };

    const buttonStyleFree = {
        border: selectedPack === 'SD FREE' ? '2px solid black' : 'none',
        color: selectedPack === 'SD FREE' ? '#ffcc75' : '#fff', // Golden color if selected, black otherwise
        // Add other common styles here
    };

    const buttonStylePremium = {
        border: selectedPack === 'SD PREMIUM' ? '2px solid black' : 'none',
        color: selectedPack === 'SD PREMIUM' ? '#ffcc75' : '#fff', // Golden color if selected, black otherwise
    };

    const handleButtonClick = () => {

        let rupees;
        // Access the selectedPack value here
        // console.log('Selected Pack:', selectedPack);

        if (selectedPack === "SD PREMIUM") {
            Navigate("/checkout");
        }

    };

    const [isGreater768, setGreater768] = useState(false);
    const auth = localStorage.getItem("isAuthenticated");
    const [isFreeUser, setFreeUser] = useState(false);

    const [timeLeft, setTimeLeft] = useState("")


    useEffect(() => {

        const secureId = localStorage.getItem("SD_Id");

        if (secureId) {
            const url = `https://backend.shubadinam.com/getpremium?secureId=${secureId}`

            axios.get(url)
                .then((response) => {
                    // console.log(response.data)
                    setTimeLeft(response.data.timeLimit)
                    calculateDaysLeft(response.data.timeLimit)
                    // setFreeUser(false)
                })
                .catch((error) => {
                    console.log(error)
                    // alert("Something unexpected happen.Please try again after sometime !")
                })

        }

        const isFreeRegister = sessionStorage.getItem("isFree")

        if (auth || isFreeRegister) {
            setFreeUser(true)
        }


        const handleResize = () => {
            setGreater768(window.innerWidth > 768);
        };

        // Initial setup
        handleResize();

        // Listen for window resize events
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const calculateDaysLeft = (purchaseDate) => {
        const purchaseDateTime = new Date(purchaseDate);
        const currentDate = new Date();
        const endDate = new Date(purchaseDateTime);
        endDate.setDate(purchaseDateTime.getDate() + 365); // Adding 365 days

        const timeDifference = endDate - currentDate;
        const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
        setDaysLeft(daysLeft);
    };

    const gotoCheckout = (reg) => {
        if (reg) {
            Navigate("/register")
            return
        }
        Navigate("/premium-checkout")
    }






    return (
        <div className="overall-container-home">
            <HelmetProvider>
                <Helmet>
                    <title>Features</title>
                    <meta name="description" content="Features - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <div className="overall-percent-home">
                <ScrollToTop smooth />

                <div className="container-header-profile">
                    <SlidingHeader />
                </div>

                <Top10px />

                {/* <div className="services-container"> */}
                <br />



                <section className="pricing-overall">



                    {
                        isGreater768 ?
                            <div className="pricing-container">
                                <h2 style={{ textAlign: "start", fontWeight: "600", color: "#319fff", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Discover How Shubadinam Can Help You
                                </h2>
                                <div style={{ listStyle: "none" }} className="pricing-container-bullets">
                                    <li><span style={{ fontWeight: "900", marginRight: "10px", color: "#37aa00" }}>🗸</span><i style={{ fontWeight: "400", fontFamily: "roboto" }}>Shubadinam helps you to find out your Birthday based</i> <span style={{ fontWeight: "600", fontSize: "medium" }}> on Indian Calendar / Panchang.</span> </li>
                                    <li><span style={{ fontWeight: "900", marginRight: "10px", color: "#37aa00" }}>🗸</span><span style={{ fontWeight: "600", fontSize: "medium" }}>Add Unlimited Family Members</span><i style={{ fontWeight: "400", fontFamily: "roboto" }}>  - Find Indian Birthday / Janma Tithi of your Family and Friends.</i></li>
                                    <li><span style={{ fontWeight: "900", marginRight: "10px", color: "#37aa00" }}>🗸</span><span style={{ fontWeight: "600", fontSize: "medium" }}>Get Reminders</span><i style={{ fontWeight: "400", fontFamily: "roboto" }}> (Email, SMS, WhatsApp) to celebrate the Birthday as per Indian Calendar.</i></li>

                                    <li><span style={{ fontWeight: "900", marginRight: "10px", color: "#37aa00" }}>🗸</span><span style={{ fontWeight: "600", fontSize: "medium" }}>World's First and ONLY</span><i style={{ fontWeight: "400", fontFamily: "roboto" }}> Janma Nakshatra/Tithi Birthday  Reminder Service.</i> </li>
                                </div>

                                {/* <h6 style={{ textAlign: "start", padding: "10px", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                            <i style={{ fontWeight: "400", fontFamily: "roboto" }}>
                                <span style={{ fontWeight: "600", color: "#004c8e" }}>World's First and ONLY </span><i style={{ fontWeight: "400", fontFamily: "roboto" }}>Janma Nakshatra/Tithi Birthday  Reminder Service.</i></i>
                        </h6> */}

                                <h6 style={{ textAlign: "start", padding: "10px", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                                    <i style={{ fontWeight: "400", fontFamily: "roboto" }}>
                                        <span style={{ fontWeight: "600", color: "#004c8e" }}>Trusted by Lakhs of users</span>  to Find, Plan and Celebrate their   <span style={{ fontWeight: "600", color: "#004c8e" }}>TRUE Birthday</span> based on Indian Calendar.</i>
                                </h6>


                                <h5 style={{ textAlign: "start", padding: "10px", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                                    Join Premium Membership<i style={{ fontFamily: "roboto", fontWeight: "100", fontSize: "medium" }}> and enjoy exclusive benefits that includes</i> Assistance on finding your Indian Janma Dinam <i style={{ fontFamily: "roboto", fontWeight: "100", fontSize: "medium" }}> based on English Date of Birth, </i>Multiple  Advance reminders<i style={{ fontFamily: "roboto", fontWeight: "100", fontSize: "medium" }}> to plan your celebration</i>, Free Delivery <i style={{ fontFamily: "roboto", fontWeight: "100", fontSize: "medium" }}>  for Merchandise, and many more upcoming premium benefits.</i>
                                </h5>
                                <br />
                                <div class="pricing-table-wrapper">
                                    <table className="pricing-table">
                                        <thead className="pricing-table-header">
                                            <tr >
                                                <th>
                                                    <div>
                                                        Register Now
                                                        <div class="svg-wrapper">
                                                            <svg viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 17v-4h-8v-2h8v-4l6 5-6 5z" /></svg>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="heading">Basic</div>
                                                    <div class="info">
                                                        <div class="amount">Free / <label>&#8377;</label> 0<span>Yearly</span></div>
                                                        {/* <div class="billing-msg">billed annually</div> */}
                                                        <Button sx={{ width: 100, alignSelf: "center" }} type="button" disabled={isFreeUser ? true : false} onClick={() => gotoCheckout("reg")}>
                                                            {isFreeUser ? "In-Use" : "Sign Up"}
                                                        </Button>


                                                    </div>
                                                </th>
                                                <th >
                                                    <div class="heading" >Premium</div>
                                                    <div class="info">
                                                        <div class="popular">Popular</div>
                                                        <div class="amount"><label>&#8377;</label>349 <span>Yearly</span></div>
                                                        {/* <div class="billing-msg">billed annually</div> */}
                                                        {/* <div> */}
                                                        {daysLeft > 0 ? (
                                                            <>
                                                                <br />
                                                                (Premium Purchased)
                                                                <p style={{ color: "red" }}>Only {daysLeft} days to renewal.</p>

                                                            </>
                                                        ) : (
                                                            // <p>Your premium has expired</p>

                                                            <Button sx={{ width: 100, alignSelf: "center" }} type="button" onClick={() => gotoCheckout()}>Sign Up</Button>

                                                        )}
                                                        {/* </div> */}
                                                    </div>
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Add family and friends
                                                    (Unlimited Entries)</td>
                                                <td className="tick-center">
                                                    <svg class="starter" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                                <td className="tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                            </tr>

                                            {/* <tr>
                                        <td>Notification</td>
                                        <td>
                                            1
                                        </td>
                                        <td>
                                            Multiple
                                        </td>
                                    </tr> */}
                                            <tr >
                                                <td >Ads Free </td>
                                                <td className="tick-center">
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td >Multiple Advance reminders to plan your celebration</td>
                                                <td className="tick-center">
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td >Email Reminder<p>{"(India and Global)"}</p></td>
                                                <td className="tick-center">
                                                    <svg class="starter" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td >SMS Reminder <p>{"(India only)"}</p></td>
                                                <td className="tick-center">
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>
                                            <tr >
                                                <td  >
                                                    WhatsApp<p>{"(India and Global)"}</p>
                                                </td>
                                                <td className="tick-center">
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>
                                            <tr >
                                                <td >Nakshatra / Tithi Finder Assistance
                                                    <p>{"(As per English Date of Birth)"}</p></td>
                                                <td className="tick-center">
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>


                                            {/* <tr>
                                        <td>Live Sathsangh Webinar</td>
                                        <td>
                                            1
                                        </td>
                                        <td>
                                            Multiple
                                        </td>

                                    </tr> */}
                                            {/* <tr>
                                        <td>Recording Access</td>
                                        <td>
                                            <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                        </td>
                                        <td>
                                            <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                        </td>

                                    </tr> */}
                                            <tr >
                                                <td  >One Free Virtual Puja Seva Slot (of value ₹500)
                                                    <p></p></td>
                                                <td className="tick-center">
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>
                                            <tr >
                                                <td  >Free Delivery For Merchandise<p>{"(India only)"}</p></td>
                                                <td className="tick-center">
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>
                                            <tr >
                                                <td></td>
                                                <th>
                                                    <div class="info">
                                                        <div class="amount">Free / <label>&#8377;</label> 0 <span>Yearly</span></div>
                                                        {/* <div class="billing-msg">billed annually</div> */}
                                                        <Button sx={{ width: 100, alignSelf: "center" }} type="button" disabled={isFreeUser ? true : false} onClick={() => gotoCheckout("reg")}>
                                                            {isFreeUser ? "In-Use" : "Sign Up"}
                                                        </Button>


                                                    </div>
                                                </th>
                                                <th >
                                                    <div class="info">
                                                        <div class="popular">Popular</div>
                                                        <div class="amount"><label>&#8377;</label>349 <span>Yearly</span></div>
                                                        {/* <div class="billing-msg">billed annually</div> */}
                                                        {daysLeft > 0 ? (
                                                            <>
                                                                <br />
                                                                (Premium Purchased)
                                                                <p style={{ color: "red" }}>Only {daysLeft} days to renewal.</p>
                                                                {/* <br /> */}



                                                            </>
                                                        ) : (
                                                            // <p>Your premium has expired</p>

                                                            <Button sx={{ width: 100, alignSelf: "center" }} type="button" onClick={() => gotoCheckout()}>Sign Up</Button>

                                                        )}
                                                    </div>
                                                </th>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>





                            </div>
                            :
                            <div className="pricing-container">
                                <br />
                                <h2 style={{ textAlign: "start", fontWeight: "600", color: "#319fff", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Discover How Shubadinam Premium Can Help You:
                                </h2>
                                <div style={{ listStyle: "none" }} className="pricing-container-bullets">
                                    <li><span style={{ fontWeight: "900", marginRight: "10px", color: "#37aa00" }}>✓</span><i style={{ fontWeight: "400", fontFamily: "roboto" }}>Shubadinam helps you to find out your Birthday based</i> <span style={{ fontWeight: "600", }}> on Indian Calendar/ Panchang.</span> </li>
                                    <li><span style={{ fontWeight: "900", marginRight: "10px", color: "#37aa00" }}>✓</span><span style={{ fontWeight: "600" }}>Add Unlimited Family Members</span><i style={{ fontWeight: "400", fontFamily: "roboto" }}>  - Find Indian Birthday/ Janma Tithi of your Family and Friends.</i></li>
                                    <li><span style={{ fontWeight: "900", marginRight: "10px", color: "#37aa00" }}>✓</span><span style={{ fontWeight: "600", }}>Get Reminders</span><i style={{ fontWeight: "400", fontFamily: "roboto" }}> (Email, SMS, WhatsApp) to celebrate the Birthday as per Indian Calendar.</i></li>

                                    <li><span style={{ fontWeight: "900", marginRight: "10px", color: "#37aa00" }}>✓</span><span style={{ fontWeight: "600", }}>World's First and ONLY </span><i style={{ fontWeight: "400", fontFamily: "roboto" }}>Janma Nakshatra/Tithi Birthday  Reminder Service.</i> </li>
                                </div>
                                {/* <h6 style={{ textAlign: "start", padding: "10px", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                                    <i style={{ fontWeight: "400", fontFamily: "roboto" }}>
                                        <span style={{ fontWeight: "600", color: "#004c8e" }}>World's First and ONLY </span><i style={{ fontWeight: "400", fontFamily: "roboto" }}>Janma Nakshatra/Tithi Birthday  Reminder Service.</i></i>
                                </h6> */}

                                <h6 style={{ textAlign: "start", padding: "10px", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                                    <i style={{ fontWeight: "400", fontFamily: "roboto" }}>
                                        <span style={{ fontWeight: "600", color: "#004c8e" }}>Trusted by Lakhs of users</span>  to Find, Plan and Celebrate their   <span style={{ fontWeight: "600", color: "#004c8e" }}>TRUE Birthday</span> based on Indian Calendar.</i>
                                </h6>
                                {/* <h6 style={{ textAlign: "start", padding: "10px", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                                    Join Premium Membership and enjoy exclusive benefits that includes Assistance on finding your Indian Janma Dinam based on English Date of Birth, Advance reminders to plan your celebration, Free Delivery for Merchandise, and many more upcoming premium benefits.
                                </h6> */}
                                <br />
                                <div className="pricing-selection">
                                    <h3>Register Now</h3>
                                    <svg viewBox="0 0 24 24" className="svg-wrapper"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 17v-4h-8v-2h8v-4l6 5-6 5z" /></svg>
                                </div>

                                <div className="pricing-free">
                                    <div class="pricing-free-heading">Basic</div>
                                    <div class="info">
                                        <div class="amount">Free /<label>&#8377;</label> 0 <p> Yearly</p></div>
                                        {/* <div class="billing-msg">billed annually</div> */}
                                    </div>
                                    <div className="pricing-free-content">
                                        <ul>
                                            <li>
                                                Add family and friends
                                                (Unlimited Entries)<span><svg class="starter" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                                            </li>

                                            {/* <li>Notification  <span>-{" "}1</span></li> */}
                                            <li className="highlighted-text-pricing">Ads Free
                                                <span><svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg></span>
                                            </li>
                                            <li>Multiple Advance reminders to plan your celebration
                                                <span><svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg></span>
                                            </li>
                                            <li>Email Reminder
                                                (India and Globally) <span><svg class="starter" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                                            </li>
                                            <li className="highlighted-text-pricing">SMS Reminder
                                                <span><svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg></span>
                                            </li>
                                            <li className="highlighted-text-pricing">WhatsApp (India and Global)
                                                <span><svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg></span>
                                            </li>

                                            {/* <li >WhatsApp
                                                (India and Global)
                                                (India only) <span>-{" "}1</span>
                                            </li> */}
                                            <li >Nakshatra / Tithi Finder Assistance
                                                <span>{"(As per English Date of Birth)"}</span>
                                                <span><svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg></span>
                                            </li>
                                            {/* <li>Live Sathsangh Webinar <span>-{" "}1</span>
                                            </li> */}
                                            {/* <li>Recording Access
                                                <span><svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg></span>
                                            </li> */}
                                            <li >Free Delivery For Merchandise
                                                (India only)
                                                <span><svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg></span>
                                            </li>

                                        </ul>

                                    </div>
                                    <button className="pricing-button" style={{ borderRadius: "5px", backgroundColor: "#010355", color: "white" }} type="button" disabled={isFreeUser ? true : false} onClick={() => gotoCheckout("reg")}>
                                        {isFreeUser ? "In-Use" : "Sign Up"}
                                    </button>
                                    {/* <Button variant="contained" className="pricing-button" onClick={() => console.log("hello")}>Sign Up</Button> */}
                                </div>


                                <div className="pricing-premium">
                                    <div class="pricing-premium-heading" onClick={() => gotoCheckout()}>Premium</div>
                                    <div class="info">
                                        <div class="amount"><label>&#8377;</label> 349<p> Yearly</p></div>
                                        {/* <div class="billing-msg">billed annually</div> */}
                                    </div>
                                    <div className="pricing-free-content">



                                        {/* <li>Notification<span>-{" "}Multiple</span></li> */}
                                        <ul className="premium-background" >
                                            <li style={{ borderBottom: "2px solid white" }}>Add family and friends
                                                <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>{" "}(Unlimited Entries)</i>
                                                <span><svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span></li>
                                            <li style={{ borderBottom: "2px solid white" }}>Ads Free
                                                <span><svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                                            </li>
                                            <li style={{ borderBottom: "2px solid white" }}>Multiple Advance reminders to plan your celebration
                                                <span><svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                                            </li>

                                            <li style={{ borderBottom: "2px solid white" }}>Email Reminder
                                                <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>{" "}(India and Globally)</i>
                                                <span><svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span><i style={{ fontFamily: 'Roboto', fontWeight: "100" }}></i></li>
                                            <li style={{ borderBottom: "2px solid white" }}>SMS Reminder{" "}
                                                <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>(India only)</i>
                                                <span><svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span><i style={{ fontFamily: 'Roboto', fontWeight: "100" }}></i>
                                            </li>
                                            <li style={{ borderBottom: "2px solid white" }}>WhatsApp

                                                <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>  (India and Global)</i>
                                                <span><svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span><i style={{ fontFamily: 'Roboto', fontWeight: "100" }}></i>
                                            </li>
                                            <li style={{ borderBottom: "2px solid white" }}>Nakshatra / Tithi Finder Assistance
                                                <span><i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>{"(As per English Date of Birth)"}</i></span>
                                                <span><svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                                            </li>

                                            {/* <li>Live Sathsangh Webinar
                                                <span>-{" "}Multiple</span>
                                            </li> */}
                                            {/* <li>Recording Access
                                                <span><svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                                            </li> */}
                                            <li style={{ borderBottom: "2px solid white" }}>Free Delivery For Merchandise{" "}
                                                <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>(India only)</i>

                                                <span><svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                                            </li>

                                        </ul>
                                    </div>

                                    {daysLeft > 0 ? (
                                        <>
                                            <br />
                                            <span style={{ textAlign: "center" }}>(Purchased)</span>

                                            <p style={{ color: "red", textAlign: "center" }}>Only {daysLeft} days to renewal.</p>
                                            <br />
                                        </>
                                    ) : (
                                        // <p>Your premium has expired</p>

                                        <button className="pricing-button" style={{ borderRadius: "5px", backgroundColor: "#010355", color: "white" }} type="button" onClick={() => gotoCheckout()}>Sign Up</button>

                                    )}
                                    <br />
                                </div>

                                <h6 style={{ textAlign: "start", padding: "10px", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                                    Join Premium Membership<i style={{ fontFamily: "roboto", fontWeight: "100" }}> and enjoy exclusive benefits that includes</i> Assistance on finding your Indian Janma Dinam <i style={{ fontFamily: "roboto", fontWeight: "100" }}> based on English Date of Birth, </i>Advance reminders<i style={{ fontFamily: "roboto", fontWeight: "100" }}> to plan your celebration</i>, Free Delivery <i style={{ fontFamily: "roboto", fontWeight: "100" }}>  for Merchandise, and many more upcoming premium benefits.</i>
                                </h6>


                            </div>
                    }

                    <br />


                    <div className="db-box-cnt1">
                        <span style={{
                            color: '#05195e', fontSize: "35px", fontFamily: 'Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif', fontWeight: 900, textTransform: "capitalize"
                        }}>What people say</span>

                    </div>
                    <br />
                    <div className="db-box-cnt2">
                        <TestimonialScreen />
                    </div>


                </section>



                {/* </div> */}

                <br />
                <br />

                <FooterComponent></FooterComponent>
            </div >
        </div >
    );
};

export default Features;
