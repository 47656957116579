
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TextareaAutosize } from '@mui/material';
import { Button } from '@mui/material';
import Swal from 'sweetalert2';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';


const SentPerPerson = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const params = new URLSearchParams(location.search);
    const userEmail = params.get('personEmail').replace(/"/g, '');


    const [fromData, setFromData] = useState([])
    const [fromData2, setFromData2] = useState([])



    const [dynamicState, setDynamicFunction] = useState({
        companyReply: ""
    })

    useEffect(() => {

        const sId = localStorage.getItem("SD_Id");

        if (userEmail && sId) {
            const url = `https://backend.shubadinam.com/admin/get/sentchat?userEmail=${userEmail}&customId=${sId}`;
            axios.get(url)

                .then((response) => {
                    // console.log(url);
                    // console.log(response);
                    setFromData(response.data)
                    setFromData2(response.data[0])
                })
                .catch((error) => {
                    // console.log(error);
                })
        }
        else {
            if (userEmail) {
                const url = `https://backend.shubadinam.com/admin/get/sentchat?userEmail=${userEmail}`;
                axios.get(url)
                    .then((response) => {
                        // console.log(response);
                        setFromData(response.data)
                        setFromData2(response.data[0])
                    })
                    .catch((error) => {
                        // console.log(error);
                    })
            }
        }
    }, [userEmail]);

    useEffect(() => {
        setDynamicFunction(prevState => ({
            ...prevState,
            userEmail: fromData2.customerEmail,
            userName: fromData2.customerName,
            userPhone: fromData2.customerPhone,
            customerId: fromData2.customerUniqueid
        }));
    }, [fromData2]);

    const handleBack = () => {
        navigate("/contact/sent-items")
    }

    const handleChange = (e) => {
        setDynamicFunction({ ...dynamicState, [e.target.name]: e.target.value })
    }
    const handleSubmit = () => {

        // console.log(dynamicState);

        const url = `https://backend.shubadinam.com/admin/post/sentchat`;
        axios.post(url, dynamicState)
            .then((response) => {
                // console.log(response);
                Swal.fire({
                    title: 'Success',
                    text: 'Your message has been sent successfully',
                    icon: 'success',
                    confirmButtonText: 'OK'
                })
                navigate("/contact/sent-items")
            })
            .catch((error) => {
                // console.log(error);
            })
    }


    return (
        <div>
            <br />
            <h3 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Sent Items Detailed Report  </h3>
            <br />
            <Box sx={{ height: '100%', width: "100%", alignContent: "center" }}>
                {fromData2 && (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    <TableCell sx={{ textAlign: "Center" }}>Property</TableCell>
                                    <TableCell sx={{ textAlign: "Center" }}>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{ textAlign: "Center" }} component="th" scope="row">
                                        Name
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "Center" }}>{fromData2.customerName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ textAlign: "Center" }} component="th" scope="row">
                                        Email
                                    </TableCell >
                                    <TableCell sx={{ textAlign: "Center" }}>{fromData2.customerEmail}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ textAlign: "Center" }} component="th" scope="row">
                                        Id
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "Center" }}>{fromData2.customerUniqueid}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ textAlign: "Center" }} component="th" scope="row">
                                        Reply
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "Center" }}>
                                        <textarea name='companyReply' placeholder='type something here...' onChange={(e) => handleChange(e)} style={{ width: "70%", height: "100px" }}>
                                        </textarea>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ textAlign: "Center" }} component="th" scope="row">
                                        Actions
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "Center", }}>
                                        <Button onClick={() => { handleSubmit() }} sx={{ marginRight: "10px", }}>Submit</Button>
                                        <Button onClick={() => { handleBack() }}>Back</Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
            <br />
            <h3 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Chat Details  </h3>
            <br />
            <div>

                {/* {fromData
                    // .slice() // Create a shallow copy to avoid mutating the original array
                    .sort((a, b) => b.id - a.id) // Sort by id in descending order
                    .map((row, index) => (
                        <div key={index} >
                            <br />
                            {row.customerQuery && (
                                <Box
                                    className="box-admin-sent"
                                    sx={{
                                        display: "flex", justifyContent: "start", flexDirection: "column", width: "50%", marginRight: "50%", backgroundColor: "#ffedc6", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", borderRadius: "20px 20px 0 20px", padding: "20px", margin: "20px"
                                    }}
                                >
                                    <Box sx={{
                                        display: "flex", justifyContent: "start", flexDirection: "row", width: "50%", alignItems: "center"
                                    }}>
                                        <Avatar src="/broken-image.jpg" sx={{ marginRight: "10px" }} />
                                        <p style={{ fontSize: "10px", }}>{new Date(row.queryTime).toLocaleString('en-IN', { hour12: true })}</p>
                                    </Box>


                                    <br />
                                    <p style={{ fontSize: "18px", marginLeft: "20px", textAlign: "center" }}>{row.customerQuery}</p>

                                </Box>
                            )}
                            <br />
                            {row.companyReply && (
                                <Box
                                    className="box-admin-sent"
                                    sx={{ display: "flex", justifyContent: "end", flexDirection: "column", width: "50%", marginLeft: "48%", backgroundColor: "#c8e5ff", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", borderRadius: "20px 20px 20px 0px", padding: "20px" }}>
                                    <Box sx={{
                                        display: "flex", justifyContent: "start", flexDirection: "row", width: "50%", alignItems: "center"
                                    }}>
                                        <Avatar sx={{ bgcolor: "#311b92", marginRight: "10px" }}>S</Avatar>
                                        <p style={{ fontSize: "10px", }}>{new Date(row.replyTime).toLocaleString('en-IN', { hour12: true })}</p>

                                    </Box>
                                    <br />
                                    <p style={{ fontSize: "18px", marginLeft: "20px", textAlign: "center" }}>{row.companyReply}</p>
                                </Box>

                            )}

                        </div>
                    ))} */}


                {
                    fromData
                        .sort((a, b) => {
                            const timeA = Math.max(new Date(a.queryTime).getTime(), new Date(a.replyTime).getTime());
                            const timeB = Math.max(new Date(b.queryTime).getTime(), new Date(b.replyTime).getTime());
                            return timeB - timeA;
                        })
                        .map((row, index) => (
                            <div key={index}>
                                <br />
                                {new Date(row.queryTime).getTime() > new Date(row.replyTime).getTime() ? (
                                    <>
                                        {row.customerQuery && (
                                            <Box
                                                className="box-admin-sent"
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "start",
                                                    flexDirection: "column",
                                                    width: "50%",
                                                    marginRight: "50%",
                                                    backgroundColor: "#ffedc6",
                                                    fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                                                    borderRadius: "20px 20px 0 20px",
                                                    padding: "20px",
                                                    margin: "20px"
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "start",
                                                        flexDirection: "row",
                                                        width: "50%",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <Avatar src="/broken-image.jpg" sx={{ marginRight: "10px" }} />
                                                    <p style={{ fontSize: "10px" }}>{new Date(row.queryTime).toLocaleString('en-IN', { hour12: true })}</p>
                                                </Box>
                                                <br />
                                                <p style={{ fontSize: "18px", marginLeft: "20px", textAlign: "center" }}>{row.customerQuery}</p>
                                            </Box>
                                        )}
                                        <br />
                                        {row.companyReply && (
                                            <Box
                                                className="box-admin-sent"
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    flexDirection: "column",
                                                    width: "50%",
                                                    marginLeft: "48%",
                                                    backgroundColor: "#c8e5ff",
                                                    fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                                                    borderRadius: "20px 20px 20px 0px",
                                                    padding: "20px"
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "start",
                                                        flexDirection: "row",
                                                        width: "50%",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <Avatar sx={{ bgcolor: "#311b92", marginRight: "10px" }}>S</Avatar>
                                                    <p style={{ fontSize: "10px" }}>{new Date(row.replyTime).toLocaleString('en-IN', { hour12: true })}</p>
                                                </Box>
                                                <br />
                                                <p style={{ fontSize: "18px", marginLeft: "20px", textAlign: "center" }}>{row.companyReply}</p>
                                            </Box>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {row.companyReply && (
                                            <Box
                                                className="box-admin-sent"
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    flexDirection: "column",
                                                    width: "50%",
                                                    marginLeft: "48%",
                                                    backgroundColor: "#c8e5ff",
                                                    fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                                                    borderRadius: "20px 20px 20px 0px",
                                                    padding: "20px"
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "start",
                                                        flexDirection: "row",
                                                        width: "50%",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <Avatar sx={{ bgcolor: "#311b92", marginRight: "10px" }}>S</Avatar>
                                                    <p style={{ fontSize: "10px" }}>{new Date(row.replyTime).toLocaleString('en-IN', { hour12: true })}</p>
                                                </Box>
                                                <br />
                                                <p style={{ fontSize: "18px", marginLeft: "20px", textAlign: "center" }}>{row.companyReply}</p>
                                            </Box>
                                        )}
                                        <br />
                                        {row.customerQuery && (
                                            <Box
                                                className="box-admin-sent"
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "start",
                                                    flexDirection: "column",
                                                    width: "50%",
                                                    marginRight: "50%",
                                                    backgroundColor: "#ffedc6",
                                                    fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                                                    borderRadius: "20px 20px 0 20px",
                                                    padding: "20px",
                                                    margin: "20px"
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "start",
                                                        flexDirection: "row",
                                                        width: "50%",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <Avatar src="/broken-image.jpg" sx={{ marginRight: "10px" }} />
                                                    <p style={{ fontSize: "10px" }}>{new Date(row.queryTime).toLocaleString('en-IN', { hour12: true })}</p>
                                                </Box>
                                                <br />
                                                <p style={{ fontSize: "18px", marginLeft: "20px", textAlign: "center" }}>{row.customerQuery}</p>
                                            </Box>
                                        )}
                                    </>
                                )}
                            </div>
                        ))
                }







            </div>


        </div >
    );
};

export default SentPerPerson;
