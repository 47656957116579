import React, { useState, useEffect } from "react";
import { Container, Typography, Button, Grid, Box, Card, CardContent, useTheme, useMediaQuery, Stepper, Step, StepLabel, StepContent, Accordion, AccordionSummary, AccordionDetails, IconButton, } from "@mui/material";
import { styled } from "@mui/system";
import AOS from "aos";
import "aos/dist/aos.css";
import { AccountBalanceWallet, ExpandMore, MonetizationOn, PersonAdd, PlayCircle, Share } from "@mui/icons-material";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import SlidingHeader from "../../components/SlidingHeader"
import Footer from "../../components/footer"
import ScrollToTop from "react-scroll-to-top";

const HeroImage = "https://img.freepik.com/free-vector/food-youtube-thumbnail_23-2148561453.jpg?uid=R133306793&ga=GA1.1.1773690977.1730112906&semt=ais_keywords_boost";

const PageContainer = styled("div")({
    backgroundColor: "#f5f5f5",
    minHeight: "100vh",
    color: "#333",
    padding: "40px 0",
    textAlign: "center",
});

const OrangeText = styled("span")({
    color: "#ff7b00",
});

const Section = styled("div")({
    padding: "60px 0",
});

const Image = styled("img")({
    width: "100%",
    maxWidth: "500px",
    borderRadius: "8px",
    marginTop: "20px",
});

const HeroSection = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: "20px",
    "@media (min-width: 768px)": {
        flexDirection: "row",
        justifyContent: "space-between",
        textAlign: "left",
    },
});

const HeroText = styled(Box)({
    maxWidth: "500px",
    "@media (min-width: 768px)": {
        textAlign: "left",
    },
});

const steps = [
    {
        label: "1",
        description: "Register for our affiliate program with just a few clicks. No hidden fees, no complicated process—just a simple sign-up!",
        image: "https://img.freepik.com/free-vector/sign-up-concept-illustration_114360-7865.jpg"
    },
    {
        label: "2",
        description: "Once registered, receive your personalized referral link to start promoting and earning commissions.",
        image: "https://img.freepik.com/free-vector/referral-program-concept-illustration_114360-8293.jpg"
    },
    {
        label: "3",
        description: "Share your referral link on social media, blogs, or with friends. The more you promote, the more you earn!",
        image: "https://img.freepik.com/free-vector/social-media-marketing-concept-illustration_114360-1105.jpg"
    },
];

const faqs = [
    {
        question: "What is the Shubadinam Affiliate Program?",
        answer: "The Shubadinam Affiliate Program allows you to earn commissions by referring new customers to our platform using your unique referral link."
    },
    {
        question: "How do I join the affiliate program?",
        answer: "You can join by signing up on our affiliate page. After registration, you will receive a unique referral ID that you can share with others."
    },
    {
        question: "How do I earn commissions?",
        answer: "You earn a commission every time someone signs up using your referral link and makes a purchase on Shubadinam."
    },
    {
        question: "When and how will I receive my earnings?",
        answer: "Payouts are processed on a monthly basis via your preferred payment method, once you reach the minimum payout threshold."
    },
    {
        question: "Is there a limit to how much I can earn?",
        answer: "No, there is no limit! The more successful referrals you generate, the more commissions you earn."
    },
    {
        question: "Where can I track my referral earnings?",
        answer: "You can monitor your earnings and referral activity through your Shubadinam affiliate dashboard."
    }
];


const AffiliateLanding = () => {

    const theme = useTheme();

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    const [showIframe, setShowIframe] = useState(false);

    return (
        <Box>
            <ScrollToTop smooth />
            <SlidingHeader />
            <PageContainer sx={{ py: 10 }}>
                <Container>
                    {/* Hero Section */}
                    <Section data-aos="fade-up">
                        <HeroSection>
                            <HeroText>
                                <Typography variant="h3" fontWeight="bold">
                                    Join the <OrangeText>Shubadinam</OrangeText> Affiliate Program
                                </Typography>
                                <Typography variant="h6" marginTop={2}>
                                    Earn commissions by referring new customers to our platform!
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: "#ff7b00", mt: 3, color: "#fff", textTransform: "none" }}
                                    size="large"
                                >
                                    Sign Up Now
                                </Button>
                            </HeroText>

                            {/* Thumbnail with Play Button */}
                            <Box flex={1} display="flex" justifyContent="end" alignItems="end" position="relative">
                                {!showIframe ? (
                                    <Box position="relative" width="100%" maxWidth={500}>
                                        <img
                                            src={HeroImage}
                                            alt="Affiliate Program"
                                            style={{
                                                width: "100%", borderRadius: 8,
                                            }}
                                        />
                                        <IconButton
                                            sx={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                                backgroundColor: "rgba(0, 0, 0, 0.6)",
                                                color: "#ff7b00",
                                                p: 2,
                                                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.8)" }
                                            }}
                                            onClick={() => setShowIframe(true)}
                                        >
                                            <PlayCircle sx={{ fontSize: 40 }} />
                                        </IconButton>
                                    </Box>
                                ) : (
                                    <iframe
                                        width="500"
                                        height="300"
                                        src="https://www.youtube.com/embed/YOUR_VIDEO_ID"
                                        title="Affiliate Program Video"
                                        allowFullScreen
                                        style={{ borderRadius: 8, border: "none", boxShadow: "0px 4px 10px rgba(0,0,0,0.3)" }}
                                    />
                                )}
                            </Box>
                        </HeroSection>
                    </Section>

                    {/* How It Works Section */}
                    <Section data-aos="fade-up">
                        <Typography variant="h4" fontWeight="bold" textAlign="center">
                            How <OrangeText>It Works</OrangeText>
                        </Typography>

                        <Grid container spacing={4} justifyContent="center" marginTop={3}>
                            {[
                                {
                                    title: "Sign Up for Free",
                                    text: "Register in just a few clicks and get instant access to your unique affiliate dashboard.",
                                    icon: <PersonAdd fontSize="medium" />
                                },
                                {
                                    title: "Share & Promote",
                                    text: "Use your custom referral link to promote Shubadinam through social media, blogs, or ads.",
                                    icon: <Share fontSize="medium" />
                                },
                                {
                                    title: "Earn Commissions",
                                    text: "Get rewarded for every successful referral—track earnings in real-time!",
                                    icon: <MonetizationOn fontSize="medium" />
                                },
                                {
                                    title: "Withdraw & Enjoy",
                                    text: "Seamlessly withdraw your earnings with easy, fast, and secure payouts.",
                                    icon: <AccountBalanceWallet fontSize="medium" />
                                }
                            ].map((step, index) => (
                                <Grid item xs={12} sm={6} md={3} key={index} data-aos="zoom-in">
                                    <Card
                                        sx={{
                                            backgroundColor: "#061b39",
                                            color: "#fff",
                                            textAlign: "center",
                                            height: "100%",
                                            boxShadow: 3,
                                            borderRadius: 2,
                                            padding: 2
                                        }}
                                    >
                                        <CardContent>
                                            <Typography variant="h6" fontWeight="bold" color="white" sx={{ position: "absolute", top: '5%', left: '5%', p: 1, bgcolor: "#ff7b00", borderRadius: "10%", p: "10px 20px" }}>
                                                {step.icon}
                                            </Typography>
                                            <Typography variant="h6" fontWeight="bold" marginTop={2} color="#ff7b00">
                                                {step.title}
                                            </Typography>
                                            <Typography variant="body1" marginTop={1}>
                                                {step.text}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Section>

                    {/* Benefits Section */}
                    <Section data-aos="fade-up">
                        <Typography variant="h4" fontWeight="bold" textAlign="center">
                            Why Join Our <OrangeText>Affiliate Program?</OrangeText>
                        </Typography>

                        <Grid container spacing={4} justifyContent="center" marginTop={3}>
                            {[
                                { text: "Earn industry-leading commission rates on every successful referral and watch your income grow effortlessly.", image: "https://img.freepik.com/premium-vector/social-media-marketing-flat-vector-illustration-banner_128772-755.jpg" },
                                { text: "Get paid quickly and transparently with real-time tracking and hassle-free payouts.", image: "https://img.freepik.com/free-vector/data-scientist-specialist-extract-knowledge-insights-from-data-data-science-analytics-machine-learning-control-big-data-analytics-concept-pinkish-coral-bluevector-isolated-illustration_335657-2286.jpg" },
                                { text: "Access exclusive rewards, performance-based bonuses, and special incentives tailored for top affiliates.", image: "https://img.freepik.com/premium-vector/generating-new-leads-concept-illustration_86047-626.jpg" },
                                { text: "Unlock unlimited earning potential—no caps, no restrictions, just pure revenue growth!", image: "https://img.freepik.com/premium-vector/gift-box-full-rewards-stars-coins_123891-136294.jpg?uid=R133306793&ga=GA1.1.1773690977.1730112906&semt=ais_keywords_boost" }
                            ].map((benefit, index) => (
                                <Grid item xs={12} sm={6} key={index} data-aos="fade-right">
                                    <Card sx={{
                                        backgroundColor: "#fff",
                                        color: "#333",
                                        textAlign: "center",
                                        height: "100%",
                                        boxShadow: 0,
                                        padding: 2,
                                        borderRadius: 2
                                    }}>
                                        <CardContent>
                                            <Grid container spacing={2} alignItems="center">
                                                {/* Image on the Left */}
                                                <Grid item xs={4}>
                                                    <img
                                                        src={benefit.image}
                                                        alt={`Benefit ${index + 1}`}
                                                        width="100%"
                                                        height="auto"
                                                        style={{ borderRadius: "10px", objectFit: "cover" }}
                                                    />
                                                </Grid>

                                                {/* Text on the Right */}
                                                <Grid item xs={8} textAlign="left">
                                                    <Typography variant="h6" fontWeight="bold" color="#ff7b00">
                                                        Benefit {index + 1}
                                                    </Typography>
                                                    <Typography variant="body1" marginTop={1}>
                                                        {benefit.text}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Section>

                    <Section data-aos="fade-up" >
                        <Typography variant="h4" fontWeight="bold" textAlign="center" mb={4}>
                            How to <span style={{ color: "#ff7b00", }}>Get Started</span>
                        </Typography>

                        <Typography variant="h6" textAlign="center" mb={4}>
                            Join our affiliate program in just a few simple steps and start earning commissions today!
                            <Button variant="text" sx={{ color: "#ff7b00", textTransform: "none", fontWeight: "bold", fontSize: "1rem" }}>Click Here</Button>
                        </Typography>
                        <Grid container spacing={4} justifyContent="center" marginTop={3}>
                            {steps.map((step, index) => (
                                <Grid item xs={12} sm={6} md={3} key={index} data-aos="zoom-in">
                                    <Card
                                        sx={{
                                            backgroundColor: "#061b39",
                                            color: "#fff",
                                            textAlign: "center",
                                            height: "100%",
                                            boxShadow: 3,
                                            borderRadius: 2,
                                            py: 4
                                        }}
                                    >
                                        <CardContent>
                                            <Typography variant="h6" fontWeight="bold" color="white" sx={{ position: "absolute", top: '5%', left: '5%', p: 1, bgcolor: "#ff7b00", borderRadius: "10%", p: "10px 20px" }}>
                                                {step.label}
                                            </Typography>
                                            <Typography variant="body1" >
                                                {step.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Section>

                    {/* testimonials */}
                    <Section data-aos="fade-up">
                        <Typography variant="h4" fontWeight="bold" textAlign="center" mb={4}>
                            What Our <OrangeText>Affiliates Say</OrangeText>
                        </Typography>

                        <Splide
                            options={{
                                type: 'loop',
                                perPage: 3,
                                perMove: 1,
                                gap: '20px',
                                autoplay: true,
                                interval: 3000,
                                pauseOnHover: true,
                                arrows: true,
                                pagination: true,
                                breakpoints: {
                                    1024: { perPage: 2, gap: '15px' },
                                    768: { perPage: 1, gap: '10px' },
                                },
                            }}
                            style={{ padding: '10px' }}
                        >
                            {[
                                { name: "Ayesha R.", review: "I've earned consistent passive income with Shubadinam's affiliate program. Super easy and rewarding!", image: "https://randomuser.me/api/portraits/women/45.jpg" },
                                { name: "Michael T.", review: "Best decision ever! The commission rates are fantastic, and the payout process is smooth.", image: "https://randomuser.me/api/portraits/men/47.jpg" },
                                { name: "Sonia K.", review: "I love how transparent and simple the process is. Highly recommended for side income!", image: "https://randomuser.me/api/portraits/women/50.jpg" },
                                { name: "David L.", review: "Absolutely love the rewards system! It's motivating and keeps me engaged.", image: "https://randomuser.me/api/portraits/men/49.jpg" }
                            ].map((testimonial, index) => (
                                <SplideSlide key={index}>
                                    <Card sx={{
                                        backgroundColor: "#fff",
                                        color: "#333",
                                        textAlign: "center",
                                        height: "100%",
                                        boxShadow: 0,
                                        padding: 3,
                                        borderRadius: 3,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <CardContent>
                                            <img
                                                src={testimonial.image}
                                                alt={testimonial.name}
                                                width="90"
                                                height="90"
                                                style={{ borderRadius: "50%", marginBottom: "12px", border: "3px solid #ff7b00" }}
                                            />
                                            <Typography variant="h6" fontWeight="bold">
                                                {testimonial.name}
                                            </Typography>
                                            <Typography variant="body2" fontStyle="italic" marginTop={1}>
                                                "{testimonial.review}"
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </SplideSlide>
                            ))}
                        </Splide>
                    </Section>

                    {/* FAQ */}
                    <Section data-aos="fade-up">
                        <Typography variant="h4" fontWeight="bold" textAlign="center" color="#ff7b00" gutterBottom>
                            Frequently Asked Questions
                        </Typography>
                        {faqs.map((faq, index) => (
                            <Accordion key={index} sx={{ color: "black", boxShadow: 0, borderRadius: 2, marginBottom: 1 }}>
                                <AccordionSummary expandIcon={<ExpandMore sx={{ color: "#ff7b00" }} />}>
                                    <Typography variant="h6" align="left" fontWeight="bold">{faq.question}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography align="left">{faq.answer}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Section>


                </Container >
            </PageContainer >

            <Footer />
        </Box>
    );
};

export default AffiliateLanding;
