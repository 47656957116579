// NakshatraBlogs.js
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Grid, Card, CardContent, CardMedia, Typography, Button, Container } from '@mui/material';
import FooterComponent from "../components/footer";
import { NavigateNext, Star, StarBorder } from "@mui/icons-material";
import PageSlider from "./utils/PageSlider";
import SlidingHeader from "../components/SlidingHeader";
import { nakshatraData } from "./utils/nakshatraData ";

const NakshatraBlogs = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>Nakshatra Blogs</title>
                <meta name="description" content="Nakshatra Blogs - Janma Nakshatra/Tithi Birthday Reminder Service." />
            </Helmet>
            <SlidingHeader />

            {/* Hero Section */}
            <PageSlider
                bgImage={require("../images/banners/all_hero_banner.jpg")}
                pageTitle={`Nakshatra Related Blogs`}
            />

            <Container maxWidth="lg" sx={{ p: 4 }}>
                <Grid container spacing={4} justifyContent="center">
                    {nakshatraData.map((blog, index) => (
                        <Grid item xs={12} lg={6} key={index}>
                            <a href={blog.link} style={{ textDecoration: 'none' }}>
                                <Card elevation={0} sx={{ display: 'flex', height: '100%', borderRadius: 3, bgcolor: 'white', flexDirection: { xs: "column", md: "row" } }} >
                                    {/* Left side - Image */}
                                    <CardMedia
                                        component="img"
                                        sx={{ width: { xs: "100%", md: 160 }, objectFit: 'cover', }}
                                        image={blog.image}
                                        alt={blog.title}
                                    />

                                    {/* Right side - Content */}
                                    <Grid container direction="column" sx={{ p: 2 }}>
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography gutterBottom variant="h5">
                                                {blog.title}
                                            </Typography>
                                            <Typography color="primary" sx={{ my: 1 }}>
                                                {Array.from({ length: 5 }).map((_, i) =>
                                                    i < blog.rating ? (
                                                        <Star key={i} color="warning" />
                                                    ) : (
                                                        <StarBorder key={i} color="disabled" />
                                                    )
                                                )}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {blog.description}
                                            </Typography>
                                        </CardContent>
                                        <Button
                                            endIcon={<NavigateNext />}
                                            size="medium"
                                            variant="contained"
                                            color="primary"
                                            sx={{ marginTop: 'auto', textTransform: 'none', maxWidth: 200 }}
                                        >
                                            Read more
                                        </Button>
                                    </Grid>
                                </Card>
                            </a>
                        </Grid>
                    ))}
                </Grid>

            </Container>
            <FooterComponent />
        </HelmetProvider>
    );
};

export default NakshatraBlogs;
