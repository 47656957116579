import React from 'react';
import { Container, Typography, Grid, Box, Card, CardContent } from '@mui/material';
import SlidingHeader from '../../../components/SlidingHeader';
import FooterComponent from '../../../components/footer';
import { List, ListItem, ListItemText, IconButton } from "@mui/material";
import { Facebook, Twitter, LinkedIn, WhatsApp, Telegram, Instagram, X } from "@mui/icons-material";
import YouTube from '@mui/icons-material/YouTube';
import ReviewsFeedback from '../../../components/ReviewsFeedback';


const BookTwo = () => {



    const categories = ["All About Books", "Book Reviews"];
    const recentPosts = [
        "Timeless Wisdom for Modern Growth: 4 Self-Help Books to Transform Your Life",
        "Sacred Legends and Perennial Wisdom: A Journey Through Devotional and Historical Narratives",
        "Exploring the Mystical Path: A Journey Through Aghori Traditions",
    ];
    const popularPosts = [
        "Best Thriller Mystery Books – My Top 10 Favorites",
        "New 2021 Book Releases I Am Eagerly Anticipating",
        "From Lukov with Love by Mariana Zapata – Book Review",
    ];

    const books = [
        {
            title: "1. Aghori 1: An Untold Story",
            author: "Mayur Kalbag",
            description:
                "In this captivating narrative, we follow Subbu, a seeker eager to understand the ways of the Aghori sadhus. His journey to the Himalayas, prompted by his guru's request to embed three powerful mantras within him, serves as the backdrop for a series of spiritual and often startling experiences. Kalbag's storytelling immerses readers in Subbu's transformative expedition, offering a blend of spirituality and adventure.",
            link: "https://shop.shubadinam.com/collections/books/products/aghori-an-untold-story-by-mayur-kalbag",
        },
        {
            title: "2. Aghori 2: The Untold Story Continues",
            author: "Mayur Kalbag",
            description:
                "The sequel delves deeper into Subbu's spiritual odyssey. Having returned from the Himalayas, he now faces the challenge of transferring the embedded mantras to his guru. This phase introduces him to extraordinary beings, including a colossal Naga and a telepathic starfish, and presents obstacles that test his resolve. Kalbag continues to weave a tale that is both mystical and enlightening, shedding light on the profound teachings of the Aghori tradition.",
            link: "https://shop.shubadinam.com/collections/books/products/aghori-2-the-untold-story-continues-by-mayur-kalbag",
        },
    ];


    const facebookURL = 'https://www.facebook.com/profile.php?id=61551460127861';
    const instagramURL = 'https://www.instagram.com/shubadinam/';
    const youtubeURL = 'https://www.youtube.com/channel/UCsNbE7QqnpgjI7cXOTM1mXA';
    const twitterURL = 'https://twitter.com/Shubadinam';
    const whatsappURL = 'https://www.youtube.com/channel/UCsNbE7QqnpgjI7cXOTM1mXA';

    const handleIconClick = (iconCount) => {
        // console.log('Icon clicked:', iconCount);
        if (iconCount === 2) {
            window.open(facebookURL, '_blank');
        }
        else if (iconCount === 3) {
            window.open(instagramURL, '_blank');
        }
        else if (iconCount === 4) {
            window.open(youtubeURL, '_blank');
        }
        else if (iconCount === 1) {
            window.open(twitterURL, '_blank');
        }
        else {
            // window.open(whatsappURL, '_blank');
        }

    };


    return (
        <Box sx={{ bgcolor: "aliceblue" }}>

            <SlidingHeader />
            <Container maxWidth="xl" sx={{ mt: 10, }}>



                {/* Books Layout */}
                <Grid spacing={3} item container maxWidth="xl" sx={{ mt: 4 }}>
                    <Grid item xs={12} md={8}>
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: "bold",
                                px: { xs: 1, md: 2 }, // Add padding to prevent text from touching screen edges
                                mb: { xs: 2, md: 3 }, // Margin bottom: smaller on mobile, larger on desktop
                                fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" } // Adjust font size dynamically
                            }}
                        >
                            Exploring the Mystical Path: A Journey Through Aghori Traditions
                        </Typography>

                        <Typography
                            color="primary"
                            variant="h5"
                            sx={{
                                fontWeight: "bold",
                                px: { xs: 1, md: 2 }, // Add padding to prevent text from touching screen edges
                                mb: { xs: 2, md: 3 }, // Margin bottom: smaller on mobile, larger on desktop
                            }}>
                            Introduction
                        </Typography>

                        <Typography
                            variant="subtitle1"
                            sx={{
                                textAlign: { xs: "left", md: "left" }, // Center text on mobile, left-align on desktop
                                px: { xs: 1, md: 2 }, // Add padding to prevent text from touching screen edges
                                fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" } // Adjust font size
                            }}
                            color="textSecondary"
                        >
                            The Aghori sadhus of India are often shrouded in mystery, known for their unconventional practices and profound devotion to Lord Shiva. Their path, though esoteric, offers deep insights into spirituality and the human experience. For those intrigued by these ascetics, the following books provide a window into their enigmatic world.
                        </Typography>

                        <Grid item xs={12} md={8}
                            component="img"
                            src={require("../../../images/books/collage banner 3.png")}
                            alt="Satvic Revolution"
                            sx={{
                                width: "100%", // Ensures full width inside its container
                                height: 'auto', // Maintains aspect ratio
                                borderRadius: 2,
                                objectFit: "contain",
                                display: "block", // Removes extra spacing below inline images
                                mx: "auto", // Centers the image horizontally
                                mb: 2, // Adds bottom margin
                                mt: 2
                            }}
                        />


                        {books.map((book, index) => (
                            <CardContent key={index}>
                                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                    <a href={book.link} target="_blank" style={{ textDecoration: "none" }}>
                                        {book.title}
                                    </a>
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontStyle: "italic", mb: 2 }}>
                                    - {book.author}
                                </Typography>
                                <Typography variant="body1" color="text.secondary">
                                    {book.description}
                                </Typography>
                            </CardContent>
                        ))}


                        <Typography
                            color="primary"
                            variant="h5"
                            sx={{
                                fontWeight: "bold",
                            }}>
                            Conclusion
                        </Typography>


                        {/* Conclusion */}
                        <Typography variant="body1" sx={{ mt: 1, mb: 8 }}>
                            Mayur Kalbag's works offer a rare glimpse into the world of the Aghori sadhus, blending narrative flair with spiritual depth. Through Subbu's journeys, readers are invited to explore themes of devotion, transformation, and the pursuit of higher truths. These books are invaluable for anyone seeking to understand the intricate tapestry of India's spiritual traditions.
                        </Typography>

                        <ReviewsFeedback />

                    </Grid>

                    <Grid item xs={12} md={4}>
                        {/* <Box
                            component="img"
                            src="https://img.freepik.com/premium-photo/unique-meaningful-gift-ideas_1168612-254209.jpg?uid=R133306793&ga=GA1.1.1773690977.1730112906&semt=ais_hybrid_sidr"
                            alt="Satvic Revolution"
                            sx={{
                                width: "100%", // Ensures full width inside its container
                                height: "auto", // Maintains aspect ratio
                                borderRadius: 2,
                                display: "block", // Removes extra spacing below inline images
                                mx: "auto", // Centers the image horizontally
                                mb: 2, // Adds bottom margin
                            }}
                        /> */}

                        {/* Categories Section */}
                        {/* <Card sx={{ mb: 4, boxShadow: 0, borderRadius: 2, overflow: 'hidden' }}>
                        <CardContent>
                            <Typography
                                color="primary"
                                variant="h6"
                                fontWeight="bold"
                                gutterBottom
                                sx={{ display: 'flex', alignItems: 'center', gap: 1, p: 2, borderRadius: 2, bgcolor: "aliceblue" }}
                            >
                                Categories
                            </Typography>
                            <List>
                                {categories.map((category, index) => (
                                    <ListItem
                                        key={index}
                                        sx={{
                                            p: 1,
                                            cursor: "pointer",
                                            borderRadius: 1,
                                            transition: 'background-color 0.3s ease-in-out',
                                            '&:hover': { backgroundColor: 'rgba(0, 0, 255, 0.1)' },
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Bookmark color="secondary" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body2" color="text.primary">
                                                {category}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Card> */}


                        {/* Recent Posts Section */}
                        <Card sx={{ mb: 4, boxShadow: 0, borderRadius: 2, overflow: 'hidden' }}>
                            <CardContent>
                                <Typography
                                    color="primary"
                                    variant="h6"
                                    fontWeight="bold"
                                    gutterBottom
                                    sx={{ display: 'flex', alignItems: 'center', gap: 1, p: 2, borderRadius: 2, bgcolor: "aliceblue" }}
                                >
                                    Recent Posts
                                </Typography>
                                <List sx={{ padding: 0 }}>
                                    {recentPosts.map((post, index) => (
                                        <ListItem
                                            onClick={() => {
                                                const paths = [
                                                    "timeless-wisdom-for-modern-growth-4-self-help-books-to-transform-your-life",
                                                    "sacred-legends-and-perennial-wisdom-a-journey-through-devotional-and-historical-narratives",
                                                    "exploring-the-mystical-path-a-journey-through-aghori-traditions",
                                                ];
                                                window.location.href = `/books-blogs/${paths[index]}`;
                                            }}
                                            key={index}
                                            sx={{
                                                p: 1,
                                                borderBottom: index < recentPosts.length - 1 ? '1px solid #e0e0e0' : 'none',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 0, 255, 0.1)',
                                                    cursor: 'pointer',
                                                },
                                            }}
                                        >
                                            <ListItemText>
                                                <Typography
                                                    variant="body2"
                                                    color="text.primary"
                                                    sx={{ fontWeight: 'medium', transition: 'color 0.3s ease' }}
                                                >
                                                    {post}
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            </CardContent>
                        </Card>


                        {/* Popular Posts Section */}
                        {/* <Card sx={{ mb: 4, boxShadow: 3 }}>
                            <CardContent>
                                <Typography variant="h6" fontWeight="bold" gutterBottom>
                                    Popular Posts
                                </Typography>
                                <List>
                                    {popularPosts.map((post, index) => (
                                        <ListItem key={index} sx={{ p: 0 }}>
                                            <ListItemText>
                                                <Typography variant="body2" color="primary">
                                                    {post}
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            </CardContent>
                        </Card> */}


                        {/* Share Section */}
                        <Card sx={{ mb: 4, boxShadow: 0, borderRadius: 2, overflow: 'hidden' }}>
                            <CardContent>
                                <Typography
                                    color="primary"
                                    variant="h6"
                                    fontWeight="bold"
                                    gutterBottom
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        p: 2,
                                        borderRadius: 2,
                                        bgcolor: "aliceblue"
                                    }}
                                >
                                    Follow Us On
                                </Typography>



                                {/* Facebook */}
                                <IconButton
                                    onClick={() => handleIconClick(2)}
                                    color="primary"
                                    href="https://www.facebook.com/sharer/sharer.php?u=YOUR_URL"
                                    target="_blank"
                                    sx={{
                                        '&:hover': {
                                            bgcolor: 'rgba(59, 89, 152, 0.1)', // Facebook hover color
                                            transform: 'scale(1.2)',
                                            transition: 'transform 0.3s ease, bgcolor 0.3s ease',
                                        }
                                    }}
                                >
                                    <Facebook sx={{ color: "black" }} />
                                </IconButton>

                                {/* Twitter (X) */}
                                <IconButton
                                    onClick={() => handleIconClick(1)}
                                    color="primary"
                                    href="https://twitter.com/intent/tweet?url=YOUR_URL"
                                    target="_blank"
                                    sx={{
                                        '&:hover': {
                                            bgcolor: 'rgba(29, 161, 242, 0.1)', // Twitter hover color
                                            transform: 'scale(1.2)',
                                            transition: 'transform 0.3s ease, bgcolor 0.3s ease',
                                        }
                                    }}
                                >
                                    <X sx={{ color: "black" }} />
                                </IconButton>

                                {/* Instagram */}
                                <IconButton
                                    onClick={() => handleIconClick(3)}
                                    color="primary"
                                    href="https://www.linkedin.com/shareArticle?mini=true&url=YOUR_URL"
                                    target="_blank"
                                    sx={{
                                        '&:hover': {
                                            bgcolor: 'rgba(0, 119, 181, 0.1)', // LinkedIn hover color
                                            transform: 'scale(1.2)',
                                            transition: 'transform 0.3s ease, bgcolor 0.3s ease',
                                        }
                                    }}
                                >
                                    <Instagram sx={{ color: "black" }} />
                                </IconButton>

                                {/* YouTube */}
                                <IconButton
                                    onClick={() => handleIconClick(4)}
                                    color="primary"
                                    href="https://api.whatsapp.com/send?text=YOUR_URL"
                                    target="_blank"
                                    sx={{
                                        '&:hover': {
                                            bgcolor: 'rgba(37, 211, 102, 0.1)', // WhatsApp hover color
                                            transform: 'scale(1.2)',
                                            transition: 'transform 0.3s ease, bgcolor 0.3s ease',
                                        }
                                    }}
                                >
                                    <YouTube sx={{ color: "black" }} />
                                </IconButton>

                            </CardContent>
                        </Card>

                    </Grid>
                </Grid>


            </Container>
            <FooterComponent />

        </Box >
    );
};

export default BookTwo;
