import React, { useEffect, useState } from 'react';
import SDadminHeader from './SDadminHeader';
import Top20px from '../screens/Top20px';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';



const ContactInbox = () => {

    const navigate = useNavigate()

    const [contactData, setContactData] = useState([])
    const [openedRows, setOpenedRows] = useState([]);

    const [perPersonContact, setperPersonContact] = useState([])

    useEffect(() => {
        const url = `https://backend.shubadinam.com/admin/getinbox`;

        axios.get(url)
            .then((response) => {
                // console.log(response.data);
                setContactData(response.data)
            })
            .catch((error) => {
                // console.log(error);
            })

        const storedOpenedRows = localStorage.getItem('openedRows');
        if (storedOpenedRows) {
            setOpenedRows(storedOpenedRows);
            // console.log(storedOpenedRows);
            // console.log(openedRows);
        }

    }, [])




    const [chngPwd, SetPwd] = useState(false);
    const [confirmPassword, SetConfirmPassword] = useState({
        // oldPwd: "",
        confirmPwd: "",
        reconfirmPwd: "",
    });


    const togglePassword = () => {
        SetPwd(true)
    }


    const pwdhandler = (evt) => {
        SetConfirmPassword({ ...confirmPassword, [evt.target.name]: evt.target.value });
    };

    const clickSubmit = () => {
        if (confirmPassword.confirmPwd !== "") {
            if (confirmPassword.confirmPwd === confirmPassword.reconfirmPwd) {

                const url = `https://backend.shubadinam.com/admin/upt/password`;

                const adminId = sessionStorage.getItem("adminId")

                axios
                    .post(url, { ...confirmPassword, adminId: adminId })
                    .then((response) => {
                        // console.log(response);
                        alert(response.data.message)
                        // alert("Password updted successfully.");
                    })
                    .catch((error) => {
                        alert(error.response.data.message)
                        // console.log(error);
                    });

                SetPwd(false);
            } else {
                alert("not equal");
                SetPwd(false);
            }
        } else {
            alert("password field is empty");
            SetPwd(false);
        }

    };

    const clickClose = () => {
        SetPwd(false)
    };

    const handlePopupClick = (e) => {
        // Prevent the click event from reaching the pwd-container
        e.stopPropagation();
    };

    const handleRowClick = (row, index) => {
        const queryParams = new URLSearchParams({
            perPersonContact: JSON.stringify(row),
        })

        // setperPersonContact(row)

        const url = `/admin-panel/contact/inbox/person-query?${queryParams.toString()}`;
        window.open(url, '_blank');
        // if (!openedRows.includes(index)) {
        //     setOpenedRows((prevRows) => [...prevRows, index]);
        // } else {
        //     setOpenedRows((prevRows) => prevRows.filter((row) => row !== index));
        // }
    };

    useEffect(() => {
        // Store opened rows in localStorage
        localStorage.setItem('openedRows', openedRows);
    }, [openedRows]);




    return (
        <div className="sd-adminpage">
            <div className="sd-body-database">
                <div className="sd-body-database-container">
                    <h3 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Inbox  </h3>
                    <br />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: "50px" }}> S.No</TableCell>
                                    <TableCell align="center">Date</TableCell>
                                    <TableCell align="center"> Name</TableCell>
                                    <TableCell align="right" sx={{ display: "none" }}> Email</TableCell>
                                    <TableCell align="right" sx={{ display: "none" }} >Phone number</TableCell>
                                    <TableCell align="center" sx={{ maxWidth: "200px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}></TableCell>
                                    <TableCell align="right">Id</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[...contactData].reverse().map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
                                        onClick={() => handleRowClick(row, index)}
                                    >
                                        <TableCell style={{ width: "20px" }} align="left">{index + 1}</TableCell>
                                        <TableCell align="center">
                                            {new Date(row.timestamp).toLocaleString('en-GB', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true
                                            })}
                                        </TableCell>

                                        <TableCell component="th" scope="row" align="center">
                                            {row.customerName}
                                        </TableCell>
                                        <TableCell align="center" style={{
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }} sx={{ display: "none" }}>{row.customerEmail}</TableCell>
                                        <TableCell align="right" sx={{ display: "none" }}>{row.customerPhoneNumber}</TableCell>
                                        <TableCell align="center"
                                            sx={{
                                                maxWidth: "150px", // Adjust width to make it smaller
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}>
                                            <Tooltip title={row.customerMessage} arrow>
                                                <span style={{ display: "block", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                    {row.customerMessage}
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.uniqueId || "User"}
                                            {/* {!openedRows.includes(index) && <span style={{ marginLeft: 5, backgroundColor: "red", color: "white", borderRadius: 5, padding: "2px 5px" }}>New</span>} */}
                                        </TableCell>


                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            {
                chngPwd ?
                    <div className="pwd-container" onClick={clickClose}>
                        <div className="pwd-popup" onClick={handlePopupClick}>

                            <label>Enter your New Password :</label>
                            <input type="text" name="confirmPwd" onChange={pwdhandler} />
                            <br />
                            <label>Reconfirm your Password :</label>
                            <input type="text" name="reconfirmPwd" onChange={pwdhandler} />
                            <br />
                            <button onClick={() => clickSubmit()}>Submit</button>
                            <button style={{ marginLeft: "10px", backgroundColor: "#ffd7d7", color: "black" }} onClick={() => clickClose()}>Close</button>

                        </div>
                    </div>
                    :
                    <span></span>
            }
        </div>
    );
};

export default ContactInbox;