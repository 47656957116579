// BlogData.js
export const nakshatraData = [
    {
        title: "Nakshatra Food",
        description: "In Hindu astrology, a person's birth star, also known as Nakshatra, plays a significant role in their life and is believed to influence various aspects, including food preferences.",
        image: require("../../images/boxesImages/food1.jpg"),
        link: "/nakshatra-foods",
        rating: 4,
    },
    {
        title: "Nakshatra Characteristics",
        description: "This is a general overview of the characteristics associated with each Janma Nakshatra or Birth Star in Vedic astrology.",
        image: require("../../images/boxesImages/char1.jpg"),
        link: "/nakshatra-characteristics",
        rating: 5,
    },
    {
        title: "Nakshatra Deity",
        description: "In Hindu way of life, Nakshatras are lunar mansions or constellations that play a significant role in determining one's personality, behaviour, and destiny.",
        image: require("../../images/boxesImages/deity1.png"),
        link: "/nakshatra-deities",
        rating: 4,
    },
    {
        title: "Nakshatra Likes and Dislikes",
        description: "This is a general overview of some tendencies and preferences associated with each Nakshatra.",
        image: require("../../images/boxesImages/likes11.jpg"),
        link: "/nakshatra-likes-dislikes",
        rating: 4,
    }
];
