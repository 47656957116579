import React, { useState, useEffect, useRef } from 'react';
import { Container, Box, Typography, Button, Grid, Paper, FormControl, InputLabel, Select, FormGroup, FormControlLabel, Radio, MenuItem, Accordion, AccordionSummary, AccordionDetails, InputAdornment, IconButton, Chip, Alert, AlertTitle, Dialog, Tab, Tabs, Slide, Divider, DialogTitle, DialogContent, DialogActions, Snackbar, Checkbox, Skeleton } from '@mui/material';
import SlidingHeader from '../components/SlidingHeader';
import PageSlider from '../screens/utils/PageSlider';
import FooterComponent from '../components/footer';
import { Stepper, Step, StepLabel, TextField } from '@mui/material';
import { AccessTime, Add, CheckBox, Close, ExpandMore, LiveTv, LocationCity, NavigateNext, Person, PlayArrow, Remove } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PhoneVerification from '../components/PhoneVerification';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Country, State } from 'country-state-city';
import confetti from 'canvas-confetti';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import EmailIcon from '@mui/icons-material/Email'; // or any other icon you want to use
import SevaPayment from './SevaPayment';
import GallerySection from '../screens/utils/GallerySection';


// Define a premium color palette
const primaryColor = '#4B89D4';
const secondaryColor = '#F0F4F8';
const buttonColor = '#38a1f7';

const services = [
    {
        id: 0,
        name: "Avahanti-Homam",
        price: "500",
        description: "Avahanti Homam is a powerful Vedic ritual designed to eliminate barriers and foster success for students, business professionals, and individuals pursuing spiritual and dharmic paths.",
        timing: "Conducted on every Shukla Paksha Panchami (fifth day after Amavasya).",
        location: "Performed by Vedic scholars at the Veda Patashala associated with Shubadinam.com.",
        streaming: "Watch the Homam live on Shubadinam TV, available through your dashboard.",
        benefits: [
            "Enhances retention power and intelligence for students.",
            "Promotes health and well-being.",
            "Empowers speech and listening abilities for effective communication.",
            "Attracts wealth, prosperity, and continuous business growth.",
            "Supports spiritual progress towards becoming an enlightened professional."
        ],
        image: require("../images/thumbnails/ahavanti homam.jpg"),
        banner: require("../images/virtualseva/vs_ab.jpg"),
        availableDates: ["2025-01-04", "2025-02-02", "2025-03-03", "2025-04-02", "2025-05-02", "2025-05-31", "2025-06-30", "2025-07-29", "2025-08-28", "2025-09-27", "2025-10-26", "2025-11-25", "2025-12-25"], // Random dates for each month in 2024
        url: "Q5c4s_X6Bbk"
    },
    {
        id: 1,
        name: "Ganapathi-Homam",
        price: "500",
        description: "Maha Ganapathi Homam is a sacred ritual performed to invoke the blessings of Lord Ganesha, the remover of obstacles and the lord of beginnings, to ensure success in all endeavors.",
        timing: "Performed on every Krishna Paksha Chaturthi (fourth day after Poornima).",
        location: "Conducted by Vedic scholars at the Veda Patashala associated with Shubadinam.com.",
        streaming: "Watch the Homam live on Shubadinam TV, available through your dashboard.",
        benefits: [
            "Removing Obstacles: Helps overcome physical and mental challenges in personal, professional, and academic areas.",
            "Wealth and Prosperity: Assists in achieving clarity of mind to resolve financial issues, attracting wealth and prosperity.",
            "Enlightenment: Aids in finding inner peace and gaining awareness of higher spiritual realms.",
        ],
        image: require("../images/thumbnails/gnapathi homam.jpg"),
        banner: require("../images/virtualseva/vs_gb.jpg"),
        availableDates: ["2024-12-19", "2025-01-17", "2025-02-16", "2025-03-18", "2025-04-17", "2025-05-16", "2025-07-14", "2025-08-13", "2025-09-11", "2025-10-10", "2025-11-08", "2025-12-08"], // Random dates for each month in 2024
        url: "-YOQ-KzAF1E"

    },
    {
        id: 2,
        name: "Surya-Namaskaram",
        price: "250",
        description: "Popularly Surya Namaskar is done by yoga practioners as a combination of 12 Asanas. Alternatively there is a vedic way of doing Surya Namaskar in which everyone can participate and benefit. Here the Acharya will first start with a puja to invoke Lord Surya in a Kalash. Then the Vedic pundits will recite the Surya Namaskara Prashna or Aruna Prashna, that consists of 32 Anuvaakams (chapters). It is then followed by Navagraha Mantras and conclusion Puja. Surya Namaskara Prashna or Aruna Prashna parayana consists of 32 Anuvaakams (chapters). When the Japam is performed, it is in our sampradaya, to offer one sashtanga namaskaram (prostration), facing east (Surya Bhagwan), at the end of every Anuvaakam (chapter). Totally about 32+ Namaskarams will be performed. It is advised that those with any medical history may consult their physician before performing namaskarams at a stretch.",
        timing: "Conducted every Sunday at 6 am.",
        location: "Conducted by Vedic scholars at the Veda Patashala associated with Shubadinam.com.",
        streaming: "Watch the Parayana live on Shubadinam TV, available through your dashboard.",
        benefits: [
            "Promotes good health and prosperity",
            "Provides relief from sins and fears.",
            "Enhances spiritual growth and well-being.",
        ],
        image: require("../images/thumbnails/suryanamasakar.jpg"),
        banner: require("../images/virtualseva/vs_sb.jpg"),
        availableDates: ["2025-01-05", "2025-01-12", "2025-01-19", "2025-01-26", "2025-02-02", "2025-02-09", "2025-02-16", "2025-02-23", "2025-03-02", "2025-03-09", "2025-03-16", "2025-03-23", "2025-03-30", "2025-04-06", "2025-04-13", "2025-04-20", "2025-04-27", "2025-05-04", "2025-05-11", "2025-05-18", "2025-05-25", "2025-06-01", "2025-06-08", "2025-06-15", "2025-06-22", "2025-06-29", "2025-07-06", "2025-07-13", "2025-07-20", "2025-07-27", "2025-08-03", "2025-08-10", "2025-08-17", "2025-08-24", "2025-08-31", "2025-09-07", "2025-09-14", "2025-09-21", "2025-09-28", "2025-10-05", "2025-10-12", "2025-10-19", "2025-10-26", "2025-11-02", "2025-11-09", "2025-11-16", "2025-11-23", "2025-11-30", "2025-12-07", "2025-12-14", "2025-12-21", "2025-12-28"],// Random dates for each month in 2024
        url: "z6OCposFbz8"

    },
];

const nakshatras = [
    "Ashwini",
    "Bharani",
    "Krittika",
    "Rohini",
    "Mrigashira",
    "Ardra",
    "Punarvasu",
    "Pushya",
    "Ashlesha",
    "Magha",
    "Purva Phalguni",
    "Uttara Phalguni",
    "Hasta",
    "Chitra",
    "Swati",
    "Vishakha",
    "Anuradha",
    "Jyeshtha",
    "Mula",
    "Purva Ashadha",
    "Uttara Ashadha",
    "Shravana",
    "Dhanishta",
    "Shatabhisha",
    "Purva Bhadrapada",
    "Uttara Bhadrapada",
    "Revati"
];

const plan = {
    name: 'Premium Plan',
    price: '₹ 349 (including GST) / Yearly',
    description: [
        'Email Notifications (Globally)',
        'SMS (India only)',
        'WhatsApp (India and Global)',
        'Free Delivery for Merchandise (India only)',
        'One Free Virtual Puja Seva Slot (of value ₹500)',
        'Nakshatra / Tithi Finder Assistance (As per English Date of Birth)',
    ],
    image: 'https://img.freepik.com/free-vector/multicolor-infographic-with-steps_1409-28.jpg', // Replace with an actual image
};


const SevaLayout = () => {

    const slideRef = useRef(null);
    const confirmpopRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();


    const [userData, setUserData] = useState(null);
    const [premiumDetails, setPremiumDetails] = useState(null);
    const [status, setStatus] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [sevaTitle, setTitle] = useState("");
    const [isLogged, SetLogged] = useState(true);

    const [selectedService, setSelectedService] = useState();
    const [monthsToDisplay, setMonthsToDisplay] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [personCount, setPersonCount] = useState(1);
    const [personDetails, setPersonDetails] = useState([{ name: '', nakshatra: '', gotram: '' }]);

    const [dates, setDates] = useState([]);

    const [sliderOpen, setsliderOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [sliderTab, setSliderTab] = useState(0);  // To manage active tab state

    const [isChecked, setIsChecked] = useState(false);
    const [featuresOpen, setFeaturesOpen] = useState(false);

    // State to control when confetti should be shown
    const [showConfetti, setShowConfetti] = useState(false);


    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayClick = () => {
        setIsPlaying(true);
    };

    const defaults = {
        spread: 360,        // Spread of the particles (360 for a full circle)
        ticks: 100,         // Duration of the confetti effect
        gravity: 1,         // Gravity affecting the confetti (1 to make it fall)
        decay: 0.94,        // Decay rate of the particles
        startVelocity: 30,  // Initial velocity of the confetti
        colors: ['#FFE400', '#FFBD00', '#E89400', '#FFCA6C', '#FDFFB8'], // Confetti colors
        angle: 90,          // Angle of the confetti falling (90 for downward)
        origin: { x: 0.5, y: 0 }, // Origin in the middle of the top of the screen
    };

    // Function to trigger the confetti burst
    const shoot = () => {
        // Shooting stars (larger particles, spread out)
        confetti({
            ...defaults,
            particleCount: 40, // Number of particles
            scalar: 1.2,       // Scaling factor for particle size
            shapes: ['star'],  // Confetti shape - stars
        });

        // Shooting smaller circles (smaller particles)
        confetti({
            ...defaults,
            particleCount: 100, // Number of particles
            scalar: 0.75,      // Smaller particles
            shapes: ['circle'], // Confetti shape - circles
        });
    };

    // Trigger confetti bursts when the state changes
    useEffect(() => {
        if (showConfetti) {
            setTimeout(shoot, 0);   // Immediate confetti burst
            setTimeout(shoot, 100); // Short delay, second burst
            setTimeout(shoot, 200); // Another short delay, third burst

            // Reset trigger after animation (optional)
            setTimeout(() => setShowConfetti(false), 300);
        }
    }, [showConfetti]);

    // get seva name, set services, logged or not local storage getter 
    useEffect(() => {
        const pathSegments = location.pathname.split("/");
        const lastSegment = pathSegments[pathSegments.length - 1];
        setTitle(lastSegment);

        const matchedService = services.find(service => service.name === lastSegment);
        if (matchedService) {
            setSelectedService(matchedService);
        }

        const is_logged = localStorage.getItem("token");
        if (!is_logged) {
            SetLogged(false)
        }
    }, [location, isLogged])

    // entry slide ref
    useEffect(() => {

        const handleClickOutside = (event) => {
            if (!confirmpopRef.current) {
                if (slideRef.current && !slideRef.current.contains(event.target)) {
                    setsliderOpen(false);
                }
            }
        };

        // Add event listener
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);



    // Effect to control scrolling when the slider is open/closed
    useEffect(() => {
        if (sliderOpen) {
            document.body.style.overflow = 'hidden'; // Disable scrolling
        } else {
            document.body.style.overflow = 'auto'; // Restore scrolling
        }

        // Cleanup on unmount
        return () => {
            document.body.style.overflow = 'auto'; // Ensure scrolling is always restored
        };
    }, [sliderOpen]); // Runs when sliderOpen changes

    // get the details from protected route
    useEffect(() => {
        const token = localStorage.getItem("token");

        if (!token) {
            setStatus("new user");
            return;
        }

        const fetchProtectedData = async () => {
            try {
                const response = await fetch("https://backend.shubadinam.com/protected-route", {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                const data = await response.json();

                if (!response.ok) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: data.message
                    });
                    localStorage.clear();
                    setStatus("new user");
                    SetLogged(false)
                    return;
                }

                setUserData(data.user);
                setStatus("user");

                // Fetch premium details only if uniqueId is present
                if (data.user.uniqueId) {
                    await fetchPremiumDetails(data.user.uniqueId);
                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.message
                });
                localStorage.clear();
                SetLogged(false)
                setStatus("new user");
            }
        };

        const fetchPremiumDetails = async (secureId) => {
            try {
                const url = `https://backend.shubadinam.com/premiumcheck/signinpopup?secureId=${secureId}`;
                const response = await axios.get(url);
                if (response.status === 200) {
                    setPremiumDetails({ usertype: "premium" })
                } else {
                    setPremiumDetails({ usertype: "non-premium" })
                }
            } catch (error) {
                setPremiumDetails({ usertype: "non-premium" })
            }
        };

        fetchProtectedData();
    }, []); // Removed navigate from dependencies



    const handleServiceChange = (event) => {
        const selectedService = services[event.target.value];
        setSelectedService(selectedService);
        setDates(filterDatesByMonth(selectedMonth, selectedService.availableDates)); // Update dates based on selected service and month
        setSelectedDate(null); // Reset selected date when service changes
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const filterDatesByMonth = (month, availableDates) => {
        if (!month) return availableDates; // If no month selected, return all dates
        const monthIndex = new Date(`${month} 1, 2024`).getMonth(); // Convert month to index (0-11)
        return availableDates.filter((date) => new Date(date).getMonth() === monthIndex);
    };

    const handleMonthChange = (event) => {
        const month = event.target.value;
        setSelectedMonth(month);
        setDates(filterDatesByMonth(month, selectedService.availableDates)); // Filter available dates based on selected month
    };


    const handleBooking = (isbooked) => {
        // setTimeout(() => {
        //     alert("Booking Successful!");
        setShowConfetti(true);
        setActiveStep(2);
        setIsSubmitting(false);
        setSelectedDate(null);
        // }, 2000);
    };

    const handlePaybtn = (isProcessing) => {
        setIsSubmitting(isProcessing);
    };


    useEffect(() => {
        if (selectedService) {

            const currentMonth = new Date().getMonth(); // Get current month (0-11)
            const currentDate = new Date();
            const today = currentDate.getDate();

            // Get available dates for the selected service
            const availableDates = selectedService.availableDates;

            // Filter out the available dates of the current month
            const currentMonthAvailableDates = availableDates.filter(date => {
                const dateObj = new Date(date);
                return dateObj.getMonth() === currentMonth && dateObj.getDate() >= today;
            });

            if (currentMonthAvailableDates.length === 0) {
                // If no available dates left for the current month, show next 3 months
                const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                const nextThreeMonths = [
                    months[(currentMonth + 1) % 12],
                    months[(currentMonth + 2) % 12],
                    months[(currentMonth + 3) % 12],
                ];
                setMonthsToDisplay(nextThreeMonths);
            } else {
                // Show the current month and next two months
                const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                const nextThreeMonths = [
                    months[currentMonth],
                    months[(currentMonth + 1) % 12],
                    months[(currentMonth + 2) % 12],
                ];
                setMonthsToDisplay(nextThreeMonths);
            }

        }
    }, [selectedService]);



    const handlePersonCountChange = (newCount) => {
        setPersonCount(newCount);

        // Merge old values with new person count, preserving existing values.
        const newPersonDetails = [...personDetails];
        while (newPersonDetails.length < newCount) {
            // Add default values for new persons
            newPersonDetails.push({
                name: '',
                nakshatra: '', // Default nakshatra
                gotram: ''
            });
        }
        while (newPersonDetails.length > newCount) {
            // Remove extra persons if count is decreased
            newPersonDetails.pop();
        }

        setPersonDetails(newPersonDetails);
    };

    const handlePersonDetailChange = (index, field, value) => {
        const updatedDetails = [...personDetails];
        updatedDetails[index][field] = value;
        setPersonDetails(updatedDetails);
    };

    const ENTRYTYPE_handleOpen = (value) => {
        setsliderOpen(true);
        setSliderTab(value)
    };

    const ENTRYTYPE_handleTabChange = (newValue) => {
        setSliderTab(newValue);
    };

    const isFormValid = personDetails.every(person => person.name && person.nakshatra);

    const steps = ['Select Service', 'Person Details', 'Payment'];

    // Calculation
    const calculateTotal = () => {
        const total = selectedService.price * personCount;
        if (personCount > 1 && (premiumDetails && premiumDetails.usertype === "premium")) {
            const discountAmount = total - selectedService.price;
            return discountAmount;
        } else {
            return total;
        }
    };

    // calculate premium
    const calculatePremium = () => {
        if (isChecked) {
            return personCount < 2
                ? (calculateTotal() + 349)
                : calculateTotal() + 349 - selectedService.price;
        }
        return calculateTotal();
    };

    // premium features
    const handlePremiumFeatures = () => {
        setFeaturesOpen(true)
        setsliderOpen(true)
    }


    // sign up

    const [isVerifyclicked, setVerifiedClicked] = useState(false)

    const [isSubmitClicked, SetIsSubmitClicked] = useState(false);

    const [isOptSent, SetIsOtpSent] = useState(false);

    const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility



    const verifyRegOtp = () => {


        setVerifiedClicked(true)

        // const encodedDetails = localStorage.getItem("details");
        // const decodedDetails = atob(encodedDetails);

        // console.log(decodedDetails);


        const regPhone = formData.userPhone

        // const regPhone = localStorage.getItem("email")
        const regOtp = sessionStorage.getItem("otp");
        // console.log(regOtp)
        // console.log(regPhone);

        const url = `https://backend.shubadinam.com/verifyRegOtp?userPhone=${regPhone}&&dialCode=${formData.dialCode}&&userOtp=${regOtp}&&fromPage=${sevaTitle}`

        axios.get(url)
            .then((response) => {

                console.log(response.data);
                const token = response.data.token;
                const uniqueId = response.data.uniqueId
                localStorage.setItem("token", token);
                localStorage.setItem("SD_Id", uniqueId);
                localStorage.setItem("homeauth", true);
                if (response.data.routeTo === "redirect_dashboard") {
                    window.location.href = '/dashboard'; // Redirect to a success page
                } else {
                    setsliderOpen(false);
                    SetIsOtpSent(false);
                    // Set the message for the snackbar
                    setSnackbarMessage("Welcome to Shubadinam! We're glad to have you. Have a wonderful day ahead!");
                    window.location.reload();
                    // Open the Snackbar
                    setOpenSnackbar(true);
                    SetLogged(true)
                }
            })

            .catch((error) => {
                // console.log(error);

                setVerifiedClicked(false)

                setOtpInputs(["", "", "", ""]);

                const invaildOtp = error.response.data.message

                Swal.fire({
                    title: 'Authentication Revoked',
                    text: invaildOtp,
                    icon: 'info',
                    confirmButtonText: 'Got it!', // Change the confirm button text
                    confirmButtonColor: '#3085d6', // Change the confirm button color
                    background: '#f5f5f5',
                });
            })
    }

    const resendOtp = () => {
        clickSubmit()
    }

    const inputRefs = useRef([]);
    const [isNotNumber, setIsNotNumber] = useState(false)

    const otpHandler = (index, value) => {

        if (!/^\d*$/.test(value)) {
            value = ""; // Set value to an empty string if not a number
            setIsNotNumber(true)
            return
        }
        setIsNotNumber(false)
        const updatedInputs = [...otpInputs];
        updatedInputs[index] = value;
        setOtpInputs(updatedInputs);

        // Combine all input values and store in sessionStorage
        const otpValue = updatedInputs.join("").trim();
        sessionStorage.setItem("otp", otpValue);

        setOtpInputs(updatedInputs);

        if (value && index < otpInputs.length - 1) {
            inputRefs.current[index + 1].focus();
        }

    };

    const handleKeyDown = (index, event) => {
        if (event.keyCode === 8 && !otpInputs[index] && index > 0) {
            // Handle Backspace key when input is empty
            inputRefs.current[index - 1].focus();
        }
    };


    const formRef = useRef(null);




    const [formData, setFormData] = useState({
        userName: '',
        userEmail: '',
        dialCode: '',
        userPhone: '',
        userCountry: 'India',
        userState: '',
    });

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState('IN'); // Default country as India (IN)
    const [selectedState, setSelectedState] = useState('');

    useEffect(() => {
        // Fetch all countries and set them
        setCountries(Country.getAllCountries());

        // Fetch the states of India by default
        const indiaStates = State.getStatesOfCountry('IN');  // Get states of India
        setStates(indiaStates);

        // You can set the default state if needed (optional)
        setSelectedState('');  // Select the first state of India by default
    }, []);


    // country handler
    const handleCountryChange = (event) => {
        const countryCode = event.target.value;
        const country = countries.find(c => c.isoCode === countryCode); // Get full country name

        setSelectedCountry(countryCode);
        setSelectedState(''); // Reset state when country changes
        setFormData((prev) => ({
            ...prev,
            userCountry: country ? country.name : '', // Update formData with full country name
            userState: '' // Reset state in formData
        }));

        const countryStates = State.getStatesOfCountry(countryCode);
        setStates(countryStates);
    };

    // state handler
    const handleStateChange = (event) => {
        const stateCode = event.target.value;
        const state = states.find(s => s.isoCode === stateCode); // Get full state name

        setSelectedState(stateCode);
        setFormData((prev) => ({
            ...prev,
            userState: state ? state.name : '' // Update formData with full state name
        }));
    };

    // input handler
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // phone handler
    const handlePhoneChange = (value, country) => {
        console.log(country.dialCode);

        setFormData({
            ...formData,
            userPhone: value, // Store only the phone number
            dialCode: country.dialCode,
        });

    };

    // otp html
    const renderOtpInputs = () => (
        <Box
            id="reg-inputs"
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 2,
                padding: '10px 0',
            }}
        >
            {otpInputs.map((value, index) => (
                <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={value}
                    ref={ref => (inputRefs.current[index] = ref)}
                    onChange={e => otpHandler(index, e.target.value)}
                    onKeyDown={e => handleKeyDown(index, e)}
                    inputMode="numeric"
                    style={{
                        width: '40px',
                        height: '40px',
                        fontSize: '24px',
                        textAlign: 'center',
                        border: '2px solid #38a1f7',
                        borderRadius: '5px',
                        marginRight: '5px',
                        outline: 'none',
                        transition: 'border-color 0.3s',
                    }}
                    onFocus={(e) => e.target.style.borderColor = '#38a1f7'}
                    onBlur={(e) => e.target.style.borderColor = '#ccc'}
                />
            ))}
        </Box>
    );



    // pre-submit
    const clickSubmitcheck = (e) => {
        e.preventDefault();
        console.log(formData);

        if (!/^\d+$/.test(formData.userPhone)) {
            alert('Please enter only numeric values in the phone number field.');
            return; // Do not proceed further if the input is invalid
        }


        // Show the custom modal with the confirmation message
        setIsModalOpen(true);
    };

    const handleConfirm = () => {
        setIsModalOpen(false); // Close the modal
        clickSubmit(); // Proceed with the submit action
    };

    // Handle cancel to dismiss the modal
    const handleCancel = () => {
        setIsModalOpen(false); // Close the modal
    };



    const clickSubmit = () => {

        if (!isSubmitClicked) {
            SetIsSubmitClicked(true);

            const link = "https://backend.shubadinam.com/upload/mailpass"
            axios.post(link, { ...formData, fromPage: sevaTitle })
                .then((response) => {

                    if (response.data.errors) {

                        const invalidmailPass = response.data.errors[0].msg

                        Swal.fire({
                            title: 'Invalid',
                            text: invalidmailPass,
                            icon: 'error',
                            confirmButtonText: 'Got it!', // Change the confirm button text
                            confirmButtonColor: '#3085d6', // Change the confirm button color
                            background: '#f5f5f5',
                        });


                    }
                    else {

                        // shows otp 
                        SetIsOtpSent(true)

                    }

                    SetIsSubmitClicked(false);

                })
                .catch((error) => {
                    console.log(error); // Log the error for debugging

                    // Default error message
                    let backendError = "An unknown error occurred";
                    let userExist = null;

                    // Determine the type of error
                    if (error.response) {
                        userExist = error.response.data.error;
                        backendError = userExist || "An error occurred with the request.";
                    } else if (error.request) {
                        backendError = "No response received from the server.";
                    } else {
                        backendError = error.message;
                    }

                    // Specific message for network errors
                    if (backendError === "Network Error") {
                        backendError = "Network error, please check your internet connection.";
                    }

                    // Display the alert
                    Swal.fire({
                        title: userExist ? 'User Already Exist' : 'Error',
                        text: backendError,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#3085d6',
                        background: '#f5f5f5',
                    });
                });


            SetIsSubmitClicked(false);


        }

    }
    return (
        <Box >
            <SlidingHeader />
            <PageSlider
                bgImage={selectedService && selectedService.banner}
                pageTitle={selectedService && selectedService.name}
                subTitle="Celebrating Cultural Identity Through Birth Stars"
            />
            {
                selectedService ?

                    <>
                        <Container maxWidth="lg" sx={{ mb: 8, position: "relative", zIndex: 99, opacity: sliderOpen ? 0.3 : 1, bgcolor: sliderOpen ? "rgba(0, 0, 0, 0.1)" : "transparent", }}>
                            {/* Virtual Seva Information Section */}
                            <Box sx={{
                                textAlign: 'center', mb: 4, backgroundColor: secondaryColor, borderRadius: '12px', p: 4,
                                display: 'flex', flexDirection: { xs: 'column', md: 'column' }, justifyContent: 'center', gap: 1
                            }}>
                                <Typography variant="h6" sx={{
                                    textAlign: "start", fontWeight: 700, color: primaryColor,
                                }}>
                                    Benefits of {selectedService.name}
                                </Typography>
                                <Typography variant="body2" component="div" sx={{
                                    textAlign: "start", color: '#333', fontSize: { xs: '1rem', sm: '1.2rem' },
                                    listStyleType: 'disc', paddingLeft: 2  // Adds bullet points and padding
                                }}>
                                    <ul>
                                        {selectedService.benefits.map((benefit, index) => (
                                            <li key={index}>
                                                <Typography variant="body1">{benefit}</Typography>
                                            </li>
                                        ))}
                                    </ul>

                                </Typography>

                            </Box>

                            {/* Booking Section */}
                            <Grid container spacing={4} justifyContent="center">
                                <Grid item xs={12} md={6}>
                                    <Paper sx={{
                                        p: 1, borderRadius: '12px', boxShadow: 3, backgroundColor: secondaryColor,
                                    }}>
                                        <Typography
                                            variant="h5"
                                            gutterBottom
                                            sx={{
                                                fontWeight: 600,
                                                color: primaryColor,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                fontSize: '1.25rem', // Slightly larger font for the title
                                                transition: 'color 0.3s ease', // Smooth transition effect
                                                '&:hover': {
                                                    color: '#38a1f7', // Hover color change
                                                }
                                            }}
                                        >
                                            Selected Service

                                        </Typography>

                                        {selectedService && (
                                            <Typography
                                                component="span"
                                                sx={{
                                                    fontWeight: 500,
                                                    color: 'rgba(0, 0, 0, 0.7)',
                                                    fontSize: '1.1rem', // Slightly smaller font for the service name
                                                    marginLeft: 2, // Space between the label and the service name
                                                    fontStyle: 'italic' // Italicize the service name for emphasis
                                                }}
                                            >
                                                {selectedService.name} - <span style={{ color: "red" }}> ₹{selectedService.price}</span>/person (including gst).
                                            </Typography>
                                        )}

                                        {/* Show service details if selected */}
                                        {selectedService && (
                                            <Box mt={4}>
                                                <Box onClick={handlePlayClick}
                                                    sx={{
                                                        cursor: "pointer",
                                                        width: "100%",
                                                        padding: { xs: '10px', sm: '20px' },
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        position: 'relative',
                                                        borderRadius: '8px', // Add rounded corners for a modern look
                                                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
                                                        overflow: 'hidden', // Ensures that the image and iframe are clipped to rounded corners
                                                        backgroundImage: !isPlaying
                                                            ? `url('${selectedService.image}')`
                                                            : 'none', // Set the background image when not playing
                                                        backgroundSize: 'contain', // Ensure the image covers the entire container
                                                        backgroundPosition: 'center', // Keep the background centered
                                                        backgroundRepeat: 'no-repeat', // Prevent image repetition
                                                        height: '315px', // Set a fixed height for the container
                                                        transition: 'background 0.3s ease', // Smooth transition for the background change
                                                    }}
                                                >
                                                    {/* Play Button */}
                                                    {!isPlaying && (
                                                        <IconButton
                                                            size='large'
                                                            sx={{
                                                                position: 'absolute',
                                                                zIndex: 3,
                                                                fontSize: '60px', // Larger play button
                                                                color: '#fff',
                                                                backgroundColor: "black",
                                                                opacity: 0.8,
                                                                transition: 'opacity 0.3s ease',
                                                                '&:hover': {
                                                                    opacity: 1, // On hover, make the play button fully visible
                                                                },
                                                            }}
                                                            onClick={handlePlayClick}
                                                        >
                                                            <PlayArrow />
                                                        </IconButton>
                                                    )}

                                                    {/* Video Iframe */}
                                                    {isPlaying && (
                                                        <iframe
                                                            width="100%"
                                                            height="100%"
                                                            src={`https://www.youtube.com/embed/${selectedService.url}?autoplay=1`}
                                                            title="YouTube video player"
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen
                                                            style={{ borderRadius: '8px' }} // Keep iframe with rounded corners
                                                        ></iframe>
                                                    )}
                                                </Box>

                                                <Typography variant="body2" sx={{ color: '#666', p: 2 }}>
                                                    {selectedService.description}
                                                </Typography>

                                                <Typography gutterBottom>Additional Informations :</Typography>

                                                <Grid container item gap={2} mt={1} p={2}>

                                                    <Typography variant="body2" sx={{ color: '#666' }}>
                                                        <AccessTime sx={{ color: "#666", mr: 1 }} />  {selectedService.timing}
                                                    </Typography>
                                                    {/* Location */}
                                                    <Typography variant="body2" sx={{ color: '#666' }}>
                                                        <LocationCity sx={{ color: "#666", mr: 1 }} />  {selectedService.location}
                                                    </Typography>

                                                    {/* Streaming */}
                                                    <Typography variant="body2" sx={{ color: '#666' }}>
                                                        <LiveTv sx={{ color: "#666", mr: 1 }} />  {selectedService.streaming}
                                                    </Typography>
                                                </Grid>

                                            </Box>

                                        )}
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Paper sx={{ p: 2, borderRadius: '12px', boxShadow: 3, backgroundColor: secondaryColor }}>

                                        <Typography variant="h5" gutterBottom sx={{
                                            fontWeight: 600, color: primaryColor, mb: 4, fontSize: '1.25rem',
                                        }}>
                                            Book your slots in 3 simple steps:
                                        </Typography>

                                        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
                                            {steps.map((label, index) => (
                                                <Step key={index}>
                                                    <StepLabel
                                                        sx={{ '& .MuiStepLabel-label': { color: 'grey', fontWeight: '500' } }}
                                                    >
                                                        {label}
                                                    </StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>



                                        {activeStep === 0 && (

                                            isLogged ?

                                                <>

                                                    {/* Dropdown for selecting service */}
                                                    <FormControl fullWidth variant="outlined" disabled={!isLogged}>
                                                        <InputLabel htmlFor="service-select">Select a Service</InputLabel>
                                                        <Select
                                                            value={selectedService.id}
                                                            onChange={handleServiceChange}
                                                            label="Select a Service"
                                                            inputProps={{ id: 'service-select' }}
                                                            sx={{ borderRadius: '8px', padding: '8px', marginBottom: 2 }}
                                                        >
                                                            <MenuItem disabled>Select a Service</MenuItem>
                                                            {services.map((service) => (
                                                                <MenuItem key={service.id} value={service.id}>
                                                                    {service.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>


                                                    {/* Dropdown for selecting month */}
                                                    <FormControl fullWidth variant="outlined" disabled={!isLogged}>
                                                        <InputLabel htmlFor="month-select">Select Month</InputLabel>
                                                        <Select
                                                            value={selectedMonth}
                                                            onChange={handleMonthChange}
                                                            label="Select Month"
                                                            inputProps={{ id: 'month-select' }}
                                                            sx={{ borderRadius: '8px', padding: '8px', marginBottom: 2 }}
                                                        >
                                                            <MenuItem disabled>Select a month</MenuItem>
                                                            {monthsToDisplay.map((month, index) => (
                                                                <MenuItem key={index} value={month}>
                                                                    {month}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>

                                                    {/* Dropdown for selecting date */}
                                                    <FormControl fullWidth variant="outlined" disabled={!isLogged}>
                                                        <InputLabel htmlFor="date-select">Select a Date</InputLabel>
                                                        <Select
                                                            value={selectedDate}
                                                            onChange={handleDateChange}
                                                            label="Select a Date"
                                                            inputProps={{ id: 'date-select' }}
                                                            sx={{ borderRadius: '8px', padding: '8px' }}
                                                            disabled={!selectedMonth}
                                                        >
                                                            <MenuItem disabled>Select a Date</MenuItem>
                                                            {dates.map((date) => (
                                                                <MenuItem key={date} value={date}>
                                                                    {new Date(date).toLocaleString("en-GB", { dateStyle: "short" })}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>


                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        onClick={() => setActiveStep(1)}
                                                        sx={{ mt: 3, fontWeight: 600, backgroundColor: buttonColor }}
                                                        disabled={isSubmitting || !selectedDate}
                                                    >
                                                        Next
                                                    </Button>





                                                </>
                                                :
                                                <>
                                                    <Divider sx={{ width: "100%", height: "1px", bgcolor: "grey" }} />
                                                    <Grid container item direction="column" justifyContent="space-evenly" alignItems="center" sx={{ width: "100%" }}>
                                                        {/* login */}
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => ENTRYTYPE_handleOpen(0)}
                                                            sx={{ mt: 3, fontWeight: 600, backgroundColor: "#115993", width: "40%" }}
                                                        >
                                                            Sign Up
                                                        </Button>
                                                        {/* sign up */}
                                                        <Box
                                                            sx={{
                                                                textAlign: 'center',
                                                                mt: 2,
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="subtitle1"
                                                                sx={{
                                                                    mb: 2,
                                                                    color: '#555',
                                                                    fontSize: '1rem',
                                                                    fontWeight: 500
                                                                }}
                                                            >
                                                                Already have an account?
                                                                <Link onClick={() => ENTRYTYPE_handleOpen(1)}
                                                                    style={{
                                                                        textDecoration: 'none',
                                                                        display: 'inline-block',
                                                                        marginTop: '8px',
                                                                        marginLeft: "10px"
                                                                    }}
                                                                >
                                                                    Log In
                                                                </Link>
                                                            </Typography>
                                                        </Box>


                                                    </Grid>
                                                </>
                                        )}

                                        {activeStep === 1 && (
                                            <>

                                                {/* prmeium note */}
                                                {
                                                    premiumDetails.usertype !== "premium" && (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-evenly',
                                                                borderRadius: '8px',
                                                                minWidth: '320px',
                                                                mb: 2,
                                                                transition: 'all 0.3s ease-in-out',
                                                            }}
                                                        >
                                                            <Box sx={{ display: "flex", flexDirection: "column", cursor: "pointer" }} onClick={() => handlePremiumFeatures()}>
                                                                <Typography
                                                                    variant="body2"
                                                                    sx={{
                                                                        color: '#0d47a1',
                                                                        fontSize: '14px',
                                                                        transition: 'color 0.3s',
                                                                        '&:hover': {
                                                                            color: '#0d47a1',
                                                                            textDecoration: 'underline',
                                                                        },
                                                                    }}
                                                                >
                                                                    1 Free slot... worth ₹500 !
                                                                </Typography>
                                                                <Button
                                                                    size='small'
                                                                    variant="subtitle2"
                                                                    endIcon={<NavigateNext />}
                                                                    sx={{ fontSize: "0.8rem !important", textTransform: "none", color: "grey", width: "fit-content", p: 0 }}
                                                                >
                                                                    Unlock Premium
                                                                </Button>
                                                            </Box>


                                                            <Button
                                                                variant="contained"
                                                                size='small'
                                                                sx={{
                                                                    bgcolor: isChecked ? "gray" : '#1565c0',
                                                                    color: '#fff',
                                                                    borderRadius: '20px',
                                                                    padding: '8px 22px',
                                                                    fontSize: '0.8rem !important',
                                                                    fontWeight: 'bold',
                                                                    boxShadow: '0 3px 8px rgba(0, 0, 0, 0.15)',
                                                                    '&:hover': {
                                                                        bgcolor: '#0d47a1',
                                                                        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
                                                                    },
                                                                }}
                                                                onClick={() => setIsChecked(!isChecked)}
                                                            >
                                                                {isChecked ? "Cancel" : "Add"}
                                                            </Button>
                                                        </Box>
                                                    )
                                                }

                                                {/* Input for person count */}
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    sx={{
                                                        mb: 2,
                                                        borderRadius: '8px',
                                                    }}
                                                >


                                                    <Typography variant="body1" >
                                                        Person Count :
                                                    </Typography>
                                                    <Box display="flex" alignItems="center">
                                                        <IconButton
                                                            size='small'
                                                            onClick={() => handlePersonCountChange(Math.max(1, personCount - 1))}
                                                            disabled={personCount <= 1}
                                                            sx={{
                                                                backgroundColor: '#e0e0e0',
                                                                '&:hover': { backgroundColor: '#d6d6d6' },
                                                            }}
                                                        >
                                                            <Remove />
                                                        </IconButton>
                                                        <TextField
                                                            size='small'
                                                            value={personCount}
                                                            onChange={(e) => {
                                                                const newValue = e.target.value; // Get raw value as string
                                                                if (newValue === '' || (parseInt(newValue, 10) >= 1 && parseInt(newValue, 10) <= 5)) {
                                                                    handlePersonCountChange(newValue === '' ? '' : parseInt(newValue, 10));
                                                                }
                                                            }}
                                                            onBlur={() => {
                                                                // On blur, ensure a valid value is set
                                                                if (personCount === '' || isNaN(personCount)) {
                                                                    handlePersonCountChange(1); // Default to 1 if empty or invalid
                                                                }
                                                            }}
                                                            type="number"
                                                            inputProps={{
                                                                min: 1,
                                                                max: 5,
                                                                style: { textAlign: 'center' },
                                                            }}
                                                            sx={{
                                                                mx: 2,
                                                                width: '60px',
                                                                '& input': { fontSize: '1.2rem' },
                                                            }}
                                                        />
                                                        <IconButton
                                                            size='small'
                                                            onClick={() => handlePersonCountChange(Math.min(5, personCount + 1))}
                                                            disabled={personCount >= 5}
                                                            sx={{
                                                                backgroundColor: '#e0e0e0',
                                                                '&:hover': { backgroundColor: '#d6d6d6' },
                                                            }}
                                                        >
                                                            <Add />
                                                        </IconButton>
                                                    </Box>
                                                </Box>

                                                <Typography variant='subtitle1'>Person Details :</Typography>

                                                {/* Textfields for person details */}
                                                {personDetails.map((person, index) => (
                                                    <Accordion key={index} defaultExpanded={index === 0} sx={{ mb: 2, boxShadow: 0, minWidth: 300 }}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMore />}
                                                            aria-controls={`person-details-${index}-content`}
                                                            id={`person-details-${index}-header`}
                                                            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                                        >
                                                            <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
                                                                Person {index + 1}
                                                            </Typography>
                                                            {!person.name || !person.nakshatra ? (
                                                                <Chip
                                                                    label="Pending"
                                                                    color="warning"
                                                                    size="small"
                                                                    sx={{ fontSize: "0.8rem !important" }}
                                                                />
                                                            ) : <CheckBox sx={{ color: "green" }} />}
                                                        </AccordionSummary>
                                                        <AccordionDetails sx={{ padding: 1, textAlign: "center", display: "flex", justifyContent: "center", flexDirection: { xs: "column", lg: "column" }, alignItems: "center" }}>
                                                            <TextField
                                                                size='small'
                                                                label={`Person ${index + 1} Name`}
                                                                variant="outlined"
                                                                fullWidth
                                                                value={person.name}
                                                                required
                                                                onChange={(e) => handlePersonDetailChange(index, 'name', e.target.value)}
                                                                sx={{
                                                                    mb: 2,
                                                                    // mr: { xs: 0, sm: 2 },
                                                                    maxWidth: 300,
                                                                    textAlign: "start",
                                                                }}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <Person color="action" />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                            <FormControl fullWidth variant="outlined" required sx={{ mb: 2, borderRadius: '8px', maxWidth: 300, textAlign: "start" }}>
                                                                <InputLabel htmlFor={`nakshatra-select-${index}`}>Nakshatra</InputLabel>
                                                                <Select
                                                                    size='small'
                                                                    value={person.nakshatra}
                                                                    onChange={(e) => handlePersonDetailChange(index, 'nakshatra', e.target.value)}
                                                                    label="Nakshatra"
                                                                    inputProps={{ id: `nakshatra-select-${index}` }}
                                                                >
                                                                    {nakshatras.map((nakshatra) => (
                                                                        <MenuItem key={nakshatra} value={nakshatra}>
                                                                            {nakshatra}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>

                                                            {/* gotram */}
                                                            <TextField
                                                                size='small'
                                                                label={`Person ${index + 1} Gotram (optional)`}
                                                                variant="outlined"
                                                                fullWidth
                                                                value={person.gotram}
                                                                onChange={(e) => handlePersonDetailChange(index, 'gotram', e.target.value)}
                                                                sx={{
                                                                    mb: 2,
                                                                    // mr: { xs: 0, sm: 2 },
                                                                    maxWidth: 300,
                                                                    textAlign: "start",
                                                                }}
                                                            />

                                                        </AccordionDetails>
                                                    </Accordion>
                                                ))}

                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Prashad delivery to: <address with pincode>"
                                                        name="userAddress"
                                                        value={formData.userAddress}
                                                        onChange={(e) => {
                                                            setUserData((prev) => ({
                                                                ...prev,
                                                                userAddress: e.target.value, // Corrected key usage
                                                            }));
                                                        }}
                                                        multiline
                                                        sx={{ bgcolor: "#ffff !important", fontSize: "1rem" }}
                                                        rows={3}
                                                        required
                                                        variant="outlined"
                                                    />

                                                </Grid>
                                                <Box sx={{ maxWidth: 600, margin: "0px", mt: 4, p: 0 }}>
                                                    <Accordion sx={{ bgcolor: "#eeee", boxShadow: 0, border: "1px solid #eee", m: 0, p: 0 }}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMore />}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
                                                                <Typography variant="h6">
                                                                    Summary
                                                                </Typography>
                                                                <Typography variant="h6">
                                                                    ₹  {
                                                                        isChecked ? calculatePremium() : calculateTotal()
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        </AccordionSummary>


                                                        <AccordionDetails>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    padding: 0,
                                                                    maxWidth: "600px",
                                                                    margin: "0 0",
                                                                    bgcolor: "#eeee",
                                                                    borderRadius: "12px",
                                                                }}
                                                            >

                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        borderRadius: "8px",
                                                                        padding: 1,
                                                                    }}
                                                                >
                                                                    <Typography variant="subtitle1">
                                                                        Person Count:
                                                                    </Typography>
                                                                    <Typography variant="subtitle1">{personCount}</Typography>
                                                                </Box>
                                                                <Divider sx={{ height: 1, bgcolor: "grey" }} />
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        padding: 1,
                                                                        borderRadius: "8px",
                                                                    }}
                                                                >
                                                                    <Typography variant="subtitle1">
                                                                        Price
                                                                        ( {personCount} x {selectedService.price}  ):
                                                                    </Typography>
                                                                    <Typography variant="subtitle1">
                                                                        {
                                                                            ((personCount > 1 && isChecked) && premiumDetails.usertype === "non-premium") ?
                                                                                <Typography variant="subtitle1">
                                                                                    <Typography
                                                                                        variant="span"
                                                                                        sx={{
                                                                                            color: "red",
                                                                                            fontSize: "1rem",
                                                                                            textDecoration: "line-through",
                                                                                            marginLeft: "5px",
                                                                                            marginRight: "5px",
                                                                                        }}
                                                                                    >
                                                                                        ₹{calculateTotal()}
                                                                                    </Typography>
                                                                                    ₹{calculateTotal() - selectedService.price}
                                                                                </Typography>
                                                                                :
                                                                                <>
                                                                                    ₹{calculateTotal()}
                                                                                </>
                                                                        }</Typography>
                                                                </Box>
                                                                <Divider sx={{ height: 1, bgcolor: "grey" }} />
                                                                {
                                                                    isChecked && <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            padding: 1,
                                                                            borderRadius: "8px",
                                                                        }}
                                                                    >
                                                                        <Typography variant="subtitle1">
                                                                            Premium package:
                                                                        </Typography>
                                                                        <Typography variant="subtitle1" >₹ 349</Typography>
                                                                    </Box>
                                                                }

                                                                <Divider sx={{ height: 1, bgcolor: "grey" }} />
                                                                {premiumDetails?.usertype === "premium" && personCount > 1 && (
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            padding: 2,
                                                                            borderRadius: "8px",
                                                                            bgcolor: "#e8f5e9",
                                                                            boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
                                                                        }}
                                                                    >
                                                                        <Typography variant="body1" color="green">
                                                                            <strong>Discount:</strong>
                                                                        </Typography>
                                                                        <Typography variant="body1" color="green">
                                                                            ₹{selectedService.price} (1 Slot Free)
                                                                        </Typography>
                                                                    </Box>
                                                                )}

                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        flexDirection: "column",
                                                                        padding: 1,
                                                                        borderRadius: "8px",
                                                                    }}
                                                                >
                                                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#0d47a1" }}>
                                                                            Total

                                                                        </Typography>


                                                                        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#0d47a1" }}>
                                                                            ₹  {
                                                                                isChecked ? calculatePremium() : calculateTotal()
                                                                            }

                                                                        </Typography>
                                                                    </Box>


                                                                    {/* Offer details */}
                                                                    <Box>
                                                                        {premiumDetails?.usertype === "premium" ? (
                                                                            <Box sx={{ mt: 1 }}>
                                                                                {/* Original price with strike-through */}

                                                                                {
                                                                                    personCount > 1 ?
                                                                                        <>
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                sx={{
                                                                                                    textDecoration: "line-through",
                                                                                                    color: "textSecondary",
                                                                                                }}
                                                                                            >
                                                                                                Original: ₹{selectedService.price * personCount}
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                variant="body2"
                                                                                                sx={{
                                                                                                    color: "green",
                                                                                                    fontWeight: "500",
                                                                                                    fontSize: "0.9rem",
                                                                                                }}
                                                                                            >
                                                                                                Premium Offer Applied: Save ₹{selectedService.price}
                                                                                            </Typography>
                                                                                        </>
                                                                                        :
                                                                                        <> <Typography
                                                                                            variant="body2"
                                                                                            sx={{
                                                                                                color: "grey",
                                                                                                fontWeight: "500",
                                                                                                fontSize: "0.9rem",
                                                                                            }}
                                                                                        >
                                                                                            Premium offer not applicable. Select more than 1 slot and save ₹{selectedService.price}.                                                                                    </Typography></>
                                                                                }
                                                                            </Box>
                                                                        ) : (
                                                                            <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "end" }}>

                                                                                {
                                                                                    (personCount > 1 && !isChecked) &&
                                                                                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#0d47a1" }}>
                                                                                        <Typography
                                                                                            variant="span"
                                                                                            sx={{
                                                                                                color: "red",
                                                                                                fontSize: "1rem",
                                                                                                textDecoration: "line-through",
                                                                                                marginLeft: "5px",
                                                                                                marginRight: "5px",
                                                                                            }}
                                                                                        >
                                                                                            ₹{calculateTotal()}
                                                                                        </Typography>
                                                                                        ₹{calculateTotal() - selectedService.price}
                                                                                    </Typography>
                                                                                }

                                                                                <Box sx={{ width: "100%", mt: 2, display: "flex", justifyContent: "space-evenly", flexDirection: "row" }}>



                                                                                    <Box sx={{ display: "flex", flexDirection: "column", width: "70%" }} onClick={() => handlePremiumFeatures()}>
                                                                                        <Typography
                                                                                            variant="subtitle2"
                                                                                            sx={{
                                                                                                cursor: "pointer",
                                                                                            }}

                                                                                        >
                                                                                            1 Free slot... worth ₹500 !
                                                                                        </Typography>

                                                                                        <Button
                                                                                            size='small'
                                                                                            variant="subtitle2"
                                                                                            endIcon={<NavigateNext />}
                                                                                            sx={{ fontSize: "0.8rem !important", textTransform: "none", color: "grey", width: "fit-content", p: 0 }}
                                                                                        >
                                                                                            Buy Premium
                                                                                        </Button>
                                                                                    </Box>



                                                                                    <Button
                                                                                        size='small'
                                                                                        variant="contained"
                                                                                        sx={{
                                                                                            bgcolor: isChecked ? "gray" : '#1565c0',
                                                                                            width: "fit-content",
                                                                                            color: '#fff',
                                                                                            borderRadius: '20px',
                                                                                            height: "fit-content",
                                                                                            fontSize: '0.8rem !important',
                                                                                            padding: '8px 22px',
                                                                                            boxShadow: '0 3px 8px rgba(0, 0, 0, 0.15)',
                                                                                            '&:hover': {
                                                                                                bgcolor: '#0d47a1',
                                                                                                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
                                                                                            },
                                                                                        }}
                                                                                        onClick={() => setIsChecked(!isChecked)}
                                                                                    >
                                                                                        {isChecked ? "Cancel" : "Add"}
                                                                                    </Button>
                                                                                </Box>


                                                                            </Box>
                                                                        )}


                                                                    </Box>
                                                                </Box>


                                                            </Box>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Box>

                                                {/* Buttons for navigating steps */}
                                                <Grid container spacing={2} sx={{ mt: 3 }}>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            fullWidth
                                                            onClick={() => setActiveStep(0)}
                                                            sx={{ fontWeight: 600 }}
                                                        >
                                                            Back
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            fullWidth
                                                            sx={{ fontWeight: 600, backgroundColor: buttonColor, p: 0 }}
                                                            disabled={isSubmitting || !isFormValid || !userData.userAddress} // Disable button if not valid or submitting
                                                        >
                                                            {isSubmitting ? 'Loading...' :
                                                                <SevaPayment
                                                                    userData={userData}
                                                                    premiumDetails={premiumDetails}
                                                                    status={status}
                                                                    sevaTitle={sevaTitle}
                                                                    selectedService={selectedService}
                                                                    selectedDate={selectedDate}
                                                                    personCount={personCount}
                                                                    personDetails={personDetails}
                                                                    isChecked={isChecked} // Pass isChecked from parent
                                                                    disablePaybtn={handlePaybtn}
                                                                    showConfetti={handleBooking}
                                                                />
                                                            }
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}

                                        {activeStep === 2 && (
                                            <Box
                                                sx={{
                                                    p: 4,
                                                    borderRadius: 2,
                                                    textAlign: 'center',
                                                    maxWidth: 400,
                                                    margin: '0 auto',
                                                }}
                                            >
                                                <Typography
                                                    variant="h4"
                                                    gutterBottom
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        color: primaryColor,
                                                    }}
                                                >
                                                    🎉 Booking Confirmed!
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    gutterBottom
                                                    sx={{
                                                        mt: 2,
                                                        color: '#555',
                                                    }}
                                                >
                                                    Your virtual puja seva has been successfully booked. You can now view your bookings and history on the dashboard.
                                                </Typography>
                                                <Button
                                                    size='small'
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{
                                                        mt: 3,
                                                        fontSize: '1rem',
                                                        textTransform: 'none',
                                                    }}
                                                    onClick={() => navigate('/dashboard')}
                                                >
                                                    Go to Dashboard
                                                </Button>
                                            </Box>
                                        )}


                                    </Paper>
                                </Grid>
                            </Grid>

                            <Alert severity="info" sx={{ mt: 6 }}>
                                <AlertTitle>How to participate</AlertTitle>
                                <strong>Booking Slots: </strong>   Book instantly for the upcoming event or pre-book up to 2 months in advance. [Premium customers receive one slot for free.]
                                <br />
                                <strong>Provide Details: </strong>     Enter the name and Nakshatra of the person to be included in the Sankalpa.
                                <br />
                                <strong>Live Sankalpa: </strong>    The names and Nakshatras of all yajamans will be read during the Sankalpa and can be viewed live.
                                <br />
                                <strong>Dress Code: </strong>    Yajamans are encouraged to wear traditional attire while watching the live stream.
                                <br />
                                <strong>Prashad Delivery: </strong>      Homam Prashad will be sent via post.

                            </Alert>


                        </Container >
                        <Dialog ref={confirmpopRef} open={isModalOpen} onClose={handleCancel} maxWidth="sm" fullWidth>
                            <DialogTitle sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                                <Typography variant="h6">Confirm Your Details</Typography>
                            </DialogTitle>
                            <DialogContent sx={{ padding: 3, backgroundColor: '#fafafa' }}>
                                <Typography variant="body1" paragraph>
                                    Please confirm that you want to proceed with the following information:
                                </Typography>
                                <Typography variant="subtitle1" paragraph>
                                    <strong>Name:</strong> {formData.userName}
                                </Typography>
                                <Typography variant="subtitle1" paragraph>
                                    <strong>Email:</strong> {formData.userEmail}
                                </Typography>
                                <Typography variant="subtitle1" paragraph>
                                    <strong>Phone Number:</strong> {formData.userPhone}
                                </Typography>
                                <Typography variant="subtitle1" paragraph>
                                    <strong>Country:</strong> {formData.userCountry}
                                </Typography>
                                <Typography variant="subtitle1" paragraph>
                                    <strong>State:</strong> {formData.userState}
                                </Typography>
                            </DialogContent>
                            <DialogActions sx={{ padding: '1rem', backgroundColor: '#f5f5f5' }}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleCancel}
                                    sx={{
                                        width: '120px',
                                        borderColor: '#d32f2f',
                                        '&:hover': {
                                            backgroundColor: '#f8d7da',
                                            borderColor: '#d32f2f',
                                        },
                                    }}
                                >
                                    No
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleConfirm}
                                    sx={{
                                        width: '120px',
                                        backgroundColor: '#388e3c',
                                        '&:hover': {
                                            backgroundColor: '#2c6b2f',
                                        },
                                    }}
                                >
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                    :
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            p: 3,
                            borderRadius: 2,
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                            textAlign: 'center',
                            backgroundColor: '#f5f5f5',
                            width: '100%',
                            maxWidth: '400px',
                            margin: '0 auto',
                        }}
                    >
                        <Skeleton variant="text" width="60%" height={30} sx={{ mb: 2 }} />
                        <Skeleton variant="rectangular" width="100%" height={200} sx={{ mb: 2 }} />
                        <Skeleton variant="text" width="80%" height={20} sx={{ mb: 2 }} />
                        <Skeleton variant="text" width="50%" height={20} sx={{ mb: 2 }} />
                    </Box>
            }


            <GallerySection />

            <FooterComponent />

            {/* singup or login dialog tabs */}
            <Slide direction="up" in={sliderOpen} ref={slideRef} mountOnEnter unmountOnExit >
                <Box
                    sx={{
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        height: "auto",
                        width: "100%",
                        bgcolor: "background.paper",
                        boxShadow: 3,
                        zIndex: 99,
                        p: 1,
                        overflow: "auto"
                    }}
                >
                    {
                        featuresOpen ?
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: { xs: 'column', md: 'row' }, // Column for mobile, row for desktop
                                        alignItems: 'center',
                                        backgroundColor: '#252e3f',
                                        borderRadius: { xs: '8px', md: '16px' },
                                        width: '100%',
                                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                        position: 'relative',
                                        padding: { xs: 2, md: 4 },
                                    }}
                                >
                                    {/* Close Button */}
                                    <IconButton
                                        onClick={() => { setsliderOpen(false); SetIsOtpSent(false); }}
                                        size="small"
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: '1%',
                                            bgcolor: '#cccc',
                                            borderRadius: 20,
                                            '&:hover': { bgcolor: '#e14c4c', color: 'black' }
                                        }}
                                    >
                                        <Close sx={{ color: 'white', fontSize: "12px" }} />
                                    </IconButton>

                                    {/* Mobile View - Content */}
                                    <Box sx={{ width: '100%', textAlign: 'center', display: { xs: 'block', md: 'none' } }}>
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#ffff',
                                                marginBottom: 2,
                                                fontSize: '1.5rem',
                                            }}
                                        >
                                            {plan.name}
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                fontWeight: 'medium',
                                                color: '#ffff',
                                                marginBottom: 2,
                                                fontStyle: 'italic',
                                                fontSize: '1rem',
                                            }}
                                        >
                                            {plan.price}
                                        </Typography>

                                        {/* Image */}
                                        <Box sx={{ marginBottom: 2, display: 'flex', justifyContent: 'center' }}>
                                            <img
                                                src={plan.image}
                                                alt={plan.name}
                                                style={{ width: '100%', height: 'auto', maxWidth: '100%', objectFit: 'contain' }}
                                            />
                                        </Box>

                                        {/* Buttons */}
                                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mt: 3 }}>
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    bgcolor: "white",
                                                    borderRadius: '20px',
                                                    padding: { xs: '8px 16px', md: '12px 24px' },
                                                    fontSize: { xs: '14px', md: '18px' },
                                                    fontWeight: 'bold',
                                                    textTransform: 'uppercase',
                                                    '&:hover': {
                                                        bgcolor: "white",
                                                    },
                                                }}
                                            >
                                                Learn More
                                            </Button>
                                            <Button
                                                onClick={() => { setIsChecked(!isChecked); setsliderOpen(false) }}
                                                variant="contained"
                                                sx={{
                                                    bgcolor: "#2b9fbf",
                                                    borderRadius: '20px',
                                                    padding: { xs: '8px 16px', md: '12px 24px' },
                                                    fontSize: { xs: '14px', md: '18px' },
                                                    fontWeight: 'bold',
                                                    textTransform: 'uppercase',
                                                    '&:hover': {
                                                        bgcolor: "#2b9fbf",
                                                    },
                                                }}
                                            >
                                                Add Premium
                                            </Button>
                                        </Box>
                                    </Box>

                                    {/* Desktop View - Image Left, Content Right */}
                                    <Box sx={{ display: { xs: 'none', md: 'flex' }, width: '100%' }}>
                                        {/* Left Side - Image */}
                                        <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: "400px", overflow: "hidden" }}>
                                            <img
                                                src={plan.image}
                                                alt={plan.name}
                                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                            />
                                        </Box>

                                        {/* Right Side - Content */}
                                        <Box sx={{ width: '50%', textAlign: 'left', pl: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                            <Typography
                                                variant="h4"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: '#ffff',
                                                    marginBottom: 2,
                                                    fontSize: '2.5rem', // Larger font for desktop
                                                }}
                                            >
                                                {plan.name}
                                            </Typography>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    fontWeight: 'medium',
                                                    color: '#ffff',
                                                    marginBottom: 2,
                                                    fontStyle: 'italic',
                                                    fontSize: '1.25rem',
                                                }}
                                            >
                                                {plan.price}
                                            </Typography>

                                            <Box sx={{ display: 'flex', gap: 3, justifyContent: 'flex-start', mt: 3 }}>
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        bgcolor: "white",
                                                        borderRadius: '20px',
                                                        padding: { xs: '8px 16px', md: '12px 24px' },
                                                        fontSize: { xs: '14px', md: '18px' },
                                                        fontWeight: 'bold',
                                                        textTransform: 'uppercase',
                                                        '&:hover': {
                                                            bgcolor: "white",
                                                        },
                                                    }}
                                                >
                                                    Learn More
                                                </Button>
                                                <Button
                                                    onClick={() => { setIsChecked(!isChecked); setsliderOpen(false) }}
                                                    variant="contained"
                                                    sx={{
                                                        bgcolor: "#2b9fbf",
                                                        borderRadius: '20px',
                                                        padding: { xs: '8px 16px', md: '12px 24px' },
                                                        fontSize: { xs: '14px', md: '18px' },
                                                        fontWeight: 'bold',
                                                        textTransform: 'uppercase',
                                                        transition: "transform 0.2s ease",
                                                        '&:hover': {
                                                            bgcolor: "#2b9fbf",
                                                            transform: "scale(0.96)"
                                                        },
                                                        boxShadow: "5px 2px 2px 0px white"

                                                    }}
                                                >
                                                    Add Premium
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>




                            </>
                            :

                            <>
                                {/* Tabs for different content */}
                                <Box sx={{ display: "flex", justifyContent: "left", gap: 2 }}>
                                    <Button
                                        variant={sliderTab === 0 ? "contained" : "outlined"}
                                        onClick={() => ENTRYTYPE_handleTabChange(0)}
                                        sx={{
                                            textTransform: "none",
                                            fontWeight: sliderTab === 0 ? "bold" : "normal",
                                            bgcolor: sliderTab === 0 ? "primary.main" : "inherit",
                                            color: sliderTab === 0 ? "white" : "primary.main",
                                            "&:hover": { bgcolor: sliderTab === 0 ? "primary.dark" : "" },
                                        }}
                                    >
                                        Sign Up
                                    </Button>
                                    <Button
                                        variant={sliderTab === 1 ? "contained" : "outlined"}
                                        onClick={() => ENTRYTYPE_handleTabChange(1)}
                                        sx={{
                                            textTransform: "none",
                                            fontWeight: sliderTab === 1 ? "bold" : "normal",
                                            bgcolor: sliderTab === 1 ? "primary.main" : "inherit",
                                            color: sliderTab === 1 ? "white" : "primary.main",
                                            "&:hover": { bgcolor: sliderTab === 1 ? "primary.dark" : "" },
                                        }}
                                    >
                                        Log In
                                    </Button>
                                </Box>

                                {/* Button to close the slide */}
                                <Box textAlign="center" sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: "1%",
                                }}>
                                    <IconButton onClick={() => { setsliderOpen(false); SetIsOtpSent(false); }} size='small' sx={{
                                        bgcolor: "#cccc", borderRadius: 0, color: "white",
                                        '&:hover': { bgcolor: "#cccc", color: "white" }
                                    }}>
                                        <Close sx={{ color: "white" }} />
                                    </IconButton>
                                </Box>

                                {/* Content based on tab index */}
                                <Box sx={{ p: 3, textAlign: "center" }}>
                                    {sliderTab === 0 && (
                                        <Box >
                                            <Grid container spacing={4} display="flex" >
                                                <Grid item xs={12} md={8} ref={formRef} sx={{ order: { xs: 1, md: 2 }, backgroundColor: "#ffff" }}>
                                                    {
                                                        isOptSent ?
                                                            <Box sx={{ maxWidth: 400, margin: '0 auto', padding: 3, boxShadow: 3, borderRadius: 2, mt: 5, mb: 5, backgroundColor: "#eaf5ffb3" }}>
                                                                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                                                    OTP Verification
                                                                </Typography>
                                                                <Typography sx={{ mb: 2, color: 'text.secondary' }}>
                                                                    We have sent a verification code to your mobile number.
                                                                </Typography>
                                                                {isNotNumber && (
                                                                    <Typography color="error" sx={{ mb: 2 }}>
                                                                        (Note: Please enter numeric values.)
                                                                    </Typography>
                                                                )}
                                                                {/* OTP Inputs */}

                                                                {renderOtpInputs()}

                                                                <Grid item xs={12} md={12} >

                                                                    <Grid container spacing={1} justifyContent="center">

                                                                        <Grid item xs={6}>
                                                                            <Button
                                                                                size="small"
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                onClick={resendOtp}
                                                                                sx={{ bgcolor: "white", }}
                                                                                fullWidth
                                                                            >
                                                                                Resend OTP
                                                                            </Button>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Button
                                                                                size="small"
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={verifyRegOtp}
                                                                                disabled={isVerifyclicked}
                                                                                fullWidth
                                                                            >
                                                                                {isVerifyclicked ? "Verifying" : "Verify me"}
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                            :

                                                            <form
                                                                onSubmit={clickSubmitcheck} // Assuming clickSubmitcheck handles form submission
                                                                style={{
                                                                    padding: '24px',
                                                                    borderRadius: '8px',
                                                                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    color="secondary"
                                                                    sx={{ marginBottom: 5 }}
                                                                >
                                                                    Embrace Your Janma Nakshatra/Tithi. Unlock insights into your destiny with just a click.
                                                                </Typography>

                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <TextField
                                                                            label="Name"
                                                                            name="userName"
                                                                            placeholder="Enter name"
                                                                            fullWidth
                                                                            sx={{ backgroundColor: "white" }}
                                                                            size="small"
                                                                            onChange={handleChange}
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <Person />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                            required
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <TextField
                                                                            label="Email"
                                                                            name="userEmail"
                                                                            placeholder="Email address"
                                                                            fullWidth
                                                                            size="small"
                                                                            required
                                                                            onChange={handleChange}
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <EmailIcon />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>

                                                                        <PhoneInput
                                                                            validateMobilePrefix={true}
                                                                            country={'in'} // Default country code
                                                                            value={formData.userPhone} // Display with the country code
                                                                            name="userPhone"
                                                                            onChange={handlePhoneChange}
                                                                            inputStyle={{
                                                                                width: '100%',
                                                                                height: '40px',
                                                                                borderRadius: '5px',
                                                                                border: '1px solid #ced4da',
                                                                                paddingLeft: '50px', // Space for the country code dropdown
                                                                            }}
                                                                            inputProps={{
                                                                                inputMode: "numeric"
                                                                            }}
                                                                            dropdownStyle={{
                                                                                borderRadius: '5px',
                                                                            }}
                                                                        />

                                                                    </Grid>

                                                                    <Grid item xs={12} sm={6}>
                                                                        <FormControl fullWidth sx={{ textAlign: "start", textIndent: 15 }}>
                                                                            <select
                                                                                value={selectedCountry}
                                                                                onChange={handleCountryChange}
                                                                                label="Country"
                                                                                placeholder="country"
                                                                                name="userCountry"
                                                                                required
                                                                                style={{ padding: "10px", border: "0.2px solid #d3d1d1", borderRadius: "5px", backgroundColor: "white !important" }}
                                                                            >
                                                                                <option value="" disabled>
                                                                                    Country
                                                                                </option>
                                                                                {countries.map((country) => (
                                                                                    <option key={country.isoCode} value={country.isoCode}>
                                                                                        {country.name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </FormControl>
                                                                    </Grid>


                                                                    <Grid item xs={12} sm={6}>
                                                                        <FormControl fullWidth sx={{ textAlign: "start", textIndent: 15 }}>
                                                                            <select
                                                                                value={selectedState}
                                                                                onChange={handleStateChange}
                                                                                label="State"
                                                                                disabled={!selectedCountry}
                                                                                name="userState"
                                                                                style={{ padding: "10px", border: "1px solid grey !important", borderRadius: "5px", backgroundColor: "white !important" }}
                                                                            >
                                                                                <option value="" disabled>
                                                                                    State
                                                                                </option>
                                                                                {states.map((state) => (
                                                                                    <option key={state.isoCode} value={state.isoCode}>
                                                                                        {state.name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid item xs={12} sx={{ mt: 1 }}>
                                                                        <Button
                                                                            type="submit"
                                                                            variant="contained"
                                                                        >
                                                                            {isSubmitClicked ? 'Submitting...' : 'Submit'}
                                                                        </Button>
                                                                    </Grid>

                                                                </Grid>

                                                            </form>
                                                    }
                                                </Grid>

                                                <Grid item xs={12} md={4} sx={{ order: { xs: 2, md: 1 } }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                                        <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: 'center', alignItems: 'center', overflow: 'hidden', mb: 2 }}>
                                                            <img
                                                                src={require("../images/signup-img.png")}
                                                                alt="Sign Up"
                                                                style={{ maxWidth: '100%', borderRadius: '8px', boxShadow: '0 4px 20px rgba(0,0,0,0.1)', objectFit: "cover" }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}


                                    {sliderTab === 1 && (
                                        <Box sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            alignItems: "center"
                                        }}>

                                            <Grid item container maxWidth="md" display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                                                {/* Image Section */}
                                                <Grid item sx={{ width: { xs: '100%', sm: '50%' }, display: { xs: 'none', sm: 'block' }, height: { xs: '20%', sm: '20%' }, order: { xs: 2, sm: 1 }, }}>
                                                    <img
                                                        src='https://img.freepik.com/free-vector/silhouette-female-yoga-pose-against-mandala-design_1048-13082.jpg?uid=R133306793&ga=GA1.1.1773690977.1730112906&semt=ais_hybrid'
                                                        alt="Yoga Pose"
                                                        style={{ height: '300px', width: '100%', borderRadius: '8px', objectFit: "contain" }}
                                                    />
                                                </Grid>

                                                {/* Phone Verification Section */}
                                                <Grid item sx={{ width: { xs: '100%', sm: '50%' }, padding: { xs: '16px', sm: '24px' }, order: { xs: 1, sm: 2 }, }}>
                                                    <Typography variant="body2" color="textSecondary" align="center" sx={{ mb: 4 }}>
                                                        Enter your registered phone number to proceed further
                                                    </Typography>
                                                    <PhoneVerification />
                                                </Grid>
                                            </Grid>


                                        </Box>
                                    )}
                                </Box>

                            </>

                    }
                </Box>
            </Slide>

            {/* snack bar */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={4000} // Adjust duration as needed
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{
                    vertical: 'top',    // Position the Snackbar at the top
                    horizontal: 'center' // Center the Snackbar horizontally
                }}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success" // Success color
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>


        </Box >
    );
};

export default SevaLayout;
