import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
    Box,
    TextField,
    Button,
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    InputAdornment,
    MenuItem,
    Autocomplete,
    IconButton,
} from "@mui/material";
import ScrollToTop from "react-scroll-to-top";
import FooterComponent from "../../components/footer";
import { Edit as EditIcon, NavigateBefore, Save as SaveIcon } from "@mui/icons-material";
import { AccountCircle, Email, Phone, Public, LocationOn, Fingerprint } from "@mui/icons-material";
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Country, State } from 'country-state-city'; // Import Country and State
import SlidingHeader from "../../components/SlidingHeader";
import { useNavigate } from "react-router-dom";

const PersonalInfo = ({ userData }) => {
    const navigate = useNavigate()
    const { userName, userEmail, userPhone, uniqueId, userCountry, userState } = userData;

    const [profileData, setProfileData] = useState({
        userName,
        userEmail,
        userPhone,
        uniqueId,
        userCountry,
        userState,
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // can be 'success', 'error', etc.

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);

    const [isEditing, setIsEditing] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [originalPhone, setOriginalPhone] = useState(userPhone); // Store the original phone number

    useEffect(() => {
        setOriginalPhone(userPhone);
    }, [userPhone]);



    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    useEffect(() => {
        const allCountries = Country.getAllCountries();
        setCountries(allCountries);

        // Set default country and states
        const defaultCountry = allCountries.find(country => country.name === profileData.userCountry);
        if (defaultCountry) {
            const statesForCountry = State.getStatesOfCountry(defaultCountry.isoCode);
            setStates(statesForCountry);

            // Set the default state if it exists in the states array
            const defaultState = statesForCountry.find(state => state.name === profileData.userState);
            if (defaultState) {
                setProfileData(prev => ({ ...prev, userState: defaultState.name })); // Update userState
            }
        }
    }, []);


    useEffect(() => {
        if (profileData.userCountry) {
            const selectedCountry = countries.find(country => country.name === profileData.userCountry);
            if (selectedCountry) {
                const statesForCountry = State.getStatesOfCountry(selectedCountry.isoCode);
                setStates(statesForCountry);
            }
        }
    }, [profileData.userCountry, countries]);

    const handleCountryChange = (event, newValue) => {
        if (newValue) {
            setProfileData({
                ...profileData,
                userCountry: newValue.name, // Use country name here
                userState: '', // Reset state when country changes
            });
        }
    };

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            setProfileData({
                ...profileData,
                userState: newValue.name,
            });
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData({
            ...profileData,
            [name]: value,
        });
    };

    const handleSendOtp = async () => {
        try {
            const response = await axios.post("https://backend.shubadinam.com/profile/send-otp", {
                phone: profileData.userPhone,
            });
            if (response.data.success) {
                setSnackbarMessage("OTP sent to your phone!");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                setOtpSent(true);
            } else {
                setSnackbarMessage("Error sending OTP.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error("Error sending OTP:", error);
            setSnackbarMessage(error.response.data.message || "An error occurred while sending OTP.");
            setSnackbarSeverity("warning");
            setSnackbarOpen(true);
        }
    };

    const handleVerifyOtp = async () => {
        try {
            const response = await axios.post("https://backend.shubadinam.com/profile/verify-otp", {
                phone: profileData.userPhone,
                uniqueId: userData.uniqueId,
                otp,
            });
            if (response.data.success) {
                const token = response.data.token;
                localStorage.setItem("token", token);
                setOtpSent(false);
                setSnackbarMessage("Phone number updated successfully!");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                setIsOtpVerified(true);
            } else {
                setSnackbarMessage("Invalid OTP. Please try again.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error("Error verifying OTP:", error);
            setSnackbarMessage("An error occurred while verifying OTP.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const handleSave = async () => {
        const isPhoneChanged = originalPhone !== profileData.userPhone;

        if (isPhoneChanged && !isOtpVerified) {
            setSnackbarMessage("Please verify your phone number before saving changes.");
            setSnackbarSeverity("warning");
            setSnackbarOpen(true);
            return;
        }

        try {
            const response = await axios.put("https://backend.shubadinam.com/update-profile", profileData);

            if (response.data.success) {
                setIsEditing(false);
                setOtpSent(false); // Reset OTP sent status
                setIsOtpVerified(false); // Reset OTP verification status
                setSnackbarMessage("Profile updated successfully.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                setOtp("")
            } else {
                setSnackbarMessage("Error updating profile.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error("Error updating profile:", error);
            setSnackbarMessage(error.response.data.message || "An error occurred while sending OTP.");
            setSnackbarSeverity("warning");
            setSnackbarOpen(true);
        }
    };

    // Check if any fields have changed
    const hasChanges = Object.keys(profileData).some(key => profileData[key] !== userData[key]);

    return (
        <Box>
            <HelmetProvider>
                <Helmet>
                    <title>My Profile</title>
                    <meta name="description" content="My Profile - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>

            <ScrollToTop smooth />
            <SlidingHeader />

            <Container maxWidth="lg" >
                <Card sx={{ p: { xs: 1, md: 3 }, boxShadow: 0 }}>
                    <CardContent>
                        <Box textAlign="center" mb={3} position="relative">
                            <Typography variant="h6" gutterBottom align="left" color="secondary" sx={{ fontWeight: "bold" }}>
                                <IconButton size="medium" onClick={() => navigate(-1)}>
                                    <NavigateBefore color="primary" />
                                </IconButton>
                                Personal Info
                            </Typography>
                            {/* <Box
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Button
                                    variant="text"
                                    color={isEditing ? "secondary" : "primary"}
                                    startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
                                    onClick={isEditing ? handleSave : () => setIsEditing(true)}
                                // disabled={!hasChanges} // Disable save button if no changes
                                >
                                    {isEditing ? "Save" : "Edit"}
                                </Button>
                            </Box> */}
                        </Box>

                        <Grid container spacing={3}>
                            {[{ label: "Username", name: "userName", icon: <AccountCircle /> },
                            { label: "Email", name: "userEmail", icon: <Email /> },
                            { label: "Phone", name: "userPhone", icon: <Phone /> },
                            { label: "User Id", name: "uniqueId", icon: <Fingerprint />, disabled: true },
                            ].map(({ label, name, icon, disabled = false }) => (
                                <Grid item xs={12} sm={6} key={name}>
                                    <TextField
                                        fullWidth
                                        label={label}
                                        name={name}
                                        value={profileData[name]}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        disabled={!isEditing || disabled}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {icon}
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            backgroundColor: "#fff",
                                            borderRadius: 1,
                                            "& .MuiOutlinedInput-root": {
                                                "&:hover fieldset": {
                                                    borderColor: isEditing ? "primary.main" : "grey.400",
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "primary.main",
                                                },
                                            },
                                        }}
                                    />
                                    {name === "userPhone" && isEditing && !otpSent && (
                                        <Button variant="outlined" color="primary" onClick={handleSendOtp} sx={{ mt: 1 }}>
                                            Send OTP
                                        </Button>
                                    )}
                                    {name === "userPhone" && otpSent && (
                                        <>
                                            <TextField
                                                fullWidth
                                                label="Enter OTP"
                                                value={otp}
                                                onChange={(e) => setOtp(e.target.value)}
                                                variant="outlined"
                                                sx={{ mt: 1 }}
                                            />
                                            <Button variant="outlined" color="secondary" onClick={handleVerifyOtp} sx={{ mt: 1 }}>
                                                Verify OTP
                                            </Button>
                                        </>
                                    )}
                                </Grid>
                            ))}

                            {/* Separate Country and State Fields */}
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    sx={{ bgcolor: "white" }}
                                    options={countries}
                                    getOptionLabel={(option) => option.name}
                                    value={countries.find(country => country.name === profileData.userCountry) || null} // Set default value
                                    onChange={handleCountryChange}
                                    disabled={!isEditing}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start"><Public /></InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    sx={{ bgcolor: "white" }}
                                    options={states}
                                    getOptionLabel={(option) => option.name}
                                    value={states.find(state => state.name === profileData.userState) || null} // Set default value
                                    onChange={handleStateChange}
                                    disabled={!isEditing}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="State"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start"><LocationOn /></InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>


                    </CardContent>
                </Card>
            </Container>


            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Set position to top center
            >
                <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>

        </Box>
    );
};

export default PersonalInfo;
