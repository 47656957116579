import React, { useState, useEffect } from "react";
import { Container, TextField, Select, MenuItem, Button, Typography, Paper, Box, InputLabel, FormControl, IconButton, Rating, Grid, Snackbar, Alert } from "@mui/material";
import { CameraAlt } from "@mui/icons-material";
import axios from "axios";
import { NavLink } from "react-router-dom";
import PhoneVerification from "./PhoneVerification";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";


const ReviewsFeedback = () => {

    const [isLogged, setIsLogged] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const isTestimonial = window.location.href.includes("testimonials");

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };


    useEffect(() => {
        // Retrieve token from localStorage
        const token = localStorage.getItem('token');

        // Set up the request URL
        const url = "https://backend.shubadinam.com/userdetails/feedback/";

        // Make the GET request with the token in the headers
        const fetchData = async () => {
            try {
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`, // Add token in the Authorization header
                    },
                });
                if (response.status === 200) {

                    setIsLogged(true);
                    setUserDetails(response.data.user)
                } else {
                    setIsLogged(false)
                }
            } catch (error) {
                setIsLogged(false)
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures this runs once after component mounts


    const [formData, setFormData] = useState({
        userName: "",
        category: isTestimonial ? "Testimonials" : "Blogs",
        rating: 0,
        explanation: "",
        bookTitle: "",
        userEmail: "",
        userPhone: "",
        // file: null,
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, file: e.target.files[0] });
    };

    const [openDialog, setOpenDialog] = useState(false); // State to control dialog visibility
    const [showPhoneVerification, setShowPhoneVerification] = useState(false); // State to toggle content

    // Open the dialog
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    // Close the dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setShowPhoneVerification(false); // Reset state when dialog is closed
    };

    // Handle button click to show PhoneVerification
    const handleButtonClick = () => {
        setShowPhoneVerification(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const submissionData = new FormData();

        submissionData.append("userName", userDetails.userName);
        submissionData.append("userEmail", userDetails.userEmail);
        submissionData.append("userPhone", userDetails.userPhone);
        submissionData.append("uniqueId", userDetails.uniqueId);
        submissionData.append("userCountry", userDetails.userCountry);
        submissionData.append("userState", userDetails.userState);
        submissionData.append("profile_percent", userDetails.profile_percent);
        submissionData.append("category", formData.category);
        submissionData.append("rating", formData.rating);
        submissionData.append("explanation", formData.explanation);
        submissionData.append("bookTitle", formData.bookTitle);
        // if (formData.file) {
        //     submissionData.append("file", formData.file);
        // }

        try {
            const response = await axios.post("https://backend.shubadinam.com/admin/feedback", submissionData);

            // ✅ Reset Form Fields After Successful Submission
            setFormData({
                category: isTestimonial ? "Testimonials" : "Blogs",
                rating: 0,
                explanation: "",
                bookTitle: "",
                file: null
            });

            // ✅ Show Success Snackbar
            setSnackbarMessage("Feedback Submitted Successfully!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);

        } catch (error) {
            console.error("Error submitting feedback:", error);

            // ✅ Show Error Snackbar
            setSnackbarMessage("Submission Failed. Please try again.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh", py: 5 }}>
            <Paper sx={{ p: 2, borderRadius: 3, width: "100%", backgroundColor: "aliceblue", boxShadow: 0 }}>
                <Typography variant="h4" color="primary" fontWeight="bold" textAlign="center" gutterBottom>
                    Leave Your Comment
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }} color="textSecondary" textAlign="center" gutterBottom>
                    We value your feedback and reviews. Let us know your thoughts!
                </Typography>
                <Grid container spacing={3} alignItems="center" justifyContent="center">

                    <Grid item xs={12} sm={8} justifyContent="center" alignItems="center">
                        <form onSubmit={handleSubmit} style={{ marginTop: "16px" }}>
                            {/* <TextField fullWidth label="Name / Surname" name="name" value={formData.name} onChange={handleChange} variant="outlined" margin="normal" /> */}

                            <TextField fullWidth label="Leave Your Comment" multiline rows={4} name="explanation" onChange={handleChange} variant="outlined" margin="normal" value={formData.explanation} />
                            {/* <FormControl fullWidth margin="normal">
                                <InputLabel>Category</InputLabel>
                                <Select label="Category" sx={{ textIndent: 15 }} name="category" value={formData.category} onChange={handleChange}>
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem value="book review">Book Review</MenuItem>
                                    <MenuItem value="website feedback">Website Feedback</MenuItem>
                                    <MenuItem value="others">Others</MenuItem>
                                </Select>
                            </FormControl> */}
                            {/* {formData.category === "others" && <TextField fullWidth label="Specify" name="explanation" onChange={handleChange} variant="outlined" margin="normal" />}
                            {formData.category === "website feedback" && (
                                <>
                                    <TextField fullWidth label="Type Something" multiline rows={4} name="explanation" onChange={handleChange} variant="outlined" margin="normal" />
                                    <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>

                                    </Box>
                                </>
                            )}
                            {formData.category === "book review" && (
                                <>
                                    <TextField fullWidth label="Book Title" name="bookTitle" onChange={handleChange} variant="outlined" margin="normal" />
                                    <TextField fullWidth label="Type Something" multiline rows={6} name="explanation" onChange={handleChange} variant="outlined" margin="normal" />

                                </>
                            )} */}

                            <Grid container direction="column" alignItems="start" justifyContent="start" spacing={2} sx={{ p: 2 }}>
                                <Grid
                                    container
                                    alignItems="start"
                                    justifyContent="start"
                                    spacing={2}
                                    sx={{ py: 2 }}
                                >
                                    <Grid item>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontWeight: "bold",
                                                color: "#444"
                                            }}
                                        >
                                            Rating:
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Rating
                                            value={formData.rating}
                                            precision={1}
                                            size="large"
                                            sx={{
                                                color: "#ff9800",
                                                "& .MuiRating-iconFilled": { color: "#ffb400" },
                                                "& .MuiRating-iconHover": { color: "#ff9800" }
                                            }}
                                            onChange={(e, newValue) => setFormData({ ...formData, rating: newValue })}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontWeight: "bold",
                                                color: "#666",
                                                textAlign: "center",
                                                px: 1,
                                                background: "#f5f5f5",
                                                borderRadius: "8px",
                                                display: "inline-block",
                                                minWidth: "80px",
                                                textAlign: "center"
                                            }}
                                        >
                                            {
                                                formData.rating === 2 ? "Fair" :
                                                    formData.rating === 3 ? "Good" :
                                                        formData.rating === 4 ? "Very Good" :
                                                            formData.rating === 5 ? "Excellent" : ""}
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </Grid>



                            {/* <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                                <input type="file" hidden id="file-upload" onChange={handleFileChange} />
                                <label htmlFor="file-upload">
                                    <IconButton component="span">
                                        <CameraAlt color="primary" />
                                    </IconButton>
                                    <Typography variant="body2" color="primary">Add photo</Typography>
                                </label>
                            </Box> */}
                            {isLogged ? (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ width: "100%", backgroundColor: "#1976d2", color: "white", mt: 2, py: 1.5, borderRadius: 2, placeSelf: "center" }}
                                >
                                    SEND
                                </Button>
                            ) : (

                                <div>
                                    {/* Button to open the dialog */}
                                    <Button
                                        variant="contained"
                                        onClick={handleOpenDialog}
                                        sx={{
                                            width: "100%",
                                            backgroundColor: "#1976d2",
                                            color: "white",
                                            mt: 2,
                                            py: 1.5,
                                            borderRadius: 2,
                                            placeSelf: "center",
                                        }}
                                    >
                                        LOGIN TO REVIEW
                                    </Button>

                                    {/* Dialog */}
                                    <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="xs" fullWidth>
                                        <DialogTitle>Log In</DialogTitle>
                                        <DialogContent>
                                            {/* Conditionally render PhoneVerification or the login button */}
                                            <PhoneVerification />

                                        </DialogContent>
                                    </Dialog>
                                </div>


                            )}
                        </form>
                    </Grid>
                    <Grid item xs={12} sm={8} sx={{ display: "flex", justifyContent: "center" }}>
                        <img
                            src="https://cdn.leonardo.ai/users/ab543ed5-899d-432d-a85d-4ae843bec9d0/generations/2a559938-d4c0-47ef-8b92-d5bc9b6cebcc/Leonardo_Kino_XL_girl_writing_book_reviews_for_the_blog_surrou_0.jpg"
                            alt="Feedback Illustration"
                            style={{ maxWidth: "100%", height: "auto", borderRadius: 8 }}
                        />
                    </Grid>
                </Grid>
            </Paper >

            {/* snackbar alert for form submission */}
            < Snackbar
                open={snackbarOpen}
                autoHideDuration={4000} // Close after 4 seconds
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} // ✅ Position: Top-Right
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar >

        </Box >
    );
};

export default ReviewsFeedback;
