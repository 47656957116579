import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';
import SDadminHeader from './SDadminHeader';

const WebsiteViews = () => {
    const [viewsData, setViewsData] = useState([]);

    useEffect(() => {
        // Fetch website views data from the backend
        const fetchViewsData = async () => {
            try {
                const response = await axios.get('https://backend.shubadinam.com/admin/get/admin-todayviews'); // Adjust the endpoint if necessary
                setViewsData(response.data); // Assuming your backend returns the array of data
            } catch (error) {
                console.error('Error fetching website views data:', error);
            }
        };

        fetchViewsData();
    }, []);

    return (
        <div>
            <SDadminHeader />
            <Box sx={{ mt: 15, p: 2 }}>

                <Typography variant="h5" component="h2" gutterBottom color="primary" align="center">
                    Website Views for Today
                </Typography>
                <TableContainer component={Paper} sx={{
                    p: { xs: 0, md: 5 }, mt: 5,
                }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: 50 }}>S.no</TableCell>
                                <TableCell>Country</TableCell>
                                <TableCell>State</TableCell>
                                <TableCell>City</TableCell>
                                <TableCell>Timestamp</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {viewsData.map((view, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{view.country}</TableCell>
                                    <TableCell>{view.state}</TableCell>
                                    <TableCell>{view.city}</TableCell>
                                    <TableCell>{new Date(view.timestamp).toLocaleString("en-GB", {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true
                                    })}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>
        </div>
    );
};

export default WebsiteViews;
