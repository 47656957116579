import React, { useState, useEffect, } from "react";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import FooterComponent from "../components/footer";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import HomamEvents from "../components/HomamEvents";
import ShopComponent from "../Shopee/ShopComponent";

import { NavigateNext } from "@mui/icons-material";
import SevalandingTestimonial from "../homamutils/SevalandingTestimonial";
import SlidingHeader from "../components/SlidingHeader";
import GallerySection from "./utils/GallerySection";
import HeroSlider from "./utils/HeroSlider";

import VerifiedIcon from "@mui/icons-material/Verified";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { orange } from "@mui/material/colors";


const LoginScreen = () => {

    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({ duration: 1000, once: false });
    }, []);

    const [dashsignOut, setSignOut] = useState(false)

    const [isHomeauth, setisHomeauth] = useState(false)


    useEffect(() => {

        const auth = localStorage.getItem("homeauth");
        if (auth) {
            setSignOut(true)
            setisHomeauth(true)
        }
    }, []);


    const [ismobile, setIsmobile] = useState(false)


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIsmobile(true);
            } else {
                setIsmobile(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // ip info
    // useEffect(() => {
    //     const fetchIPData = async () => {
    //         try {
    //             // Replace with your ipinfo.io API token
    //             const ipInfoToken = 'b0ecbaf60e55e4'; // Get this from ipinfo.io/account/home

    //             // Fetch IP data
    //             const response = await axios.get(`https://ipinfo.io?token=${ipInfoToken}`);
    //             const { ip, city, region, country, loc } = response.data;

    //             // Prepare payload for the backend
    //             const payload = {
    //                 ip_address: ip,
    //                 city,
    //                 state: region,
    //                 country,
    //                 coordinates: loc, // Already in "latitude,longitude" format
    //             };

    //             // Send data to your backend
    //             await axios.post('https://backend.shubadinam.com/website-views', payload);
    //         } catch (error) {
    //             console.error('Error fetching or sending IP data:', error);
    //         }
    //     };

    //     // fetchIPData();
    // }, []);


    const buttonStyles = {
        p: { xs: 1, md: 2 },
        textTransform: "none",
        borderRadius: 3,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.15)',
            color: '#fff',
        },
        '&:active': {
            transform: 'scale(1)',
            boxShadow: 'none',
        },
    };

    const BlogCard = ({ image, title, subtext, button, route }) => {


        return (
            <a href={route} >
                <Card
                    sx={{
                        cursor: "pointer",
                        position: "relative",
                        borderRadius: 6,
                        overflow: "hidden",
                        boxShadow: 5,
                        transition: "transform 0.3s ease, box-shadow 0.3s ease",
                        "&:hover": {
                            transform: "translateY(-10px)",
                            boxShadow: 12,
                        },
                    }}
                >
                    <CardMedia
                        component="img"
                        loading="lazy"
                        height="300"
                        image={image}
                        alt={title}
                        onContextMenu={(e) => e.preventDefault()}
                        sx={{
                            filter: "brightness(80%)",
                            transition: "filter 0.3s ease",
                            "&:hover": { filter: "brightness(100%)" },
                        }}
                    />
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            bgcolor: "rgba(255, 255, 255, 0.8)",
                            padding: 3,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                            transition: "background-color 0.3s ease",
                            "&:hover": {
                                bgcolor: "rgba(255, 255, 255, 0.9)",
                            },
                        }}
                    >
                        <Typography
                            variant="body5"
                            fontWeight="bold"
                            color="#333"
                            sx={{ mb: 1, textTransform: "uppercase", letterSpacing: 1 }}
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ mb: 2, lineHeight: 1, fontSize: "0.9rem" }}
                        >
                            {subtext}
                        </Typography>
                        <Button
                            endIcon={<NavigateNext />}
                            size="small"
                            variant="contained"
                            color="primary"
                            sx={{
                                p: 1,
                                textTransform: "none",
                                borderRadius: 2,
                            }}
                        >
                            {button}
                        </Button>
                    </Box>
                </Card>
            </a>
        );
    };

    const features = [
        {
            icon: <VerifiedIcon color="secondary" sx={{ fontSize: 50, }} />,
            title: "Trusted & Secure",
            description: "Shubadinam ensures a safe and secure affiliate system with verified transactions."
        },
        {
            icon: <SupportAgentIcon color="secondary" sx={{ fontSize: 50, }} />,
            title: "24/7 Support",
            description: "Our team is here to help you anytime with dedicated affiliate support."
        }
    ];

    return (
        <Box
            sx={{ bgcolor: '#F9FAFB' }}>
            <HelmetProvider>
                <Helmet>
                    <title>Shubadinam</title>
                    <meta name="description" content="Shubadinam - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <ScrollToTop smooth />

            <SlidingHeader />

            <Box>

                {/* hero slider */}
                <HeroSlider />

                <Box sx={{ mt: 5, px: 3, display: "flex", justifyContent: "center" }} >
                    <Grid container spacing={2} justifyContent="center" maxWidth='lg'>
                        {features.map((feature, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index} data-aos='fade-up'>
                                <Card
                                    sx={{
                                        textAlign: "center",
                                        boxShadow: 0,
                                        borderRadius: 3,
                                        border: `1px dashed grey`,
                                        // p: '0px 30px'
                                    }}
                                >
                                    <CardContent>
                                        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                                            {feature.icon}
                                        </Box>
                                        <Typography variant="h6" color='secondary' fontWeight="bold"
                                        // color="#ff7b00"
                                        >
                                            {feature.title}
                                        </Typography>
                                        {/* <Typography variant="body2" mt={1}>
                                            {feature.description}
                                        </Typography> */}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>


                {/* services */}
                <Box sx={{ mt: 2, }}>
                    <HomamEvents />
                </Box>


                {/* tutorial */}
                <Box sx={{ width: "100%", mt: 2, display: "flex", justifyContent: "center", alignItems: "center", }} >
                    <Box
                        maxWidth='lg'
                        sx={{
                            px: { xs: 2, sm: 3 },
                            py: 4,
                            textAlign: 'center',
                            bgcolor: '#020332',
                            // background: 'linear-gradient(135deg, #056697, #003366)',
                            borderRadius: 5,
                            // boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.2)',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                marginBottom: 3,
                                fontWeight: 'bold',
                                fontSize: { xs: '1.5rem', sm: '1.5em', md: '2rem' },
                                color: '#fff',
                                letterSpacing: '2px',
                                textTransform: 'capitalize',
                                lineHeight: 1.3,
                                textShadow: '4px 4px 10px rgba(0, 0, 0, 0.3)',
                                fontFamily: 'Montserrat, sans-serif',
                            }}
                        >
                            How to use <span style={{ color: orange[700] }}>Shubadinam Heritage Services</span> ?
                        </Typography>

                        {/* Image and Button Section */}
                        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                            {/* Image Section */}
                            <Grid item xs={12} sm={6} md={6}>
                                <Box
                                    sx={{
                                        borderRadius: 5,
                                        overflow: 'hidden',
                                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
                                        textAlign: 'center',
                                    }}
                                >
                                    <img
                                        data-aos='fade-up'
                                        src={require("../images/banners/landing_tutorial.jpg")}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            borderRadius: '5px',
                                            objectFit: 'contain',
                                            maxHeight: '250px',
                                        }}
                                        loading="lazy"
                                    />
                                </Box>
                            </Grid>

                            {/* Button Section */}
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 3,
                                    textAlign: 'center',
                                }}
                            >
                                {/* Description */}
                                <Typography
                                    variant="body2"
                                    sx={{
                                        marginBottom: 2,
                                        color: '#fff',
                                        fontSize: { xs: '1rem', sm: '1.1rem', md: '1.3rem' },
                                        lineHeight: 1.6,
                                        fontStyle: 'italic',
                                        maxWidth: '800px',
                                        marginX: 'auto',
                                        opacity: 0.85,
                                        fontFamily: 'Roboto, sans-serif',
                                    }}
                                >
                                    Explore our detailed step-by-step tutorials to maximize your experience with our website and services. These tutorials include detailed videos and engaging slideshows, thoughtfully designed to guide you through each step with ease.
                                </Typography>

                                {/* Buttons */}
                                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }} >

                                    <a href="/tutorial" style={{ textDecoration: 'none' }}>
                                        <Button endIcon={<NavigateNext />}
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                fontSize: "1rem",
                                                ...buttonStyles,
                                                bgcolor: orange[600],
                                            }}
                                        >
                                            View Tutorial
                                        </Button>
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                {/* shop */}
                <Box maxwidth="xl">
                    <ShopComponent />
                </Box>


                {/* gallery */}
                <Box maxwidth="lg" display="flex" justifyContent="center" sx={{ textAlign: "center", }}>
                    <GallerySection />
                </Box>

                {/* blogs */}
                <Box sx={{ mt: 2, p: 2, }} >
                    <Typography
                        variant="h4"
                        align="center"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            color: '#2c3e50',
                            mb: 2,
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        Explore Our <span style={{ color: orange[700] }}>Blogs</span>
                    </Typography>

                    <Typography variant="body1"
                        align="center"
                        sx={{
                            color: '#7f8c8d',
                            mb: 4,
                            fontStyle: 'italic',
                        }}>
                        Our blogs bring you expert advice, tips, and in-depth knowledge to keep you informed on our culture and tradition.
                    </Typography>

                    <Grid item container spacing={6} xs={12} md={8} xl={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center", placeSelf: "center" }} >
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <BlogCard
                                image={require("../images/boxesImages/deity2.png")}
                                title="Nakshatra Related Blogs"
                                subtext="Unlocking Destiny: Understanding Janma Nakshatra's Influence. Explore the cosmic blueprint shaping personalities."
                                button="Read More"
                                route="/blogs-nakshatra"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <BlogCard
                                image={require("../images/boxesImages/deity1.png")}
                                title="Tithi Related Blogs"
                                subtext="Decoding Destiny: Unraveling Janma Tithi's Significance. Discover the profound impact of Janma Tithi on life's journey."
                                button="Read More"
                                route="/blogs-tithi"

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <BlogCard
                                image={require("../images/boxesImages/books_cover_img.jpg")}
                                title="Book Reviews"
                                subtext="Unlock the power of knowledge! Discover books on inspiration, spirituality, self-growth, and heritage-timeless wisdom for every soul."
                                button="Read More"
                                route="/books-blogs"

                            />
                        </Grid>
                    </Grid>
                </Box>

                {/* testimonial */}
                <SevalandingTestimonial />

                {/* about */}
                <Box maxwidth="lg" sx={{ textAlign: "center", mt: 1, p: 1 }}>
                    {/* Title Section */}

                    <Typography
                        variant="h4"
                        align="center"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            color: '#2c3e50',
                            mb: 2,
                            p: 2,
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        About Us
                    </Typography>

                    {/* Description Section */}
                    <Typography
                        variant="subtitle1"
                        sx={{
                            p: 2,
                            marginTop: 2,
                            lineHeight: 1.5,
                            color: 'text.primary', // You can adjust the text color here
                        }}
                        align="left"
                    >
                        Shubadinam Heritage Services stands as a humble offering toward the noble cause of Retaining and Reclaiming the profound cultural legacy of Hinduism. Our mission is to illuminate the importance of celebrating Birth Stars, known as Janma Nakshatra / Janma Tithi. We firmly believe that{' '}
                        <a
                            href="/about-us"
                            style={{
                                textDecoration: 'underline',
                                color: 'primary.main', // You can change this to any color you prefer
                                '&:hover': {
                                    textDecoration: 'none',
                                },
                            }}
                        >
                            read more
                        </a>
                        .
                    </Typography>
                </Box>

            </Box>
            <FooterComponent />


        </Box >

    )
}
export default LoginScreen;