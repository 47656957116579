import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Typography, Box, Grid, Paper, List, ListItem, ListItemText, Divider, Card, CardContent } from "@mui/material";
import ScrollToTop from "react-scroll-to-top";
import FooterComponent from "../components/footer";
import LandingHeader from "../components/LandingHeader";
import SlidingHeader from "../components/SlidingHeader";
import PageSlider from "./utils/PageSlider";
import { Email, Phone, AccessTime, ReportProblem, ExpandMore, CheckCircle, Cancel, EmailOutlined, Timer, Warning, Info } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';




const RefundPolicyPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
    }, []);

    return (
        <Box sx={{ bgcolor: "aliceblue" }}>
            <HelmetProvider>
                <Helmet>
                    <title>Refund Policy</title>
                    <meta name="description" content="Refund Policy - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>

            {/* Landing Header */}
            <SlidingHeader />

            {/* Hero Section */}
            {/* <PageSlider
                bgImage={require("../images/banners/all_hero_banner.jpg")}
                pageTitle="Refund Policy"
            /> */}
            <ScrollToTop smooth />


            {/* Main Content */}
            {/* <Container maxWidth="lg" sx={{ paddingTop: 4, paddingBottom: 4, textAlign: "center" }}>
                <Box sx={{ backgroundColor: "#f9f9f9", py: 8, minHeight: "100vh" }}> */}
            <Container maxWidth="xl" sx={{ py: 6, mt: 10 }}>

                <Grid container spacing={1}>

                    <Grid item xs={12} >
                        <Typography variant="h5" gutterBottom fontWeight="bold">
                            Refund and Cancellation Policy
                        </Typography>
                        <Typography mb={4}>
                            Thank you for choosing Shubadinam Shoppe for your shopping needs. We are committed to providing a seamless and enjoyable shopping experience. Please review our policy below to understand your rights and our processes regarding refunds, cancellations, and returns.
                        </Typography>
                    </Grid>

                    {/* Refund Policy */}
                    <Grid item xs={12} >
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h6" fontWeight="bold">
                                    Refund Policy
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ display: 'flex', flexDirection: { xs: "column", md: 'row' }, borderRadius: 2, backgroundColor: 'white', boxShadow: 0, overflow: 'hidden' }}>
                                    {/* Image Section */}
                                    <Box sx={{ width: { xs: '100%', sm: '40%' }, height: { xs: 180, sm: "100%" }, overflow: 'hidden' }}>
                                        <img
                                            src={require("../images/refund_page/Refund Policy.png")}
                                            alt="Refund Policy"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                borderRadius: '8px',
                                                transition: 'transform 0.3s ease-in-out',
                                            }}


                                        />
                                    </Box>

                                    {/* Content Section */}
                                    <CardContent sx={{ padding: { xs: 2, sm: 3 }, display: 'flex', flexDirection: 'column', flex: 1 }}>

                                        <Typography variant="body2" sx={{ color: '#555', mt: 2, mb: 2, fontSize: { xs: '0.875rem', sm: '1rem' }, lineHeight: 1.6 }}>
                                            <Box component="p" sx={{ margin: 0 }}>
                                                We offer refunds under the following conditions:
                                            </Box>
                                            <Box component="ul" sx={{ paddingLeft: 2, color: "black", marginTop: 1, marginBottom: 2, listStyleType: 'disc' }}>
                                                <Box component="li" sx={{ marginBottom: 1, color: "black" }}>
                                                    <strong>Defective or Damaged Products:</strong> If your product arrives defective or damaged, please contact us immediately.
                                                </Box>
                                                <Box component="li" sx={{ marginBottom: 1, color: "black" }}>
                                                    <strong>Incorrect Items:</strong> If you receive an item different from what you ordered, let us know, and we will resolve the issue.
                                                </Box>
                                                <Box component="li" sx={{ marginBottom: 1, color: "black" }}>
                                                    <strong>Eligibility:</strong> To be eligible for a refund, you must report the issue within  <strong>7 days of receiving your order.</strong>
                                                </Box>
                                            </Box>
                                        </Typography>


                                        <Typography variant="body5" sx={{ fontWeight: 'bold', color: '#333', mb: 1, }}>
                                            Process
                                        </Typography>

                                        <List sx={{ paddingLeft: 0, marginBottom: 2 }}>
                                            <ListItem sx={{ paddingLeft: 0 }}>
                                                <CheckCircle color="success" />
                                                <ListItemText
                                                    secondary={
                                                        <>
                                                            Contact our customer support team at{' '}
                                                            <NavLink style={{ textDecoration: "none" }}>
                                                                support@shubadinam.com
                                                            </NavLink>{' '}
                                                            with your order number, photos of the issue, and a brief description.
                                                        </>
                                                    }
                                                    sx={{ ml: 1 }}
                                                />
                                            </ListItem>

                                            <ListItem sx={{ paddingLeft: 0 }}>
                                                <CheckCircle color="success" />
                                                <ListItemText
                                                    secondary={
                                                        <>
                                                            Once your claim is verified, we will provide a{' '}
                                                            <strong style={{ fontWeight: 'bold', color: "black" }}>
                                                                replacement
                                                            </strong>{' '}
                                                            or{' '}
                                                            <strong component="span" style={{ fontWeight: 'bold', color: "black" }}>
                                                                refund
                                                            </strong>{' '}
                                                            (including return shipping costs, if applicable).
                                                        </>
                                                    }
                                                    sx={{ ml: 1 }}
                                                />
                                            </ListItem>

                                            <ListItem sx={{ paddingLeft: 0 }}>
                                                <CheckCircle color="success" />
                                                <ListItemText
                                                    secondary={
                                                        <>
                                                            Refunds will be processed within{' '}
                                                            <strong style={{ fontWeight: 'bold', color: "black" }}>
                                                                5-7 business days
                                                            </strong>{' '}
                                                            and credited to your original payment method.
                                                        </>
                                                    }
                                                    sx={{ ml: 1 }}
                                                />
                                            </ListItem>

                                        </List>

                                        <ListItem sx={{ paddingLeft: 0 }}>
                                            <Info color="info" sx={{ mr: 1 }} />
                                            <Typography>
                                                Note: Refunds are not available for products that have been used, altered, or damaged after delivery.
                                            </Typography>
                                        </ListItem>
                                    </CardContent>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    {/* Cancellation Policy */}
                    <Grid item xs={12} >

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h6" fontWeight="bold">
                                    Cancellation Policy
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                <Box component="p" sx={{ margin: 0 }}>
                                    You may cancel your order under the following conditions:
                                </Box>
                                <Box component="ul" sx={{ paddingLeft: 2, color: "black", marginTop: 1, marginBottom: 2, listStyleType: 'disc' }}>
                                    <Box component="li" sx={{ marginBottom: 1, color: "black" }}>
                                        <strong>Before Shipping:</strong>  If your order has not yet been shipped, you can request a cancellation within  <strong>2 hours of placing the order</strong>. Contact us at   <NavLink style={{ textDecoration: "none" }}>
                                            support@shubadinam.com
                                        </NavLink>{' '} with your order number.
                                    </Box>
                                    <Box component="li" sx={{ marginBottom: 1, color: "black" }}>
                                        <strong>After Shipping:</strong> Once your order has been shipped, it cannot be cancelled. However, you may return the item(s) as per our <strong>Return Policy</strong> (see below).
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    {/* Return Policy */}
                    <Grid item xs={12} >


                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h6" fontWeight="bold">
                                    Return Policy
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>


                                <Box sx={{ display: 'flex', flexDirection: { xs: "column", md: 'row' }, borderRadius: 2, backgroundColor: 'white', boxShadow: 0, overflow: 'hidden' }}>
                                    <Typography variant="body2" sx={{ color: '#555', mt: 2, mb: 2, fontSize: { xs: '0.875rem', sm: '1rem' }, lineHeight: 1.6 }}>
                                        <Box component="p" sx={{ margin: 0 }}>
                                            We accept returns under the following conditions:
                                        </Box>
                                        <Box component="div" sx={{ paddingLeft: 2, color: "black", marginTop: 1, marginBottom: 2, listStyleType: 'disc' }}>
                                            <Box component="div" sx={{ marginBottom: 1, color: "black" }}>
                                                1. <strong>	Eligibility:</strong> If your product arrives defective or damaged, please contact us immediately.
                                            </Box>
                                            <Box component="div" sx={{ marginBottom: 1, color: "black" }}>
                                                2. <strong>	Process:</strong>


                                                <List sx={{ paddingLeft: 0, marginBottom: 2 }}>
                                                    <ListItem sx={{ paddingLeft: 0 }}>
                                                        <CheckCircle color="success" />
                                                        <ListItemText
                                                            secondary={
                                                                <>
                                                                    Contact us at {' '}
                                                                    <NavLink style={{ textDecoration: "none" }}>
                                                                        support@shubadinam.com
                                                                    </NavLink>{' '}
                                                                    to initiate a return and receive a{" "}
                                                                    <strong style={{ fontWeight: 'bold', color: "black" }}>
                                                                        Return Authorization Number (RAN).
                                                                    </strong>
                                                                </>
                                                            }
                                                            sx={{ ml: 1 }}
                                                        />
                                                    </ListItem>

                                                    <ListItem sx={{ paddingLeft: 0 }}>
                                                        <CheckCircle color="success" />
                                                        <ListItemText
                                                            secondary={
                                                                <>
                                                                    Ship the item(s) back to us using a trackable shipping method. Return shipping costs are the responsibility of the customer, except in cases of defective, damaged, or incorrect items.
                                                                </>
                                                            }
                                                            sx={{ ml: 1 }}
                                                        />
                                                    </ListItem>

                                                    <ListItem sx={{ paddingLeft: 0 }}>
                                                        <CheckCircle color="success" />
                                                        <ListItemText
                                                            secondary={
                                                                <>
                                                                    Once we receive and inspect the returned item(s), we will process your refund within{" "}
                                                                    <strong style={{ fontWeight: 'bold', color: "black" }}>
                                                                        5-7 business days.
                                                                    </strong>
                                                                </>
                                                            }
                                                            sx={{ ml: 1 }}
                                                        />
                                                    </ListItem>

                                                </List>

                                            </Box>
                                        </Box>
                                        <strong  >
                                            Non-Returnable Items:
                                        </strong>
                                        <Box component="p" sx={{ margin: 0 }}>
                                            Products that are personalized, custom-made, or marked as "final sale" cannot be returned.
                                        </Box>
                                    </Typography>



                                    <Box sx={{ width: { xs: '100%', sm: '40%' }, height: { xs: 180, sm: "100%" }, overflow: 'hidden' }}>
                                        <img
                                            src={require("../images/refund_page/Return Policy.png")}
                                            alt="Return Policy"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                borderRadius: '8px',
                                                transition: 'transform 0.3s ease-in-out',
                                            }}


                                        />
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    {/* Damages and Issues */}
                    <Grid item xs={12} >
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h6" fontWeight="bold">
                                    Damages and Issues
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body5" sx={{ color: '#555', mb: 2 }}>
                                    Please inspect your order upon receipt. If you find that the product is defective, damaged, or if you received an incorrect item, contact us within  <strong style={{ fontWeight: 'bold', color: "black" }}> 7 days of delivery. </strong> We will assess the situation and provide a <strong style={{ fontWeight: 'bold', color: "black" }}> replacement </strong>  or <strong style={{ fontWeight: 'bold', color: "black" }}>refund</strong> , as appropriate, to resolve the issue.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    {/* Contact Us */}
                    <Grid item xs={12} >
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h6" fontWeight="bold">
                                    Contact Us
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ display: 'flex', flexDirection: { xs: "column", md: 'row' }, borderRadius: 2, backgroundColor: 'white', boxShadow: 0, overflow: 'hidden' }}>

                                    <Box sx={{ width: { xs: '100%', sm: '40%' }, height: { xs: 180, sm: "100%" }, overflow: 'hidden' }}>
                                        <img
                                            src={require("../images/refund_page/Contact Us.png")}
                                            alt="Contact Us"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                borderRadius: '8px',
                                                transition: 'transform 0.3s ease-in-out',
                                            }}


                                        />
                                    </Box>
                                    <CardContent sx={{ padding: 3, display: 'flex', flexDirection: 'column', flex: 1 }}>
                                        <Typography variant="body5" sx={{ color: '#555', mb: 2 }}>
                                            For any questions, concerns, or assistance regarding refunds, cancellations, or returns, please reach out to our customer support team:
                                        </Typography>
                                        <List sx={{ paddingLeft: 0 }}>
                                            <ListItem sx={{ paddingLeft: 0 }}>
                                                <Email color="primary" />
                                                <ListItemText secondary=" support@shubadinam.com" sx={{ ml: 1 }} />
                                            </ListItem>
                                            <ListItem sx={{ paddingLeft: 0 }}>
                                                <Phone color="primary" />
                                                <ListItemText secondary="+91 8925251938." sx={{ ml: 1 }} />
                                            </ListItem>
                                            <ListItem sx={{ paddingLeft: 0 }}>
                                                <Timer color="primary" />
                                                <ListItemText secondary="Business Hours: Monday through Friday from 9 AM to 6:30 PM." sx={{ ml: 1 }} />
                                            </ListItem>
                                        </List>
                                        <Typography variant="body5" sx={{ color: '#555', mb: 2 }}>
                                            We appreciate your understanding and support as we work to bring you unique and meaningful products that celebrate culture and identity. Thank you for shopping with <strong style={{ fontWeight: 'bold', color: "black" }}>Shubadinam</strong>!
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>

            </Container>







            {/* </Box>
            </Container> */}

            {/* Footer */}
            <FooterComponent />
        </Box >
    );
};

export default RefundPolicyPage;
