import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import FooterComponent from "../components/footer";
import Swal from 'sweetalert2';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Box, Button, Container, Divider, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import { Country, State } from 'country-state-city';
import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
import EmailIcon from '@mui/icons-material/Email'; // or any other icon you want to use
import { Person } from "@mui/icons-material";
import SlidingHeader from "../components/SlidingHeader";
import { styled } from "@mui/system";
import CardGiftcard from "@mui/icons-material/CardGiftcard";

const StyledPhoneInput = styled(PhoneInput)({
    "& .form-control": {
        width: "100%",
        borderRadius: "0px !important",
        borderBottom: "1px solid gray !important",
        borderTop: "transparent !important",
        borderRight: "transparent !important",
        outline: "none !important",
        boxShadow: "none !important", // Prevents focus glow
    },

    "& .form-control:focus, & .form-control:focus-within, & .form-control:active": {
        borderBottom: "1px solid gray !important",
        borderTop: "transparent !important",
        borderRight: "transparent !important",
        borderLeft: "transparent !important",
        outline: "none !important",
        boxShadow: "none !important",
    },

    "& .flag-dropdown": {
        borderRadius: "0px !important",
        borderBottom: "1px solid gray !important",
        borderTop: "transparent !important",
        borderLeft: "transparent !important",
        borderRight: "transparent !important",
        backgroundColor: "#f5f5f5",
    },

    "& .flag-dropdown:focus, & .flag-dropdown:focus-within, & .flag-dropdown:active": {
        borderBottom: "1px solid gray !important",
        borderTop: "transparent !important",
        borderLeft: "transparent !important",
        borderRight: "transparent !important",
        outline: "none !important",
        boxShadow: "none !important",
        backgroundColor: "#4b769f !important",
    },
    "& .selected-flag": {
        backgroundColor: "white !important",
    },
    "& .country-list": {
        display: "flex !important",
        flexDirection: 'column !important',
        justifyContent: 'start !important',
        alignItems: 'start !important',
    }
});




AOS.init({
    duration: 1000, // Notice the single quotes around '2s'
});



const RegisterPage = () => {

    const [isVerifyclicked, setVerifiedClicked] = useState(false)


    useEffect(() => {

        window.scrollTo(0, 0);
        AOS.refresh();

    }, []);


    const [isSubmitClicked, SetIsSubmitClicked] = useState(false);

    const [isOptSent, SetIsOtpSent] = useState(false);

    const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);


    const verifyRegOtp = () => {


        setVerifiedClicked(true)

        // const encodedDetails = localStorage.getItem("details");
        // const decodedDetails = atob(encodedDetails);

        // console.log(decodedDetails);


        const regPhone = formData.userPhone

        // const regPhone = localStorage.getItem("email")
        const regOtp = sessionStorage.getItem("otp");
        // console.log(regOtp)
        // console.log(regPhone);

        const url = `https://backend.shubadinam.com/verifyRegOtp?userPhone=${regPhone}&&dialCode=${formData.dialCode}&&userOtp=${regOtp}`

        axios.get(url)
            .then((response) => {

                console.log(response.data);
                const token = response.data.token;
                const uniqueId = response.data.uniqueId
                localStorage.setItem("token", token);
                localStorage.setItem("SD_Id", uniqueId);
                localStorage.setItem("homeauth", true);

                window.location.href = '/dashboard_thankyou'; // Redirect to a success page

            })

            .catch((error) => {
                // console.log(error);

                setVerifiedClicked(false)

                setOtpInputs(["", "", "", ""]);

                const invaildOtp = error.response.data.message

                Swal.fire({
                    title: 'Authentication Revoked',
                    text: invaildOtp,
                    icon: 'info',
                    confirmButtonText: 'Got it!', // Change the confirm button text
                    confirmButtonColor: '#3085d6', // Change the confirm button color
                    background: '#f5f5f5',
                });
            })
    }

    const resendOtp = () => {
        clickSubmit()
    }

    const inputRefs = useRef([]);
    const [isNotNumber, setIsNotNumber] = useState(false)

    const otpHandler = (index, value) => {
        if (!/^\d*$/.test(value)) {
            value = ""; // Set value to empty if not a number
            setIsNotNumber(true);
            return;
        }
        setIsNotNumber(false);

        // ✅ Restrict OTP to 4 digits
        if (value.length > 4) return;

        setOtpInputs(value.split("")); // Store OTP as an array of characters

        // ✅ Store full OTP in sessionStorage
        sessionStorage.setItem("otp", value);

        // ✅ Auto-focus next input logic (not needed for single field)
    };


    const handleKeyDown = (index, event) => {
        if (event.keyCode === 8 && !otpInputs[index] && index > 0) {
            // Handle Backspace key when input is empty
            inputRefs.current[index - 1].focus();
        }
    };

    const formRef = useRef(null)

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('IN'); // Default country as India (IN)
    const [selectedState, setSelectedState] = useState('');
    const [refError, setError] = useState(null);

    useEffect(() => {
        // Fetch all countries and set them
        setCountries(Country.getAllCountries());

        // Fetch the states of India by default
        const indiaStates = State.getStatesOfCountry('IN');  // Get states of India
        setStates(indiaStates);

        // You can set the default state if needed (optional)
        setSelectedState('');  // Select the first state of India by default
    }, []);

    const [formData, setFormData] = useState({
        userName: '',
        userEmail: '',
        dialCode: '',
        userPhone: '',
        userCountry: 'India',
        userState: '',
    });

    // country handler
    const handleCountryChange = (event) => {
        const countryCode = event.target.value;
        const country = countries.find(c => c.isoCode === countryCode); // Get full country name

        setSelectedCountry(countryCode);
        setSelectedState(''); // Reset state when country changes
        setFormData((prev) => ({
            ...prev,
            userCountry: country ? country.name : '', // Update formData with full country name
            userState: '' // Reset state in formData
        }));

        const countryStates = State.getStatesOfCountry(countryCode);
        setStates(countryStates);
    };

    // state handler
    const handleStateChange = (event) => {
        const stateCode = event.target.value;
        const state = states.find(s => s.isoCode === stateCode); // Get full state name

        setSelectedState(stateCode);
        setFormData((prev) => ({
            ...prev,
            userState: state ? state.name : '' // Update formData with full state name
        }));
    };

    // input handler
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // phone handler
    const handlePhoneChange = (value, country) => {
        console.log(country.dialCode);

        setFormData({
            ...formData,
            userPhone: value, // Store only the phone number
            dialCode: country.dialCode,
        });

    };

    // otp html
    const renderOtpInputs = () => (
        <TextField
            size="small"
            variant="outlined"
            placeholder="Enter OTP"
            value={otpInputs.join("")}  // Convert array to string
            onChange={(e) => otpHandler(0, e.target.value)}  // Keep function name same
            inputProps={{ maxLength: 4, inputMode: "numeric", pattern: "[0-9]*" }}
            error={isNotNumber}
            helperText={isNotNumber ? "Only numeric values allowed" : ""}
            fullWidth
            sx={{
                mb: 4,
                "& input": {
                    letterSpacing: "10px", // Space between digits
                    textAlign: "center",
                    fontSize: "24px",
                },
            }}
        />

    );

    // pre-submit
    const clickSubmitcheck = (e) => {

        e.preventDefault()


        if (!/^\d+$/.test(formData.userPhone)) {
            alert('Please enter only numeric values in the phone number field.');
            return; // Do not proceed further if the input is invalid
        }


        Swal.fire({
            title: "Confirmation",
            html: `
                <div style="font-family: Arial, sans-serif; text-align: left;">
                    <p style="font-size: 16px;">Please confirm that you want to proceed with the following information:</p>
                    <ul style="list-style: none; padding: 0; margin: 0;">
                        <li style="margin-bottom: 10px;">
                            <strong>Name:</strong> <span style="color: #333;">${formData.userName}</span>
                        </li>
                        <li style="margin-bottom: 10px;">
                            <strong>Email Address:</strong> <span style="color: #333;">${formData.userEmail}</span>
                        </li>
                        <li style="margin-bottom: 10px;">
                            <strong>Phone Number:</strong> <span style="color: #333;">${formData.userPhone}</span>
                        </li>
                        <li style="margin-bottom: 10px;">
                            <strong>Country:</strong> <span style="color: #333;">${formData.userCountry}</span>
                        </li>
                        <li style="margin-bottom: 10px;">
                            <strong>State:</strong> <span style="color: #333;">${formData.userState}</span>
                        </li>
                    </ul>
                </div>
            `,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes, Continue",
            cancelButtonText: "Cancel",
            backdrop: true,
        }).then((result) => {
            if (result.isConfirmed) {
                clickSubmit();
            }
        });


    }

    const clickSubmit = () => {

        if (!isSubmitClicked) {
            SetIsSubmitClicked(true);

            const link = "https://backend.shubadinam.com/upload/mailpass"
            axios.post(link, formData)
                .then((response) => {

                    if (response.data.errors) {

                        const invalidmailPass = response.data.errors[0].msg

                        Swal.fire({
                            title: 'Invalid',
                            text: invalidmailPass,
                            icon: 'error',
                            confirmButtonText: 'Got it!', // Change the confirm button text
                            confirmButtonColor: '#3085d6', // Change the confirm button color
                            background: '#f5f5f5',
                        });


                    }
                    else {

                        // shows otp 
                        SetIsOtpSent(true)

                    }

                    SetIsSubmitClicked(false);

                })
                .catch((error) => {
                    console.log(error); // Log the error for debugging

                    // Default error message
                    let backendError = "An unknown error occurred";
                    let userExist = null;
                    let invalidReferral = null;
                    // Determine the type of error
                    if (error.response) {
                        invalidReferral = error.response.data.message;
                        setError(invalidReferral)
                        if (invalidReferral) {
                            return;
                        }
                        userExist = error.response.data.error;
                        backendError = userExist || "An error occurred with the request.";
                    } else if (error.request) {
                        backendError = "No response received from the server.";
                    } else {
                        backendError = error.message;
                    }

                    // Specific message for network errors
                    if (backendError === "Network Error") {
                        backendError = "Network error, please check your internet connection.";
                    }

                    // Display the alert
                    Swal.fire({
                        title: userExist ? 'User Already Exist' : '',
                        text: backendError,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#3085d6',
                        background: '#f5f5f5',
                    });
                });


            SetIsSubmitClicked(false);


        }

    }

    return (
        <Box >
            <HelmetProvider>
                <Helmet>
                    <title>Register </title>
                    <meta name="description" content="Register  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>


            <SlidingHeader />

            <Box sx={{ mt: 10, mb: 20, p: { xs: 0, md: 6 }, bgcolor: "aliceblue", display: "flex", justifyContent: "center", minHeight: "100vh", alignItems: "center" }} >


                <Grid container spacing={2} display="flex" maxWidth='xl'>
                    <Grid item xs={12} lg={6} ref={formRef} sx={{ order: { xs: 1, lg: 2 }, }}>

                        <form
                            onSubmit={clickSubmitcheck} // Assuming clickSubmitcheck handles form submission
                            style={{
                                width: "100%",
                                padding: '10px 30px',
                                borderRadius: '8px',
                                textAlign: 'center',
                                background: "#ffff",
                                border: '1px solid #dddd',
                                marginBottom: { xs: '16px', md: '0' },

                            }}
                        >
                            <Typography
                                variant="h6"
                                color="secondary"
                                textAlign="start"
                                fontWeight="normal"
                                sx={{ marginBottom: 4, mt: 2 }}
                            >
                                Embrace Your <i style={{ fontWeight: "bold" }}>Janma Nakshatra/Tithi</i>.
                                Unlock insights into your destiny with just a click ✨.
                            </Typography>

                            <Grid item xs={12} sx={{ p: 2, bgcolor: "aliceblue", borderRadius: 3 }}>

                                <TextField
                                    placeholder="Referral Code"
                                    variant="outlined"
                                    fullWidth
                                    error={!!refError}  // Convert refError to boolean
                                    helperText={refError || ""} // Show error message if refError exists
                                    onChange={(e) => setFormData({ ...formData, referredBy: e.target.value })}
                                    name="referredBy"
                                    InputProps={{
                                        sx: {
                                            borderRadius: 3,
                                            bgcolor: "white",
                                            letterSpacing: '10px',
                                        },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    size="small"
                                                    edge="start"
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        backgroundColor: "#e3f2ff", // Light grey shade
                                                        padding: "5px",
                                                    }}
                                                >
                                                    <CardGiftcard sx={{ color: "#1b3e69" }} />
                                                </IconButton>

                                            </InputAdornment>
                                        ),
                                    }}
                                />


                            </Grid>

                            {/* <Divider sx={{ height: 2, bgcolor: "#ddd", mt: 2 }} /> */}

                            <Grid container spacing={5} sx={{ mt: 0.5 }}>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        // label="Name"
                                        name="userName"
                                        placeholder="Name"
                                        fullWidth
                                        variant="standard"
                                        sx={{ backgroundColor: "white" }}
                                        size="small"
                                        onChange={handleChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Person color="secondary" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>


                                    <StyledPhoneInput
                                        validateMobilePrefix={true}
                                        country={"in"}
                                        value={formData.userPhone}
                                        name="userPhone"
                                        onChange={handlePhoneChange}
                                        inputProps={{
                                            inputMode: "numeric",
                                        }}
                                    />

                                    {/* <PhoneInput
                                        validateMobilePrefix={true}
                                        country={'in'} // Default country code
                                        value={formData.userPhone} // Display with the country code
                                        name="userPhone"
                                        onChange={handlePhoneChange}
                                        inputStyle={{
                                            width: '100%',
                                            height: '40px',
                                            borderRadius: '5px',
                                            borderTop: "transparent !imprortant",
                                            borderBottom: '1px solid #ced4da',
                                            paddingLeft: '50px', // Space for the country code dropdown
                                        }}
                                        inputProps={{
                                            inputMode: "numeric"
                                        }}
                                        dropdownStyle={{
                                            borderRadius: '5px',
                                        }}
                                    /> */}

                                </Grid>

                                <Grid item xs={12} >
                                    <TextField
                                        name="userEmail"
                                        variant="standard"
                                        placeholder="Email address"
                                        fullWidth
                                        required
                                        size="small"
                                        onChange={handleChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailIcon color="secondary" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>


                                <Grid item xs={12} md={6}>
                                    <FormControl variant="standard"
                                        fullWidth sx={{ textAlign: "start", textIndent: 15 }}>
                                        {/* <InputLabel>Country</InputLabel> */}
                                        <Select
                                            value={selectedCountry}
                                            onChange={handleCountryChange}
                                            label="Country"
                                            placeholder="country"
                                            name="userCountry"
                                            required
                                        >
                                            {countries.map((country) => (
                                                <MenuItem key={country.isoCode} value={country.isoCode}>
                                                    {country.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl variant="standard"
                                        fullWidth sx={{ textAlign: "start", textIndent: 15 }}>
                                        {/* <InputLabel>State</InputLabel> */}
                                        <Select
                                            required
                                            displayEmpty
                                            value={selectedState}
                                            onChange={handleStateChange}
                                            label="State"
                                            disabled={!selectedCountry}
                                            name="userState"
                                        >
                                            <MenuItem value="" disabled>
                                                State
                                            </MenuItem>
                                            {states.map((state) => (
                                                <MenuItem key={state.isoCode} value={state.isoCode}>
                                                    {state.name}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>

                            {isOptSent ? (


                                <Box sx={{ maxWidth: 400, margin: '0 auto', padding: 3, boxShadow: 3, borderRadius: 2, mt: 5, mb: 5, backgroundColor: "#eaf5ffb3" }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                        OTP Verification
                                    </Typography>
                                    <Typography sx={{ mb: 2, color: 'text.secondary' }}>
                                        We have sent a verification code to your mobile number.
                                    </Typography>
                                    {isNotNumber && (
                                        <Typography color="error" sx={{ mb: 2 }}>
                                            (Note: Please enter numeric values.)
                                        </Typography>
                                    )}
                                    {/* OTP Inputs */}

                                    {renderOtpInputs()}

                                    <Grid item xs={12} md={12} >

                                        <Grid container spacing={1} justifyContent="center">

                                            <Grid item xs={6}>
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={resendOtp}
                                                    sx={{ bgcolor: "white", }}
                                                    fullWidth
                                                >
                                                    Resend OTP
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={verifyRegOtp}
                                                    disabled={isVerifyclicked}
                                                    fullWidth
                                                >
                                                    {isVerifyclicked ? "Verifying" : "Verify me"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                                :
                                (
                                    <Box sx={{ mt: 3, width: '100%', display: "flex", justifyContent: "center", p: 2 }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{ mb: 2, width: "50%", textTransform: "none" }}
                                        >
                                            {isSubmitClicked ? 'Submitting...' : 'Submit'}
                                        </Button>
                                    </Box>)

                            }


                            <Typography sx={{ textAlign: 'center' }}>
                                Already have an account?
                                <NavLink to="/signin" style={{ textDecoration: 'none', marginLeft: 4 }}>
                                    <span style={{ color: '#38a1f7', fontWeight: 'bold' }}>Sign In</span>
                                </NavLink>
                            </Typography>
                        </form>

                    </Grid>

                    <Grid item xs={12} lg={6} sx={{ order: { xs: 2, lg: 1 } }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            {/* Image Container with Overlay */}
                            <Box
                                sx={{
                                    position: "relative",
                                    width: "100%",
                                    // maxWidth: 500,
                                    borderRadius: "12px",
                                    overflow: "hidden",
                                }}
                            >
                                <img
                                    src={require("../images/thumbnails/lg_hero.png")}
                                    alt="Sign Up"
                                    style={{ width: "100%", height: 500, objectFit: "cover" }}
                                />

                                {/* Gradient Overlay */}
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        background: "linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))",
                                    }}
                                />
                            </Box>

                            {/* Text Content */}
                            <Box sx={{ mt: 3, px: { xs: 2, md: 4 } }}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    sx={{ fontWeight: "bold", color: "#333", mb: 1 }}
                                >
                                    ✨ Aligning Stars ✨
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ color: "#666", lineHeight: 1.6, fontSize: "1rem" }}
                                >
                                    Embrace Your <strong>Janma Nakshatra/Tithi</strong>.
                                    Unlock insights into your destiny with just a click.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <FooterComponent></FooterComponent>


        </Box >
    );
};


export default RegisterPage;







