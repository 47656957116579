import React, { useState } from "react";
import {
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Collapse,
    ListItemIcon,
    Divider,
    CssBaseline,
    Toolbar,
    AppBar,
    Typography,
    IconButton,
    useMediaQuery,
} from "@mui/material";
import { ExpandLess, ExpandMore, Menu as MenuIcon, Close, NavigateNext } from "@mui/icons-material";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
    Dashboard, People, TempleHindu, Work, CalendarMonth, ManageAccounts, Settings, Logout,
    Group, Star, PersonOff, HowToReg, Login, ThumbUp, Comment, Event, Sms, YouTube, Email, PostAdd, PersonAdd
} from "@mui/icons-material";
import ScrollToTop from "react-scroll-to-top";

const menuItems = [
    { name: "Dashboard", icon: <Dashboard />, path: "/admin-panel" },
    {
        name: "Reports",
        icon: <People />,
        subItems: [
            {
                name: "Users",
                icon: <Group />, // Icon for Users submenu
                subItems: [
                    { name: "All Users", icon: <PersonAdd />, path: "/admin-panel/total-customers" },
                    { name: "Premium Users", icon: <Star />, path: "/admin-panel/premiumusers" },
                    { name: "Non Premium Users", icon: <PersonOff />, path: "/admin-panel/non-premiumusers" },
                ],
            },
            {
                name: "Visitors",
                icon: <HowToReg />, // Icon for Visitors submenu
                subItems: [
                    { name: "Sign Up", icon: <PersonAdd />, path: "/admin-panel/signup-visitors" },
                    { name: "Log In", icon: <Login />, path: "/admin-panel/login-visitors" },
                    { name: "Premium", icon: <Star />, path: "/admin-panel/premium-visitors" },
                    { name: "Homam", icon: <TempleHindu />, path: "/admin-panel/homam-visitors" },
                ],
            },
            { name: "Feedback", icon: <Comment />, path: "/admin-panel/feedback" },
        ],
    },
    {
        name: "Homam",
        icon: <TempleHindu />,
        subItems: [
            { name: "Homam Live Update", icon: <Event />, path: "/admin-panel/homam-admin" },
            { name: "Homam Purchased Report", icon: <ThumbUp />, path: "/admin-panel/homam-purchased-report" },
            { name: "Homam Monthly Bookings", icon: <CalendarMonth />, path: "/admin-panel/homam-customer-bookings" },
        ],
    },
    {
        name: "Operations",
        icon: <Work />,
        subItems: [
            { name: "Date Finder", icon: <Event />, path: "/admin-panel/date-finder" },
            { name: "Send B'day SMS", icon: <Sms />, path: "/admin-panel/birthday-sms" },
        ],
    },
    {
        name: "Database",
        icon: <CalendarMonth />,
        subItems: [
            { name: "Upload Panchangam", icon: <PostAdd />, path: "/admin-panel/upload-panchangam" },
            { name: "Upload Tithi", icon: <PostAdd />, path: "/admin-panel/upload-tithi" },
        ],
    },
    {
        name: "Others",
        icon: <Work />,
        subItems: [
            { name: "Upload Youtube Videos", icon: <YouTube />, path: "/admin-panel/upload-youtube-videos" },
            { name: "Create Premium", icon: <Star />, path: "/admin-panel/create-premium" },
            { name: "Generate Daily Post", icon: <PostAdd />, path: "/admin-panel/generate-post" },
            { name: "Compose Email", icon: <Email />, path: "/admin-panel/compose-email" },
        ],
    },
    { name: "Manage Customer", icon: <ManageAccounts />, path: "/admin-panel/manage-customer" },

    {
        name: "Settings",
        icon: <Settings />,
        subItems: [
            { name: "Add User", icon: <PersonAdd />, path: "/admin-panel/add-user" },
        ],
    },
    { name: "Logout", icon: <Logout />, path: "/logout" },
];

const AffiliateDashboardLayout = () => {
    const [openMenus, setOpenMenus] = useState({});
    const [drawerOpen, setDrawerOpen] = useState(window.innerWidth > 700);
    const [hoverOpen, setHoverOpen] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const drawerWidth = isMobile ? "100%" : 250;
    const collapsedWidth = 60;

    // Toggle drawer for mobile
    const toggleDrawer = () => {
        setDrawerOpen((prev) => !prev);
        setOpenMenus({})
    };

    // Handle menu expansion
    const handleMenuClick = (menuName) => {
        setOpenMenus((prev) => ({
            ...prev,
            [menuName]: !prev[menuName],
        }));
    };

    // Handle navigation & close drawer on mobile
    const handleNavigation = (path) => {
        if (isMobile) {
            setDrawerOpen(false); // Close drawer on mobile after clicking
            setOpenMenus({});
        }
        navigate(path);
    };

    const renderMenuItems = (items, level = 0) => {
        return items.map((item, index) => {
            const isActive = location.pathname === item.path;

            return (
                <div key={index}>
                    <ListItem disablePadding sx={{ pl: level * 2 }}>
                        <ListItemButton
                            sx={{
                                p: 1,
                                "&:hover": {
                                    bgcolor: level === 0 ? theme.palette.primary.dark : theme.palette.action.hover,
                                    color: "white",
                                },
                            }}
                            onClick={() =>
                                item.subItems ? handleMenuClick(item.name) : handleNavigation(item.path)
                            }
                        >
                            {item.icon && (
                                <ListItemIcon sx={{ color: theme.palette.primary.light }}>
                                    {item.icon}
                                </ListItemIcon>
                            )}
                            {(drawerOpen || hoverOpen) && (
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" sx={{ fontSize: "1rem", fontWeight: { xs: 'bold', md: 500 } }}>
                                            {item.name}
                                        </Typography>
                                    }
                                />
                            )}
                            {item.subItems ? openMenus[item.name] ? <ExpandLess /> : <ExpandMore /> : null}
                        </ListItemButton>
                    </ListItem>

                    {/* Render dropdown if item has subItems */}
                    {item.subItems && (
                        <Collapse in={openMenus[item.name]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {item.subItems.map((subItem, subIndex) => (
                                    <div key={subIndex}>
                                        <ListItem disablePadding sx={{ pl: (level + 1) * 2 }}>
                                            <ListItemButton
                                                onClick={() =>
                                                    subItem.subItems
                                                        ? handleMenuClick(subItem.name)
                                                        : handleNavigation(subItem.path)
                                                }
                                            >
                                                {subItem.icon && (
                                                    <ListItemIcon sx={{ color: "darkgrey" }}>
                                                        {subItem.icon}
                                                    </ListItemIcon>
                                                )}
                                                <ListItemText
                                                    secondary={
                                                        <Typography variant="body1" sx={{ fontWeight: { xs: 900, md: 500 }, color: { xs: 'gray', md: 'black' }, }}>
                                                            {subItem.name}
                                                        </Typography>
                                                    }
                                                />
                                                {subItem.subItems ? (
                                                    openMenus[subItem.name] ? <ExpandLess /> : <ExpandMore />
                                                ) : null}
                                            </ListItemButton>
                                        </ListItem>

                                        {/* Render nested dropdowns for Users and Visitors */}
                                        {subItem.subItems && (
                                            <Collapse in={openMenus[subItem.name]} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {subItem.subItems.map((nestedItem, nestedIndex) => (
                                                        <ListItem key={nestedIndex} disablePadding sx={{ pl: (level + 2) * 2 }}>
                                                            <ListItemButton onClick={() => handleNavigation(nestedItem.path)}>
                                                                {nestedItem.icon && (
                                                                    <ListItemIcon sx={{ color: "slategrey" }}>
                                                                        {/* {nestedItem.icon}  */} <NavigateNext sx={{ fontSize: "1rem" }} />
                                                                    </ListItemIcon>
                                                                )}
                                                                <ListItemText
                                                                    secondary={
                                                                        <Typography variant="body5" sx={{ fontWeight: { xs: 500, md: 500 }, color: { xs: 'grey', md: 'grey' }, fontSize: { xs: '1rem', md: '0.9rem' } }}>
                                                                            {nestedItem.name}
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Collapse>
                                        )}
                                    </div>
                                ))}
                            </List>
                        </Collapse>
                    )}
                </div>
            );
        });
    };


    return (
        <div style={{ display: "flex" }}>
            <ScrollToTop smooth />
            <CssBaseline />
            <AppBar elevation={0} position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1, bgcolor: theme.palette.primary.dark }}>
                <Toolbar>
                    {!isMobile && (
                        <IconButton color="inherit" edge="start" onClick={toggleDrawer} sx={{ mr: 2 }}>
                            {drawerOpen ? <Close /> : <MenuIcon />}
                        </IconButton>
                    )}
                    <Typography variant="h6" noWrap>ShubaMitra Dashboard</Typography>
                    {isMobile && (
                        <IconButton color="inherit" onClick={toggleDrawer} sx={{ ml: "auto" }}>
                            {drawerOpen ? <Close /> : <MenuIcon />}
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>

            <Drawer
                variant={isMobile ? "temporary" : "permanent"}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                ModalProps={{ keepMounted: true }}
                sx={{
                    width: drawerOpen ? drawerWidth : collapsedWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerOpen ? drawerWidth : collapsedWidth,
                        boxSizing: "border-box",
                        transition: "width 0.3s",
                        overflowX: "hidden",
                    },
                }}
            >
                <Toolbar />
                <List sx={{ overflowX: "hidden", "&::-webkit-scrollbar": { width: "0.2px" } }}>{renderMenuItems(menuItems)}</List>
                <Divider />
            </Drawer>

            <div
                style={{
                    flex: 1,
                    transition: "width 0.3s ease-in-out",
                    padding: "30px",
                    width: `calc(100% - ${drawerOpen ? drawerWidth : collapsedWidth}px)`,
                    overflowX: "hidden",
                }}
            >
                <Toolbar />
                <Outlet />
            </div>
        </div>
    );
};

export default AffiliateDashboardLayout;
