import React from "react";
import FooterComponent from "../components/footer";
import { NavLink, useNavigate } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import BottomSpace from "./BottomSpace";
import LandingHeader from "../components/LandingHeader";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Container,
    Grid,
    Typography,
    Button,
    Box,
} from '@mui/material';
import SlidingHeader from "../components/SlidingHeader";
import PageSlider from "./utils/PageSlider";

const AboutScreen = () => {
    const navigate = useNavigate();

    const getStarted = () => {
        navigate("/");
    };

    return (
        <div style={{ backgroundColor: "aliceblue" }}>
            <HelmetProvider>
                <Helmet>
                    <title>About</title>
                    <meta name="description" content="About - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <ScrollToTop smooth />
            <SlidingHeader />

            {/* Hero Section */}
            <PageSlider
                bgImage={require("../images/banners/all_hero_banner.jpg")}
                pageTitle={`About Us`}
                subTitle="Celebrating Cultural Identity Through Birth Stars"

            />

            <Box sx={{ mt: 5 }}>
                <Container maxWidth="lg">
                    {/* Heading Section */}


                    {/* Content Section */}
                    <Grid container spacing={6} alignItems="center">
                        {/* Left Column */}
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" color="primary" fontWeight="bold" mb={3}>
                                Welcome to Shubadinam Heritage Services:
                            </Typography>
                            <Typography variant="body1" color="text.primary" paragraph>
                                Shubadinam Heritage Services stands as a humble offering toward the noble cause of retaining and reclaiming the profound cultural legacy of India.
                                Our mission is to illuminate the importance of celebrating Birth Stars, known as Janma Nakshatra / Janma Tithi.
                            </Typography>
                            <Typography variant="body1" color="text.primary">
                                We firmly believe that comprehending and rejoicing in one's Birth Star is a potent means to connect with our identity and pay homage to the timeless wisdom passed down by our ancestors.
                            </Typography>
                        </Grid>

                        {/* Right Column */}
                        <Grid item xs={12} md={6}>
                            <img
                                src={require("../images/about-us.png")}
                                alt="About Us"
                                style={{ width: '100%', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0,0,0,0.1)' }}
                                onContextMenu={(e) => e.preventDefault()}
                            />
                        </Grid>
                    </Grid>

                    {/* Journey Section */}
                    <Box mt={8} >
                        <Grid container spacing={6} >
                            {/* Content Section */}
                            <Grid item xs={12} >
                                <Box>
                                    <Typography variant="h4" color="primary" fontWeight="bold" mb={3}>
                                        Our Journey of Rediscovery
                                    </Typography>

                                    <Typography variant="body1" color="text.secondary" mb={3}>
                                        As students, we were once influenced by the colonial hangover like many others. It was our exposure to
                                        Sanskrit Scholars and Astikas in our family that ignited a deep desire to preserve and celebrate the
                                        ancient wisdom of our traditions. <strong>Shubadinam Heritage Services</strong> was born from this longing—a passion to embrace and share our cultural
                                        identity with the world.
                                    </Typography>


                                    {/* Card Section */}
                                    <Box
                                    >
                                        <Typography variant="h5" fontWeight="bold" gutterBottom color="primary">
                                            Action, Not Just Gyaan (Knowledge)
                                        </Typography>

                                        <Typography variant="body1" color="text.secondary">
                                            During brainstorming sessions, we stumbled upon the topic of why people celebrate
                                            <strong> “English Birthday vs Janma Dinam”</strong>. The biggest challenge was the lack of
                                            knowledge and ease of following the Indian Calendar (Panchang).
                                        </Typography>

                                        <Typography variant="body1" color="text.primary" fontWeight="bold" mt={2}>
                                            Our solution: Register Once and Receive Reminders for Life.
                                        </Typography>

                                        <Typography variant="body1" mt={2}>
                                            (<NavLink to="/why-us" style={{ color: '#007bff', textDecoration: 'none', fontWeight: 'bold' }}>
                                                Click here to read more about “Why Shubadinam Heritage Services?”
                                            </NavLink>)
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                    <Box mt={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={getStarted}
                        >
                            Get Started
                        </Button>
                    </Box>
                </Container>
            </Box >
            <BottomSpace />
            <FooterComponent />
        </div >
    );
};

export default AboutScreen;
