import React, { useState } from "react";
import { Button, Typography, Box, Container, Grid, IconButton } from "@mui/material";
import { green, orange } from "@mui/material/colors";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import LoginIcon from "@mui/icons-material/Login";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import SlidingHeader from "../../components/SlidingHeader";
import { PlayCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


const slides = [
    {
        src: require("../../images/thumbnails/slide1.jpg"),
        alt: "Banner 3",
        link: "/register"
    },
    {
        src: require("../../images/thumbnails/slide2.png"),
        alt: "Banner 1",
        link: "/pricing"
    },
    {
        src: require("../../images/thumbnails/slide3.png"),
        alt: "Banner 2",
        link: "/virtual-puja-seva"
    },
    {
        src: require("../../images/thumbnails/slide4.png"),
        alt: "Banner 4",
        link: "https://shop.shubadinam.com/"
    }
];


const HeroSlider = () => {

    const [showIframe, setShowIframe] = useState(false);

    const navigate = useNavigate();

    const isLogged = localStorage.getItem("token");


    return (
        <Box>
            <SlidingHeader />
            {/* Banner Section - Splide Carousel */}
            <Box sx={{ width: "100%", overflow: "hidden", mt: { xs: 10, md: 0 } }}>
                <Splide options={{ type: "loop", autoplay: false, interval: 3000, pagination: true, arrows: true }}>
                    {slides.map((slide, index) => (
                        <SplideSlide
                            key={index}
                            onClick={() => {
                                if (slide.link.startsWith("./")) {
                                    navigate(slide.link); // Internal navigation
                                } else if (slide.link.startsWith("https")) {
                                    window.open(slide.link, "_blank"); // Open in new tab
                                }
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            <img src={slide.src} alt={slide.alt} style={{ width: "100%", borderRadius: "8px" }} />
                        </SplideSlide>
                    ))}
                </Splide>
            </Box>

            <Grid container spacing={4} alignItems="center" sx={{ bgcolor: "#F9FAFB", minHeight: "80vh", p: { xs: 4, md: 8 } }} >
                {/* Left Content Section */}
                <Grid item xs={12} md={6} textAlign={{ xs: "center", md: "left" }} data-aos='fade-up'>
                    <Typography variant="h5" fontWeight="bold" gutterBottom sx={{ fontSize: { xs: "1.8rem", sm: "2rem", md: "2.5rem", lg: "3rem" } }}>
                        <span style={{ color: orange[700] }} >Birthdays</span> are special
                    </Typography>
                    <Typography variant="h5" fontWeight="bold" gutterBottom sx={{ fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem", lg: "2.5rem" } }}>
                        Make it <span style={{ color: orange[700] }}>Shubadinam</span>, Make it Impactful
                    </Typography>
                    <Typography variant="h6" color="text.secondary" mt={1} sx={{ fontSize: { xs: "1rem", sm: "1.2rem", md: "1.3rem", lg: "1.5rem" } }}>
                        Get reminders to celebrate the Janma Nakshatra / Janma Tithi as per the Indian calendar and make every celebration memorable.
                    </Typography>
                    <Box mt={3} display="flex" flexDirection={{ xs: "row", sm: "row" }} gap={2} justifyContent={{ xs: "space-between", md: "flex-start" }}>
                        <Button variant="contained" startIcon={<LoginIcon />} sx={{ bgcolor: orange[600], color: "white", fontWeight: "bold", width: { xs: "50%", sm: "auto" } }} onClick={() => navigate(isLogged ? "/dashboard" : "/register")}
                        >
                            Sign Up
                        </Button>
                        <Button variant="outlined" startIcon={<PlayArrowIcon />} sx={{ color: 'black', borderColor: orange[600], fontWeight: "bold", width: { xs: "50%", sm: "auto" } }}>
                            Watch Video
                        </Button>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} textAlign='center' data-aos='fade-up'>
                    {/* Thumbnail with Play Button */}
                    {!showIframe ? (
                        <Box position="relative" width="100%">
                            <img
                                src={require("../../images/thumbnails/aaaa.jpg")}
                                alt="Affiliate Program"
                                style={{
                                    width: "100%", borderRadius: 8, height: 'auto'
                                }}
                            />
                            <IconButton
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                                    color: "#ff7b00",
                                    p: 2,
                                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.8)" }
                                }}
                                onClick={() => setShowIframe(true)}
                            >
                                <PlayCircle sx={{ fontSize: 40 }} />
                            </IconButton>
                        </Box>
                    ) : (
                        <iframe
                            width="100%"
                            height="400px"
                            src="https://www.youtube.com/embed/x1YUd2Ane2s?autoplay=1&controls=0"

                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{ borderRadius: '8px' }} // Keep iframe with rounded corners
                        ></iframe>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default HeroSlider;