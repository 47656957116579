import React, { useEffect, useState } from 'react';
import SDadminHeader from './SDadminHeader';
import Top20px from '../screens/Top20px';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { NavLink, useNavigate } from 'react-router-dom';
import { Prev } from 'react-bootstrap/esm/PageItem';

const ContactSentItems = () => {

    const [fromdata, setFromData] = useState([])
    useEffect(() => {
        const url = `https://backend.shubadinam.com/admin/get/sentitems`;
        axios.get(url)
            .then((response) => {
                // console.log(response.data);
                setFromData(response.data)
                setFromData(prev => {
                    if (prev) {
                        const uniqueEmails = new Set();
                        const uniqueData = prev.filter(row => {
                            if (!uniqueEmails.has(row.customerEmail)) {
                                uniqueEmails.add(row.customerEmail);
                                return true;
                            }
                            return false;
                        });
                        return uniqueData;
                    }
                    return prev;
                });
            })
            .catch((error) => {
                // console.log(error);
            })
    }, [])



    const [chngPwd, SetPwd] = useState(false);
    const [confirmPassword, SetConfirmPassword] = useState({
        // oldPwd: "",
        confirmPwd: "",
        reconfirmPwd: "",
    });


    const togglePassword = () => {
        SetPwd(true)
    }


    const pwdhandler = (evt) => {
        SetConfirmPassword({ ...confirmPassword, [evt.target.name]: evt.target.value });
    };

    const clickSubmit = () => {
        if (confirmPassword.confirmPwd !== "") {
            if (confirmPassword.confirmPwd === confirmPassword.reconfirmPwd) {

                const url = `https://backend.shubadinam.com/admin/upt/password`;

                const adminId = sessionStorage.getItem("adminId")

                axios
                    .post(url, { ...confirmPassword, adminId: adminId })
                    .then((response) => {
                        // console.log(response);
                        alert(response.data.message)
                        // alert("Password updted successfully.");
                    })
                    .catch((error) => {
                        alert(error.response.data.message)
                        // console.log(error);
                    });

                SetPwd(false);
            } else {
                alert("not equal");
                SetPwd(false);
            }
        } else {
            alert("password field is empty");
            SetPwd(false);
        }

    };

    const clickClose = () => {
        SetPwd(false)
    };

    const handlePopupClick = (e) => {
        // Prevent the click event from reaching the pwd-container
        e.stopPropagation();
    };

    const [dynamicState, setdynamicState] = useState({
        // oldPwd: "",
        userDetail: "",
        searchType: "",
    });

    const handleOpenNewTab = (row) => {

        const queryParams = new URLSearchParams({
            personEmail: JSON.stringify(row.customerEmail)
        });

        const url2 = `/contact/sent-items/per-person?${queryParams.toString()}`;
        window.open(url2, '_blank');
    };



    return (
        <div className="sd-adminpage">
            <header className="sd-header">
                <SDadminHeader clickPass={togglePassword} />
            </header>
            <Top20px />
            <div className="sd-body-database">

                <div className="sd-body-database-container">
                    <h3 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Sent Items  </h3>
                    <br />


                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: "50px" }}> S.No</TableCell>
                                    {/* <TableCell align="center">Date</TableCell> */}
                                    <TableCell align="center"> Name</TableCell>
                                    <TableCell align="center" > Email</TableCell>
                                    <TableCell align="center" > Id</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fromdata.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}

                                    >
                                        <TableCell style={{ width: "20px" }} align="left">{index + 1}</TableCell>
                                        {/* <TableCell align="center">
                                            {new Date(row.replyTime).toLocaleString('en-US', { hour12: false })}
                                        </TableCell> */}
                                        <TableCell component="th" scope="row" align="center">
                                            {row.customerName}
                                        </TableCell>
                                        <TableCell align="center" style={{
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }} >{row.customerEmail}</TableCell>
                                        <TableCell align="center">
                                            {row.customerUniqueid}
                                        </TableCell>
                                        <TableCell align="center">
                                            <NavLink onClick={() => handleOpenNewTab(row)}>View Replies</NavLink>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                </div>
            </div>
            {
                chngPwd ?
                    <div className="pwd-container" onClick={clickClose}>
                        <div className="pwd-popup" onClick={handlePopupClick}>

                            <label>Enter your New Password :</label>
                            <input type="text" name="confirmPwd" onChange={pwdhandler} />
                            <br />
                            <label>Reconfirm your Password :</label>
                            <input type="text" name="reconfirmPwd" onChange={pwdhandler} />
                            <br />
                            <button onClick={() => clickSubmit()}>Submit</button>
                            <button style={{ marginLeft: "10px", backgroundColor: "#ffd7d7", color: "black" }} onClick={() => clickClose()}>Close</button>

                        </div>
                    </div>
                    :
                    <span></span>
            }
        </div>
    );
};

export default ContactSentItems;