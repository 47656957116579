import React, { useEffect, useState } from 'react';
import { Container, Typography, Card, CardMedia, CardContent, Box, useMediaQuery, Button } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css'; // Import the default CSS for Splide
import { Skeleton } from '@mui/material'; // Import Skeleton component
import { NavLink } from 'react-router-dom';
import CardGiftcard from '@mui/icons-material/CardGiftcard';
import { useSwipeable } from 'react-swipeable';
import { orange } from '@mui/material/colors';


const categories = [
    {
        name: 'Inspirational',
        img: require("../../images/books/inspiration_1.webp"),
        link: "https://shop.shubadinam.com/collections/inspirational",
        description: "Fuel your soul with stories that inspire and uplift."
    },
    {
        name: 'Spiritual',
        img: require("../../images/books/spiritual_1.webp"),
        link: "https://shop.shubadinam.com/collections/spiritual",
        description: "Discover divine wisdom and inner peace."
    },
    {
        name: 'Self-Help',
        img: require("../../images/books/self books.webp"),
        link: "https://shop.shubadinam.com/collections/self-help",
        description: "Unlock your potential and transform your life."
    },
    {
        name: 'Heritage',
        img: require("../../images/books/heritage_1.webp"),
        link: "https://shop.shubadinam.com/collections/heritage",
        description: "Explore timeless traditions and cultural roots."
    },
    {
        name: 'Motivational',
        img: require("../../images/books/motivation_1.webp"),
        link: "https://shop.shubadinam.com/collections/motivational",
        description: "Stay driven with books that ignite success."
    },
];

const BooksCategory = () => {


    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => console.log('Swiped Left'),
        onSwipedRight: () => console.log('Swiped Right'),
        trackMouse: true,  // Optional: track mouse events for swipe-like behavior on desktop
    });


    return (
        <Splide
            options={{
                type: "slide",
                perPage: 3, // Default for large screens
                perMove: 1,
                gap: "16px",
                pagination: true,
                drag: "free", // Enables smooth dragging
                arrows: true,
                snap: true, // Ensures cards snap into place after dragging
                breakpoints: {
                    2000: { perPage: 4 },  // Large desktops (4K screens)
                    1600: { perPage: 5 },  // Large desktops (4K screens)
                    1300: { perPage: 4 },  // Regular desktops
                    1024: { perPage: 4 }, // Small desktops & large tablets
                    768: { perPage: 3 },   // Tablets & small laptops
                    600: { perPage: 2 }, // **Small mobile screens should show 2 per page**
                    480: { perPage: 2 }, // **Standard mobile screens should also show 2 per page**
                    360: { perPage: 1.5 },   // **Very small mobile screens show 1.5 per page**
                },
            }}
            style={{
                // background: "#053559",
                borderRadius: "8px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.05)",
            }}
        >
            {categories.map((category, index) => (
                <SplideSlide key={index}>
                    <Card
                        onClick={() => window.open(category.link, "_blank")}
                        sx={{
                            cursor: "pointer",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            borderRadius: 2,
                            bgcolor: '#020332',
                            transition: "transform 0.3s ease, box-shadow 0.3s ease",
                            "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                            },
                            width: "100%", // Ensure cards are responsive
                            maxWidth: "220px", // Keep a max width for consistency
                            height: "auto", // Fixed height for uniformity
                        }}
                        data-aos="fade-up"
                        data-aos-delay={200 * index}
                    >
                        <CardMedia
                            component="img"
                            height="150"
                            width="200"
                            image={category.img}
                            alt={category.title}
                            sx={{
                                borderTopLeftRadius: 2,
                                borderTopRightRadius: 2,
                                bgcolor: "#eeee",
                                objectFit: "contain",
                            }}
                        />
                        <CardContent sx={{ paddingBottom: 2 }}>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ fontWeight: "bold", color: orange[600], fontSize: "1em" }}
                            >
                                {category.name}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="white"
                                sx={{ fontSize: "0.9rem" }}
                            >
                                {category.description}
                            </Typography>
                        </CardContent>
                    </Card>
                </SplideSlide>
            ))}
        </Splide>
    )
}

export default BooksCategory