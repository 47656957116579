import React, { useState } from "react";
import {
    TextField, Button, Container, Typography, Box, Snackbar, Alert, IconButton, InputAdornment
} from "@mui/material";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import AccountCircle from "@mui/icons-material/AccountCircle";

const AddUser = () => {
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!userId.trim() || !password.trim()) {
            setSnackbar({ open: true, message: "All fields are required!", severity: "error" });
            return;
        }

        if (password.length < 6) {
            setSnackbar({ open: true, message: "Password must be at least 6 characters", severity: "error" });
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post("https://backend.shubadinam.com/admin/add-user", { userId, password });
            setSnackbar({ open: true, message: response.data.message, severity: "success" });
            setUserId("");
            setPassword("");
        } catch (error) {
            setSnackbar({ open: true, message: "Failed to add user!", severity: "error" });
            console.error("Error adding user:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    mt: 5, p: 4,
                    boxShadow: 0, borderRadius: 2,
                    bgcolor: "background.paper",

                }}
            >
                <Typography variant="h5" gutterBottom align="center">
                    Add New User
                </Typography>
                <form onSubmit={handleSubmit} style={{
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <TextField
                        fullWidth
                        label="User ID"
                        variant="outlined"
                        margin="normal"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            )
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Lock />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2, maxWidth: 200, }}
                        disabled={loading}
                    >
                        {loading ? "Adding User..." : "Add User"}
                    </Button>
                </form>
            </Box>

            {/* Snackbar Notification */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert severity={snackbar.severity} variant="filled">
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default AddUser;
