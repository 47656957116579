import React from "react";
import { useEffect } from "react";
import FooterComponent from "../components/footer";
import { NavLink } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import ScrollToTop from "react-scroll-to-top";
import LandingHeader from "../components/LandingHeader";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Grid, Typography, List, ListItem, ListItemText } from "@mui/material";
import SlidingHeader from "../components/SlidingHeader";
import ReviewsFeedback from "../components/ReviewsFeedback";

const tithiData = [
    {
        tithi: 'Pratipada',
        likes: 'Leadership roles, initiating new projects, being in control, creativity, challenges.',
        dislikes: 'Routine tasks, being overshadowed, lack of innovation, stagnant environments.'
    },
    {
        tithi: 'Dvitiya',
        likes: 'Social interactions, learning new skills, travel, variety, building connections.',
        dislikes: 'Isolation, rigid routines, stagnation, feeling restricted.'
    },
    {
        tithi: 'Tritiya',
        likes: 'Expressing creativity, trying new things, solving problems, communication, adventure.',
        dislikes: 'Repetition, lack of stimulation, being confined, excessive rules.'
    },
    {
        tithi: 'Chaturthi',
        likes: 'Stability, routine, organization, family time, building a secure foundation.',
        dislikes: 'Sudden changes, chaos, unpredictability, feeling unrooted.'
    },
    {
        tithi: 'Panchami',
        likes: 'Growth opportunities, learning, expanding horizons, nurturing others, family gatherings.',
        dislikes: 'Stagnation, narrow-mindedness, isolation, lack of support.'
    },
    {
        tithi: 'Shashthi',
        likes: 'Taking care of others, health and wellness activities, family bonding, routines.',
        dislikes: 'Disruption, neglect of loved ones, feeling unappreciated, instability.'
    },
    {
        tithi: 'Saptami',
        likes: 'Seeking knowledge, introspection, spiritual practices, solitude, self-discovery.',
        dislikes: 'Superficial interactions, distractions, busy environments, shallowness.'
    },
    {
        tithi: 'Ashtami',
        likes: 'Overcoming challenges, self-improvement, deep transformation, taking responsibility.',
        dislikes: 'Mediocrity, lack of motivation, being controlled by others, superficiality.'
    },
    {
        tithi: 'Navami',
        likes: 'Fulfilling desires, helping others, charitable acts, harmony, family well-being.',
        dislikes: 'Selfishness, discord, unfulfilled aspirations, isolation.'
    },
    {
        tithi: 'Dashami',
        likes: 'Leadership roles, achieving goals, being in control, making a positive impact.',
        dislikes: 'Inefficiency, lack of direction, feeling unproductive, lack of responsibility.'
    },
    {
        tithi: 'Ekadashi',
        likes: 'Spiritual practices, self-reflection, devotion, self-purification, learning.',
        dislikes: 'Materialism, superficiality, distractions, lack of purpose.'
    },
    {
        tithi: 'Dvadashi',
        likes: 'Balancing material and spiritual pursuits, family bonding, introspection, traditions.',
        dislikes: 'Imbalance, conflict, lack of harmony, isolation from loved ones.'
    },
    {
        tithi: 'Trayodashi',
        likes: 'Seeking guidance, personal growth, letting go of the past, renewal, inner transformation.',
        dislikes: 'Stagnation, clinging to the past, resistance to change.'
    },
    {
        tithi: 'Chaturdashi',
        likes: 'Preparing for change, artistic expression, self-care, introspection, beauty.',
        dislikes: 'Chaos, feeling rushed, neglecting self-care, lack of beauty.'
    },
    {
        tithi: 'Purnima',
        likes: 'Celebration, fulfillment, spiritual pursuits, higher knowledge, expressing gratitude.',
        dislikes: 'Negativity, unfulfilled desires, lack of purpose, disharmony.'
    }
];


const BoxPage8 = () => {

    useEffect(() => {
        AOS.refresh();
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);


    return (

        <HelmetProvider>
            <Helmet>
                <title>Tithi Likes and Dislikes</title>
                <meta name="description" content="Tithi Likes and Dislikes - Janma Nakshatra/Tithi Birthday Reminder Service." />
            </Helmet>
            <SlidingHeader />
            <Container maxWidth="lg">
                <ScrollToTop smooth />

                <Box
                    mt={15}
                    sx={{
                        width: '100%',
                        height: '200px',
                        backgroundImage: `url(${require("../images/boxesImages/char2.jpg")})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: '8px'
                    }}
                    onContextMenu={(e) => e.preventDefault()} // Prevent right-click
                />



                <Grid container spacing={2} sx={{ mt: 2 }}>

                    <Grid item xs={12} md={8}>
                        <Box sx={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>

                            <Typography variant="h4" gutterBottom>Tithi Likes and Dislikes</Typography>
                            <Typography variant="body1" color="textSecondary" paragraph>
                                This a general overview of the likes and dislikes that individuals born on each Janma Tithi might have, according to Vedic astrology. Keep in mind that these are broad generalizations and should be taken as a starting point for understanding potential preferences. A person's likes and dislikes can be influenced by various factors, including cultural background, upbringing, and individual experiences.
                            </Typography>

                            <Box sx={{ padding: '20px', backgroundColor: '#f3f4f6' }}>
                                <Grid container spacing={4}>
                                    {tithiData.map((item, index) => (
                                        <Grid item key={index} xs={12} sm={6} md={6}>
                                            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', boxShadow: 3, borderRadius: 2 }}>
                                                <CardContent>
                                                    <Typography variant="h5" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
                                                        {item.tithi}
                                                    </Typography>
                                                    <Typography variant="body1" color="textSecondary">
                                                        <strong>Likes: </strong>{item.likes}
                                                    </Typography>
                                                    <Typography variant="body1" color="textSecondary" sx={{ marginTop: 2 }}>
                                                        <strong>Dislikes: </strong>{item.dislikes}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Box>
                        <ReviewsFeedback />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Card
                            sx={{
                                margin: '20px 0',
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                transition: '0.3s',
                                '&:hover': {
                                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                },

                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Related Searches
                                </Typography>
                                <List>
                                    {[
                                        { path: '/nakshatra-foods', label: 'Nakshatra Foods' },
                                        { path: '/nakshatra-characteristics', label: 'Nakshatra Characteristics' },
                                        { path: '/nakshatra-deities', label: 'Nakshatra Deities' },
                                        { path: '/nakshatra-likes-dislikes', label: 'Nakshatra Likes and Dislikes' },
                                        { path: '/tithi-foods', label: 'Tithi Foods' },
                                        { path: '/tithi-characteristics', label: 'Tithi Characteristics' },
                                        { path: '/tithi-deities', label: 'Tithi Deities' },
                                        { path: '/tithi-likes-dislikes', label: 'Tithi Likes and Dislikes' },
                                    ].map((item) => (
                                        <ListItem
                                            key={item.path}
                                            component={NavLink} // Make the ListItem act like a NavLink
                                            to={item.path} // Set the path for navigation
                                            sx={{
                                                padding: '10px 0',
                                                textDecoration: 'none', // Removes the underline from NavLink
                                                '&:hover': {
                                                    backgroundColor: '#f0f0f0',
                                                    cursor: 'pointer', // Changes the cursor on hover
                                                },
                                            }}
                                        >
                                            <ListItemText sx={{}} primary={item.label} />
                                        </ListItem>
                                    ))}
                                </List>

                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </Container>



            <FooterComponent />
        </HelmetProvider>

    )
}
export default BoxPage8;