import React from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import blueBanner from "../../images/banners/blue-banner-01.png"
import greenBanner from "../../images/banners/green-banner-01.png"
import brownBanner from "../../images/banners/brown-banner-01.png"

import bluePh from "../../images/banners/blue-ph.png"
import greenPh from "../../images/banners/green-ph.png"
import brownPh from "../../images/banners/brown-ph.png"

const BannerSlider = () => {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("lg"));




    return (

        <Box sx={{ width: "100%", maxWidth: "1500px", margin: "auto", }}>
            <Splide
                options={{
                    type: "loop",
                    autoplay: true,
                    interval: 3000,
                    arrows: false,
                    pagination: true,
                }}
            >
                {/* Slide 1 */}
                <SplideSlide>
                    <a href="/pricing">
                        <Box
                            sx={{
                                height: { xs: "150px", md: "200px" },
                                backgroundImage: `url(${mobile ? bluePh : blueBanner})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "relative",
                                overflow: "hidden",
                            }}
                        >
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    // backgroundColor: "rgba(0, 0, 0, 0.3)", // Optional overlay
                                    borderRadius: "20px",
                                }}
                            ></Box>

                        </Box>
                    </a>
                </SplideSlide>
                {/* Slide 2 */}
                <SplideSlide>
                    <a href="https://shop.shubadinam.com/" target="_blank">
                        <Box
                            sx={{
                                height: { xs: "150px", md: "200px" },
                                backgroundImage: `url(${mobile ? greenPh : greenBanner})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "relative",
                                overflow: "hidden",
                            }}
                        >
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    // backgroundColor: "rgba(0, 0, 0, 0.3)", // Optional overlay
                                    borderRadius: "20px",
                                }}
                            ></Box>

                        </Box>
                    </a>
                </SplideSlide>
                {/* Slide 3 */}
                <SplideSlide>
                    <a href="/virtual-puja-seva">
                        <Box
                            sx={{
                                height: { xs: "150px", md: "200px" },
                                backgroundImage: `url(${mobile ? brownPh : brownBanner})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "relative",
                                overflow: "hidden",
                            }}
                        >
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    // backgroundColor: "rgba(0, 0, 0, 0.3)", // Optional overlay
                                    borderRadius: "20px",
                                }}
                            ></Box>

                        </Box>
                    </a>
                </SplideSlide>
            </Splide>

        </Box>


    );
};

export default BannerSlider;
