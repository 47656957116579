import React, { useState, useEffect } from "react";
import { Container, Typography, Grid, Button, Card, CardContent, CardMedia, IconButton, Avatar, Box, CircularProgress, Rating } from "@mui/material";
import { Star, PlayCircle, ArrowDropDownTwoTone, NavigateNext } from "@mui/icons-material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import ReviewsFeedback from "../components/ReviewsFeedback";
import SlidingHeader from "../components/SlidingHeader";
import FooterComponent from "../components/footer";

const reviews = [

];

// Helper function to format the date
function formatDate(dateStr) {
    const [year, month, day] = dateStr.split('-');
    const date = new Date(`${year}-${month}-${day}`);
    return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
    });
}




const TestimonialPage = () => {

    const [feedback, setFeedback] = useState([]);

    useEffect(() => {
        fetch("https://backend.shubadinam.com/approved/testimonials") // Adjust the backend URL accordingly
            .then((res) => res.json())
            .then((data) => setFeedback(data))
            .catch((err) => console.error("Error fetching feedback:", err));
    }, []);



    const combinedTestimonials = [...reviews, ...feedback];
    const [visibleTestimonials, setVisibleTestimonials] = useState(6);

    const handleLoadMore = () => {
        setVisibleTestimonials(prev => prev + 6);
    };


    return (
        <Box>
            <SlidingHeader />

            <Container maxWidth="xl" sx={{ mt: 10, py: 4, bgcolor: "#f8fffa" }}>
                <Typography
                    variant="h4"
                    align="center"
                    sx={{
                        mt: 1,
                        fontWeight: "bold",
                        background: "linear-gradient(45deg, #4a148c, #6a1b9a, #283593, #1565c0, #0d47a1)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                    }}
                >
                    What Our Customers Say
                </Typography>


                <Typography variant="body1" color="textSecondary" align="center" sx={{ mt: 1 }}>
                    Don’t take our word for it. Hear what our lovely customers are saying.
                </Typography>

                <Grid container spacing={4} sx={{ mt: 4 }}>
                    {[...combinedTestimonials].reverse().slice(0, visibleTestimonials).map((testimonial, index) => (
                        <Grid item xs={12} sm={6} lg={4} key={index}>
                            <Card
                                sx={{
                                    bgcolor: index % 2 === 0
                                        ? '#e8f4f8'  // Light bluish for even 
                                        : index % 3 === 0
                                            ? '#f1f8e9'  // Light greenish for index divisible by 3
                                            : index % 4 === 0
                                                ? '#fff3e0'  // Light peach for index divisible by 4
                                                : index % 5 === 0
                                                    ? '#fff8e1'  // Light yellow for index divisible by 5
                                                    : '#f9fbe7',  // Very light greenish-yellow for other odd cases
                                    p: 3,
                                    borderRadius: 3,
                                    boxShadow: 1,
                                    transition: "0.3s",
                                    "&:hover": { boxShadow: 4 },
                                }}
                            >
                                <CardContent>
                                    <Box display="flex" alignItems="center" gap={2} mb={4}>
                                        <Avatar src={testimonial.image} alt={testimonial.userName} />
                                        <Box>
                                            <Typography fontWeight="bold">{testimonial.userName}</Typography>
                                            <Rating name="read-only" value={testimonial.rating} readOnly />
                                            <Typography variant="caption" component="div" sx={{ color: "GrayText" }}>
                                                - {formatDate(testimonial.created_at)}
                                            </Typography>


                                            {/* <Typography variant="body2" color="success.main">
                                            {testimonial.company}
                                        </Typography> */}
                                        </Box>
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontStyle: "italic",
                                            position: "relative",
                                            color: "text.secondary",
                                            "&::before": {
                                                content: '"“"',
                                                fontSize: 40,
                                                position: "absolute",
                                                top: -30,
                                                left: -10,
                                                color: "primary.main",
                                            },
                                            "&::after": {
                                                content: '"”"',
                                                fontSize: 40,
                                                position: "absolute",
                                                bottom: -30,
                                                right: -10,
                                                color: "primary.main",
                                            },
                                        }}
                                    >
                                        {testimonial.explanation}
                                    </Typography>

                                    {
                                        testimonial.reviewFrom && testimonial.reviewFrom.trim() !== "" && (
                                            <Typography align="end" mt={2}>
                                                - {testimonial.reviewFrom}
                                            </Typography>
                                        )
                                    }

                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                {visibleTestimonials < combinedTestimonials.length && (
                    <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
                        <Button
                            endIcon={<NavigateNext />}
                            variant="contained"
                            color="primary"
                            onClick={handleLoadMore}
                            sx={{
                                padding: "10px 30px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                borderRadius: "8px",
                                boxShadow: 2,
                                textTransform: "none",
                                "&:hover": {
                                    backgroundColor: "primary.dark",
                                    boxShadow: 6,
                                },
                                "&:active": {
                                    backgroundColor: "primary.light",
                                    boxShadow: 1,
                                },
                                transition: "all 0.3s ease",
                            }}
                        >

                            Load More

                        </Button>
                    </Box>
                )}

                <ReviewsFeedback />

            </Container>

            <FooterComponent />

        </Box>
    );
};

export default TestimonialPage;