import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Box,
    Typography,
    TablePagination,
    Tabs,
    Tab,
    Container,
} from "@mui/material";
import { AddCircle, CheckCircle, Cancel, Pending, Warning } from "@mui/icons-material";
import SDadminHeader from "./SDadminHeader";
import axios from "axios";

const BirthdaySms = () => {
    const [todayBirthday, setTodayBday] = useState([]);
    const [selfBirthdays, setSelfBirthdays] = useState([]);
    const [relativesBirthdays, setRelativesBirthdays] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `https://backend.shubadinam.com/admin/fetch/todaybday`;
                const response = await axios.get(url);
                console.log(response.data);


                setSelfBirthdays(response.data.self);
                setRelativesBirthdays(response.data.relatives);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);


    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const formatDateWithDifference = (dateStr) => {
        if (!dateStr) return "N/A"; // Handle missing date

        const parseDate = (dateStr) => {
            const [day, month, year] = dateStr.split("-").map(Number);
            return new Date(year, month - 1, day);
        };

        const today = new Date();
        today.setHours(0, 0, 0, 0); // Normalize today’s date (remove time)

        const eventDate = parseDate(dateStr);
        const diffInMs = eventDate - today;
        const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24)); // Convert ms to days

        if (diffInDays === 0) {
            return `${dateStr} (Today)`;
        } else {
            return `${dateStr} (${diffInDays} days left)`;
        }
    };


    const sortByDate = (dateA, dateB) => {
        const parseDate = (dateStr) => {
            const [day, month, year] = dateStr.split("-").map(Number);
            return new Date(year, month - 1, day);
        };

        return parseDate(dateA) - parseDate(dateB);
    };

    const handleSendSMS = async (phone, name, birthDate, type, uniqueId) => {

        console.log(phone, name, birthDate, type, uniqueId);
        try {
            const response = await axios.post("https://backend.shubadinam.com/admin/manage/send-sms", {
                phone,
                name,
                birthDate,
                type,  // "self" or "relative"
                uniqueId
            });

            if (response.data.success) {
                alert("📩 SMS sent successfully!");
            } else {
                alert("⚠️ Failed to send SMS.");
            }
        } catch (error) {
            console.error("❌ Error sending SMS:", error);
            alert("Error sending SMS. Check console.");
        }
    };


    const renderTable = (data) => (
        <TableContainer elevation={0} component={Paper} sx={{ borderRadius: 2, maxWidth: 1200, ml: 2 }}>
            <Table>
                <TableHead>
                    <TableRow sx={{ backgroundColor: "#f8f8f8" }}>
                        <TableCell sx={{ fontWeight: "bold", width: "10%" }}>S.No</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>User Name</TableCell>
                        {
                            tabIndex === 1 && <TableCell sx={{ fontWeight: "bold" }}>User's Relation Name</TableCell>
                        }
                        <TableCell sx={{ fontWeight: "bold" }}>Relation</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{row.userName}</TableCell>
                            {
                                tabIndex === 1 && <TableCell sx={{ fontWeight: "bold" }}>{row.relName}</TableCell>
                            }
                            <TableCell>{tabIndex === 0 ? row.userRelation : row.relRelation}</TableCell>
                            <TableCell>
                                {tabIndex === 0 ? formatDateWithDifference(row.userDate) : formatDateWithDifference(row.relDate)}
                            </TableCell>
                            <TableCell>
                                {row.smsStatus === null ? (
                                    <Warning color="warning" sx={{ verticalAlign: "middle", mr: 1 }} />
                                ) : (
                                    <>
                                        <CheckCircle color="success" sx={{ verticalAlign: "middle", mr: 1 }} />
                                        {row.smsStatus}
                                    </>

                                )}
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    sx={{ ml: 1, textTransform: "capitalize" }}
                                    onClick={() => handleSendSMS(row.userPhone, tabIndex === 0 ? row.userName : row.relName, tabIndex === 0 ? row.userDate : row.relDate, tabIndex === 0 ? "self" : "relative", row.uniqueId)}
                                >
                                    Send
                                </Button>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <TablePagination
                sx={{ mt: 2 }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );

    return (
        <Box sx={{ p: 3, minHeight: "100vh", width: "100%" }}>
            <Container maxWidth="xl">
                <Typography variant="h6" gutterBottom sx={{ textAlign: "left", p: 2 }}>
                    Birthday SMS Management
                </Typography>

                {/* Tabs for switching between Self and Relatives' Birthdays */}
                <Box sx={{ mb: 3 }}>
                    <Tabs value={tabIndex} onChange={handleChangeTab} textColor="primary" indicatorColor="primary">
                        <Tab sx={{ textTransform: "none" }} label="Self Birthdays" />
                        <Tab sx={{ textTransform: "none" }} label="Relatives' Birthdays" />
                    </Tabs>
                </Box>

                <Box sx={{ mb: 3, width: "100%", justifyContent: "center" }}>
                    {/* Render tables based on selected tab */}
                    {tabIndex === 0 && renderTable([...selfBirthdays].sort((a, b) => sortByDate(a.userDate, b.userDate)))}
                    {tabIndex === 1 && renderTable([...relativesBirthdays].sort((a, b) => sortByDate(a.relDate, b.relDate)))}
                </Box>

            </Container>

        </Box>
    );
};

export default BirthdaySms;
