import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { Book, BookOnline, CardGiftcard, NavigateNext, ReadMore, Star, } from '@mui/icons-material';
import {
    Container,
    Card,
    CardMedia,
    CardContent,
    Chip,
} from '@mui/material';
import Aos from 'aos';
import { orange } from '@mui/material/colors';
const HomamEvents = () => {

    useEffect(() => {
        Aos.init()
    }, [])

    const navigate = useNavigate();

    const handleRouting = (homam) => {
        window.location.href = `/${homam}`;
    };

    const products = [
        { id: "virtual-puja-seva", name: "Virutal Puja Seva", price: "₹250 - ₹500", img: require("../images/thumbnails/virtual pooja thumnail.jpg"), label: "Popular", icon: <BookOnline />, buttonText: "Learn More", },
        { id: "pricing", name: "Premium Membership", price: "₹349", img: require("../images/landingservices/landing_premium.png"), label: "Featured", icon: <Star />, buttonText: "Learn More", },
        { id: "https://shop.shubadinam.com", name: "Gift Shoppe", price: "₹99 - ₹999 ", img: require("../images/thumbnails/gift poster-01.png"), label: "Exclusive", icon: <CardGiftcard />, isExternal: true, buttonText: "Gift Now", },
        { id: "https://shop.shubadinam.com/collections/books", name: "Books", price: "₹99 - ₹999 ", img: require("../images/landingservices/landing_books.jpg"), label: "Exclusive", icon: <Book />, buttonText: "Buy Now", isExternal: true, }
    ];

    return (
        <Box
            sx={{
                minHeight: '70vh',
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                py: 4
            }}
        >
            <Container >
                <Typography
                    variant="h4"
                    align="center"
                    gutterBottom
                    color='primary'
                    sx={{
                        fontWeight: 'bold',
                        // color: '#2c3e50',
                        mb: 2,
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                    }}
                    data-aos="fade-up"
                >
                    Exclusive <span style={{ color: orange[700] }}>Heritage</span> Services
                </Typography>
                <Typography
                    variant="body1"
                    align="center"
                    sx={{
                        color: '#7f8c8d',
                        mb: 4,
                        fontStyle: 'italic',
                    }}
                    data-aos="fade-up"
                >
                    Explore our services and exclusive products to enrich your life
                </Typography>

                <Grid
                    container
                    spacing={{ xs: 2, sm: 3, md: 4 }}
                    p={{ xs: 1, sm: 2, md: 3 }}
                    justifyContent="center"
                >
                    {products.map((product, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                            <Card
                                onClick={() =>
                                    product.isExternal ? window.open(product.id, "_blank") : handleRouting(product.id)
                                }
                                sx={{
                                    cursor: "pointer",
                                    borderRadius: 4,
                                    boxShadow: 0,
                                    bgcolor: "#eeee",
                                    overflow: "hidden",
                                    position: "relative",
                                    width: {
                                        xs: "100%",   // XXL screens (4K and above)
                                    },
                                    // maxWidth: "400px", // Prevents card from becoming too wide
                                    height: 'auto',
                                    padding: {
                                        xs: "8px",
                                        sm: "10px",
                                        md: "12px",
                                        lg: "14px",
                                        xl: "16px",
                                    },
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                    // background: "linear-gradient(to bottom, #ffffff, #f7f8fa)",
                                    "&:hover": {
                                        transform: "translateY(-5px)",
                                        boxShadow: 1,
                                        background: "linear-gradient(to bottom, #f7f8fa, #ffffff)",
                                    },
                                }}
                                data-aos="fade-up"
                            >
                                {/* Label Chip */}
                                {product.label && (
                                    <Chip
                                        size="small"
                                        label={product.label}
                                        color="warning"
                                        sx={{
                                            color: "white",
                                            fontSize: "12px !important",
                                            position: "absolute",
                                            p: 0,
                                            top: 4,
                                            right: 8,
                                            zIndex: 1,
                                            bgcolor: product.label === "Upcoming" ? "orange" : "",
                                        }}
                                    />
                                )}

                                {/* Product Image */}
                                <CardMedia
                                    component="img"
                                    height="auto"
                                    image={product.img}
                                    alt={product.name}
                                    sx={{
                                        objectFit: "contain",
                                        width: "100%",
                                    }}
                                />

                                {/* Content */}
                                <CardContent
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "start",
                                        justifyContent: "start",
                                        pb: "10px !important",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            minHeight: { xs: "30px", md: "50px" },
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle2"
                                            fontWeight="bold"
                                            sx={{
                                                textAlign: "start",
                                                fontSize: {
                                                    xs: "14px",
                                                    sm: "16px",
                                                    md: "18px",
                                                },
                                            }}
                                        >
                                            {product.name}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>


                {/* View All Button */}
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Button
                        endIcon={<NavigateNext />}
                        onClick={() => navigate("/more-services")}
                        variant="contained"
                        color="primary"
                        sx={{
                            textTransform: 'none',
                            borderRadius: 2,
                            p: 1,
                            // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                            // '&:hover': {
                            //     background: '#0056b3',
                            //     transform: 'scale(1.05)',
                            //     boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.25)',
                            // },
                        }}
                    >
                        View All Services
                    </Button>
                </Box>
            </Container>
        </Box>

    );

};

export default HomamEvents;
