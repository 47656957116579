

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, Card, CardContent, CardMedia, Typography, Container, Box, Tab, Tabs, Avatar, Button, IconButton, DialogContent, DialogActions, Dialog, DialogTitle, Stack, Skeleton, InputAdornment, TextField } from "@mui/material";
import { Book, BookOnline, CardGiftcard, Close, CloseFullscreenSharp, ContactMail, ContactPage, Dashboard, ExitToApp, FullscreenExit, Info, PlayArrow, Star, WorkspacePremium } from "@mui/icons-material";
import {
    AppBar,
    Toolbar,
    InputBase,
    List,
    ListItem,
    Drawer,
    Divider,
    ListItemIcon,
    Chip,
    ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Link, useNavigate } from "react-router-dom";
import FooterComponent from "../components/footer";
import highorderComponent from "../screens/hoc/HighorderComponent";
import YouTube from "@mui/icons-material/YouTube";
import DashboardEvents from "../components/DashboardEvents";
import Services from "../screens/utils/services";

const suggestions = [
    " Suggestion 1",
    " Suggestion 2",
    " Suggestion 3",
    " Suggestion 4",
    " Suggestion 5",
    " Suggestion 6",
    " Suggestion 7",
    " Suggestion 8",
    " Suggestion 9",
    " Suggestion 10",
];



const ShubadinamTv = ({ handleLoading, handleError, loading, error }) => {
    const [videos, setVideos] = useState({
        home: [],
        shorts: [],
        live: [],
        video: [],
        searchVideos: [], // New key for filtered videos

    });
    const totalVideos = Object.values(videos).reduce((acc, curr) => acc + curr.length, 0);

    const [searchValue, setSearchValue] = React.useState("");
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    const [selectedTab, setSelectedTab] = useState(0); // Tab state to track selected tab
    const [channelInfo, setChannelInfo] = useState({ logoUrl: '', description: '' });
    const [showFullDescription, setShowFullDescription] = useState(false); // State for toggling description view
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedVideoId, setSelectedVideoId] = useState(null);

    const navigate = useNavigate();

    const handleRouting = (homam) => {
        window.location.href = `/virtual-seva/${homam}`;
    };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);

        // Filter videos across all categories
        const filteredVideos = Object.keys(videos)
            .filter((key) => key !== "searchVideos") // Exclude `searchVideos` from filtering
            .flatMap((category) =>
                videos[category].filter((video) =>
                    video.title.toLowerCase().includes(value.toLowerCase())
                )
            );

        // Update the searchVideos array with filtered results
        setVideos((prev) => ({ ...prev, searchVideos: filteredVideos }));
    };


    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setIsDrawerOpen(open);
    };


    const handleOpenDialog = (videoId) => {
        setSelectedVideoId(videoId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedVideoId(null);
    };

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                handleLoading(true)
                const response = await axios.get("https://backend.shubadinam.com/test/videos");
                setVideos(response.data.videos);
                setChannelInfo(response.data.channelInfo);
                handleLoading(false)
            } catch (error) {
                handleLoading(true)
                console.error("Error fetching videos", error);
            }
        };

        fetchVideos();
    }, []);


    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };


    const renderVideoCards = (sectionTitle, videos) => {

        if (!videos || videos.length < 1) {
            // Render skeleton loader if loading
            return (
                <>
                    <Typography
                        variant="h5"
                        sx={{
                            color: '#4b769f',
                            marginBottom: '16px',
                            textAlign: 'start',
                        }}
                    >
                        {sectionTitle}
                    </Typography>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                        {Array.from({ length: 3 }).map((_, index) => (
                            <Box key={index} sx={{ maxWidth: 345, flex: '1 1 auto' }}>
                                <Skeleton variant="rectangular" height={200} />
                                <Skeleton variant="text" height={30} sx={{ mt: 2 }} />
                            </Box>
                        ))}
                    </Stack>
                </>
            );
        } else {
            return (
                <>
                    <Typography
                        variant="h5"
                        sx={{
                            color: '#4b769f',
                            marginBottom: '16px',
                            textAlign: 'start',
                        }}
                    >
                        {sectionTitle}<Typography component="span" variant="caption" sx={{ p: "0px 10px" }}>({videos.length})</Typography>
                    </Typography>

                    <Splide
                        options={{
                            type: 'slide',  // Enables looping of slides
                            perPage: 3,    // Number of slides visible at once
                            gap: '10px',   // Gap between slides
                            arrows: true,
                            pagination: false,
                            breakpoints: {
                                1024: {
                                    perPage: 3, // Number of slides visible for medium screens
                                },
                                700: {
                                    perPage: 2, // Number of slides visible for smaller screens
                                },
                                500: {
                                    perPage: 1, // Number of slides visible for smaller screens
                                },
                            },
                        }}
                    >
                        {videos.map((video, index) => (
                            <SplideSlide key={index} onClick={() => handleOpenDialog(video.videoId)}>
                                <Card
                                    sx={{
                                        maxWidth: 345,
                                        boxShadow: 0,
                                        // bgcolor: "#eeee",
                                        borderRadius: 2,
                                        cursor: 'pointer',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Box
                                        component="a"
                                        onClick={() => handleOpenDialog(video.videoId)}
                                        sx={{
                                            display: 'block',
                                            position: 'relative',
                                            width: '100%',
                                            height: '200px',
                                            borderRadius: '8px',
                                            backgroundImage: `url(https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg)`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                padding: '10px',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                                },
                                            }}
                                        >
                                            <PlayArrow
                                                sx={{
                                                    color: '#fff',
                                                    fontSize: '3rem',
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <CardContent>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                color: '#333',
                                                textAlign: 'start',
                                            }}
                                        >
                                            {video.title}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </SplideSlide>
                        ))}
                    </Splide>

                    <Divider sx={{ my: 2, height: "1px", bgcolor: "#eeee" }}></Divider>

                </>
            )
        }
    };

    const isValidSearch = searchValue?.trim().length > 0;  // Safely check if search value is valid


    const renderVideos = () => {
        let content = null;

        switch (selectedTab) {
            case 1:
                // Shorts tab
                content = videos["video"].map((video, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} onClick={() => handleOpenDialog(video.videoId)}>
                        <Card sx={{ maxWidth: 345, boxShadow: 3, borderRadius: 2, cursor: 'pointer' }}>
                            <a
                                onClick={() => handleOpenDialog(video.videoId)}
                                style={{
                                    cursor: 'pointer',
                                    display: 'block',
                                    position: 'relative',
                                    width: '100%',
                                    height: '200px',
                                    borderRadius: '8px',
                                    backgroundImage: `url(https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg)`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        padding: '10px',
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                        },
                                    }}
                                >
                                    <PlayArrow sx={{ color: '#fff', fontSize: '3rem' }} />
                                </Box>
                            </a>

                            <CardContent>
                                <Typography variant="body5" component="div" sx={{ color: '#333' }}>
                                    {video.title}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ));
                break;

            case 2:
                // Live tab
                content = videos["shorts"].map((video, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} onClick={() => handleOpenDialog(video.videoId)}>
                        <Card sx={{ maxWidth: 345, boxShadow: 3, borderRadius: 2, cursor: 'pointer' }}>
                            <a
                                onClick={() => handleOpenDialog(video.videoId)}
                                style={{
                                    cursor: 'pointer',
                                    display: 'block',
                                    position: 'relative',
                                    width: '100%',
                                    height: '200px',
                                    borderRadius: '8px',
                                    backgroundImage: `url(https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg)`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        padding: '10px',
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                        },
                                    }}
                                >
                                    <PlayArrow sx={{ color: '#fff', fontSize: '3rem' }} />
                                </Box>
                            </a>

                            <CardContent>
                                <Typography variant="body5" component="div" sx={{ color: '#333' }}>
                                    {video.title}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ));
                break;

            case 3:
                // Videos tab
                content = videos["live"].map((video, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} onClick={() => handleOpenDialog(video.videoId)}>
                        <Card sx={{ maxWidth: 345, boxShadow: 3, borderRadius: 2, cursor: 'pointer' }}>
                            <a
                                onClick={() => handleOpenDialog(video.videoId)}
                                style={{
                                    cursor: 'pointer',
                                    display: 'block',
                                    position: 'relative',
                                    width: '100%',
                                    height: '200px',
                                    borderRadius: '8px',
                                    backgroundImage: `url(https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg)`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        padding: '10px',
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                        },
                                    }}
                                >
                                    <PlayArrow sx={{ color: '#fff', fontSize: '3rem' }} />
                                </Box>
                            </a>

                            <CardContent>
                                <Typography variant="body5" component="div" sx={{ color: '#333' }}>
                                    {video.title}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ));
                break;

            case 4:
                // Manual Services tab (not related to videos)
                content = (
                    <>
                        <Typography
                            variant="h6"
                            align="left"
                            gutterBottom
                            sx={{
                                fontWeight: 'bold',
                                color: '#2c3e50',
                                mb: 2,
                                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            Exclusive Services
                        </Typography>
                        <Services />
                    </>

                );
                break;

            case 5:
                content = (
                    <Grid item xs={12}>
                        <Card sx={{ maxWidth: 345, boxShadow: 3, borderRadius: 2 }}>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    Tab 5 Content
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    You can display any content for Tab 5 here.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                );
                break;

            // Add more cases for tab 6 to 10
            case 6:
                content = (
                    <Grid item xs={12}>
                        <Card sx={{ maxWidth: 345, boxShadow: 3, borderRadius: 2 }}>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    Tab 6 Content
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    You can display any content for Tab 6 here.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                );
                break;

            // Continue for all the other tabs
            default:
                content = (
                    <Grid item xs={12}>
                        <Card sx={{ maxWidth: 345, boxShadow: 3, borderRadius: 2 }}>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    Default Tab Content
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    This is the default content for tabs other than 1 to 3.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                );
                break;
        }

        return content;
    };



    return (

        <Box sx={{ bgcolor: "aliceblue" }}>

            <AppBar position="fixed" elevation={1} sx={{ backgroundColor: "aliceblue" }}>
                {/* Logo */}
                <Box
                    sx={{ flexGrow: 1, cursor: "pointer", display: { xs: "flex", sm: 'none' }, justifyContent: "center" }}
                >
                    <img src={require("../images/shubadinam_tv.png")} style={{ width: "fit-content", height: "50px", objectFit: "contain" }} />
                </Box>
                <Toolbar>
                    {/* Left menu icon */}
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon sx={{ color: "black" }} />
                    </IconButton>

                    {/* Logo */}
                    <Box
                        sx={{ flexGrow: 1, cursor: "pointer", display: { xs: "none", sm: 'block' }, p: 2 }}
                    >
                        <img src={require("../images/shubadinam_tv.png")} style={{ width: "fit-content", height: "50px", objectFit: "contain" }} />
                    </Box>

                    {/* Search Bar */}
                    <TextField
                        size="small"
                        variant="outlined"
                        placeholder="Search videos, shorts, live here..."
                        value={searchValue}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            flexGrow: 2,
                            backgroundColor: "#ffff",
                            borderRadius: "4px",
                            color: "black",
                            mx: 2,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'transparent', // Default border is transparent
                                },
                                '&:hover fieldset': {
                                    borderColor: '1px solid #ddd', // Border color on focus (1px solid)
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '1px solid #ddd', // Border color on focus (1px solid)
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: '#000', // Ensures input text is black and visible
                            },
                        }}
                    />

                    {/* Account Avatar */}
                    {/* <IconButton edge="end">
                        <Avatar alt="Account" src="/static/images/avatar/1.jpg" />
                    </IconButton> */}
                </Toolbar>
            </AppBar>

            {/* banner */}
            <Box
                textAlign="center"
                mb={4}
                mt={10}
                sx={{
                    backgroundImage: `url(${require("../images/banners/tv_banner.jpg")})`,
                    backgroundSize: "cover", // Ensure the background image covers the entire box
                    backgroundPosition: "center", // Center the image within the box
                    height: { xs: "150px", md: "200px" }, // Set a fixed height
                    width: "100%", // Ensure the width is 100%
                }}
            >
                {/* Content goes here, if needed */}
            </Box>

            {/* Drawer */}
            <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        <Box sx={{ width: "100%", mb: 4 }}>
                            <img
                                src={require("../images/shubadinam_tv.png")}
                                alt="Shubadinam TV"
                                style={{ width: "100%", height: "auto" }}
                            />
                        </Box>
                        <ListItem button component={Link} to="/">
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItem>
                        {/* <ListItem button component={Link} to="/dashboard">
                            <ListItemIcon>
                                <Dashboard />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem> */}
                        <ListItem button component={Link} to="/pricing">
                            <ListItemIcon>
                                <Star />
                            </ListItemIcon>
                            <ListItemText primary="Premium" />
                        </ListItem>
                        <ListItem button component={Link} target="_blank" to="https://shop.shubadinam.com/">
                            <ListItemIcon>
                                <CardGiftcard />
                            </ListItemIcon>
                            <ListItemText primary="Gift Shoppe" />
                        </ListItem>
                        <ListItem button component={Link} to="/virtual-puja-seva">
                            <ListItemIcon>
                                <BookOnline />
                            </ListItemIcon>
                            <ListItemText primary="Virtual Puja Seva" />
                        </ListItem>
                        <ListItem button component={Link} to="/about-us">
                            <ListItemIcon>
                                <Info />
                            </ListItemIcon>
                            <ListItemText primary="About Us" />
                        </ListItem>
                        <ListItem button component={Link} to="/contact-us">
                            <ListItemIcon>
                                <ContactPage />
                            </ListItemIcon>
                            <ListItemText primary="Contact Us" />
                        </ListItem>
                    </List>
                    <Divider />
                </Box>
            </Drawer>

            {/* suggestion Splide Carousel */}
            {/* <Box
                sx={{
                    alignContent: "center",
                    width: "100%",
                    overflow: "hidden",
                    position: "relative", // Ensure proper positioning for the carousel
                    padding: { xs: "0 20px", md: "0 40px" }, // Add padding to prevent overlap with arrows
                }}
            >
                <Splide
                    style={{ maxWidth: "1000px", margin: "0 auto" }}
                    options={{
                        type: 'slide',
                        gap: '1rem',
                        pagination: false, // Show pagination
                        arrows: true, // Enable arrows
                        drag: true, // Allow dragging
                        keyboard: 'global', // Enable global keyboard navigation
                        padding: { left: '2rem', right: '2rem' }, // Ensure content doesn't touch the arrows
                        breakpoints: {
                            2000: {
                                perPage: 9, // Desktop: 9 items per page
                            },
                            1200: {
                                perPage: 6, // Medium screens: 6 items per page
                            },
                            900: {
                                perPage: 2, // Tablet: 3 items per page
                            },
                            0: {
                                perPage: 1, // Mobile (small screens): 1 item per page
                            },
                        },
                    }}
                >
                    {suggestions.map((suggestion, index) => (
                        <SplideSlide
                            key={index}
                            style={{
                                width: "fit-content",
                                display: "inline-block",
                            }}
                        >
                            <Chip
                                label={suggestion}
                                sx={{
                                    maxWidth: "fit-content",
                                    padding: "8px",
                                    textAlign: "center",
                                }}
                            />
                        </SplideSlide>
                    ))}
                </Splide>
            </Box> */}


            <Container sx={{ mb: 10 }}>



                <Box sx={{ textAlign: 'center' }}>

                    {/* Chanel Logo and Description */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: { xs: 'row', sm: 'row' }, // Stack vertically on small screens, horizontally on larger screens
                            gap: 2, // Add spacing between the elements
                            padding: 2, // Add padding to the container
                            borderRadius: 2, // Rounded corners for the overall box
                            boxShadow: 0.5, // Add subtle shadow for better UI depth
                        }}
                    >
                        {/* Channel Logo */}
                        <Avatar
                            alt="Channel Logo"
                            src={require("../images/banners/tv_star_logo.jpg") || "shubadinam-logo-url.jpg"}
                            sx={{
                                width: 80,
                                height: 80,
                                borderRadius: "50%",
                                border: "3px solid #fff",
                                boxShadow: 1,
                            }}
                        />

                        {/* Channel Title and Button */}
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems={{ xs: "center", sm: "flex-start" }} // Center-align text on small screens
                            sx={{
                                marginLeft: { sm: 2 }, // Add space next to the logo on larger screens
                                marginTop: { xs: 2, sm: 0 }, // Add space above the title on small screens
                                textAlign: { xs: "center", sm: "left" },
                            }}
                        >
                            {/* Channel Title */}
                            <Typography
                                variant="h6"
                                color="textPrimary"
                                sx={{
                                    fontWeight: "bold",
                                }}
                            >
                                Shubadinam Heritage Services
                            </Typography>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                @shubadinam - {videos.live.length > 0 ? Object.values(videos).reduce((total, category) => total + category.length, 0) : "Loading"} Videos
                            </Typography>
                            {/* View Channel Button */}
                            <Button
                                startIcon={<YouTube />}
                                variant="contained"
                                color="primary"
                                sx={{
                                    borderRadius: 4,
                                    marginTop: 1,
                                    textTransform: "none", // Keeps text casing as entered
                                }}
                                onClick={() => {
                                    window.open("https://www.youtube.com/@Shubadinam", "_blank");
                                }}
                            >
                                Visit Channel
                            </Button>

                        </Box>
                    </Box>


                    {/* Tabs for Navigation */}
                    <Tabs value={selectedTab} onChange={handleTabChange} sx={{ mt: 2 }}
                        variant="scrollable"
                        scrollButtons="auto"
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        <Tab label="Home" />
                        <Tab label="Videos" />
                        <Tab label="Shorts" />
                        <Tab label="Live" />
                        <Tab label="services" />
                    </Tabs>


                    <Box sx={{ marginTop: 4 }}>
                        {/* Tab Content */}
                        {/* <Grid container spacing={3} sx={{ ml: 0 }}> */}

                        {/* homee tab */}
                        {selectedTab === 0 ? (
                            <Box sx={{ width: "100%", p: 2 }}>

                                {
                                    isValidSearch && videos["searchVideos"].length > 0 ? (
                                        <Grid sx={{ width: "100%" }}>
                                            {renderVideoCards('Search', videos["searchVideos"])}
                                        </Grid>
                                    )
                                        : (
                                            <>
                                                <Grid sx={{ width: "100%" }}>
                                                    {renderVideoCards('Latest', videos["live"])}
                                                </Grid>
                                                <Grid sx={{ width: "100%" }}>
                                                    {renderVideoCards('Videos', videos["video"])}
                                                </Grid>
                                                <Grid sx={{ width: "100%" }}>
                                                    {renderVideoCards('Shorts', videos["shorts"])}
                                                </Grid>

                                            </>
                                        )
                                }
                            </Box>

                        )
                            : (


                                <Grid container spacing={3} p={2}>

                                    {renderVideos()}
                                </Grid>

                                // shorts,live,vidoes
                                // videos[selectedTab === 1
                                //     ? "video" // Shorts tab
                                //     : selectedTab === 2
                                //         ? "shorts" // Live tab
                                //         : selectedTab === 3 ? "live" : "live"// Videos tab
                                // ].map((video, index) => (
                                //     < Grid item xs={12} sm={6} md={4} key={index} onClick={() => handleOpenDialog(video.videoId)}>
                                //         <Card
                                //             sx={{
                                //                 maxWidth: 345,
                                //                 boxShadow: 3,
                                //                 borderRadius: 2,
                                //                 cursor: 'pointer',
                                //             }}
                                //         >
                                //             <a
                                //                 onClick={() => handleOpenDialog(video.videoId)}
                                //                 style={{
                                //                     cursor: 'pointer',
                                //                     display: 'block',
                                //                     position: 'relative',
                                //                     width: '100%',
                                //                     height: '200px', // Adjust height as needed
                                //                     borderRadius: '8px',
                                //                     backgroundImage: `url(https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg)`,
                                //                     backgroundSize: 'cover',
                                //                     backgroundPosition: 'center',
                                //                 }}
                                //             >
                                //                 <Box
                                //                     sx={{
                                //                         position: 'absolute',
                                //                         top: '50%',
                                //                         left: '50%',
                                //                         transform: 'translate(-50%, -50%)',
                                //                         backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                //                         padding: '10px',
                                //                         borderRadius: '50%',
                                //                         cursor: 'pointer',
                                //                         display: 'flex',
                                //                         justifyContent: 'center',
                                //                         alignItems: 'center',
                                //                         '&:hover': {
                                //                             backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                //                         },
                                //                     }}
                                //                 >
                                //                     {/* Custom Play Icon */}
                                //                     <PlayArrow
                                //                         sx={{
                                //                             color: '#fff',
                                //                             fontSize: '3rem',
                                //                         }}
                                //                     />
                                //                 </Box>
                                //             </a>

                                //             <CardContent>
                                //                 <Typography variant="body5" component="div" sx={{ color: '#333' }}>
                                //                     {video.title}
                                //                 </Typography>
                                //             </CardContent>
                                //         </Card>
                                //     </Grid>
                                // ))

                            )}
                        {/* </Grid> */}

                        {/* Dialog to show the selected video */}
                        <Dialog
                            fullScreen
                            open={openDialog}
                            onClose={handleCloseDialog}
                            maxWidth="md"
                            fullWidth
                        >
                            <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>

                                {/* logo */}
                                <Box
                                    sx={{ cursor: "pointer", }}
                                >
                                    <img src={require("../images/shubadinam_tv.png")} style={{ width: "fit-content", height: "50px", objectFit: "contain" }} />
                                </Box>

                                {/* close */}
                                <IconButton sx={{
                                    height: "30px",
                                    width: "30px",
                                    bgcolor: "#e63737",
                                    borderRadius: 0,
                                    color: "white",
                                    "&:hover": {
                                        bgcolor: "#e63737", // Keep the same background color
                                        color: "white",     // Keep the same text color
                                    },
                                }} onClick={handleCloseDialog}>
                                    <Close />
                                </IconButton>


                            </DialogTitle>
                            <DialogContent>
                                <Box sx={{ position: 'relative', paddingTop: { xs: '60.25%', md: "40.25%" }  /* 16:9 aspect ratio */, height: "auto" }}>
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src={`https://www.youtube.com/embed/${selectedVideoId}?autoplay=1`}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            borderRadius: '8px 8px 0 0',
                                        }}
                                    ></iframe>
                                </Box>
                                <Typography variant="h6" gutterBottom p={2}> Recommended For You</Typography>
                                <Grid container item xs={12} spacing={4}>
                                    {videos["live"].map((video, index) => (
                                        < Grid item xs={12} sm={6} md={4} key={index} onClick={() => handleOpenDialog(video.videoId)}>
                                            <Card
                                                sx={{
                                                    maxWidth: 345,
                                                    boxShadow: 3,
                                                    borderRadius: 2,
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <a
                                                    onClick={() => handleOpenDialog(video.videoId)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        display: 'block',
                                                        position: 'relative',
                                                        width: '100%',
                                                        height: '200px', // Adjust height as needed
                                                        borderRadius: '8px',
                                                        backgroundImage: `url(https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg)`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                            padding: '10px',
                                                            borderRadius: '50%',
                                                            cursor: 'pointer',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            '&:hover': {
                                                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                                            },
                                                        }}
                                                    >
                                                        {/* Custom Play Icon */}
                                                        <PlayArrow
                                                            sx={{
                                                                color: '#fff',
                                                                fontSize: '3rem',
                                                            }}
                                                        />
                                                    </Box>
                                                </a>

                                                <CardContent>
                                                    <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold', color: '#333' }}>
                                                        {video.title}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </Box>

                </Box>


            </Container >

            <FooterComponent />

        </Box >
    );

};
export default highorderComponent(ShubadinamTv);
















