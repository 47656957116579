import React, { useState, useEffect } from 'react';
import SDadminHeader from './SDadminHeader';
import Top20px from '../screens/Top20px';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import Top10px from '../screens/Top10px';
import { Button } from '@mui/material';

const AdminBillings = () => {


    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const [chngPwd, SetPwd] = useState(false);

    const [confirmPassword, SetConfirmPassword] = useState({
        // oldPwd: "",
        confirmPwd: "",
        reconfirmPwd: "",
    });


    const togglePassword = () => {
        SetPwd(true)
    }


    const pwdhandler = (evt) => {
        SetConfirmPassword({ ...confirmPassword, [evt.target.name]: evt.target.value });
    };

    const clickSubmit = () => {
        if (confirmPassword.confirmPwd !== "") {
            if (confirmPassword.confirmPwd === confirmPassword.reconfirmPwd) {

                const url = `https://backend.shubadinam.com/admin/upt/password`;

                const adminId = sessionStorage.getItem("adminId")

                axios
                    .post(url, { ...confirmPassword, adminId: adminId })
                    .then((response) => {
                        // console.log(response);
                        alert(response.data.message)
                        // alert("Password updted successfully.");
                    })
                    .catch((error) => {
                        alert(error.response.data.message)
                        // console.log(error);
                    });

                SetPwd(false);
            } else {
                alert("not equal");
                SetPwd(false);
            }
        } else {
            alert("password field is empty");
            SetPwd(false);
        }

    };

    const clickClose = () => {
        SetPwd(false)
    };

    const handlePopupClick = (e) => {
        // Prevent the click event from reaching the pwd-container
        e.stopPropagation();
    };

    const currentMonthIndex = new Date().getMonth();
    const [currMonth, setcurrMonth] = useState((currentMonthIndex));

    useEffect(() => {

        getBillings(currentMonthIndex);

    }, [])

    const getBillings = (monthIndex) => {

        const url = `https://backend.shubadinam.com/admin/billings?billingMonth=${monthIndex}`;


        axios.get(url)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const handleChange = (event) => {
        const monthIndex = event.target.value
        setcurrMonth(monthIndex);
        getBillings(monthIndex);
    };

    const [billingMonth, setBillingMonth] = useState(months[new Date().getMonth()]);


    const handleMonths = (mth) => {
        console.log(mth);
        setBillingMonth(mth)
    }

    const data = [
        { personName: 'John Doe', premiumCount: 2, amount: 100 },
        { personName: 'Jane Smith', premiumCount: 1, amount: 50 },
        { personName: 'Sam Johnson', premiumCount: 3, amount: 150 },
    ];

    const handleDownload = () => {
        const csvHeaders = ['Billing Period', 'Premium Count', 'Total'];
        const csvRows = data.map(row => [billingMonth, row.personName, row.premiumCount, row.amount]);

        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += csvHeaders.join(',') + '\n';
        csvContent += csvRows.map(row => row.join(',')).join('\n');

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'billing_data.csv');
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };




    return (
        <div className="sd-adminpage">
            <header className="sd-header">
                <SDadminHeader clickPass={togglePassword} />
            </header>
            <Top20px />
            <br />
            <div className="sd-body-database-billing">
                <div className='admin-billings-title'>

                    <Button sx={{ mr: 1, }} onClick={handleDownload} >Download to CSV</Button>

                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-helper-label">Month</InputLabel>
                        <Select
                            // labelId="demo-simple-select-helper-label"
                            // id="demo-simple-select-helper"
                            value={currMonth}
                            // label="Age"
                            onChange={handleChange}
                        >

                            {months.map((mth, index) => {
                                return <MenuItem key={index} value={index} onClick={() => handleMonths(mth)}>{mth}</MenuItem>
                            })}

                        </Select>
                        {/* <FormHelperText>With label + helper text</FormHelperText> */}
                    </FormControl>

                </div>
                <br />
                <div className='admin-billings-container'>
                    <table>
                        <thead>
                            <tr>
                                <th>Billing Period</th>
                                <th>Premium Count</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{billingMonth}</td>
                                <td>10</td>
                                <td>$100</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

            </div>
            {
                chngPwd ?
                    <div className="pwd-container" onClick={clickClose}>
                        <div className="pwd-popup" onClick={handlePopupClick}>

                            <label>Enter your New Password :</label>
                            <input type="text" name="confirmPwd" onChange={pwdhandler} />
                            <br />
                            <label>Reconfirm your Password :</label>
                            <input type="text" name="reconfirmPwd" onChange={pwdhandler} />
                            <br />
                            <button onClick={() => clickSubmit()}>Submit</button>
                            <button style={{ marginLeft: "10px", backgroundColor: "#ffd7d7", color: "black" }} onClick={() => clickClose()}>Close</button>

                        </div>
                    </div>
                    :
                    <span></span>
            }
        </div>

    );
};

export default AdminBillings;