import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../components/footer";
import Button from '@mui/material/Button';
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "react-scroll-to-top";
import axios from "axios";
import { Typography, useTheme, useMediaQuery, Container, IconButton, Paper, Avatar, } from "@mui/material";
import TestimonialScreen from "./testimonials";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SlidingHeader from "../components/SlidingHeader";
import PageSlider from "./utils/PageSlider";
import SevalandingTestimonial from "../homamutils/SevalandingTestimonial";
import {
    Box,
    Grid,
    Card,
    CardContent,
    Chip,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Grow,
    Hidden,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSwipeable } from 'react-swipeable';
import Cancel from "@mui/icons-material/Cancel";
import { Star } from "@mui/icons-material";
import { Dashboard, Login, PlayArrow } from "@mui/icons-material";
import { Rating } from "@mui/material";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { ArrowBackIos, ArrowForwardIos, NavigateBefore, NavigateNext } from '@mui/icons-material';



const reviews = [
    {
        name: 'Priyakumari',
        role: 'Software Engineer at Palantir',
        rating: 5,
        review: 'Shubadinam has transformed the way I celebrate my birthday. Embracing Indian traditions is giving a special feel on my birthday, sorry janmadin!',
        image: 'https://placeholder.co/150',
    },
    {
        name: 'Rahul',
        role: 'Web Developer at CodesCandy',
        rating: 5,
        review: 'I registered on Shubadinam last month for testing purpose. It was an eye-opening experience. The notification was very insightful. Highly recommend!',
        image: 'https://placeholder.co/150',
    },
    {
        name: 'Prashanth',
        role: 'Marketing Manager at EaseTemplate',
        rating: 5,
        review: 'It`s a joy and a satisfying feeling to celebrate birthdays the Indian way. Thanks to Shubadinam for making it easy!',
        image: 'https://placeholder.co/150',
    },
    {
        name: 'Ananya',
        role: 'Software Engineer at Palantir',
        rating: 5,
        review: `As someone deeply connected to my Indian roots, I found Shubadinam very useful and definitely recommend it's a must-visit site for anyone seeking to preserve our Hindu identity!`,
        image: 'https://placeholder.co/150',
    },
];

const Pricing = () => {


    const Navigate = useNavigate();

    const isLogged = localStorage.getItem("token");

    // MENU BAR

    const [isGreater768, setGreater768] = useState(false);
    const auth = localStorage.getItem("isAuthenticated");
    const [isFreeUser, setFreeUser] = useState(false);
    const [daysLeft, setDaysLeft] = useState(0);
    const [timeLeft, setTimeLeft] = useState("")


    useEffect(() => {

        window.scroll(0, 0);

        const secureId = localStorage.getItem("SD_Id");

        if (secureId) {
            const url = `https://backend.shubadinam.com/getpremium?secureId=${secureId}`

            axios.get(url)
                .then((response) => {
                    setTimeLeft(response.data.timeLimit)
                    calculateDaysLeft(response.data.timeLimit)
                    if (response.data.type === "premium") {
                        setFreeUser(false)
                    } else {
                        setFreeUser(true)
                    }

                })
                .catch((error) => {
                    console.log(error)
                    // alert("Something unexpected happen.Please try again after sometime !")
                })

        }

        const isFreeRegister = sessionStorage.getItem("isFree")

        if (auth || isFreeRegister) {
            setFreeUser(true)
        }


        const handleResize = () => {
            setGreater768(window.innerWidth > 768);
        };

        // Initial setup
        handleResize();

        // Listen for window resize events
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [Navigate]);


    const calculateDaysLeft = (purchaseDate) => {
        const purchaseDateTime = new Date(purchaseDate);
        const currentDate = new Date();
        const endDate = new Date(purchaseDateTime);
        endDate.setDate(purchaseDateTime.getDate() + 365); // Adding 365 days

        const timeDifference = endDate - currentDate;
        const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
        setDaysLeft(daysLeft);
    };

    const gotoCheckout = (page) => {
        Navigate(`/${page}`)
    }

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => console.log('Swiped Left'),
        onSwipedRight: () => console.log('Swiped Right'),
        trackMouse: true,  // Optional: track mouse events for swipe-like behavior on desktop
    });

    const getStyledFeature = (index, feature) => {
        let modifiedFeature = feature;

        if (index === 3 && feature.includes("₹500")) {
            modifiedFeature = (
                <>
                    {modifiedFeature.split("₹500")[0]}
                    <span style={{ color: "orange" }}>₹500</span>
                    {modifiedFeature.split("₹500")[1]}
                </>
            );
        }

        if (index === 4 && feature.includes("₹70 to ₹125")) {
            console.log(index);
            modifiedFeature = (
                <>
                    {modifiedFeature.split("₹70 to ₹125")[0]}
                    <span style={{ color: "orange" }}>₹70 to ₹125</span>
                    {modifiedFeature.split("₹70 to ₹125")[1]}
                </>
            );
        }

        return modifiedFeature;
    };


    const PricingCard = ({ title, price, features, popular, available }) => (

        <Grow in timeout={1000}>
            <Card
                sx={{
                    p: { xs: 0, md: 3 },
                    borderRadius: 3,
                    boxShadow: 5,
                    backgroundColor: popular ? "#fffbeb" : "#ffffff",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                        transform: "scale(1.05)",
                    },
                    position: "relative",
                    overflow: "visible",
                    width: { xs: "300px", md: "auto" }
                }}
            >
                {popular && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            backgroundColor: "#f59e0b",
                            color: "white",
                            fontWeight: "bold",
                            px: 2,
                            py: 0.5,
                            borderRadius: "15px",
                            boxShadow: 3,
                        }}
                    >
                        Popular
                    </Box>
                )}
                <CardContent>
                    <Typography
                        variant="h6"
                        fontWeight="700"
                        gutterBottom
                        sx={{ fontSize: { xs: "1rem", md: "1.25rem" } }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="h3"
                        fontWeight="800"
                        gutterBottom
                        sx={{ fontSize: { xs: "2rem", md: "3rem" }, color: popular ? "darkorange" : "#3b82f6" }}
                    >
                        ₹{price}
                        <Typography
                            variant="body1"
                            component="span"
                            sx={{ fontWeight: "400", fontSize: { xs: "0.9rem", md: "1.2rem" }, ml: 0.5 }}
                        >
                            {popular ? "Yearly" : ""}
                        </Typography>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        fontWeight="600"
                        sx={{
                            mb: 2,
                            textTransform: "uppercase",
                            color: "text.secondary",
                            fontSize: { xs: "0.8rem", md: "1rem" },
                        }}
                    >
                        Features
                    </Typography>
                    <List disablePadding>
                        {features.map((feature, index) => (
                            <ListItem
                                key={index}
                                disableGutters
                                sx={{
                                    py: 0.5,
                                    backgroundColor: index % 2 === 0 ? "#f9fafb" : "#ffffff",
                                    borderRadius: 1,
                                }}
                            >
                                <ListItemIcon>
                                    {available[index] ? (
                                        <CheckCircleIcon
                                            sx={{
                                                color: popular ? "darkorange" : "#3b82f6",
                                                fontSize: { xs: "1rem", md: "1.5rem" },
                                            }}
                                        />
                                    ) : (
                                        <Cancel
                                            sx={{
                                                color: "grey",
                                                fontSize: { xs: "1rem", md: "1.5rem" },
                                            }}
                                        />
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    primary={getStyledFeature(index, feature)}
                                    primaryTypographyProps={{
                                        fontSize: { xs: "0.75rem", md: "0.875rem" },
                                        fontWeight: "500",
                                        color: !available[index] ? "grey" : "inherit",
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>

                    {
                        isLogged && !isFreeUser && daysLeft > 0 ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column', // Stack elements vertically
                                    alignItems: 'center',
                                    backgroundColor: '#f5f5f5',
                                    padding: { xs: '8px 12px', sm: '10px 15px' },
                                    borderRadius: '8px',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                    color: '#333',
                                    width: '100%',
                                    maxWidth: '600px',
                                    margin: '0 auto',
                                }}
                            >
                                {/* First line with Premium Purchased text */}
                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: { xs: '4px', sm: '8px' } }}>
                                    <Star sx={{ color: 'darkorange', marginRight: 1, fontSize: { xs: '1.5rem', sm: '2rem' } }} />
                                    <Typography
                                        variant="body1"
                                        fontWeight="500"
                                        sx={{
                                            fontSize: { xs: '0.9rem', sm: '1rem' },
                                        }}
                                    >
                                        Premium Purchased!
                                    </Typography>
                                </Box>

                                {/* Second line with days left and renewal message */}
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography
                                        variant="body1"
                                        fontWeight="500"
                                        sx={{
                                            fontSize: { xs: '0.9rem', sm: '1rem' },
                                        }}
                                    >
                                        Only
                                    </Typography>
                                    <Chip
                                        label={`${daysLeft} days`}
                                        sx={{
                                            backgroundColor: 'darkorange',
                                            color: 'white',
                                            fontWeight: 'bold',
                                            fontSize: { xs: '0.9rem', sm: '1rem' },
                                            marginLeft: '5px',
                                        }}
                                    />
                                    <Typography
                                        variant="body1"
                                        fontWeight="500"
                                        sx={{
                                            marginLeft: 1,
                                            fontSize: { xs: '0.9rem', sm: '1rem' },
                                        }}
                                    >
                                        to renewal.
                                    </Typography>
                                </Box>
                            </Box>
                        )
                            :

                            <Button
                                onClick={() => gotoCheckout(popular ? "premium-checkout" : "register")}
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 2,
                                    background: popular
                                        ? "linear-gradient(90deg, #f59e0b, #d97706)"
                                        : "linear-gradient(90deg, #4b6cb7, #182848)",
                                    color: "white",
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    borderRadius: 2,
                                    py: { xs: 1, md: 1.5 },
                                    fontSize: { xs: "0.85rem", md: "1rem" },
                                    boxShadow: popular
                                        ? "0px 4px 15px rgba(245, 158, 11, 0.4)"
                                        : "0px 4px 12px rgba(0, 0, 0, 0.15)",
                                    transition: "all 0.3s ease-in-out",
                                    "&:hover": {
                                        background: popular
                                            ? "linear-gradient(90deg, #d97706, #f59e0b)"
                                            : "linear-gradient(90deg, #182848, #4b6cb7)",
                                        boxShadow: popular
                                            ? "0px 6px 20px rgba(245, 158, 11, 0.5)"
                                            : "0px 6px 18px rgba(0, 0, 0, 0.25)",
                                        transform: "scale(1.05)",
                                    },
                                    "&:active": {
                                        transform: "scale(0.97)",
                                        boxShadow: popular
                                            ? "0px 3px 10px rgba(245, 158, 11, 0.3)"
                                            : "0px 3px 8px rgba(0, 0, 0, 0.15)",
                                    },
                                }}
                            >

                                {popular ? (
                                    "Get Premium Plan"
                                ) : (
                                    "Choose Basic Plan"
                                )}

                            </Button>

                    }
                </CardContent>
            </Card>
        </Grow>
    );

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("lg"));

    const navigate = useNavigate();

    const [isPlaying, setIsPlaying] = useState(false);
    const [bgColor, setBgColor] = useState("transparent"); // Start with a slightly darker color
    const [textColor, setTextColor] = useState("#003a70");

    const handlePlayClick = () => setIsPlaying((prev) => !prev);


    const splideRef = useRef(null);

    const handlePrev = () => {
        splideRef.current.splide.go('<');
    };

    const handleNext = () => {
        splideRef.current.splide.go('>');
    };




    return (




        <div>
            <SlidingHeader />



            <Box
                sx={{
                    textAlign: "center",
                    py: 10,
                    px: { xs: 2, md: 3 },
                    background: "linear-gradient(to bottom, #3b82f6, #1e293b)",
                    color: "white",
                    minHeight: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Container maxWidth="xl" sx={{ py: { xs: 3, md: 5 } }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Left Section */}
                        <Grid item xs={12} md={6}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h4" align="center" sx={{ fontWeight: "bold", color: '#0c197d', mb: 2 }}>
                                    Birthdays are Special
                                </Typography>
                                <Typography variant="h5" align="center" sx={{ color: 'white', mb: 2, }}>
                                    Make it Impactful... Make it Shubadinam...
                                </Typography>
                                <Typography
                                    variant="h6"
                                    color="white"
                                    sx={{
                                        mb: { xs: 2, sm: 3, md: 4 },
                                        // fontSize: { xs: "0.975rem", sm: "1rem", md: "1.125rem", xl: "1.5rem" },
                                        textAlign: { xs: "center", md: "center" },
                                    }}
                                >
                                    Get reminders to celebrate the Janma Nakshatra / Janma Tithi as per the Indian calendar and make every
                                    celebration memorable.
                                </Typography>
                                {/* <Box sx={{ display: "flex", gap: 2 }}>
                                    <Button
                                        startIcon={isLogged ? <Dashboard /> : <Login />}
                                        onClick={() => navigate(isLogged ? "/dashboard" : "/register")}
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            fontSize: "1rem",
                                            border: "1px solid transparent",
                                            textTransform: "none",
                                            color: "white",
                                            bgcolor: textColor,
                                            borderRadius: 1,
                                        }}
                                    >
                                        {isLogged ? "Dashboard" : "Sign Up Now"}
                                    </Button>
                                    <Button
                                        startIcon={<PlayArrow />}
                                        onClick={handlePlayClick}
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            bgcolor: "white",
                                            color: "black",
                                            fontSize: "1rem",
                                            textTransform: "none",
                                        }}
                                    >
                                        Play Video
                                    </Button>
                                </Box> */}
                            </Box>
                        </Grid>
                        {/* Right Section */}
                        <Grid item xs={12} md={6} sx={{ position: 'relative', pt: { xs: "10px !important", md: "20px !important" } }}>
                            <Box onClick={handlePlayClick}
                                sx={{
                                    cursor: "pointer",
                                    width: { xs: '100%', sm: '100%' },
                                    padding: { xs: '10px', sm: '10px' },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'relative',
                                    borderRadius: '8px', // Add rounded corners for a modern look
                                    boxShadow: 0, // Soft shadow for depth
                                    overflow: 'hidden', // Ensures that the image and iframe are clipped to rounded corners
                                    backgroundImage: !isPlaying
                                        ? `url(${require("../images/thumbnails/lg_hero.png")})`
                                        : 'none', // Set the background image when not playing
                                    backgroundSize: 'cover', // Ensure the image covers the entire container
                                    backgroundPosition: 'center', // Keep the background centered
                                    backgroundRepeat: 'no-repeat', // Prevent image repetition
                                    height: '300px', // Set a fixed height for the container
                                    transition: 'background 0.3s ease', // Smooth transition for the background change
                                }}
                            >
                                {/* Play Button */}
                                {!isPlaying && (
                                    <IconButton
                                        size='large'
                                        sx={{
                                            position: 'absolute',
                                            zIndex: 3,
                                            fontSize: '60px', // Larger play button
                                            color: '#fff',
                                            backgroundColor: "black",
                                            opacity: 0.8,
                                            transition: 'opacity 0.3s ease',
                                            '&:hover': {
                                                opacity: 1, // On hover, make the play button fully visible
                                            },
                                        }}
                                        onClick={handlePlayClick}
                                    >
                                        <PlayArrow />
                                    </IconButton>
                                )}

                                {/* Video Iframe */}
                                {isPlaying && (
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src="https://www.youtube.com/embed/x1YUd2Ane2s?autoplay=1"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        style={{ borderRadius: '8px' }} // Keep iframe with rounded corners
                                    ></iframe>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Typography
                    variant="h4"
                    fontWeight="700"
                    gutterBottom
                    sx={{ fontSize: { xs: "1.5rem", md: "2.125rem" }, color: "#0c197d" }}
                >
                    What You Get
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ mb: 4, fontSize: { xs: "0.9rem", md: "1rem" } }}
                >
                    Shubadinam helps you to find out your Birthday based on Indian Calendar / Panchang. Join Free or Choose Premium Membership for Exclusive Benefits.
                </Typography>


                <Box sx={{
                    display: { xs: "flex", md: "flex" },
                    width: "100% !important",
                    overflowX: 'auto',  // Enables horizontal scrolling
                    position: 'relative',
                    borderRadius: 1,
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05)',
                    '&::-webkit-scrollbar': {
                        height: '8px', // Custom height of the scrollbar
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#38a1f7',  // Custom color for the thumb (the part that moves)
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#053559',  // Custom color for the track (the part the thumb moves along)
                    },
                }}>
                    <Box
                        sx={{
                            width: "100% !important",
                            display: 'flex',
                            alignItems: { xs: "none", md: "center" },
                            justifyContent: { xs: "none", md: "center" },
                            gap: 2,
                            mb: 2
                        }}
                        {...swipeHandlers} // Attach swipeable handlers here
                    >
                        <Grid>
                            <PricingCard
                                title="Premium plan"
                                price="349"
                                popular={true}
                                features={[
                                    "Find Nakshatra / Janma Tithi(As per Indian calender)",
                                    "Find Nakshatra / Janma Tithi(As per English Date of Birth)",
                                    "Add family and friends(Unlimited Entries)",
                                    "One Free Virtual Puja Seva Slot (of value ₹500)",
                                    "Free Delivery For Merchandise(save  ₹70 to ₹125 - India only)",
                                    "Multiple Advance reminders to plan your celebration",
                                    "Email Reminder(India and Global)",
                                    "SMS Reminder(India only)",
                                    "WhatsApp Reminder(India and Global)",
                                    "Ads Free",
                                ]}
                                available={[true, true, true, true, true, true, true, true, true, true]}
                            />
                        </Grid>
                        <Grid>
                            <PricingCard
                                title="Basic plan"
                                price="0/ Free"
                                features={[
                                    "Find Nakshatra / Janma Tithi(As per Indian calender)",
                                    "Add family and friends(Unlimited Entries)",
                                    "Email Reminder(India and Global)",
                                    "Find Nakshatra / Janma Tithi(As per English Date of Birth)",
                                    "One Free Virtual Puja Seva Slot (of value ₹500)",
                                    "Free Delivery For Merchandise(save  ₹70 to ₹125 - India only)",
                                    "Multiple Advance reminders to plan your celebration",
                                    "SMS Reminder(India only)",
                                    "WhatsApp Reminder(India and Global)",
                                    "Ads Free",
                                ]}
                                available={[true, true, true, false, false, false, false, false, false]} // Mark unavailable features with false
                            />
                        </Grid>
                    </Box>
                </Box>

                <Container sx={{ maxWidth: 'lg', px: { xs: 2, md: 4 }, py: 6 }}>
                    <Box sx={{ textAlign: 'center', mb: 4 }}>
                        <Typography
                            variant="h4"
                            align="center"
                            gutterBottom
                            sx={{
                                fontWeight: 'bold',
                                mb: 2,
                                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                            }}>
                            What our customers say
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 2, }}>
                            Don’t take our word for it. Hear what our lovely customers are saying.
                        </Typography>

                        <a href="/testimonials" style={{ textDecoration: "none", color: "white" }}>
                            <Button endIcon={<NavigateNext sx={{ fontSize: "12px", }} />} sx={{ mt: 4, px: 4, py: 2, backgroundColor: '#1b3e69', color: '#fff', borderRadius: 4, border: `1px solid white`, textTransform: "none", '&:hover': { backgroundColor: '#1b3e69' } }}>
                                View All Reviews
                            </Button>
                        </a>


                    </Box>
                    <Splide
                        ref={splideRef}
                        options={{
                            type: 'loop',
                            perPage: 3,
                            autoplay: true,
                            interval: 3000,
                            arrows: true,
                            gap: 10,
                            pauseOnHover: true,
                            arrows: false,
                            pagination: false,
                            breakpoints: {
                                3000: {
                                    perPage: 4, // 2 slides per page for screens 1200px and below
                                },
                                1600: {
                                    perPage: 3, // 2 slides per page for screens 1200px and below
                                },
                                1200: {
                                    perPage: 2, // 1 slide per page for screens 800px and below
                                },
                                480: {
                                    perPage: 1, // 1 slide per page for very small screens (480px and below)
                                    arrows: false, // Hide arrows on small screens
                                },
                            },
                        }}
                        style={{ marginTop: '20px', padding: '20px !important' }}
                    >
                        {reviews.map((review, index) => (
                            <SplideSlide key={index}>
                                <Paper sx={{
                                    p: 6,
                                    borderRadius: 4,
                                    boxShadow: 3,
                                    m: 2,
                                    position: 'relative', // Needed for absolute positioning of quotes
                                    overflow: 'hidden'
                                }}>
                                    {/* Left Top Quotation */}
                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            top: 10,
                                            left: 20,
                                            fontSize: 60,
                                            fontWeight: 'bold',
                                            color: '#d1d5db'
                                        }}
                                    >
                                        “
                                    </Typography>

                                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                                        <Avatar alt={review.name} src={review.image} sx={{ width: 64, height: 64 }} />
                                    </Box>

                                    <Typography variant="body1" sx={{ color: '#4b5563', textAlign: 'center', mb: 4 }}>
                                        {review.review}
                                    </Typography>

                                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                                        <Rating value={review.rating} readOnly size="large" sx={{ color: '#f59e0b' }} />
                                        <Typography variant="body2" sx={{ color: '#1f2937', ml: 2 }}>
                                            {review.rating}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1f2937' }}>
                                            - {review.name}
                                        </Typography>
                                    </Box>

                                    {/* Right Bottom Quotation */}
                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 20,
                                            fontSize: 60,
                                            fontWeight: 'bold',
                                            color: '#d1d5db'
                                        }}
                                    >
                                        ”
                                    </Typography>
                                </Paper>

                            </SplideSlide>
                        ))}
                    </Splide>
                    <Box sx={{ textAlign: 'center', mt: 2 }}>
                        <Button
                            onClick={handlePrev}
                            sx={{
                                backgroundColor: '#1b3e69',
                                color: '#fff',
                                borderRadius: 10,
                                mr: 1,
                                p: 2,
                                '&:hover': { backgroundColor: '#1b3e69' }
                            }}
                        >
                            <NavigateBefore />
                        </Button>
                        <Button
                            onClick={handleNext}
                            sx={{
                                backgroundColor: '#1b3e69',
                                color: '#fff',
                                borderRadius: 10,
                                ml: 1,
                                p: 2,
                                '&:hover': { backgroundColor: '#1b3e69' }
                            }}
                        >
                            <NavigateNext />
                        </Button>
                    </Box>

                </Container>

            </Box>
            <FooterComponent />
        </div >




    );
};

export default Pricing;

