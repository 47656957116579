// import React, { useState } from "react";
// import { Container, Typography, Grid, Button, Card, CardContent, CardMedia, IconButton, Avatar, Box, CircularProgress, Rating } from "@mui/material";
// import { Star, PlayCircle } from "@mui/icons-material";
// import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
// import ReviewsFeedback from "../../components/ReviewsFeedback";

// const testimonials = [
//     { quote: "This service has exceeded my expectations. The experience was seamless and highly professional.", name: "John Doe", company: "Tech Solutions", avatar: "https://i.pravatar.cc/150?img=3", rating: 5 },
//     { quote: "Fantastic customer support and great value for money. Highly recommended!", name: "Jane Smith", company: "Creative Studio", avatar: "https://i.pravatar.cc/150?img=5", rating: 4 },
//     { quote: "A truly remarkable experience. Their dedication and expertise are unmatched!", name: "Alice Brown", company: "InnovateX", avatar: "https://i.pravatar.cc/150?img=7", rating: 5 },
//     { quote: "This is hands down the best platform I’ve ever used. Super intuitive and powerful!", name: "Michael Johnson", company: "Alpha Tech", avatar: "https://i.pravatar.cc/150?img=8", rating: 5 },
//     { quote: "The user experience is fantastic. I can’t imagine going back to what I used before.", name: "Emily Davis", company: "Bright Media", avatar: "https://i.pravatar.cc/150?img=9", rating: 4 },
//     { quote: "Customer service was exceptional. They went above and beyond to assist me.", name: "Robert Wilson", company: "NextGen Corp", avatar: "https://i.pravatar.cc/150?img=10", rating: 5 },
//     { quote: "A life-changing experience. I wish I had discovered this earlier!", name: "Sophia Martinez", company: "Future Innovations", avatar: "https://i.pravatar.cc/150?img=11", rating: 5 },
//     { quote: "Their commitment to quality and excellence is evident in everything they do.", name: "David Brown", company: "Quantum Systems", avatar: "https://i.pravatar.cc/150?img=12", rating: 4 },
//     { quote: "Their expertise saved us so much time and effort. Couldn’t be happier!", name: "Isabella Hernandez", company: "Visionary AI", avatar: "https://i.pravatar.cc/150?img=13", rating: 5 },
//     { quote: "Absolutely top-notch service. They truly care about their customers.", name: "James Lee", company: "Skyline Ventures", avatar: "https://i.pravatar.cc/150?img=14", rating: 5 },
//     { quote: "Their product completely changed how we work. Highly recommended!", name: "Olivia White", company: "Tech Titans", avatar: "https://i.pravatar.cc/150?img=15", rating: 4 },
//     { quote: "From start to finish, the entire process was smooth and easy. Five stars!", name: "Daniel Walker", company: "Cloud Nine Ltd.", avatar: "https://i.pravatar.cc/150?img=16", rating: 5 },
//     { quote: "Their attention to detail and customer focus is truly impressive.", name: "Ava King", company: "Elite Solutions", avatar: "https://i.pravatar.cc/150?img=17", rating: 4 },
//     { quote: "I can’t say enough great things about this team. They are simply the best!", name: "Liam Carter", company: "Peak Performance", avatar: "https://i.pravatar.cc/150?img=18", rating: 5 },
//     { quote: "The best investment we’ve made for our business. Worth every penny!", name: "Mia Foster", company: "Strive Digital", avatar: "https://i.pravatar.cc/150?img=19", rating: 5 },
//     { quote: "They offer solutions that actually work. I’m beyond impressed!", name: "Noah Evans", company: "Elevate Inc.", avatar: "https://i.pravatar.cc/150?img=20", rating: 4 },
//     { quote: "This is what true customer-centric service looks like. Amazing!", name: "Charlotte Morgan", company: "Summit Enterprises", avatar: "https://i.pravatar.cc/150?img=21", rating: 5 },
//     { quote: "Their platform has revolutionized the way we do business. Game changer!", name: "William Parker", company: "New Horizon", avatar: "https://i.pravatar.cc/150?img=22", rating: 5 },
//     { quote: "I was skeptical at first, but now I’m a loyal customer for life!", name: "Amelia Ross", company: "Synergy Works", avatar: "https://i.pravatar.cc/150?img=23", rating: 4 },
//     { quote: "Their team is incredibly responsive and truly understands our needs.", name: "Benjamin Scott", company: "Core Solutions", avatar: "https://i.pravatar.cc/150?img=24", rating: 5 },
//     { quote: "We’ve tried others, but nothing comes close to what they offer!", name: "Harper Adams", company: "Dynamic Ventures", avatar: "https://i.pravatar.cc/150?img=25", rating: 4 },
//     { quote: "A world-class experience with top-notch support!", name: "Elijah Turner", company: "Infinity Tech", avatar: "https://i.pravatar.cc/150?img=26", rating: 5 },
//     { quote: "I never write reviews, but this service truly deserves one!", name: "Evelyn Cooper", company: "Prime Marketing", avatar: "https://i.pravatar.cc/150?img=27", rating: 4 },
//     { quote: "They exceeded all my expectations. I’ll be back for sure!", name: "Mason Bennett", company: "Vortex AI", avatar: "https://i.pravatar.cc/150?img=28", rating: 5 }
// ];


// const RoughSeven = () => {
//     const [visibleTestimonials, setVisibleTestimonials] = useState(testimonials.length / 2);

//     const handleLoadMore = () => {
//         setVisibleTestimonials(prev => prev + 10);
//     };

//     return (
//         <Container maxWidth="xl" sx={{ py: 4, bgcolor: "#f8fffa" }}>
//             <Typography variant="h4" fontWeight="bold" align="center">What Bonsai customers say</Typography>
//             <Typography variant="body1" color="textSecondary" align="center" sx={{ mt: 1 }}>
//                 Successful agencies, consulting, and professional services firms rely on Bonsai every day.
//             </Typography>

//             <Grid container spacing={4} sx={{ mt: 4 }}>
//                 {testimonials.slice(0, visibleTestimonials).map((testimonial, index) => (
//                     <Grid item xs={12} sm={6} lg={4} key={index}>
//                         <Card
//                             sx={{
//                                 bgcolor: index % 2 === 0
//                                     ? '#e8f4f8'  // Light bluish for even 
//                                     : index % 3 === 0
//                                         ? '#f1f8e9'  // Light greenish for index divisible by 3
//                                         : index % 4 === 0
//                                             ? '#fff3e0'  // Light peach for index divisible by 4
//                                             : index % 5 === 0
//                                                 ? '#fff8e1'  // Light yellow for index divisible by 5
//                                                 : '#f9fbe7',  // Very light greenish-yellow for other odd cases
//                                 p: 3,
//                                 borderRadius: 3,
//                                 boxShadow: 1,
//                                 transition: "0.3s",
//                                 "&:hover": { boxShadow: 4 },
//                             }}
//                         >
//                             <CardContent>
//                                 <Box display="flex" alignItems="center" gap={2} mb={4}>
//                                     <Avatar src={testimonial.avatar} alt={testimonial.name} />
//                                     <Box>
//                                         <Typography fontWeight="bold">{testimonial.name}</Typography>
//                                         <Rating name="read-only" value={testimonial.rating} readOnly />
//                                         {/* <Typography variant="body2" color="success.main">
//                                             {testimonial.company}
//                                         </Typography> */}
//                                     </Box>
//                                 </Box>
//                                 <Typography
//                                     sx={{
//                                         fontStyle: "italic",
//                                         position: "relative",
//                                         color: "text.secondary",
//                                         "&::before": {
//                                             content: '"“"',
//                                             fontSize: 40,
//                                             position: "absolute",
//                                             top: -30,
//                                             left: -10,
//                                             color: "primary.main",
//                                         },
//                                         "&::after": {
//                                             content: '"”"',
//                                             fontSize: 40,
//                                             position: "absolute",
//                                             bottom: -30,
//                                             right: -10,
//                                             color: "primary.main",
//                                         },
//                                     }}
//                                 >
//                                     {testimonial.quote}
//                                 </Typography>
//                             </CardContent>
//                         </Card>
//                     </Grid>
//                 ))}
//             </Grid>

//             {visibleTestimonials < testimonials.length && (
//                 <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
//                     <Button
//                         variant="contained"
//                         color="primary"
//                         onClick={handleLoadMore}
//                         sx={{
//                             padding: "10px 30px",
//                             fontWeight: "bold",
//                             fontSize: "16px",
//                             borderRadius: "8px",
//                             boxShadow: 2,
//                             textTransform: "none",
//                             "&:hover": {
//                                 backgroundColor: "primary.dark",
//                                 boxShadow: 6,
//                             },
//                             "&:active": {
//                                 backgroundColor: "primary.light",
//                                 boxShadow: 1,
//                             },
//                             transition: "all 0.3s ease",
//                         }}
//                     >

//                         Load More

//                     </Button>
//                 </Box>
//             )}

//             <ReviewsFeedback />

//         </Container>
//     );
// };

// export default RoughSeven;


import React from 'react'

const RoughSeven = () => {
    return (
        <div>RoughSeven</div>
    )
}

export default RoughSeven