/* global ShopifyBuy */ // Inform ESLint that ShopifyBuy is a global variable

import React, { useEffect, useState } from 'react';
import { Container, Typography, Card, CardMedia, CardContent, Box, useMediaQuery, Button } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css'; // Import the default CSS for Splide
import { Skeleton } from '@mui/material'; // Import Skeleton component
import { NavLink } from 'react-router-dom';
import CardGiftcard from '@mui/icons-material/CardGiftcard';
import BooksCategory from '../screens/utils/BooksCategory';
import { orange } from '@mui/material/colors';

const categories = [
    { name: 'Nicey', img: require("../images/shop/nicey.webp"), link: "https://shop.shubadinam.com/collections/nicey", description: "Thoughtful and memorable presents for every occasion." },
    { name: 'Kiddos', img: require("../images/shop/kiddos_shop.webp"), link: "https://shop.shubadinam.com/collections/kiddos", description: "Fun and educational gifts perfect for children." },
    { name: 'Utility', img: require("../images/shop/utility_shop.webp"), link: "https://shop.shubadinam.com/collections/utility", description: "Practical and useful items that everyone needs." },
    { name: 'Divine', img: require("../images/shop/nicey_shop.webp"), link: "https://shop.shubadinam.com/collections/divine", description: "Gifts that connect hearts to the divine." },
    { name: 'Gift Cards', img: require("../images/shop/giftcard_shop.webp"), link: "https://shop.shubadinam.com/collections/gift-cards", description: "Give the gift of choice so the recipient can choose what they desire." },
];

const products = [
    { name: 'Bronze Gift Card', img: require("../images/shop/gift_cards/gift card-01.png"), link: "https://shop.shubadinam.com/collections/gift-cards/products/bronze-gift-card", description: "Thoughtful and memorable presents for every occasion." },
    { name: 'Silver Gift Card', img: require("../images/shop/gift_cards/gift card-02.png"), link: "https://shop.shubadinam.com/collections/gift-cards/products/silver-gift-card", description: "Fun and educational gifts perfect for children." },
    { name: 'Gold Gift Card', img: require("../images/shop/gift_cards/gift card-03.png"), link: "https://shop.shubadinam.com/collections/gift-cards/products/gold-gift-card", description: "Practical and useful items that everyone needs." },
    { name: 'Platinum Gift Card', img: require("../images/shop/gift_cards/gift card-04.png"), link: "https://shop.shubadinam.com/collections/gift-cards/products/platinum-gift-card", description: "Gifts that connect hearts to the divine." },
    { name: 'Diamond Gift Card', img: require("../images/shop/gift_cards/gift card-05.png"), link: "https://shop.shubadinam.com/collections/gift-cards/products/diamond-gift-card", description: "Give the gift of choice so the recipient can choose what they desire." },
];


const ShopComponent = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(max-width:900px)');

    // useEffect(() => {
    //     // Load the Shopify Buy Button script
    //     const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

    //     const loadScript = () => {
    //         const script = document.createElement('script');
    //         script.async = true;
    //         script.src = scriptURL;
    //         script.onload = () => {
    //             fetchProducts(); // Fetch products after script is loaded
    //         };
    //         (document.head || document.body).appendChild(script);
    //     };

    //     const fetchProducts = async () => {
    //         const client = ShopifyBuy.buildClient({
    //             domain: '67cc47-90.myshopify.com',
    //             storefrontAccessToken: '184085a79bdc9a41b82921e03a10e1b0',
    //         });

    //         // Replace 'your-collection-id' with your actual collection ID
    //         const collectionId = 'gid://shopify/Collection/294958039092';

    //         try {
    //             const collection = await client.collection.fetchWithProducts(collectionId);
    //             const formattedProducts = collection.products.map((product) => ({
    //                 name: product.title,
    //                 img: product.images[0]?.src || 'https://via.placeholder.com/150',
    //                 link: product.onlineStoreUrl,
    //                 price: product.variants[0].price.amount
    //             }));

    //             // setProducts(formattedProducts);
    //         } catch (error) {
    //             console.error("Error fetching products from collection:", error);
    //         }
    //     };

    //     // Load the script
    //     if (window.ShopifyBuy && window.ShopifyBuy.UI) {
    //         fetchProducts();
    //     } else {
    //         loadScript();
    //     }

    //     // Cleanup function
    //     return () => {
    //         const existingScript = document.querySelector(`script[src="${scriptURL}"]`);
    //         if (existingScript) {
    //             existingScript.remove();
    //         }
    //     };
    // }, []);


    return (
        <Container maxWidth="lg" sx={{ py: 6, borderRadius: 2, }}>

            {/* products gifting */}
            <Typography variant="h4"
                align="center"
                gutterBottom
                color='primary'
                sx={{
                    fontWeight: 'bold',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                }}>
                <span style={{ color: orange[700] }}>Gifting</span> Products
            </Typography>
            <Typography variant="body1"
                align="center"
                sx={{
                    color: '#7f8c8d',
                    mb: 4,
                    p: 2,
                    fontStyle: 'italic',
                }}>At Shubadinam Heritage Services, we offer a wide range of handpicked gifts categorized to make your choices meaningful and easy!  <NavLink style={{ textDecoration: "none" }} to="https://shop.shubadinam.com/" target="_blank">View All Categories</NavLink></Typography>
            <Splide
                options={{
                    type: "slide",
                    perPage: 3, // Default for large screens
                    perMove: 1,
                    gap: "16px",
                    pagination: true,
                    drag: "free", // Enables smooth dragging
                    arrows: true,
                    snap: true, // Ensures cards snap into place after dragging
                    breakpoints: {
                        2000: { perPage: 4 },  // Large desktops (4K screens)
                        1600: { perPage: 5 },  // Large desktops (4K screens)
                        1300: { perPage: 4 },  // Regular desktops
                        1024: { perPage: 4 }, // Small desktops & large tablets
                        768: { perPage: 3 },   // Tablets & small laptops
                        600: { perPage: 2 }, // **Small mobile screens should show 2 per page**
                        480: { perPage: 2 }, // **Standard mobile screens should also show 2 per page**
                        360: { perPage: 1.5 },   // **Very small mobile screens show 1.5 per page**
                    },
                }}
                style={{
                    // background: "#053559",
                    borderRadius: "8px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.05)",
                }}
            >
                {categories.map((category, index) => (
                    <SplideSlide key={index}>
                        <Card
                            onClick={() => window.open(category.link, "_blank")}
                            sx={{
                                cursor: "pointer",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                borderRadius: 2,
                                bgcolor: '#020332',
                                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                "&:hover": {
                                    transform: "scale(1.05)",
                                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                                },
                                width: "100%", // Ensure cards are responsive
                                maxWidth: "220px", // Keep a max width for consistency
                                height: "auto", // Fixed height for uniformity
                            }}
                            data-aos="fade-up"
                            data-aos-delay={200 * index}
                        >
                            <CardMedia
                                component="img"
                                height="150"
                                width="200"
                                image={category.img}
                                alt={category.title}
                                sx={{
                                    borderTopLeftRadius: 2,
                                    borderTopRightRadius: 2,
                                    bgcolor: "#eeee",
                                    objectFit: "contain",
                                }}
                            />
                            <CardContent sx={{ paddingBottom: 2 }}>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{ fontWeight: "bold", color: orange[600], fontSize: "1em" }}
                                >
                                    {category.name}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="white"
                                    sx={{ fontSize: "0.9rem" }}
                                >
                                    {category.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </SplideSlide>
                ))}
            </Splide>

            <br />

            {/* books gifting */}
            <Typography variant="h4"
                align="center"
                gutterBottom
                sx={{
                    fontWeight: 'bold',
                    color: '#2c3e50',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                }}>
                Gifting   <span style={{ color: orange[700] }}>Books</span>
            </Typography>
            <Typography variant="body1"
                align="center"
                sx={{
                    color: '#7f8c8d',
                    mb: 4,
                    p: 2,
                    fontStyle: 'italic',
                }}>
                At Shubadinam Heritage Services, we offer a wide range of choicest books that inspire, inform and entertain!  <NavLink style={{ textDecoration: "none" }} to="https://shop.shubadinam.com/collections/books" target="_blank">View All Categories</NavLink>
            </Typography>

            <BooksCategory />


            {/* gift cards */}
            <Typography variant="h5" component="h3" align="center" sx={{ mt: 6, fontWeight: 'bold', color: "black", p: 2 }}>
                Not sure what to gift?
            </Typography>
            <Typography variant="body1"
                align="center"
                sx={{
                    color: '#7f8c8d',
                    mb: 4,
                    fontStyle: 'italic',
                }}>
                Buy gift cards. Give the gift of choice so the recipient can choose what they want.
            </Typography>

            <Splide
                options={{
                    type: 'slide',
                    perPage: isSmallScreen ? 2 : isMediumScreen ? 3 : 5,
                    gap: '1rem',
                    arrows: true,
                    pagination: true,
                    autoplay: false,
                    interval: 3000,
                    perMove: 1,
                    breakpoints: {
                        2000: { perPage: 4 },  // Large desktops (4K screens)
                        1600: { perPage: 5 },  // Large desktops (4K screens)
                        1300: { perPage: 4 },  // Regular desktops
                        1024: { perPage: 4 }, // Small desktops & large tablets
                        768: { perPage: 3 },   // Tablets & small laptops
                        600: { perPage: 2 }, // **Small mobile screens should show 2 per page**
                        480: { perPage: 2 }, // **Standard mobile screens should also show 2 per page**
                        360: { perPage: 1 },   // **Very small mobile screens show 1.5 per page**
                    },
                }}
            >
                {products.length === 0 ? (
                    // Skeleton loading if products are empty
                    Array.from({ length: 4 }).map((_, index) => (
                        <SplideSlide key={index}>
                            <Card sx={{ width: 250, borderRadius: 2 }}>
                                <Skeleton variant="rectangular" width="100%" height={150} />
                                <CardContent sx={{ paddingTop: 2 }}>
                                    <Skeleton variant="text" width="80%" height={24} sx={{ marginBottom: 1 }} />
                                    <Skeleton variant="rectangular" width="80%" height={36} />
                                </CardContent>
                            </Card>
                        </SplideSlide>
                    ))
                ) :
                    (
                        products.map((product, index) => (
                            <SplideSlide key={index}>
                                <a href={product.link} target='_blank' style={{ textDecoration: "none !important", }}>
                                    <Card
                                        sx={{
                                            width: "100%", // Ensure cards are responsive
                                            maxWidth: "220px", // Keep a max width for consistency
                                            height: "auto", // Fixed height for uniformity
                                            // width: 250,
                                            textDecoration: "none !important",
                                            borderRadius: 2,
                                            // boxShadow: 2,
                                        }}
                                    >
                                        <CardMedia
                                            component="img"
                                            height={{ xs: "auto", md: "150" }}
                                            image={product.img}
                                            alt={product.name}
                                            sx={{
                                                textDecoration: "none !important",
                                                borderTopLeftRadius: 2,
                                                borderTopRightRadius: 2,
                                                objectFit: { xs: "contain", md: "cover" },
                                            }}
                                        />
                                        <CardContent
                                            sx={{
                                                textDecoration: "none !important",
                                                paddingTop: 2,
                                                borderBottom: "1px solid grey",
                                                borderBottomLeftRadius: 2,
                                                borderBottomRightRadius: 2,
                                                bgcolor: "#eeeeee21"
                                            }}
                                        >
                                            <Typography
                                                variant="caption"
                                                component="div"
                                                sx={{
                                                    textDecoration: "none !important",
                                                    color: '#333',
                                                    marginBottom: 1,
                                                }}
                                            >
                                                {product.name}
                                                {/* - <Typography
                                                variant="body5"
                                                component="span"
                                                sx={{
                                                    color: 'grey',
                                                    marginBottom: 1,
                                                    fontSize: "1em"
                                                }}
                                            >
                                                ₹{product.price} /-
                                            </Typography> */}
                                            </Typography>

                                            <Button
                                                size='small'
                                                startIcon={<CardGiftcard />}
                                                href={product.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                variant="contained"
                                                sx={{
                                                    p: 1,
                                                    color: '#fff',
                                                    borderRadius: 2,
                                                    textTransform: 'none', '&:hover': {
                                                        color: '#fff',
                                                    }
                                                }}
                                            >
                                                View Gift
                                            </Button>
                                        </CardContent>
                                    </Card>
                                </a>
                            </SplideSlide>
                        ))
                    )
                }
            </Splide>

        </Container>
    );
};

export default ShopComponent;
