import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Navigate, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Doughnut, Pie, Bar, Bubble, Scatter, HorizontalBar, StackedBar, Mixed, Radar, Line, PolarArea } from 'react-chartjs-2';

import CountUp from 'react-countup';
import { Grid, Card, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, Breadcrumbs, Box, Chip, Container, Skeleton } from '@mui/material';
import { Group as GroupIcon, AccountBox as AccountBoxIcon, Stars as StarsIcon, RemoveRedEye, CardGiftcard, CheckCircle } from '@mui/icons-material';
import UserChart from './UserChart';


ChartJS.register(
    CategoryScale,
    LinearScale, // Register the linear scale
    BarElement,
    Title,
    Tooltip,
    Legend
);


const AdminDashboard = ({ setSelectedNavItem }) => {

    const navigate = useNavigate()



    const [userData, setUserData] = useState([]);
    const [newUser, setNewuser] = useState([]);
    const [premUser, setpremUser] = useState([]);
    const [todayBirthday, setTodayBirthday] = useState([]);

    const totalUsers = userData.length;

    // Chart data
    const chartData = {
        type: 'bar',
        labels: ['Total Users'],
        datasets: [

            {
                label: 'Customers',
                data: [totalUsers, 2000 - totalUsers], // Assuming a total of 100 users
                backgroundColor: ['#36A2EB', '#FFCE56'], // You can customize colors

            },
        ],
    };

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true,
            },
            x: {
                beginAtZero: true,

            }
        },
    };



    const [users, setUsers] = useState([]);
    const [todayViews, setTodayViews] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responses = await Promise.allSettled([
                    axios.get('https://backend.shubadinam.com/admin/analyseusers'),
                    axios.get('https://backend.shubadinam.com/admin/newusers'),
                    axios.get('https://backend.shubadinam.com/admin/get/nonpremiumusers'),
                    axios.get('https://backend.shubadinam.com/admin/get/premiumusers'),
                    axios.get('https://backend.shubadinam.com/admin/fetch/todaybday'),
                    axios.get('https://backend.shubadinam.com/admin/get/todayviews')
                ]);
                responses.forEach((res, index) => {
                    if (res.status === "fulfilled") {
                        switch (index) {
                            case 0: setUserData(res.value.data); break;
                            case 1: setNewuser(res.value.data.setNewuser); break;
                            case 2: setUsers(res.value.data.length); break;
                            case 3: setpremUser(res.value.data.length); break;
                            case 4:
                                const today = new Date().toLocaleDateString("en-GB").split("/").join("-"); // Convert to dd-mm-yyyy
                                const selfBirthdays = Object.values(res.value.data.self || {}).filter(user => user.userDate === today).length;
                                const relativesBirthdays = (res.value.data.relatives || []).filter(rel => rel.userDate === today).length;
                                setTodayBirthday(selfBirthdays + relativesBirthdays);
                                break;
                            case 5: setTodayViews(res.value.data.uniqueIpCount); break;
                        }
                    } else {
                        console.error(`API ${index} failed:`, res.reason);
                    }
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleClick = () => {
        navigate('/admin-panel/total-customers');
    };


    const StatsSkeleton = () => {
        return (
            <Grid container spacing={3}>
                {Array.from(new Array(4)).map((_, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card sx={{ backgroundColor: "#eeeeee", p: 3, boxShadow: 3, borderRadius: 2 }}>
                            <Typography variant="h6">
                                <Skeleton width="80%" />
                            </Typography>
                            <Typography variant="h4" display="flex" alignItems="center">
                                <Skeleton variant="circular" width={40} height={40} sx={{ mr: 1 }} />
                                <Skeleton width="50%" />
                            </Typography>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        );
    };


    return (

        <Box sx={{ bgcolor: "#f8fcff" }}>
            <Box>
                <Typography variant="h6" fontWeight={500} mb={3}>Dashboard</Typography>
            </Box>


            {
                totalUsers ?


                    <Grid container spacing={3}>

                        {/** Total Customers **/}
                        <Grid item xs={12} sm={6} md={3}>
                            <Card sx={{ backgroundColor: "#ef5350", color: "white", p: 3, boxShadow: 3, borderRadius: 2 }}>
                                <NavLink to="/admin-panel/total-customers" style={{ textDecoration: "none", color: "inherit" }}>
                                    <Typography variant="h6">Total Customers</Typography>
                                    <Typography variant="h4" display="flex" alignItems="center">
                                        <GroupIcon sx={{ mr: 1 }} />
                                        <CountUp start={0} end={totalUsers} duration={2} />
                                    </Typography>
                                </NavLink>
                            </Card>
                        </Grid>

                        {/** Customers Today **/}
                        <Grid item xs={12} sm={6} md={3}>
                            <Card sx={{ backgroundColor: "#42a5f5", color: "white", p: 3, boxShadow: 0, borderRadius: 2 }}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="h6">Customers Today</Typography>
                                    {/* <Chip
                                        onClick={() => console.log("Navigate to views")}
                                        label={todayViews}
                                        icon={<RemoveRedEye />}
                                        color="info"
                                        sx={{ cursor: "pointer" }}
                                    /> */}

                                </Box>
                                <Typography variant="h4" display="flex" alignItems="center">
                                    <AccountBoxIcon sx={{ mr: 1 }} />

                                    <CountUp start={0} end={newUser} duration={2} />

                                </Typography>
                            </Card>
                        </Grid>

                        {/** Premium Customers **/}
                        <Grid item xs={12} sm={6} md={3}>
                            <Card sx={{ backgroundColor: "#66bb6a", color: "white", p: 3, boxShadow: 3, borderRadius: 2 }}>
                                <NavLink to="/admin-panel/premiumusers" style={{ textDecoration: "none", color: "inherit" }}>
                                    <Typography variant="h6">Premium Customers</Typography>
                                    <Typography variant="h4" display="flex" alignItems="center">
                                        <StarsIcon sx={{ mr: 1 }} />
                                        <CountUp start={0} end={premUser} duration={2} />
                                    </Typography>
                                </NavLink>
                            </Card>
                        </Grid>

                        {/** Birthdays Today **/}
                        <Grid item xs={12} sm={6} md={3}>
                            <Card sx={{ background: "linear-gradient(90deg, #4B99FC, #FA45FC)", color: "white", p: 3, boxShadow: 3, borderRadius: 2 }}>
                                <NavLink to="/adminpage" style={{ textDecoration: "none", color: "inherit" }}>
                                    <Typography variant="h6">Birthdays Today</Typography>
                                    <Typography variant="h4" display="flex" alignItems="center">
                                        <CardGiftcard sx={{ mr: 1 }} />
                                        <CountUp start={0} end={todayBirthday} duration={2} />
                                    </Typography>
                                </NavLink>
                            </Card>
                        </Grid>
                    </Grid>

                    :

                    <StatsSkeleton />

            }

            {/* user chart */}
            <UserChart />


            {/* Charts Section */}
            <Grid container spacing={3} mt={3}>
                <Grid item xs={12} md={6}>
                    <Card sx={{ p: 3, boxShadow: 0 }}>
                        <Typography variant="h6">Area Chart</Typography>
                        <Bar data={chartData} options={chartOptions} />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card sx={{ p: 3, boxShadow: 0 }}>
                        <Typography variant="h6">Detailed Report</Typography>
                        <Typography>Total Users: <span style={{ color: "green" }}><CountUp start={0} end={totalUsers} duration={2} /></span></Typography>
                        <Typography>New Users Today: <span style={{ color: "blue" }}><CountUp start={0} end={newUser} duration={2} /></span></Typography>
                    </Card>
                </Grid>
            </Grid>


        </Box>
    );
};

export default AdminDashboard;