import React from "react";
import {
    Avatar, Box, Card, CardContent, Typography, Button, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, Divider, IconButton
} from "@mui/material";
import {
    AccountCircle, CreditCard, Description, Lock, AccountBalanceWallet, ContentCopy, ChevronRight, Payments, BookOnline
} from "@mui/icons-material";
import { Outlet, useNavigate } from "react-router-dom";
import History from "@mui/icons-material/History";
import SlidingHeader from "../../components/SlidingHeader";
import FooterComponent from "../../components/footer";
import greenBanner from "../../images/banners/green-banner-01.png"
import BannerSlider from "../utils/BannerSlider";


const ProfileSection = ({ name, avatar }) => (
    <Box textAlign="center" sx={{ mb: 3 }}>
        <Avatar alt={`Profile picture of ${name}`} sx={{ width: 80, height: 80, mx: "auto", }} />
        <Typography variant="h5" fontWeight="bold" mt={2} color="white">
            {name}
        </Typography>
    </Box>
);

const WalletBalance = ({ balance }) => (
    <Box textAlign="center" sx={{ bgcolor: "#f0f0f0", color: "#333", py: 1, borderRadius: 2, mt: 2, display: "flex", alignItems: "center", justifyContent: "center", boxShadow: 0 }}>
        <AccountBalanceWallet sx={{ fontSize: 30, color: "#48bb78", mr: 2 }} />
        <Typography variant="h5" fontWeight="bold" color="#48bb78">
            ₹{balance}
        </Typography>
    </Box>
);


const handleCopy = async (code) => {
    if (!code) {
        alert("No referral code available!");
        return;
    }
    try {
        if (navigator.clipboard && typeof navigator.clipboard.writeText === "function") {
            await navigator.clipboard.writeText(code);
            alert("Referral code copied!");
        } else {
            // Fallback for older browsers & iOS Safari
            const textArea = document.createElement("textarea");
            textArea.value = code;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
            alert("Referral code copied!");
        }
    } catch (err) {
        alert("Clipboard copy failed. Please copy manually.");
    }
};


const ReferralCode = ({ code }) => {
    return (
        <Box textAlign="center" sx={{ background: "linear-gradient(45deg, rgb(133 68 194), rgb(255 135 195))", color: "white", py: 2, borderRadius: 2, mt: 3, position: "relative", boxShadow: 0 }}>
            <Typography variant="body1" fontWeight="bold">Refer and Earn Rewards</Typography>
            <Typography variant="h5" fontWeight="bold" sx={{ letterSpacing: 2, mt: 1 }}>{code}</Typography>
            <IconButton sx={{ position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)", color: "white" }} onClick={() => handleCopy(code)}>
                <ContentCopy fontSize="small" />
            </IconButton>
        </Box>
    );
};

const MyAccountLayout = ({ userData }) => {

    return (
        <Box>
            <SlidingHeader />

            <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", bgcolor: "#ffff", mt: { xs: 10, md: 10 } }}>

                <Paper sx={{ width: { xs: "100%", md: "80%", xl: "60%" }, borderRadius: 3, boxShadow: 0, p: 2, mb: 6 }}>
                    {/* Desktop Banner Image */}
                    <Box sx={{ display: { xs: "none", md: "block" }, height: 200, width: "100%", }}>
                        <BannerSlider />
                        {/* <img src={greenBanner} alt="Banner" style={{ width: "100%", height: "200px", objectFit: "cover", borderRadius: "10px" }} /> */}
                    </Box>
                    <Grid container justifyContent="center" sx={{ mt: 2 }}>
                        <Grid item xs={12} md={4} textAlign="center" sx={{ bgcolor: "#030f40", borderRadius: 4, p: 2 }}>
                            <ProfileSection name={userData.userName} avatar="https://placehold.co/120x120" />
                            <WalletBalance balance={0.0} />
                            <ReferralCode code={userData.referralId} />
                        </Grid>
                        {/* mobile Banner Image */}
                        <Box sx={{ display: { xs: "block", md: "none" }, width: "100%", mt: 1 }}>
                            <img src="https://cdn.vectorstock.com/i/500p/57/56/template-banner-for-online-store-with-shopping-vector-42935756.jpg" alt="Banner" style={{ width: "100%", objectFit: "contain", borderRadius: "20px" }} />
                        </Box>

                        {/* Dynamic Content (Menu Listings) */}
                        <Grid item xs={12} md={8}>
                            <Outlet />  {/* This will dynamically change */}
                        </Grid>
                    </Grid>
                </Paper>
            </Box>

            <FooterComponent />
        </Box>
    );
};

export default MyAccountLayout;
