import React, { useState, useEffect } from 'react';
import SDadminHeader from './SDadminHeader';
import Top20px from '../screens/Top20px';
import { Stack, Typography, TextField } from '@mui/material';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const HomamPurchasedReport = () => {
    const [selectedDate, setSelectedDate] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    // Effect to set today's date as the default value
    // useEffect(() => {
    //     const today = new Date();

    //     const day = String(today.getUTCDate()).padStart(2, '0');
    //     const month = String(today.getUTCMonth() + 1).padStart(2, '0');
    //     const year = today.getUTCFullYear();

    //     const formattedDate = `${year}-${month}-${day}`;

    //     setSelectedDate(formattedDate);
    // }, []);

    // Effect to fetch data once
    useEffect(() => {
        const url = `https://backend.shubadinam.com/admin/gethomampurchasers`;
        axios.get(url)
            .then((response) => {
                console.log(response.data);
                setData(response.data);
                setFilteredData(response.data); // Initialize filtered data with all fetched data
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // Filter data based on selected date
    useEffect(() => {
        if (selectedDate) {
            const filtered = data.filter(item => {
                const entryDate = new Date(item.entry_time).toISOString().split('T')[0];
                return entryDate === selectedDate;
            });
            setFilteredData(filtered);
        }
    }, [selectedDate, data]);

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const getMonthName = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const options = { month: 'long' };
        return date.toLocaleDateString(undefined, options);
    };

    const selectedMonth = getMonthName(selectedDate);

    return (
        <>
            <Stack direction={{ sm: "column", md: "row" }} spacing={60} sx={{ p: 2, mt: 5 }}>
                <Typography variant='h6'>Homam Purchased Report</Typography>
                <Stack direction={{ sm: "column", md: "column" }} sx={{ p: 2, mt: 2 }}>
                    <TextField
                        label="Date"
                        type="date"
                        size='small'
                        defaultValue={selectedDate}
                        value={selectedDate}
                        onChange={handleDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ width: 250, textAlign: "end" }}
                    />
                    {selectedDate && (
                        <Typography variant="body1" sx={{ width: 250, textAlign: "end", mt: 1 }}>
                            Selected Month: {selectedMonth}
                        </Typography>
                    )}
                </Stack>
            </Stack>
            <TableContainer component={Paper} sx={{ margin: 'auto', }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: "50px" }}>S.No</TableCell>
                            <TableCell align='center'>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell sx={{ width: "100px" }}>Amount</TableCell>
                            <TableCell sx={{ width: "70px" }}>Plan</TableCell>
                            <TableCell>Booke on</TableCell>
                            <TableCell>Address</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((customer, index) => (
                            <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{customer.accountHolderId}</TableCell>
                                <TableCell>{customer.accountHolderName}</TableCell>
                                <TableCell>{customer.accountHolderEmail}</TableCell>
                                <TableCell>{customer.accountHolderPhone}</TableCell>
                                <TableCell>Rs. {(customer.amount) / 100} /-</TableCell>
                                <TableCell>{customer.userType}</TableCell>
                                <TableCell>{new Date(customer.entry_time).toLocaleString("en-GB", { dateStyle: "short" })}</TableCell>
                                <TableCell>{customer.userAddress}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default HomamPurchasedReport;
