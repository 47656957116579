import React from "react";
import { Container, Grid, Typography, Link, Box, IconButton } from "@mui/material";
import { Twitter, Facebook, Instagram, WhatsApp, YouTube, EmailOutlined, PhoneOutlined } from "@mui/icons-material";


const FooterComponent = () => {



    const facebookURL = 'https://www.facebook.com/profile.php?id=61551460127861';
    const instagramURL = 'https://www.instagram.com/shubadinam/';
    const youtubeURL = 'https://www.youtube.com/channel/UCsNbE7QqnpgjI7cXOTM1mXA';
    const twitterURL = 'https://twitter.com/Shubadinam';
    const whatsappURL = 'https://www.youtube.com/channel/UCsNbE7QqnpgjI7cXOTM1mXA';

    const handleIconClick = (iconCount) => {
        // console.log('Icon clicked:', iconCount);
        if (iconCount === 2) {
            window.open(facebookURL, '_blank');
        }
        else if (iconCount === 3) {
            window.open(instagramURL, '_blank');
        }
        else if (iconCount === 4) {
            window.open(youtubeURL, '_blank');
        }
        else if (iconCount === 1) {
            window.open(twitterURL, '_blank');
        }
        else {
            // window.open(whatsappURL, '_blank');
        }

    };


    return (
        <Box component="footer" sx={{ bgcolor: "#020332", color: "#fff", py: 2 }}>
            <Grid container spacing={4} sx={{ p: { xs: 3, md: 8 } }}>
                {/* Useful Links */}
                <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h6" fontWeight="bold" sx={{ color: "#d5ebff" }}>Useful Links</Typography>
                    {[
                        { text: "Home", url: "/" },
                        { text: "About us", url: "/about-us" },
                        { text: "FAQs", url: "/faq" },
                        { text: "Terms of Service", url: "/termsandconditions" },
                        { text: "Privacy Policy", url: "/privacypolicy" },
                        { text: "Why Us", url: "/why-us" },
                        { text: "Contact Us", url: "/contact-us" },
                    ].map((link, index) => (
                        <Typography key={index} variant="body2" sx={{ mt: 1, lineHeight: 1.5 }}>
                            ❖  <Link href={link.url} color="inherit" underline="hover" sx={{ ml: 1, }}>
                                {link.text}
                            </Link>
                        </Typography>
                    ))}
                </Grid>

                {/* Our Services */}
                <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h6" fontWeight="bold" sx={{ color: "#d5ebff" }}>Our Services</Typography>
                    {[
                        { text: "Blogs", url: "/blogs" },
                        { text: "Gift Shoppe", url: "https://shop.shubadinam.com/", external: true },
                        { text: "Find Janma Nakshatra / Tithi", url: "/dashboard" },
                    ].map((service, index) => (
                        <Typography key={index} variant="body2" sx={{ mt: 1, lineHeight: 1.5 }}>
                            ❖   <Link
                                sx={{ ml: 1 }}
                                href={service.url}
                                color="inherit"
                                underline="hover"
                                target={service.external ? "_blank" : "_self"}
                                rel={service.external ? "noopener noreferrer" : ""}
                            >
                                {service.text}
                            </Link>
                        </Typography>
                    ))}
                </Grid>

                {/* Contact Us */}
                <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h6" fontWeight="bold" sx={{ color: "#d5ebff" }}>Contact Us</Typography>
                    <Typography variant="body2" sx={{ mt: 1, lineHeight: 1.5 }}>
                        <PhoneOutlined fontSize="small" sx={{ mr: 2, lineHeight: 1.5 }} /> +91 8925251938
                    </Typography>
                    <Typography variant="body2">
                        <EmailOutlined fontSize="small" sx={{ mr: 2, lineHeight: 1.5 }} /> support@shubadinam.com
                    </Typography>
                </Grid>

                {/* About Shubadinam */}
                <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h6" fontWeight="bold" sx={{ color: "#d5ebff" }}>About Shubadinam</Typography>
                    <Typography variant="body2" sx={{ mt: 1, lineHeight: 1.5 }}>
                        Shubadinam stands as a humble offering toward the noble cause of Retaining and Reclaiming the profound cultural legacy of Hinduism.
                    </Typography>
                    <Box sx={{ mt: 2, display: "flex", }}>
                        {[Twitter, Facebook, Instagram, WhatsApp, YouTube].map((Icon, index) => (
                            <IconButton
                                key={index}
                                sx={{
                                    bgcolor: "#26547d",
                                    color: "white",
                                    mr: 1,
                                    "&:hover": {
                                        bgcolor: "#1b3a57", // Darker shade on hover
                                    },
                                }}
                                onClick={() => handleIconClick(index + 1)}
                            >
                                <Icon fontSize="small" />
                            </IconButton>

                        ))}
                    </Box>
                </Grid>
            </Grid>

            {/* Footer Bottom */}
            <Box sx={{ textAlign: "center", p: 4, borderTop: "1px solid rgba(255,255,255,0.2)" }}>
                <Typography variant="body2">© Copyright <strong>Shubadinam</strong>. All Rights Reserved</Typography>
                <Box sx={{ mt: 1 }}>
                    {["/pricing-policy", "/shipping", "/refundandcancellationpolicy"].map((url, index) => (
                        <Link key={index} href={url} color="inherit" underline="hover" sx={{ mx: 1, fontSize: "small", textTransform: "capitalize", }}>
                            {url.split("/").pop().replace(/-/g, " ")}
                        </Link>
                    ))}
                </Box>
            </Box>

        </Box>

    )
}
export default FooterComponent;