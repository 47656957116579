import React from "react";
import { Container, Grid, Card, CardContent, Typography, CardMedia, List, ListItem, ListItemText, Box, Button, ListItemIcon, useMediaQuery, useTheme } from "@mui/material";
import { Bookmark, Category, Star, StarBorder } from "@mui/icons-material";
import SlidingHeader from "../../../components/SlidingHeader";
import FooterComponent from "../../../components/footer";
import { useNavigate } from "react-router-dom";
import bookBanner from "../../../images/banners/book banner ph-01.png"

const categories = ["All About Books", "Book Reviews"];
const recentPosts = [
    "Timeless Wisdom for Modern Growth: 4 Self-Help Books to Transform Your Life",
    "Sacred Legends and Perennial Wisdom: A Journey Through Devotional and Historical Narratives",
    "Exploring the Mystical Path: A Journey Through Aghori Traditions",
];
const popularPosts = [
    "Best Thriller Mystery Books – My Top 10 Favorites",
    "New 2021 Book Releases I Am Eagerly Anticipating",
    "From Lukov with Love by Mariana Zapata – Book Review",
];

const BooksBlogs = () => {




    const navigate = useNavigate();


    return (
        <Box>
            <SlidingHeader />



            <Container maxWidth="lg" sx={{ py: 4, mt: 10, }}>

                {/* banner */}
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                    <img
                        src={bookBanner}
                        alt="Book Banner"
                        style={{ width: '100%', objectFit: 'cover', borderRadius: 10, }}
                    />
                </Box>

                {/* books review */}
                <Grid container spacing={4} sx={{ py: 4 }}>
                    <Grid item xs={12} lg={8}>
                        {/* Book Review Cards */}
                        {[
                            {
                                image: require("../../../images/books/collage banner 2.png"),
                                title: "Timeless Wisdom for Modern Growth: 4 Self-Help Books to Transform Your Life",
                                author: "Subah Saraf and Harshvardhan Saraf,Hector García and Francesc Miralles... ",
                                description: "Embarking on a journey of self-improvement can be profoundly transformative.The following four self-help books, available at Shubadinam Shoppe, offer unique perspectives and practical guidance to navigate life's complexities.",
                                rating: 4,
                                link: "timeless-wisdom-for-modern-growth-4-self-help-books-to-transform-your-life",
                            },
                            {
                                image: require("../../../images/books/collage banner 1.png"),
                                title: "Sacred Legends and Perennial Wisdom: A Journey Through Devotional and Historical Narratives",
                                author: "Amit Kapoor, Bibek Debroy, Vertul Singh ...",
                                description: "India's rich spiritual heritage is woven through sacred texts, mythological narratives, and historical explorations that continue to inspire seekers across generations. From the divine Jyotirlingas to the Everlasting Insights of the Kurma Purana, and from the cultural soul of Banaras to the ideal life of Lord Rama, these books provide a profound understanding of faith, history, and dharma. Whether you seek spiritual enlightenment, historical insights, or a deeper connection with India’s cultural legacy, this collection of books offers a gateway to timeless wisdom and sacred storytelling.",
                                rating: 4,
                                link: "sacred-legends-and-perennial-wisdom-a-journey-through-devotional-and-historical-narratives",

                            },
                            {
                                image: require("../../../images/books/collage banner 3.png"),
                                title: "Exploring the Mystical Path: A Journey Through Aghori Traditions",
                                author: "Mayur Kalbag",
                                description: "The Aghori sadhus of India are often shrouded in mystery, known for their unconventional practices and profound devotion to Lord Shiva. Their path, though esoteric, offers deep insights into spirituality and the human experience. For those intrigued by these ascetics, the following books provide a window into their enigmatic world.",
                                rating: 4,
                                link: "exploring-the-mystical-path-a-journey-through-aghori-traditions",

                            },
                        ].map((book, index) => (

                            <a href={`/books-blogs/${book.link}`} style={{ textDecoration: "none", }}>

                                <Card key={index} sx={{ mb: 4, boxShadow: `0px 0px 0px 0px lightgrey`, display: "flex", cursor: "pointer", flexDirection: { xs: "column", sm: "row" } }}
                                >
                                    {/* Left Side: Image */}
                                    <CardMedia
                                        component="img"
                                        sx={{ width: { xs: "100%", md: 200 }, height: "auto" }}
                                        image={book.image} // Dynamic image source
                                        alt={book.title}   // Dynamic alt text
                                    />

                                    {/* Right Side: Content */}
                                    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                        <CardContent>
                                            <Typography variant="caption" color="text.secondary">
                                                BOOK REVIEWS
                                            </Typography>
                                            <Typography variant="h6" fontWeight="bold" gutterBottom color='primary'>
                                                {book.title}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                by {book.author}
                                            </Typography>
                                            <Typography color="primary" sx={{ my: 1 }}>
                                                {Array.from({ length: 5 }).map((_, i) =>
                                                    i < book.rating ? (
                                                        <Star key={i} color="warning" />
                                                    ) : (
                                                        <StarBorder key={i} color="disabled" />
                                                    )
                                                )}
                                            </Typography>
                                            <Typography variant="body2">
                                                {book.description.length > 250
                                                    ? `${book.description.substring(0, 250)}... `
                                                    : book.description}

                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Card>

                            </a>
                        ))}

                    </Grid>


                    <Grid item xs={12} lg={4}>

                        <a href="/pricing">
                            <Box
                                component="img"
                                src={require("../../../images/thumbnails/blogs-sideBanner.png")}
                                alt="Satvic Revolution"
                                sx={{
                                    cursor: "pointer",
                                    width: "100%", // Ensures full width inside its container
                                    height: "auto", // Maintains aspect ratio
                                    borderRadius: 2,
                                    display: "block", // Removes extra spacing below inline images
                                    mx: "auto", // Centers the image horizontally
                                    mb: 2, // Adds bottom margin
                                }}
                            />
                        </a>


                        {/* Categories Section */}
                        {/* <Card sx={{ mb: 4, boxShadow: 0, borderRadius: 2, overflow: 'hidden' }}>
                            <CardContent>
                                <Typography
                                    color="primary"
                                    variant="h6"
                                    fontWeight="bold"
                                    gutterBottom
                                    sx={{ display: 'flex', alignItems: 'center', gap: 1, p: 2, borderRadius: 2, bgcolor: "aliceblue" }}
                                >
                                    Categories
                                </Typography>
                                <List>
                                    {categories.map((category, index) => (
                                        <ListItem
                                            key={index}
                                            sx={{
                                                p: 1,
                                                cursor: "pointer",
                                                borderRadius: 1,
                                                transition: 'background-color 0.3s ease-in-out',
                                                '&:hover': { backgroundColor: 'rgba(0, 0, 255, 0.1)' },
                                            }}
                                        >
                                            <ListItemIcon>
                                                <Bookmark color="secondary" />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="body2" color="text.primary">
                                                    {category}
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            </CardContent>
                        </Card> */}


                        {/* Recent Posts Section */}
                        <Card sx={{ mb: 4, boxShadow: 0, borderRadius: 2, overflow: 'hidden' }}>
                            <CardContent>
                                <Typography
                                    color="primary"
                                    variant="h6"
                                    fontWeight="bold"
                                    gutterBottom
                                    sx={{ display: 'flex', alignItems: 'center', gap: 1, p: 2, borderRadius: 2, bgcolor: "aliceblue" }}
                                >
                                    Recent Posts
                                </Typography>
                                <List sx={{ padding: 0 }}>
                                    {recentPosts.map((post, index) => (
                                        <ListItem
                                            onClick={() => {
                                                const paths = [
                                                    "timeless-wisdom-for-modern-growth-4-self-help-books-to-transform-your-life",
                                                    "sacred-legends-and-perennial-wisdom-a-journey-through-devotional-and-historical-narratives",
                                                    "exploring-the-mystical-path-a-journey-through-aghori-traditions",
                                                ];
                                                window.location.href = `/books-blogs/${paths[index]}`;
                                            }}
                                            key={index}
                                            sx={{
                                                p: 1,
                                                borderBottom: index < recentPosts.length - 1 ? '1px solid #e0e0e0' : 'none',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 0, 255, 0.1)',
                                                    cursor: 'pointer',
                                                },
                                            }}
                                        >
                                            <ListItemText>
                                                <Typography
                                                    variant="body2"
                                                    color="text.primary"
                                                    sx={{ fontWeight: 'medium', transition: 'color 0.3s ease' }}
                                                >
                                                    {post}
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            </CardContent>
                        </Card>


                        {/* Popular Posts Section */}
                        {/* <Card sx={{ mb: 4, boxShadow: 3 }}>
                            <CardContent>
                                <Typography variant="h6" fontWeight="bold" gutterBottom>
                                    Popular Posts
                                </Typography>
                                <List>
                                    {popularPosts.map((post, index) => (
                                        <ListItem key={index} sx={{ p: 0 }}>
                                            <ListItemText>
                                                <Typography variant="body2" color="primary">
                                                    {post}
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            </CardContent>
                        </Card> */}
                    </Grid>

                </Grid>

            </Container>
            <FooterComponent />
        </Box >
    );
};

export default BooksBlogs;