import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Button, Typography, Paper, Box, CircularProgress, Alert, Grid, Card, CardContent, CardActions, List, ListItem, ListItemText, Divider, Chip, Table, TableBody, TableRow, TableCell, TableHead, TableContainer } from '@mui/material';

const PerCustomerDetails = () => {
    const [userDetails, setUserDetails] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false); // Track loading state
    const location = useLocation(); // Access the location object

    // Extract query parameter 'uniqueId' from the URL
    const getQueryParam = (name) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(name);
    };

    const uniqueId = getQueryParam('uniqueId'); // Get 'uniqueId' from the query string

    const fetchUserDetails = async () => {
        if (!uniqueId) {
            setError('Unique ID is required');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.get(`https://backend.shubadinam.com/admin/get-user-details`, {
                params: { uniqueId }
            });
            setUserDetails(response.data);
            setError(null);
        } catch (error) {
            console.error(error);
            setError('Failed to fetch user details');
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (uniqueId) {
            fetchUserDetails();
        }
    }, [uniqueId]); // Fetch details when uniqueId changes


    const renderUserDetails = () => {
        if (loading) {
            return <CircularProgress sx={{ marginTop: 2 }} />;
        }
        if (error) {
            return <div>Something went wrong</div>;
        }
        const isAllArraysEmpty = () => {
            if (!userDetails) return true;  // Check if userDetails is null or undefined

            const { bookings, premium, relatives, userProfile } = userDetails;

            return bookings.length === 0 && premium.length === 0 && relatives.length === 0 && userProfile.length === 0;
        };

        if (isAllArraysEmpty()) {
            return <div>No user data available</div>;
        }


        return (
            <Box>
                <Typography variant="h5" align='center' gutterBottom color='primary' fontWeight={700}>User Details</Typography>
                <Grid container spacing={0} sx={{ bgcolor: "#f8f8f8" }}>
                    {/* User Credentials */}
                    <Grid item xs={12} md={6} >
                        < Card sx={{ boxShadow: 0, }}>
                            <CardContent>
                                <Typography variant="h6" sx={{ bgcolor: "#f8f8f8" }} color='secondary' gutterBottom>User Credentials</Typography>
                                <Table sx={{ width: '100%', borderRadius: 4 }}>
                                    <TableBody>
                                        {/* Profile Progress Row */}
                                        <TableRow>
                                            <TableCell><strong>Profile Completion</strong></TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={`${userDetails.userProfile[0]?.profile_percent}%`}
                                                    size="small"
                                                    sx={{
                                                        fontWeight: "bold",
                                                        backgroundColor: userDetails.userProfile[0]?.profile_percent === "100" ? "green" : "orange",
                                                        color: "white",
                                                        padding: "5px 10px",
                                                        fontSize: '0.875rem',
                                                        borderRadius: '16px',
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        {/* Username Row */}
                                        <TableRow>
                                            <TableCell><strong>Name</strong></TableCell>
                                            <TableCell>{userDetails.userProfile[0]?.userName || "N/A"}</TableCell>
                                        </TableRow>

                                        {/* Email Row */}
                                        <TableRow>
                                            <TableCell><strong>Email</strong></TableCell>
                                            <TableCell>{userDetails.userProfile[0]?.userEmail || "N/A"}</TableCell>
                                        </TableRow>

                                        {/* Phone Row */}
                                        <TableRow>
                                            <TableCell><strong>Phone</strong></TableCell>
                                            <TableCell>{userDetails.userProfile[0]?.userPhone || "N/A"}</TableCell>
                                        </TableRow>

                                        {/* Location Row */}
                                        <TableRow>
                                            <TableCell><strong>Location</strong></TableCell>
                                            <TableCell>
                                                {userDetails.userProfile[0]?.userCountry} / {userDetails.userProfile[0]?.userState}
                                            </TableCell>
                                        </TableRow>

                                        {/* Type Row */}
                                        <TableRow>
                                            <TableCell><strong>Type</strong></TableCell>
                                            <TableCell sx={{ bgcolor: "#fafafa", fontStyle: "italic" }}>
                                                {userDetails.userProfile[0]?.userType || "N/A"}
                                            </TableCell>
                                        </TableRow>

                                        {/* ID Row */}
                                        <TableRow>
                                            <TableCell><strong>ID</strong></TableCell>
                                            <TableCell>{userDetails.userProfile[0]?.uniqueId || "N/A"}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>

                            </CardContent>
                        </Card>
                    </Grid>

                    {/* User Data */}
                    <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: 0 }}>
                            <CardContent>
                                <Typography variant="h6" color='secondary' gutterBottom sx={{ bgcolor: "#f8f8f8" }} >JanmaDinam Details</Typography>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><strong>Calendar</strong></TableCell>
                                            <TableCell>{userDetails.user.userCalender}</TableCell>
                                        </TableRow>                                        {userDetails.user.nakshatratithi && (
                                            <TableRow>
                                                <TableCell><strong>Nakshatra / Tithi</strong></TableCell>
                                                <TableCell>{userDetails.user.nakshatratithi}</TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell><strong>Month</strong></TableCell>
                                            <TableCell>{userDetails.user.userMonth}</TableCell>
                                        </TableRow>
                                        {userDetails.user.userNakshathra && userDetails.user.nakshatratithi === 'Nakshatra' && (
                                            <TableRow>
                                                <TableCell><strong>Nakshatra</strong></TableCell>
                                                <TableCell>{userDetails.user.userNakshathra}</TableCell>
                                            </TableRow>
                                        )}
                                        {userDetails.user.userTithi && userDetails.user.nakshatratithi === 'Tithi' && (
                                            <TableRow>
                                                <TableCell><strong>Tithi</strong></TableCell>
                                                <TableCell>{userDetails.user.userTithi}</TableCell>
                                            </TableRow>
                                        )}
                                        {userDetails.user.userPaksham?.trim() && (
                                            <TableRow>
                                                <TableCell><strong>Paksham</strong></TableCell>
                                                <TableCell>{userDetails.user.userPaksham}</TableCell>
                                            </TableRow>
                                        )}

                                        {userDetails.user.userDate && (
                                            <TableRow>
                                                <TableCell><strong>Birthdate</strong></TableCell>
                                                <TableCell>{userDetails.user.userDate}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Relatives */}
                    <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: 0 }}>
                            <CardContent>
                                <Typography variant="h6" color="secondary" sx={{ bgcolor: "#f8f8f8" }} gutterBottom>Relatives</Typography>
                                {userDetails.relatives.length > 0 ? (
                                    <TableContainer sx={{ maxHeight: 400, overflowY: 'auto' }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><strong>S.No</strong></TableCell>
                                                    <TableCell><strong>Relative Name</strong></TableCell>
                                                    <TableCell><strong>Relationship</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {userDetails.relatives.map((relative, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{relative.relName || "N/A"}</TableCell>
                                                        <TableCell>{relative.relRelation || "N/A"}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Typography sx={{ p: 3, border: `1px solid #dddd` }}>No relatives data available</Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>


                    {/* Premium */}
                    <Grid item xs={12} md={6} sx={{ mt: 3 }}>
                        <Card sx={{ boxShadow: 0 }}>
                            <CardContent>
                                <Typography variant="h6" color="secondary" sx={{ bgcolor: "#f8f8f8" }} gutterBottom>Premium</Typography>
                                {userDetails.premium.length > 0 ? (
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                        <Chip
                                            label="Premium Member"
                                            color="success"
                                            size="medium"
                                            sx={{
                                                fontWeight: 'bold',
                                                backgroundColor: 'green',
                                                color: 'white',
                                                padding: '6px 15px',
                                                borderRadius: '16px',
                                            }}
                                        />
                                        {userDetails.premium[0] && (
                                            <>
                                                {userDetails.premium[0].entry_time && (
                                                    <Typography variant="body5" color="textSecondary">
                                                        <strong>Purchase Time:</strong> {new Date(userDetails.premium[0]?.entry_time).toLocaleString("en-GB", { timeZone: "UTC", hour12: true, dateStyle: "short", timeStyle: "short" }) || "N/A"}
                                                    </Typography>
                                                )}
                                                {userDetails.premium[0].amount && (
                                                    <Typography variant="body5" color="textSecondary">
                                                        <strong>Amount:</strong> ₹{(userDetails.premium[0]?.amount) / 100 || "N/A"}/-
                                                    </Typography>
                                                )}
                                                {userDetails.premium[0].pay_method && (
                                                    <Typography variant="body5" color="textSecondary">
                                                        <strong>Payment Method:</strong> {userDetails.premium[0]?.pay_method || "N/A"}
                                                    </Typography>
                                                )}
                                                {userDetails.premium[0].currency && (
                                                    <Typography variant="body5" color="textSecondary">
                                                        <strong>Order Id:</strong> {userDetails.premium[0]?.orderId || "N/A"}
                                                    </Typography>
                                                )}
                                                {userDetails.premium[0].pay_status && (
                                                    <Typography variant="body5" color="textSecondary">
                                                        <strong>Payment Status:</strong> {userDetails.premium[0]?.pay_status || "N/A"}
                                                    </Typography>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                ) : (
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                        <Chip
                                            label="No Premium Purchased"
                                            color="default"
                                            size="medium"
                                            sx={{
                                                fontWeight: 'bold',
                                                backgroundColor: 'gray',
                                                color: 'white',
                                                padding: '6px 15px',
                                                borderRadius: '16px',
                                            }}
                                        />
                                        <Typography variant="body2" color="textSecondary">
                                            The user hasn't purchased premium yet.
                                        </Typography>
                                    </Box>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>



                    {/* Bookings */}
                    <Grid item xs={12} md={12}>
                        <Card sx={{ boxShadow: 0 }}>
                            <CardContent>
                                <Typography variant="h6" sx={{ bgcolor: "#f8f8f8" }} gutterBottom>Bookings</Typography>
                                {userDetails.bookings.length > 0 ? (
                                    <TableContainer sx={{ maxHeight: 400, overflowY: 'auto' }}>
                                        <Table >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><strong>S.No</strong></TableCell>
                                                    <TableCell><strong>Homam Name</strong></TableCell>
                                                    <TableCell><strong>Payment</strong></TableCell>
                                                    <TableCell><strong>in the name of </strong></TableCell>
                                                    <TableCell><strong>Date</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {userDetails.bookings.map((booking, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{booking.homamName || "N/A"}</TableCell>
                                                        <TableCell>
                                                            <Chip
                                                                label={booking.payment}
                                                                color="success"
                                                                size="medium"
                                                                sx={{
                                                                    fontWeight: 'bold',
                                                                    backgroundColor: booking.payment === 'paid' ? 'green' : 'orange',
                                                                    color: 'white',
                                                                    padding: '6px 15px',
                                                                    borderRadius: '16px',
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>{(booking.customersName)}</TableCell>
                                                        <TableCell>
                                                            {new Date(booking.entry_time).toLocaleString("en-GB", { timeZone: "UTC", hour12: true, dateStyle: "short", timeStyle: "short" }) || "N/A"}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Typography>No bookings available</Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid >
            </Box >
        );
    };


    return (
        <Box sx={{ p: 3 }}>
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

            {renderUserDetails()}
        </Box>
    );
};

export default PerCustomerDetails;
