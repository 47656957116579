import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Box, Button, TextField, Typography } from '@mui/material';
import {
    Grid,
    IconButton,
    InputAdornment,
} from "@mui/material";
import { AccountCircle, Lock, Visibility, VisibilityOff } from "@mui/icons-material";

const AdminAccess = () => {

    const navigate = useNavigate()


    const [adminDetails, SetAdminDetails] = useState({
        adminUserId: "",
        adminPassword: ""
    })
    const [showPassword, setShowPassword] = useState(false);

    const inputHandle = (event) => {
        SetAdminDetails({ ...adminDetails, [event.target.name]: event.target.value })
    }

    const handleEnterKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent default behavior (e.g., form submission)
            event.stopPropagation(); // Stop event propagation

            clickSubmit();
        }
    };

    const clickClose = () => {
        navigate
            ("/")
    }


    const clickSubmit = () => {
        axios.post("https://backend.shubadinam.com/admin/login", {
            adminUserId: adminDetails.adminUserId,
            adminPassword: adminDetails.adminPassword
        })
            .then((response) => {
                const { token } = response.data;
                sessionStorage.setItem("token", token);
                navigate("/admin-panel");
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Authorization Failed',
                    text: error.response.data.message || "Invalid credentials",
                    icon: 'error'
                });
            });
    };

    // INSERT INTO admintable (adminUserId, adminPassword) VALUES ('sd001', '$2b$10$o4nfRSxy4RJovILY.O3UA.m\O8ZVxmi3w9u0hejn6vf4Y5O1CvgMu');


    return (


        <Grid
            container
            sx={{
                width: "100%",
                height: "100vh",
                bgcolor: "white",
                overflow: "hidden",
                p: 2
            }}
        >
            {/* Left Side - Image */}
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "#e3f2fd", // Light blue background
                    overflow: "hidden",
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 20,
                }}
            >
                <img
                    src="https://img.freepik.com/free-photo/view-boat-water-night_23-2150693532.jpg?uid=R133306793&ga=GA1.1.1773690977.1730112906&semt=ais_hybrid"
                    alt="Admin Login"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}
                />
            </Grid>

            {/* Right Side - Login Form */}
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    bgcolor: "#f8f8f8",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    p: 4,
                    textAlign: "center",
                }}
            >
                {/* Admin Login Title */}
                <Typography variant="h5" fontWeight={700} color="primary" gutterBottom>
                    Admin Login
                </Typography>

                {/* Admin ID Input */}
                <TextField
                    fullWidth
                    label="Admin ID"
                    name="adminUserId"
                    variant="outlined"
                    placeholder="Enter your Admin ID"
                    margin="normal"
                    onChange={inputHandle}
                    onKeyPress={handleEnterKey}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": { borderRadius: "10px" }, maxWidth: 300,
                    }}
                />

                {/* Password Input */}
                <TextField
                    fullWidth
                    label="Password"
                    name="adminPassword"
                    variant="outlined"
                    placeholder="Enter your Password"
                    type={showPassword ? "text" : "password"}
                    margin="normal"
                    onChange={inputHandle}
                    onKeyPress={handleEnterKey}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": { borderRadius: "10px" }, maxWidth: 300,
                    }} />

                {/* Submit Button */}
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, p: 1.5, fontWeight: "bold", borderRadius: "10px", maxWidth: 300, }}
                    onClick={clickSubmit}
                >
                    Login
                </Button>

                {/* Back Button */}
                <Button
                    fullWidth
                    variant="outlined"
                    sx={{
                        mt: 2,
                        p: 1.5,
                        fontWeight: "bold",
                        borderRadius: "10px",
                        "&:hover": { backgroundColor: "#fff2f2", borderColor: "red", },
                        maxWidth: 300,
                    }}
                    onClick={clickClose}
                >
                    Back to Home
                </Button>
            </Grid>
        </Grid>


    );
};

export default AdminAccess;