import React, { useState } from 'react';
import SDadminHeader from './SDadminHeader';
import Top20px from '../screens/Top20px';
import axios from "axios";
import { Button, Grid, TextField, Typography, Card, CardContent, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FilterList } from '@mui/icons-material';
import Search from '@mui/icons-material/Search';
import CustomerDetails from './CustomerDetails';

const ManageCustomer = () => {

    const [showDetails, setShowDetails] = useState(false);
    const [dynamicState, setdynamicState] = useState({
        // oldPwd: "",
        userDetail: "",
        searchType: "",
    });

    const handleChange = (event) => {
        setdynamicState({ ...dynamicState, [event.target.name]: event.target.value })
    };

    const handleClear = (event) => {
        setdynamicState({ ...dynamicState, [event.target.name]: "" })
        window.location.reload();
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setShowDetails(false)

        if (!dynamicState.userDetail.trim() || !dynamicState.searchType) {
            alert("Please enter user details and select a search type.");
            return;
        }

        const url = `https://backend.shubadinam.com/admin/getuserdetails?userDetail=${dynamicState.userDetail}&searchType=${dynamicState.searchType}`;

        axios.get(url)
            .then((response) => {
                // console.log(response.data);

                if (
                    response.data.userEmailResult.length === 0 &&
                    response.data.userProfileResult.length === 0 &&
                    response.data.userRelResult.length === 0 &&
                    response.data.premiumUserResult.length === 0 &&
                    response.data.premTabResult.length === 0
                ) {
                    setShowDetails(false)
                    alert('No matches found in database !');
                    const element1 = document.getElementById("outlined-password-input");
                    if (element1) {
                        element1.value = '';
                    } else {
                        // console.log('Element with ID "outlined-password-input" not found.');
                    }
                    return;

                }
                setShowDetails(true)

                const userCredent = response.data.userEmailResult[0]
                const userProfile = response.data.userProfileResult[0]
                const userRel = response.data.userRelResult
                const userPremiumtable = response.data.premTabResult
                // const userPremiumuser = response.data.premiumUserResult[0]
                const userPremiumuser = response.data.premiumUserResult.filter(item => item.pay_status === 'captured');

                sessionStorage.setItem('userCredent', JSON.stringify(userCredent));
                sessionStorage.setItem('userProfile', JSON.stringify(userProfile));
                sessionStorage.setItem('userRel', JSON.stringify(userRel));
                sessionStorage.setItem('userPremiumtable', JSON.stringify(userPremiumtable));
                sessionStorage.setItem('userPremiumuser', JSON.stringify(userPremiumuser));

                // if (userCredent, userProfile) {
                //     window.location.href = '/admin-panel/manage-customers-details';
                // }


            })
            .catch((error) => {
                if (error.response && error.response.status === 500) {
                    alert("Invalid search type. Please try again!");
                } else {
                    alert("You are facing an unexpected issue. Please try again later!");
                }
            })
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh" flexDirection="column">
            <Card elevation={0} sx={{ width: "100%", maxWidth: 500, borderRadius: 3 }}>
                <CardContent>
                    <Typography variant="h5" mb={4} fontWeight="bold" align="center" gutterBottom>
                        Search User
                    </Typography>

                    <Grid container spacing={2} direction="column" alignItems="center">
                        {/* Input Field with Search Icon */}
                        <Grid item sx={{ width: "100%" }}>
                            <TextField
                                fullWidth
                                label="User Details"
                                type="text"
                                name="userDetail"
                                onChange={handleChange}
                                required
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search color="primary" />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    backgroundColor: "white",
                                    borderRadius: 2,
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": { borderColor: "#c7c7c7" },
                                        "&:hover fieldset": { borderColor: "#0c0036" },
                                        "&.Mui-focused fieldset": { borderColor: "#15005f" },
                                    },
                                }}
                            />
                        </Grid>

                        {/* Select Dropdown with Filter Icon */}
                        <Grid item sx={{ width: "100%" }}>
                            <Select
                                fullWidth
                                value={dynamicState.searchType}
                                name="searchType"
                                onChange={handleChange}
                                required
                                displayEmpty
                                variant="outlined"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <FilterList color="primary" />
                                    </InputAdornment>
                                }
                                sx={{
                                    backgroundColor: "white",
                                    borderRadius: 2,
                                    "& .MuiOutlinedInput-notchedOutline": { borderColor: "#c7c7c7" },
                                    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#0c0036" },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "#15005f" },
                                }}
                            >
                                <MenuItem value="" disabled>Search Type</MenuItem>
                                <MenuItem value={10}>By Email</MenuItem>
                                <MenuItem value={20}>By ID</MenuItem>
                            </Select>
                        </Grid>

                        {/* Buttons */}
                        <Grid item sx={{ width: "100%", display: "flex", justifyContent: "space-between", mt: 2 }}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                sx={{ width: "48%" }}
                                onClick={handleClear}
                            >
                                Clear
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ width: "48%" }}
                                onClick={handleSearch}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>

            <Box sx={{ width: "100%" }}>
                {
                    showDetails && <CustomerDetails />
                }

            </Box>
        </Box>
    );
};

export default ManageCustomer;