import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Box, Chip } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const CustomerDetails = () => {

    const navigate = useNavigate();


    const [userEmailResult, setUserEmailResult] = useState([]);
    const [userProfileResult, setUserProfileResult] = useState([]);
    const [userRelResult, setUserRelResult] = useState([]);
    const [premiumResultTab, setPremiumResultTab] = useState([]);
    const [premiumResultUser, setPremiumResultUser] = useState([]);

    useEffect(() => {
        // Retrieve stored results from session storage
        const result1 = sessionStorage.getItem('userCredent');
        const result2 = sessionStorage.getItem('userProfile');
        const result3 = sessionStorage.getItem('userRel');
        const result4 = sessionStorage.getItem('userPremiumtable');
        const result5 = sessionStorage.getItem('userPremiumuser');

        if (result1) {
            try {
                setUserEmailResult(JSON.parse(result1));
            } catch (error) {
                console.error("Error parsing JSON for result1:", error);
                setUserEmailResult([]); // Set to an empty array if parsing fails
            }
        } else {
            setUserEmailResult([]); // Set to an empty array if result1 is undefined, null, or empty
        }

        // Parse and set user profile result
        if (result2) {
            try {
                setUserProfileResult(JSON.parse(result2));
            } catch (error) {
                console.error("Error parsing JSON for result2:", error);
                setUserProfileResult([]); // Set to an empty array if parsing fails
            }
        } else {
            setUserProfileResult([]); // Set to an empty array if result2 is undefined, null, or empty
        }

        // Similar checks for result3, result4, and result5...
        // Parse and set user relationship result
        if (result3) {
            try {
                setUserRelResult(JSON.parse(result3));
            } catch (error) {
                console.error("Error parsing JSON for result3:", error);
                setUserRelResult([]); // Set to an empty array if parsing fails
            }
        } else {
            setUserRelResult([]); // Set to an empty array if result3 is undefined, null, or empty
        }

        // Parse and set premium result table
        if (result4) {
            try {
                setPremiumResultTab(JSON.parse(result4));
            } catch (error) {
                console.error("Error parsing JSON for result4:", error);
                setPremiumResultTab([]); // Set to an empty array if parsing fails
            }
        } else {
            setPremiumResultTab([]); // Set to an empty array if result4 is undefined, null, or empty
        }

        // Parse and set premium result user
        if (result5) {
            try {
                setPremiumResultUser(JSON.parse(result5));
            } catch (error) {
                console.error("Error parsing JSON for result5:", error);
                setPremiumResultUser([]); // Set to an empty array if parsing fails
            }
        } else {
            setPremiumResultUser([]); // Set to an empty array if result5 is undefined, null, or empty
        }
    }, []);



    const formatData = (dateString) => {
        if (!dateString || typeof dateString !== 'string') {
            return ''; // Return empty string if dateString is undefined or not a string
        }

        const [datePart, timePart] = dateString.split('T');
        if (!datePart || !timePart) {
            return ''; // Return empty string if dateString does not contain a 'T' separator
        }

        const [year, month, day] = datePart.split('-');
        if (!year || !month || !day) {
            return ''; // Return empty string if any part of the date is missing
        }

        const [time, _] = timePart.split('.');
        if (!time) {
            return ''; // Return empty string if time part is missing
        }

        const [hours, minutes, _2] = time.split(':');
        if (!hours || !minutes) {
            return ''; // Return empty string if any part of the time is missing
        }

        return `${day}-${month}-${year} ${hours}:${minutes}`;
    };


    const handleOpenNewTab = (userEmailResult) => {
        // const queryParams = new URLSearchParams({
        //     userEmailResult: JSON.stringify(userEmailResult),
        //     userProfileResult: JSON.stringify(userProfileResult),
        //     userRelResult: userRelResult.length,
        //     // premiumResultTab: JSON.stringify(premiumResultTab),
        //     // premiumResultUser: JSON.stringify(premiumResultUser)
        // });


        window.open(`https://www.shubadinam.com/admin-panel/per-customer-detail/?uniqueId=${userEmailResult.uniqueId}`, '_blank');


        // const url = `/admin-panel/per-customer-detail?${queryParams.toString()}`;
        // window.open(url, '_blank');
    };




    return (
        <Box sx={{ mt: 4 }}>
            <Grid container spacing={3}>
                {/* Customer Info */}
                <Grid item xs={12} md={6}>
                    <Card elevation={0}>
                        <CardContent>
                            <Typography variant="h6" color='secondary' fontWeight="bold" gutterBottom>
                                Customer Information -
                                <Chip
                                    label={`${userEmailResult.profile_percent}%`}
                                    size="small"
                                    sx={{
                                        fontWeight: "bold",
                                        backgroundColor: userEmailResult.profile_percent === "100" ? "green" : "orange",
                                        color: "white",
                                        padding: "5px 10px"
                                    }}
                                />
                            </Typography>
                            <TableContainer elevation={0} component={Paper}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><strong>Name:</strong></TableCell>
                                            <TableCell>{userProfileResult.userName}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Email:</strong></TableCell>
                                            <TableCell>{userEmailResult.userEmail}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>ID:</strong></TableCell>
                                            <TableCell>{userEmailResult.uniqueId}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Phone:</strong></TableCell>
                                            <TableCell>+{userEmailResult.countryCode} {userEmailResult.userPhone}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Gender:</strong></TableCell>
                                            <TableCell>{userProfileResult.userGender}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Location:</strong></TableCell>
                                            <TableCell>{userEmailResult.userState} / {userEmailResult.userCountry}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Entry Details */}
                <Grid item xs={12} md={6}>
                    <Card elevation={0}>
                        <CardContent>
                            <Typography variant="h6" color='secondary' fontWeight="bold" gutterBottom>
                                Entry Details
                            </Typography>
                            <TableContainer elevation={0} component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>Entry Date</strong></TableCell>
                                            <TableCell><strong>No. of People Added</strong></TableCell>
                                            <TableCell><strong>Action</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>{new Date(userEmailResult.entry_time).toLocaleString('en-GB', { dateStyle: "short", timeStyle: "short", hour12: true, timeZone: 'ASIA/KOLKATA' })}</TableCell>
                                            <TableCell>
                                                <Chip label={userRelResult.length} color="primary" />
                                            </TableCell>
                                            <TableCell>
                                                <NavLink onClick={() => handleOpenNewTab(userEmailResult)} style={{ textDecoration: "none", color: "blue" }}>
                                                    View Report
                                                </NavLink>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>

                    {/* Premium Purchased */}
                    <Card elevation={0}>
                        <CardContent>
                            <Typography variant="h6" color='secondary' fontWeight="bold" gutterBottom align="left">
                                Premium Purchased
                            </Typography>
                            <TableContainer elevation={0} component={Paper}>
                                <Table>
                                    <TableBody>

                                        {premiumResultUser && premiumResultUser.length > 0 ? (
                                            premiumResultUser.map((user, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center" sx={{ backgroundColor: "#f2f2f2", color: "black", }}>
                                                        <Box
                                                            sx={{
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <Typography variant="h6" fontWeight="bold" color="primary">
                                                                ₹{user.amount / 100}/-
                                                            </Typography>

                                                            <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1 }}>
                                                                {new Date(user.entry_time).toLocaleString("en-GB", {
                                                                    dateStyle: "short",
                                                                    timeZone: "UTC",
                                                                    timeStyle: "short",
                                                                    hour12: true,
                                                                })}
                                                            </Typography>

                                                            <Typography variant="body2" fontWeight="bold" sx={{ color: "#555" }}>
                                                                Order Id: <i>{user.orderId}</i>
                                                            </Typography>
                                                        </Box>

                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell align="center" sx={{ backgroundColor: "#f2f2f2", color: "black", fontWeight: "bold" }}>
                                                    Not Purchased
                                                </TableCell>
                                            </TableRow>
                                        )}


                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>


        </Box >
    );
};

export default CustomerDetails;