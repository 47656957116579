import React from "react";
import { List, ListItem, ListItemIcon, ListItemText, Divider, Button } from "@mui/material";
import { AccountCircle, CreditCard, Description, Lock, History, BookOnline, ChevronRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Logout from "@mui/icons-material/Logout";

const AccountOptions = () => {
    const navigate = useNavigate();

    const menuItems = [
        { icon: <AccountCircle />, text: "Personal Info", path: "/my-account/personal-info" },
        { icon: <History />, text: "Payment History", path: "/homam-bookings-history" },
        { icon: <BookOnline />, text: "Bookings", path: "/homam-bookings" },
    ];

    return (
        <List sx={{ mt: 1, borderRadius: 2, boxShadow: 0, p: { xs: 0, md: 2 } }}>
            {menuItems.map(({ icon, text, path }, index) => (
                <React.Fragment key={index}>
                    <ListItem button onClick={() => navigate(path)}>
                        <ListItemIcon sx={{ color: "gray" }}>{icon}</ListItemIcon>
                        <ListItemText primary={text} sx={{ color: "gray", fontWeight: "bold" }} />
                        <ChevronRight sx={{ color: "gray" }} />
                    </ListItem>
                    {index !== menuItems.length - 1 && <Divider />}
                </React.Fragment>
            ))}

            <Divider sx={{ border: "1px solid #ddd", m: 2 }} />

            <ListItem onClick={() => {
                localStorage.clear();
                navigate("/")
            }} sx={{ justifyContent: "end" }}>
                <Button startIcon={<Logout />} size="small" variant="outlined" color="secondary" sx={{ textTransform: "none" }}>
                    Log Out
                </Button>
            </ListItem>
        </List>
    );
};

export default AccountOptions;
