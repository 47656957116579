// import React, { useState } from "react";
// import {
//   Container,
//   Typography,
//   TextField,
//   MenuItem,
//   Button,
//   Grid,
//   Paper,
//   IconButton,
// } from "@mui/material";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import DeleteIcon from "@mui/icons-material/Delete";

// const AdminBannersUpload = () => {
//   const [selectedPage, setSelectedPage] = useState("");
//   const [desktopBanner, setDesktopBanner] = useState(null);
//   const [mobileBanner, setMobileBanner] = useState(null);
//   const [uploaded, setUploaded] = useState(false);

//   const pages = ["Homepage", "About Us", "Services", "Contact Us"];

//   const handleFileChange = (event, type) => {
//     const file = event.target.files[0];
//     if (type === "desktop") setDesktopBanner(file);
//     if (type === "mobile") setMobileBanner(file);
//   };

//   const handleRemoveFile = (type) => {
//     if (type === "desktop") setDesktopBanner(null);
//     if (type === "mobile") setMobileBanner(null);
//   };

//   const handleUpload = () => {
//     if (!selectedPage || !desktopBanner || !mobileBanner) {
//       alert("Please select a page and upload both banners.");
//       return;
//     }
//     console.log("Uploading banners for:", selectedPage);
//     setUploaded(true);
//   };

//   return (
//     <Container maxWidth="md" sx={{ mt: 5, bgcolor: "aliceblue" }}>
//       <Paper
//         sx={{
//           p: 5,
//           borderRadius: 5,
//           background: "#ffffff",
//         }}
//       >
//         <Typography variant="h4" fontWeight="bold" textAlign="center" gutterBottom>
//           Upload Website Banners 🚀
//         </Typography>

//         <TextField
//           select
//           label="Select Page"
//           value={selectedPage}
//           onChange={(e) => setSelectedPage(e.target.value)}
//           fullWidth
//           sx={{ mb: 3 }}
//         >
//           {pages.map((page) => (
//             <MenuItem key={page} value={page}>
//               {page}
//             </MenuItem>
//           ))}
//         </TextField>

//         <Grid container spacing={3}>
//           {["desktop", "mobile"].map((type) => (
//             <Grid item xs={12} md={6} key={type}>
//               <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
//                 {type.charAt(0).toUpperCase() + type.slice(1)} Banner
//               </Typography>
//               <Paper
//                 sx={{
//                   p: 2,
//                   textAlign: "center",
//                   border: "2px dashed #ccc",
//                   borderRadius: 2,
//                   backgroundColor: "#f9f9f9",
//                   position: "relative",
//                 }}
//               >
//                 {type === "desktop" && desktopBanner ? (
//                   <img
//                     src={URL.createObjectURL(desktopBanner)}
//                     alt="Desktop Preview"
//                     style={{ width: "100%", maxHeight: "150px", borderRadius: 4 }}
//                   />
//                 ) : type === "mobile" && mobileBanner ? (
//                   <img
//                     src={URL.createObjectURL(mobileBanner)}
//                     alt="Mobile Preview"
//                     style={{ width: "100%", maxHeight: "150px", borderRadius: 4 }}
//                   />
//                 ) : (
//                   <Typography variant="body2" color="text.secondary">
//                     No file selected
//                   </Typography>
//                 )}
//                 <Button
//                   variant="contained"
//                   component="label"
//                   startIcon={<CloudUploadIcon />}
//                   fullWidth
//                   sx={{ mt: 2 }}
//                 >
//                   Upload {type.charAt(0).toUpperCase() + type.slice(1)} Banner
//                   <input type="file" hidden accept="image/*" onChange={(e) => handleFileChange(e, type)} />
//                 </Button>
//                 {type === "desktop" && desktopBanner && (
//                   <IconButton sx={{ position: "absolute", top: 10, right: 10 }} onClick={() => handleRemoveFile(type)}>
//                     <DeleteIcon color="error" />
//                   </IconButton>
//                 )}
//                 {type === "mobile" && mobileBanner && (
//                   <IconButton sx={{ position: "absolute", top: 10, right: 10 }} onClick={() => handleRemoveFile(type)}>
//                     <DeleteIcon color="error" />
//                   </IconButton>
//                 )}
//               </Paper>
//             </Grid>
//           ))}
//         </Grid>

//         <Grid item xs={12} sx={{ placeSelf: "center" }}>
//           <Button
//             variant="contained"
//             sx={{ mt: 3, py: 1.5, fontWeight: "bold", bgcolor: "royalblue", }}
//             onClick={handleUpload}
//             startIcon={uploaded ? <CheckCircleIcon /> : null}
//           >
//             {uploaded ? "Banners Uploaded! ✅" : "Upload Now"}
//           </Button>
//         </Grid>
//       </Paper>

//     </Container>
//   );
// };

// export default AdminBannersUpload;

import React from 'react'

const RoughTwo = () => {
  return (
    <div>RoughTwo</div>
  )
}

export default RoughTwo