import React, { useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, CircularProgress, Alert } from '@mui/material';
import { useDropzone } from 'react-dropzone';

const TamilPanchangam = () => {
  const [file, setFile] = useState(null);
  const [parsedData, setParsedData] = useState([]);
  const [loading, setLoading] = useState(false); // To manage loading state
  const [successMessage, setSuccessMessage] = useState(''); // For success message

  // Handle file drop logic
  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const validMimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

    if (validMimeTypes.includes(file.type)) {
      handleFileChange(file);
    } else {
      console.error("Invalid file type. Please upload a valid Excel file.");
    }
  };

  // Initialize dropzone hook
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop, // Using handleDrop to check the MIME type
    accept: '.xlsx, .xls', // Only allow Excel files
  });

  // Handle file change from both dropzone and file input
  const handleFileChange = (selectedFile) => {
    if (!selectedFile) return;

    setFile(selectedFile);

    const reader = new FileReader();
    reader.onload = (event) => {
      const fileData = event.target.result;
      const wb = XLSX.read(fileData, { type: 'array', cellText: false, cellDates: true });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const parsedData = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, dateNF: 'yyyy-mm-dd' });

      // Check if parsed data is an array
      if (Array.isArray(parsedData)) {
        setParsedData(parsedData);
      } else {
        console.error('Failed to parse data');
      }
    };

    reader.readAsArrayBuffer(selectedFile); // Read the file as ArrayBuffer
  };

  // Handle data upload to backend
  const handleUpload = () => {
    setLoading(true); // Set loading state to true
    const URL = "https://backend.shubadinam.com/api/upload/tamil";
    axios.post(URL, { data: parsedData })
      .then((response) => {
        setLoading(false); // Set loading state to false on success
        setSuccessMessage('Data uploaded successfully!'); // Show success message
        setTimeout(() => {
          setSuccessMessage(''); // Hide success message after 3 seconds
        }, 3000);
      })
      .catch((error) => {
        setLoading(false); // Set loading state to false on error
        console.error('Error uploading data: ', error);
      });
  };

  // Get the current path after /admin-panel/
  const currentPath = window.location.pathname;
  const pathAfterAdminPage = currentPath.includes('/admin-panel/')
    ? currentPath.split('/admin-panel/')[1]
    : 'Default Path'; // Fallback if '/admin-panel/' is not found

  return (
    <Box sx={{ m: 3, p: 3, border: '1px solid #ccc', borderRadius: 2, backgroundColor: '#f9f9f9', boxShadow: 3 }}>
      <Typography variant="h5" sx={{ color: '#333', textTransform: 'capitalize', mb: 2 }}>
        {pathAfterAdminPage}
      </Typography>

      <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
        <TextField
          type="file"
          onChange={(event) => handleFileChange(event.target.files[0])} // Handle file change from input
          fullWidth
          variant="outlined"
          sx={{ my: 1, maxWidth: 400, p: 2 }}
        />
      </Box>

      <Box
        {...getRootProps()}
        sx={{
          p: 2,
          display: "flex", justifyContent: "center",
          border: '2px dashed #4CAF50',
          borderRadius: 2,
          textAlign: 'center',
          backgroundColor: isDragActive ? '#e0f7e9' : '#f9f9f9',
          color: isDragActive ? '#4CAF50' : '#555',
          mb: 2,
          cursor: 'pointer',
        }}
      >
        <input style={{ maxWidth: 400 }} {...getInputProps()} />
        {isDragActive ? (
          <Typography>Drop the files here...</Typography>
        ) : (
          <Typography>Drag & drop a file here, or click to select one</Typography>
        )}
      </Box>

      {successMessage && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {successMessage}
        </Alert>
      )}

      {/* Display total row count */}


      <Box sx={{ mb: 3, display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
        <Button
          onClick={handleUpload}
          variant="contained"
          color="success"
          sx={{
            py: 1,
            px: 3,
            fontWeight: 600,
            backgroundColor: '#4CAF50',
            '&:hover': { backgroundColor: '#45a049' },
          }}
        >
          {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Upload Data'}
        </Button>

        {parsedData.length > 0 && (
          <Typography variant="body1" sx={{ mb: 2 }}>
            Total Rows: {parsedData.length}
          </Typography>
        )}
      </Box>

      <TableContainer component={Paper} sx={{ mt: 3, height: 500, overflow: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Column 1</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Column 2</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Column 3</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Column 4</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parsedData.map((rowData, index) => (
              <TableRow key={index}>
                <TableCell>{rowData[0]}</TableCell>
                <TableCell>{rowData[1]}</TableCell>
                <TableCell>{rowData[2]}</TableCell>
                <TableCell>{rowData[3]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TamilPanchangam;
