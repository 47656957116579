import React, { useState, useEffect } from 'react';

import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import LandingHeader from '../components/LandingHeader';
import Top20px from '../screens/Top20px';
import 'react-calendar/dist/Calendar.css';
import { Box, Button } from '@mui/material';
import axios from 'axios';

import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SlidingHeader from '../components/SlidingHeader';


const HomamCheckout = () => {


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const name = queryParams.get('name') || '';
    const nakshatra = queryParams.get('nakshatra') || '';

    // Extract dynamicFields
    const dynamicFields = [];
    let i = 0;
    while (true) {
        const fieldName = queryParams.get(`dynamicField${i}Name`);
        const fieldNakshatra = queryParams.get(`dynamicField${i}Nakshatra`);
        if (fieldName === null || fieldNakshatra === null) break;
        dynamicFields.push({ name: fieldName, nakshatra: fieldNakshatra });
        i++;
    }

    // Extract other fields
    const count = queryParams.get('count') || 0;
    const serviceName = queryParams.get('serviceName') || '';
    const chosenDate = queryParams.get('chosenDate') || '';
    const amount = queryParams.get('amount') || '';
    const userType = queryParams.get('userType') || '';

    const [finalAmount, setFinalAmount] = useState(amount);

    useEffect(() => {
        if (userType === 'Premium' && count > 1) {
            setFinalAmount(amount - 150);
        } else {
            setFinalAmount(amount);
        }
    }, [userType, amount]);

    let localId;
    const localKeyId = localStorage.getItem("SD_Id" || "premiumVerified")

    if (localKeyId) {
        localId = localKeyId
    }







    const [orderId, setOrderId] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [qrCodeUrl, setQrCodeUrl] = useState('');

    const dynamicState = {
        name,
        nakshatra,
        dynamicFields,
        count,
        serviceName,
        chosenDate,
        finalAmount,
        localId,
        userType
    };


    const createOrder = async () => {

        try {
            const response = await fetch('https://backend.shubadinam.com/create-homam-order', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dynamicState),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            // console.log(data);
            if (data.order.id) {
                console.log(data);
                setOrderId(data.order.id);
                setTransactionId(data.order.receipt);
                // handlePayment(data.order.id, data.impId);   
                handlePayment(data.order.id, data.order.amount, data.impId);

                //  transactionId = data.receipt;

                // if (data.redirect) {
                //   window.location.href = data.redirect;
                // }
            } else {
                alert('Failed to create order');
            }
        } catch (error) {
            console.error('Error:', error.message);
            alert(error.message);
        }
    };

    const handlePayment = (orderId, backendAmount, impId) => {

        // const premiumUserName = dynamicState.userName;
        // const premiumUserPhone = dynamicState.userPhone;
        // const PremiumUserEmail = dynamicState.userEmail;
        // console.log(orderId, impId);
        if (orderId) {
            const options = {
                key: 'rzp_live_gp41aILMbhUm19', // Replace with your Razorpay key
                amount: backendAmount,
                currency: 'INR',
                order_id: orderId,
                name: 'Shubadinam',
                description: 'Shubadinam Transaction',
                // image: shubadinamLogo,
                prefill: {
                    // name: premiumUserName,
                    // email: PremiumUserEmail,
                    // contact: premiumUserPhone,
                },
                // redirect: true,
                // callback_url: "https://www.shubadinam.com/PremiumProfile",

                handler: function (response) {
                    console.log(response);

                    const url = `https://backend.shubadinam.com/razorpayresponse/homam`


                    // axios.post(url, {
                    //     paymentId: response.razorpay_payment_id, orderId: response.razorpay_order_id,
                    //     signatureId: response.razorpay_signature, dynamicState: dynamicState, impId: impId
                    // })
                    //     .then((response) => {

                    //         // const paymentId= response.razorpay_payment_id
                    //         console.log(response);

                    //     })
                    //     .catch((error) => {
                    //         console.log("Error redirecting : ", error);
                    //     })
                },
                notes: {
                    address: 'Razorpay Corporate Office',
                },
                theme: {
                    color: '#3399cc',
                },
                // method: {
                //     qr: {
                //         url: qrCodeUrl,
                //         format: 'png',
                //         label: 'Scan to Pay',
                //     }
                // }
                config: {
                    display: {
                        hide: [
                            { method: 'paylater' },
                            { method: 'emi' },
                            { method: 'wallet' },
                            { method: 'bank_transfer' },
                        ],
                        preferences: { show_default_blocks: true }
                    }
                },
                method: {
                    //     netbanking: true,
                    //     card: true,
                    //     upi: true,
                    //     wallet: false,
                    //     emi: false,
                    //     bank_transfer: false,
                    // qr: false, // Disable QR Code payment option
                    //     paylater: false // Disable Pay Later option
                }
            };

            const rzp1 = new window.Razorpay(options);

            rzp1.on('payment.success', (response) => {
                // console.log(response);
                // alert(`Payment Successful. Payment ID: ${response.razorpay_payment_id}`);
            });

            rzp1.on('payment.failed', function (response) {
                // alert(response.error.code);
                alert(response.error.description);
                // alert(response.error.source);
                // alert(response.error.step);
                // alert(response.error.reason);
                // alert(response.error.metadata.order_id);
                // alert(response.error.metadata.payment_id);
            })

            rzp1.on('payment.error', (response) => {
                // console.log(response);
                alert(`Payment Failed. Error: ${response.error.description}`);
            });

            rzp1.on('payment.closed', () => {
                console.warn('Payment modal closed by user');
                alert('Payment process was not completed. Please try again.');
            });


            rzp1.open();
        } else {
            alert('Order ID is missing. Please try again.');
        }
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            document.getElementById('rzp-button1').addEventListener('click', createOrder);
        };
    }, [orderId]);



    const combinedFields = [
        { sNo: 1, name: name, nakshatra: nakshatra },
        ...dynamicFields.map((field, index) => ({
            sNo: index + 2,
            name: field.name,
            nakshatra: field.nakshatra
        }))
    ];

    return (

        <div className='homam-container'>
            <SlidingHeader />
            <Top20px />
            <div className='homam-body'>
                <div className='homam-options'>
                    <Stack direction="row" width="100%" sx={{ padding: "5px" }}>
                        <h5 style={{ textAlign: "start", width: "100%", fontSize: "x-large" }}>{serviceName}</h5>
                    </Stack>

                    <div className='homam-details-options2'>

                        <div className='homam-detail-item'>
                            <strong className='homam-ph-details'>No. of Tickets</strong>
                            <h5 className='homam-ph-details1'>{count}</h5>
                        </div>
                        <div className='homam-detail-item'>
                            <strong className='homam-ph-details'>Homam Time</strong>
                            <h5 className='homam-ph-details1'>6 am</h5>
                        </div>
                        <div className='homam-detail-item'>
                            <strong className='homam-ph-details'>Homam Date</strong>
                            <h5 className='homam-ph-details1'>
                                {chosenDate ? new Date(chosenDate).toLocaleDateString('en-GB', { weekday: 'long', day: '2-digit', month: '2-digit', year: 'numeric' }) : 'Invalid Date'}
                            </h5>
                        </div>
                        <div className='homam-detail-item'>
                            <strong className='homam-ph-details'>Total Cost</strong>
                            <h5 className='homam-ph-details1'>&#8377; {finalAmount} /-</h5>
                        </div>
                    </div>
                </div>
                <br />
                <div className='homam-gen-details'>
                    <Stack direction="row" width="100%" sx={{ padding: "5px" }}>
                        <b style={{ textAlign: "start", width: "100%", }}>Homam Checkout</b>
                    </Stack>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <TableContainer sx={{ width: 350 }} component={Paper}>
                            <Table sx={{ width: 350 }} aria-label="simple table">
                                <TableHead >
                                    <TableRow>
                                        <TableCell sx={{ width: 70 }}>Person</TableCell>
                                        <TableCell align='center'>Name</TableCell>
                                        <TableCell align='center'>Nakshatra</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {combinedFields.map((field, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align='center'>{field.sNo}</TableCell >
                                            <TableCell align='center'>{field.name}</TableCell >
                                            <TableCell align='center'>{field.nakshatra}</TableCell >
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>
                    <br />
                </div>
                <div className='homam-details-continue'>

                    <br />
                    <Box sx={{ width: "100%", display: "flex", alignItems: "end", justifyContent: "end" }} >
                        <Button className='homam-continue-btns' sx={{ marginRight: "10px" }}>Back</Button>


                        <Button id="rzp-button1" className="homam-continue-btns homam-ctn-btn" sx={{ marginRight: "40px" }}
                        > Pay Now</Button>

                    </Box>




                </div>
            </div>
        </div>



    );
};

export default HomamCheckout;
