import React, { useRef } from 'react';
import { Box, Typography, Button, Grid, Paper, Avatar } from '@mui/material';
import { Rating } from '@mui/lab';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { ArrowBackIos, ArrowForwardIos, NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Link } from 'react-router-dom';



const reviews = [
    {
        name: 'Priyakumari',
        role: 'Software Engineer at Palantir',
        rating: 5,
        review: 'Shubadinam has transformed the way I celebrate my birthday. Embracing Indian traditions is giving a special feel on my birthday, sorry janmadin!',
        image: 'https://placeholder.co/150',
    },
    {
        name: 'Rahul',
        role: 'Web Developer at CodesCandy',
        rating: 5,
        review: 'I registered on Shubadinam last month for testing purpose. It was an eye-opening experience. The notification was very insightful. Highly recommend!',
        image: 'https://placeholder.co/150',
    },
    {
        name: 'Prashanth',
        role: 'Marketing Manager at EaseTemplate',
        rating: 5,
        review: 'It`s a joy and a satisfying feeling to celebrate birthdays the Indian way. Thanks to Shubadinam for making it easy!',
        image: 'https://placeholder.co/150',
    },
    {
        name: 'Ananya',
        role: 'Software Engineer at Palantir',
        rating: 5,
        review: `As someone deeply connected to my Indian roots, I found Shubadinam very useful and definitely recommend it's a must-visit site for anyone seeking to preserve our Hindu identity!`,
        image: 'https://placeholder.co/150',
    },
];

const SevalandingTestimonials = () => {

    const splideRef = useRef(null);

    const handlePrev = () => {
        splideRef.current.splide.go('<');
    };

    const handleNext = () => {
        splideRef.current.splide.go('>');
    };

    return (
        <Box sx={{ py: 6 }} >
            <Box sx={{ maxWidth: '7xl', mx: 'auto', px: { xs: 2, md: 4 }, sm: 6, lg: 8 }}>
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    <Typography
                        variant="h4"
                        align="center"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            color: '#2c3e50',
                            mb: 2,
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                        }}>
                        What our customers say
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2, color: '#4b5563' }}>
                        Don’t take our word for it. Hear what our lovely customers are saying.
                    </Typography>

                    <a href="/testimonials" style={{ textDecoration: "none", color: "white" }}>
                        <Button endIcon={<NavigateNext sx={{ fontSize: "12px" }} />} sx={{ mt: 4, px: 4, py: 2, backgroundColor: '#1b3e69', color: '#fff', borderRadius: 4, textTransform: "none", '&:hover': { backgroundColor: '#1b3e69' } }}>
                            View All Reviews
                        </Button>
                    </a>


                </Box>
                <Splide
                    ref={splideRef}
                    options={{
                        type: 'loop',
                        perPage: 3,
                        autoplay: true,
                        interval: 3000,
                        arrows: true,
                        gap: 10,
                        pauseOnHover: true,
                        arrows: false,
                        pagination: false,
                        breakpoints: {
                            3000: {
                                perPage: 4, // 2 slides per page for screens 1200px and below
                            },
                            1600: {
                                perPage: 3, // 2 slides per page for screens 1200px and below
                            },
                            1200: {
                                perPage: 2, // 1 slide per page for screens 800px and below
                            },
                            480: {
                                perPage: 1, // 1 slide per page for very small screens (480px and below)
                                arrows: false, // Hide arrows on small screens
                            },
                        },
                    }}
                    style={{ marginTop: '20px', padding: '20px !important' }}
                >
                    {reviews.map((review, index) => (
                        <SplideSlide key={index}>
                            <Paper sx={{
                                p: 6,
                                borderRadius: 4,
                                boxShadow: 3,
                                m: 2,
                                position: 'relative', // Needed for absolute positioning of quotes
                                overflow: 'hidden'
                            }}>
                                {/* Left Top Quotation */}
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: 10,
                                        left: 20,
                                        fontSize: 60,
                                        fontWeight: 'bold',
                                        color: '#d1d5db'
                                    }}
                                >
                                    “
                                </Typography>

                                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                                    <Avatar alt={review.name} src={review.image} sx={{ width: 64, height: 64 }} />
                                </Box>

                                <Typography variant="body1" sx={{ color: '#4b5563', textAlign: 'center', mb: 4 }}>
                                    {review.review}
                                </Typography>

                                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                                    <Rating value={review.rating} readOnly size="large" sx={{ color: '#f59e0b' }} />
                                    <Typography variant="body2" sx={{ color: '#1f2937', ml: 2 }}>
                                        {review.rating}
                                    </Typography>
                                </Box>

                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1f2937' }}>
                                        - {review.name}
                                    </Typography>
                                </Box>

                                {/* Right Bottom Quotation */}
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        bottom: 10,
                                        right: 20,
                                        fontSize: 60,
                                        fontWeight: 'bold',
                                        color: '#d1d5db'
                                    }}
                                >
                                    ”
                                </Typography>
                            </Paper>

                        </SplideSlide>
                    ))}
                </Splide>
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Button
                        onClick={handlePrev}
                        sx={{
                            backgroundColor: '#1b3e69',
                            color: '#fff',
                            borderRadius: 10,
                            mr: 1,
                            p: 2,
                            '&:hover': { backgroundColor: '#1b3e69' }
                        }}
                    >
                        <NavigateBefore />
                    </Button>
                    <Button
                        onClick={handleNext}
                        sx={{
                            backgroundColor: '#1b3e69',
                            color: '#fff',
                            borderRadius: 10,
                            ml: 1,
                            p: 2,
                            '&:hover': { backgroundColor: '#1b3e69' }
                        }}
                    >
                        <NavigateNext />
                    </Button>
                </Box>

            </Box>
        </Box>
    );
};

export default SevalandingTestimonials;
