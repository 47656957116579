import React, { useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, CircularProgress, Alert, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';

const UploadPanchangam = () => {
    const [file, setFile] = useState(null);
    const [parsedData, setParsedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [language, setLanguage] = useState(''); // Default language

    const handleDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const validMimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

        if (validMimeTypes.includes(file.type)) {
            handleFileChange(file);
        } else {
            console.error("Invalid file type. Please upload a valid Excel file.");
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop,
        accept: '.xlsx, .xls',
    });

    const handleFileChange = (selectedFile) => {
        if (!selectedFile) return;

        setFile(selectedFile);

        const reader = new FileReader();
        reader.onload = (event) => {
            const fileData = event.target.result;
            const wb = XLSX.read(fileData, { type: 'array', cellText: false, cellDates: true });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const parsedData = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, dateNF: 'yyyy-mm-dd' });

            if (Array.isArray(parsedData)) {
                setParsedData(parsedData);
            } else {
                console.error('Failed to parse data');
            }
        };

        reader.readAsArrayBuffer(selectedFile);
    };

    // Handle data upload to backend with dynamic language-based endpoint
    const handleUpload = () => {
        setLoading(true);
        const URL = `https://backend.shubadinam.com/api/upload/${language}`;
        axios.post(URL, { data: parsedData })
            .then((response) => {
                setLoading(false);
                setSuccessMessage(`${language} database uploaded successfully!`);
                setTimeout(() => {
                    setSuccessMessage(''); // Clear success message
                    setParsedData([]);     // Clear parsed data
                    setLanguage('');       // Reset language selection
                }, 3000);
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error uploading data: ', error);
            });
    };


    return (
        <Box sx={{ p: 3, border: '1px solid #ccc', borderRadius: 2, backgroundColor: '#f9f9f9', boxShadow: 0, display: "flex", justifyContent: "center", alignItems: "center", width: "100%", flexDirection: "column" }}>



            {
                !language.trim() ?
                    < Box sx={{ mb: 2, display: "flex", justifyContent: "end", flexDirection: "column", alignItems: "center" }}>
                        <Typography variant="h5" sx={{ color: '#333', textTransform: 'capitalize', mb: 2, maxWidth: 300, }}>
                            Upload Panchangam Type
                        </Typography>
                        <FormControl fullWidth sx={{ mb: 2, maxWidth: 300, }}>
                            <InputLabel>Select Language</InputLabel>
                            <Select
                                label="Select Language"
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                                variant="outlined"
                            >
                                <MenuItem value="tamil">Tamil</MenuItem>
                                <MenuItem value="telugu">Telugu</MenuItem>
                                <MenuItem value="kannadam">Kannada</MenuItem>
                                <MenuItem value="malayalam">Malayalam</MenuItem>
                                <MenuItem value="hindi">Hindi</MenuItem>
                                <MenuItem value="bengali">Bengali</MenuItem>
                                <MenuItem value="gujarati">Gujarati</MenuItem>
                                <MenuItem value="marathi">Marathi</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    :
                    <>
                        <Typography variant="h5" sx={{ color: '#333', textTransform: 'capitalize', mb: 2, maxWidth: 300, }}>
                            {language} Panchangam
                        </Typography>

                        <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
                            <TextField
                                type="file"
                                onChange={(event) => handleFileChange(event.target.files[0])}
                                fullWidth
                                variant="outlined"
                                sx={{ my: 1, maxWidth: 400, p: 2 }}
                            />
                        </Box>

                        <Box
                            {...getRootProps()}
                            sx={{
                                p: 2,
                                display: "flex", justifyContent: "center",
                                border: '2px dashed #4CAF50',
                                borderRadius: 2,
                                textAlign: 'center',
                                backgroundColor: isDragActive ? '#e0f7e9' : '#f9f9f9',
                                color: isDragActive ? '#4CAF50' : '#555',
                                mb: 2,
                                cursor: 'pointer',
                            }}
                        >
                            <input style={{ maxWidth: 400 }} {...getInputProps()} />
                            {isDragActive ? (
                                <Typography>Drop the files here...</Typography>
                            ) : (
                                <Typography>Drag & drop a file here, or click to select one</Typography>
                            )}
                        </Box>

                        {successMessage && (
                            <Alert severity="success" sx={{ mb: 2 }}>
                                {successMessage}
                            </Alert>
                        )}

                        <Box sx={{ mb: 3, display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                            <Button
                                onClick={handleUpload}
                                variant="contained"
                                color="success"
                                sx={{
                                    py: 1,
                                    px: 3,
                                    fontWeight: 600,
                                    backgroundColor: '#4CAF50',
                                    '&:hover': { backgroundColor: '#45a049' },
                                }}
                            >
                                {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Upload Data'}
                            </Button>

                            {parsedData.length > 0 && (
                                <Typography variant="body1" sx={{ mb: 2 }}>
                                    Total Rows: {parsedData.length}
                                </Typography>
                            )}
                        </Box>

                        <TableContainer component={Paper} sx={{ mt: 3, height: 500, overflow: "auto", maxWidth: 900 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Month</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Nakshatra</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {parsedData.map((rowData, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{rowData[0]}</TableCell>
                                            <TableCell>{rowData[1]}</TableCell>
                                            <TableCell>{rowData[2]}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </>
            }
        </Box >
    );
};

export default UploadPanchangam;
