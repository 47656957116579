import React from "react";
import { useEffect } from "react";
import FooterComponent from "../components/footer";
import { NavLink } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import ScrollToTop from "react-scroll-to-top";
import LandingHeader from "../components/LandingHeader";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Grid, Typography, List, ListItem, ListItemText } from "@mui/material";
import SlidingHeader from "../components/SlidingHeader";
import ReviewsFeedback from "../components/ReviewsFeedback";


const nakshatraData = [
    {
        nakshatra: 'Ashwini',
        likes: 'Adventure, speed, independence, and aiding others.',
        dislikes: 'Restrictions, monotony, and stagnation.'
    },
    {
        nakshatra: 'Bharani',
        likes: 'Creativity, self-expression, material comforts, and affection.',
        dislikes: 'Criticism and feeling unappreciated.'
    },
    {
        nakshatra: 'Krittika',
        likes: 'Competition, recognition, leadership roles, and loyalty.',
        dislikes: 'Dishonesty and being controlled.'
    },
    {
        nakshatra: 'Rohini',
        likes: 'Beauty, luxury, stability, and nurturing others.',
        dislikes: 'Chaos and unpredictability.'
    },
    {
        nakshatra: 'Mrigashira',
        likes: 'Exploration, intellectual pursuits, and spiritual topics.',
        dislikes: 'Clinginess and rigidity.'
    },
    {
        nakshatra: 'Ardra',
        likes: 'Intellectual debates, embracing change, and finding solutions.',
        dislikes: 'Superficiality and lack of depth.'
    },
    {
        nakshatra: 'Punarvasu',
        likes: 'Family, tradition, learning, and spiritual pursuits.',
        dislikes: 'Confrontation and instability.'
    },
    {
        nakshatra: 'Pushya',
        likes: 'Assisting others, spirituality, and comfort.',
        dislikes: 'Criticism and feeling unloved.'
    },
    {
        nakshatra: 'Ashlesha',
        likes: 'Loyalty, forging deep connections, and inner transformation.',
        dislikes: 'Betrayal and feeling controlled.'
    },
    {
        nakshatra: 'Magha',
        likes: 'Respect, authority, recognition, and generosity.',
        dislikes: 'Disrespect and feeling insignificant.'
    },
    {
        nakshatra: 'Purva Phalguni',
        likes: 'Romance, luxury, creativity, and celebrations.',
        dislikes: 'Harsh criticism and feeling unloved.'
    },
    {
        nakshatra: 'Uttara Phalguni',
        likes: 'Social gatherings, appreciation, and harmony.',
        dislikes: 'Conflict and feeling unvalued.'
    },
    {
        nakshatra: 'Hasta',
        likes: 'Creativity, precision, cleanliness, and organization.',
        dislikes: 'Disorder and feeling misunderstood.'
    },
    {
        nakshatra: 'Chitra',
        likes: 'Beauty, art, aesthetics, and innovation.',
        dislikes: 'Mediocrity and feeling ignored.'
    },
    {
        nakshatra: 'Swati',
        likes: 'Freedom, fairness, balance, and helping others.',
        dislikes: 'Injustice and feeling restricted.'
    },
    {
        nakshatra: 'Vishakha',
        likes: 'Achieving goals, social justice, and making a positive impact.',
        dislikes: 'Manipulation and dishonesty.'
    },
    {
        nakshatra: 'Anuradha',
        likes: 'Deep connections, loyalty, and spirituality.',
        dislikes: 'Superficiality and disloyalty.'
    },
    {
        nakshatra: 'Jyeshtha',
        likes: 'Power, self-improvement, and uncovering truths.',
        dislikes: 'Feeling controlled and stagnant.'
    },
    {
        nakshatra: 'Mula',
        likes: 'Independence, exploration, and seeking spiritual truths.',
        dislikes: 'Feeling restricted and superficiality.'
    },
    {
        nakshatra: 'Purva Ashadha',
        likes: 'Ambition, leadership, and self-improvement.',
        dislikes: 'Laziness and feeling directionless.'
    },
    {
        nakshatra: 'Uttara Ashadha',
        likes: 'Achievements, responsibility, and spiritual pursuits.',
        dislikes: 'Feeling underappreciated and aimless.'
    },
    {
        nakshatra: 'Shravana',
        likes: 'Learning, teaching, and self-development.',
        dislikes: 'Arrogance and ignorance.'
    },
    {
        nakshatra: 'Dhanishta',
        likes: 'Socializing, achieving goals, and being recognized.',
        dislikes: 'Isolation and feeling undervalued.'
    },
    {
        nakshatra: 'Shatabhisha',
        likes: 'Innovation, introspection, and seeking higher knowledge.',
        dislikes: 'Feeling restricted and conventional.'
    },
    {
        nakshatra: 'Purva Bhadrapada',
        likes: 'Depth, self-awareness, and spiritual growth.',
        dislikes: 'Feeling misunderstood and undervalued.'
    },
    {
        nakshatra: 'Uttara Bhadrapada',
        likes: 'Assisting others, selflessness, and spiritual pursuits.',
        dislikes: 'Selfishness and arrogance.'
    },
    {
        nakshatra: 'Revati',
        likes: 'Compassion, creativity, and spirituality.',
        dislikes: 'Cruelty and feeling ungrounded.'
    }
];



const BoxPage4 = () => {

    useEffect(() => {
        AOS.refresh();
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);


    return (

        <HelmetProvider>
            <Helmet>
                <title>Nakshatra Likes and Dislikes</title>
                <meta name="description" content="Nakshatra Likes and Dislikes - Janma Nakshatra/Tithi Birthday Reminder Service." />
            </Helmet>
            <SlidingHeader />
            <Container maxWidth="lg">
                <ScrollToTop smooth />

                <Box
                    mt={15}
                    sx={{
                        width: '100%',
                        height: '200px',
                        backgroundImage: `url(${require("../images/boxesImages/char1.jpg")})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: '8px'
                    }}
                    onContextMenu={(e) => e.preventDefault()} // Prevent right-click
                />



                <Grid container spacing={2} sx={{ mt: 2 }}>

                    <Grid item xs={12} md={8}>
                        <Box sx={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>

                            <Typography variant="h4" gutterBottom>Nakshatra Likes and Dislikes</Typography>
                            <Typography variant="body1" color="textSecondary" paragraph>
                                This is a general overview of some tendencies and preferences associated with each Nakshatra. Keep in mind that these are general traits and may not apply to every individual born under a particular Nakshatra. The position of other planets in the birth chart and personal experiences also influence an individual's likes and dislikes.
                            </Typography>
                            <Typography variant="h6" gutterBottom>Here's a concise description:</Typography>

                            <Box sx={{ padding: '20px', backgroundColor: '#f3f4f6' }}>
                                <Grid container spacing={4}>
                                    {nakshatraData.map((item, index) => (
                                        <Grid item key={index} xs={12} sm={6} md={6}>
                                            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', boxShadow: 3, borderRadius: 2 }}>
                                                <CardContent>
                                                    <Typography variant="h5" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
                                                        {item.nakshatra}
                                                    </Typography>
                                                    <Typography variant="body1" color="textSecondary">
                                                        <strong>Likes: </strong>{item.likes}
                                                    </Typography>
                                                    <Typography variant="body1" color="textSecondary" sx={{ marginTop: 2 }}>
                                                        <strong>Dislikes: </strong>{item.dislikes}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Box>
                        <ReviewsFeedback />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Card
                            sx={{
                                margin: '20px 0',
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                transition: '0.3s',
                                '&:hover': {
                                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                },

                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Related Searches
                                </Typography>
                                <List>
                                    {[
                                        { path: '/nakshatra-foods', label: 'Nakshatra Foods' },
                                        { path: '/nakshatra-characteristics', label: 'Nakshatra Characteristics' },
                                        { path: '/nakshatra-deities', label: 'Nakshatra Deities' },
                                        { path: '/nakshatra-likes-dislikes', label: 'Nakshatra Likes and Dislikes' },
                                        { path: '/tithi-foods', label: 'Tithi Foods' },
                                        { path: '/tithi-characteristics', label: 'Tithi Characteristics' },
                                        { path: '/tithi-deities', label: 'Tithi Deities' },
                                        { path: '/tithi-likes-dislikes', label: 'Tithi Likes and Dislikes' },
                                    ].map((item) => (
                                        <ListItem
                                            key={item.path}
                                            component={NavLink} // Make the ListItem act like a NavLink
                                            to={item.path} // Set the path for navigation
                                            sx={{
                                                padding: '10px 0',
                                                textDecoration: 'none', // Removes the underline from NavLink
                                                '&:hover': {
                                                    backgroundColor: '#f0f0f0',
                                                    cursor: 'pointer', // Changes the cursor on hover
                                                },
                                            }}
                                        >
                                            <ListItemText sx={{}} primary={item.label} />
                                        </ListItem>
                                    ))}
                                </List>

                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </Container>



            <FooterComponent />
        </HelmetProvider>

    )
}
export default BoxPage4;