import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import AdminSearch from '../adminUtils/AdminSearch';


import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
    useMediaQuery,
    Stack,
    Chip,
    Tooltip,
    Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ContentCopy } from '@mui/icons-material';



// Pagination Actions Component
function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

// Main Component
const TotalCustomers = () => {

    const theme = useTheme();

    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg')); // Detect if the screen is large


    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {

        const allUsers = () => {
            const url = `https://backend.shubadinam.com/admin/totalusers`;
            axios.get(url)
                .then((response) => {
                    const sortedUsers = response.data.sort((a, b) => {
                        // Sort descending based on entry_time (assuming entry_time is a valid date string)
                        return new Date(b.entry_time) - new Date(a.entry_time);
                    });
                    setUsers(sortedUsers);
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        allUsers();
    }, []);



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (query) => {
        setSearchQuery(query);
        setPage(0); // Reset page to 0 when search query changes
    };

    const filteredUsers = users.filter(user => {
        return (
            (user.userName && user.userName.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.uniqueId && user.uniqueId.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.userEmail && user.userEmail.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.userPhone && user.userPhone.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.entry_time && user.entry_time.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.userType && user.userType.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.userCountry && user.userCountry.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.userState && user.userState.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.profile_percent && user.profile_percent.toString().includes(searchQuery))  // Ensure profile_percent is also checked
        );
    });

    const [copied, setCopied] = useState(false);

    const handleCopy = (id) => {
        navigator.clipboard.writeText(id);
        setCopied(true);
        setTimeout(() => setCopied(false), 1500); // Reset after 1.5s
    };



    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;
    console.log(filteredUsers);  // Log filtered users to check their profile_percent

    return (
        <Box>

            <Typography variant='h6' sx={{ textAlign: "center", mt: 2 }}> All Users  </Typography>
            <Box sx={{ minWidth: 50, paddingLeft: "20px", display: "flex", flexDirection: { xs: "row", md: "collumn" }, justifyContent: "end" }}>

                <AdminSearch onSearch={handleSearch} />

            </Box>


            <TableContainer elevation={0} component={Paper} sx={{ mt: 5 }}>
                {isLargeScreen ? (
                    <Table size='medium' sx={{ minWidth: 500 }} >
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: 50, backgroundColor: "#eee", }}>S.No</TableCell>
                                <TableCell style={{ backgroundColor: "#eee", }} align="left">Name</TableCell>
                                <TableCell style={{ backgroundColor: "#eee", }} align="left">ID</TableCell>
                                <TableCell style={{ backgroundColor: "#eee", }} align="left">Email</TableCell>
                                <TableCell style={{ backgroundColor: "#eee", }} align="left">Phone </TableCell>
                                <TableCell style={{ backgroundColor: "#eee", }} align="left">Entry</TableCell>
                                <TableCell style={{ backgroundColor: "#eee", }} align="left">Country</TableCell>
                                <TableCell style={{ backgroundColor: "#eee", }} align="left">Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredUsers.length > 0
                                ? filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => (


                                    <TableRow
                                        key={index}
                                        onClick={() => {
                                            const uniqueId = user.uniqueId;
                                            window.open(`https://www.shubadinam.com/admin-panel/per-customer-detail/?uniqueId=${uniqueId}`, '_blank');
                                        }}
                                        sx={{ cursor: 'pointer' }} // Makes the row clickable
                                    >
                                        <TableCell component="th" scope="row">
                                            {index + 1 + page * rowsPerPage}
                                        </TableCell>
                                        <TableCell style={{ width: 160, overflow: "auto" }} align="left">
                                            {user.userName} {`\n`}
                                            <Chip
                                                label={`${user.profile_percent}%`}
                                                size="small"
                                                sx={{
                                                    fontWeight: "bold",
                                                    backgroundColor: user.profile_percent === "100" ? "green" : "orange",
                                                    color: "white",
                                                    padding: "5px 10px"
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell style={{ width: 160, overflow: "auto", }} align="left">
                                            {user.uniqueId || user.premiumId}
                                            <Tooltip title={copied ? "Copied!" : "Click to copy"} sx={{ display: "flex", flexDirection: "row" }}>
                                                <ContentCopy onClick={(e) => { e.stopPropagation(); handleCopy(user.uniqueId || user.premiumId); }} sx={{ fontSize: 16, marginLeft: 1 }} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell style={{ width: 160, overflow: "auto" }} align="left">
                                            {user.userEmail}
                                        </TableCell>
                                        <TableCell style={{ width: 160, overflow: "auto" }} align="left">
                                            {user.userPhone}
                                        </TableCell>
                                        <TableCell style={{ width: 160, overflow: "auto" }} align="left">
                                            {new Date(user.entry_time).toLocaleString('en-GB', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true,
                                                timeZone: "UTC"
                                            })}
                                        </TableCell>
                                        <TableCell style={{ width: 160, overflow: "auto" }} align="left">
                                            {user.userCountry}/{user.userState}
                                        </TableCell>
                                        <TableCell style={{ width: 160, overflow: "auto" }} align="left">
                                            {user.userType}
                                        </TableCell>
                                    </TableRow>
                                ))
                                : null
                            }
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={7} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={7}
                                    count={filteredUsers.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                ) : (
                    filteredUsers.length > 0 ? (
                        filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => (
                            <Accordion key={index} sx={{ py: 1, boxShadow: 0 }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}-content`}
                                    id={`panel${index}-header`}
                                    sx={{ color: "black" }}
                                >
                                    <Grid container direction="row" spacing={3} >
                                        <Grid item xs={8} >
                                            <Typography>{index + 1 + page * rowsPerPage}. {user.userName}</Typography>
                                        </Grid>
                                        <Grid item xs={4} >

                                            <Chip
                                                label={`${user.profile_percent}%`}
                                                size="small"
                                                sx={{
                                                    fontWeight: "bold",
                                                    backgroundColor: user.profile_percent === "100" ? "green" : "orange",
                                                    color: "white",
                                                    padding: "5px 10px"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Table aria-label="details table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ width: 160 }}>Entry</TableCell>
                                                <TableCell>{new Date(user.entry_time).toLocaleString('en-GB', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                    timeZone: "UTC"
                                                })}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: 160 }}>ID</TableCell>
                                                <TableCell>{user.uniqueId || user.premiumId}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: 160 }}>Email</TableCell>
                                                <TableCell>{user.userEmail}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: 160 }}>Phone </TableCell>
                                                <TableCell>{user.userPhone}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: 160 }}>Type</TableCell>
                                                <TableCell>{user.userType}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: 160 }}>Country/State</TableCell>
                                                <TableCell>{user.userCountry}/{user.userState}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: 160 }}>Entry</TableCell>
                                                <TableCell>{new Date(user.entry_time).toLocaleString('en-GB', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                    timeZone: "UTC"
                                                })}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={3}>
                                                    <Button sx={{ width: '100%' }} onClick={() => {
                                                        const uniqueId = user.uniqueId;
                                                        window.open(`https://www.shubadinam.com/admin-panel/per-customer-detail/?uniqueId=${uniqueId}`, '_blank');
                                                    }} variant='outlined'>View Details</Button>
                                                </TableCell>

                                            </TableRow>
                                        </TableBody>

                                    </Table>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    ) : (
                        <Typography>No users found</Typography>
                    )
                )}
                <TableFooter sx={{ display: "flex", justifyContent: "end" }}>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={7}
                            count={filteredUsers.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </TableContainer>

        </Box>
    );
}
export default TotalCustomers;
