import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

const AdminSearch = ({ onSearch }) => {
    const [query, setQuery] = useState('');

    const handleSearch = (e) => {
        const inputValue = e.target.value;
        setQuery(inputValue); // Update state with the input value
        onSearch(inputValue.trim()); // Call onSearch with the current query
    };

    return (
        <TextField
            size='small'
            type="text"
            value={query}
            onChange={handleSearch}
            placeholder="Search..."
            InputProps={{
                endAdornment: <PersonSearchIcon color='secondary' />
            }}
        />
    );
};

export default AdminSearch;
