import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import {
    Card, Typography, TextField, Box, InputAdornment, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Paper, useMediaQuery, Grid, TableFooter, Chip
} from "@mui/material";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from "chart.js";
import { Link, NavLink } from "react-router-dom";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const UserChart = () => {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const [days, setDays] = useState(30);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://backend.shubadinam.com/admin/user-count?days=${days}`);
                const data = response.data;

                setChartData({
                    labels: data.map(item => new Date(item.date).toLocaleDateString("en-GB")),
                    datasets: [
                        {
                            label: "User Registrations",
                            data: data.map(item => item.count),
                            borderColor: "#42a5f5",
                            backgroundColor: "rgba(66, 165, 245, 0.2)",
                            borderWidth: 2,
                            pointRadius: 5,
                            fill: true,
                            tension: 0.4,
                        }
                    ]
                });
            } catch (error) {
                console.error("Error fetching user count data:", error);
            }
        };
        fetchData();
    }, [days]);

    const isMobile = useMediaQuery("(max-width:600px)"); // Check if screen is small
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responses = await Promise.allSettled([
                    axios.get('https://backend.shubadinam.com/admin/get/users'),
                ]);
                responses.forEach((res, index) => {
                    if (res.status === "fulfilled") {
                        switch (index) {
                            case 0: setUsers(res.value.data); break;
                        }
                    } else {
                        console.error(`API ${index} failed:`, res.reason);
                    }
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <Grid container spacing={3} sx={{ mt: 3 }}>
            {/* Left Section: Chart */}
            <Grid item xs={12} lg={6}>

                <Card sx={{ p: 3, mt: 3, boxShadow: 0, borderRadius: 1, height: 500, backgroundColor: "#1e1e1e", color: "#fff", }}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", textAlign: "left" }}>
                        User Registrations (Last {days} Days)
                    </Typography>
                    <Box display="flex" justifyContent="center" mb={2} sx={{ width: 100, backgroundColor: "#eee", borderRadius: 2 }}>
                        <TextField
                            size="small"
                            type="number"
                            variant="outlined"
                            value={days}
                            onChange={(e) => {
                                const value = parseInt(e.target.value, 10);
                                if (value > 0) setDays(value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">days</InputAdornment>,
                            }}
                            sx={{ borderRadius: 1 }}
                        />
                    </Box>
                    <Box height={350}>
                        <Line
                            data={chartData}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    x: { grid: { display: false }, ticks: { color: "#fff" } },
                                    y: { grid: { color: "#444" }, ticks: { color: "#fff" }, beginAtZero: true }
                                },
                                plugins: {
                                    legend: { display: true, position: "top", labels: { color: "#fff" } },
                                    tooltip: { backgroundColor: "rgba(0,0,0,0.7)" }
                                }
                            }}
                        />
                    </Box>
                </Card >
            </Grid>

            {/* Right Section: Table */}
            <Grid item xs={12} lg={6}>
                <TableContainer elevation={0} component={Paper} sx={{
                    boxShadow: 0,
                    mt: 3,
                    height: 500,
                    overflow: "auto"
                }}>
                    <Table
                        size={isMobile ? "small" : "medium"}
                    >
                        <TableHead>
                            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                                <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Time</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Country</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Profile</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...users].reverse().map((user, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={{ overflowX: "auto" }}>{user.userName}</TableCell>
                                    <TableCell sx={{ overflowX: "auto" }}>
                                        {new Date(user.entry_time).toLocaleString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true,
                                            timeZone: "UTC"
                                        })}
                                    </TableCell>


                                    <TableCell sx={{ overflowX: "auto" }}>{user.userCountry}/{user.userState}</TableCell>
                                    <TableCell>
                                        <Chip
                                            size="small"
                                            label={`${user.profile_percent}%`}
                                            sx={{
                                                fontWeight: "bold",
                                                backgroundColor: user.profile_percent === '100' ? "green" : "orange",
                                                color: "white"
                                            }}
                                        />
                                    </TableCell>


                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                    {/* Fixed Footer */}
                    <Box
                        sx={{
                            backgroundColor: "#f5f5f5",
                            p: 1,
                            textAlign: "right",
                            position: "sticky",
                            bottom: 0,
                            left: { xs: '70%', md: '90%' },
                            width: "100%",
                            boxShadow: "0px -2px 5px rgba(0,0,0,0.1)"
                        }}
                    >
                        <Link component={NavLink} to="/admin-panel/total-customers">
                            View All Users &gt;&gt;
                        </Link>
                    </Box>
                </TableContainer>

            </Grid>
        </Grid>
    );
};

export default UserChart;